import { Component, OnInit } from '@angular/core';
import {AbstractPergunta} from "../../../../../pages/_cadastros/entitys/pergunta/abstract-pergunta";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import {OpcaoRespostaPerguntaEntity} from "../../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";

@Component({template:''})
export abstract class AbstractCardPerguntaComponent implements OnInit {

  pergunta!: AbstractPergunta;
  index: number = 0;

  protected constructor() { }

  ngOnInit(): void {
  }

  public checkOutros(){
    return this.pergunta.opcoes.findIndex(perg => perg.outros === true) === -1;
  }

  public onInputFocus(index: number) {
    let indexCard = this.index;

    setTimeout(function() {
      const element = document.getElementById(`opcaoResposta-${Number(indexCard)}-${index}`);
      if(element)
        element.focus();
    });
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (this.checkOutros()) {
      moveItemInArray(this.pergunta.opcoes, event.previousIndex, event.currentIndex);

    } else {
      const outros = this.pergunta.opcoes.findIndex(perg => perg.outros === true);

      if (outros === event.currentIndex) {
        Swal.fire({
          title: 'Não é possível substituir a opção "Outros..."',
          icon: 'error',
          timer: 10000
        })
      } else {
        moveItemInArray(this.pergunta.opcoes, event.previousIndex, event.currentIndex);
      }
    }
  }

  public addOpcaoResposta(index: number, opcao = ''){
    if (this.pergunta.opcoes.length === (index + 1)) {
      // this.pergunta.opcoes.push({opcao: opcao});
      this.pergunta.opcoes.push(<OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity());
    } else {
      this.pergunta.opcoes.splice(
        (index+1), 0,
        <OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity(undefined, opcao))
    }

    this.onInputFocus(index+1);
  }

  public addOpcaoRespostaKeyPress(ev: KeyboardEvent, index: number){
    if (ev.key === 'Enter') {
      if (this.pergunta.opcoes.length === (index + 1)) {
        this.addOpcaoResposta(index);
      } else if(this.pergunta.opcoes[(index + 1)].outros){
        this.addOpcaoResposta(index);
      }
    }
  }

  public colarOpcao(event: ClipboardEvent, index: number) {
    let pastedText = event.clipboardData?.getData('text');

    if (pastedText) {
      if (pastedText.indexOf("\r\n") > -1) {
        const listOptInsert = pastedText.split("\r\n");

        listOptInsert.map((opc, indexMap) => {
          if(opc.trim() !== ''){
            if (indexMap == 0) {
              this.pergunta.opcoes[index].opcao = opc.trim();
            } else {
              this.addOpcaoResposta((index + (indexMap)), opc.trim());
            }
          }
        });

        return false
      }
    }

    return true;
  }

  public alterOutros(index?: number) {
    if (index){
      this.pergunta.opcoes.splice(index, 1);
    } else {
      this.pergunta.opcoes.push(
        <OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity(undefined, 'Outros...', true)
      )
    }
  }

  public deleteOpcaoResposta(i: number): void {
    this.pergunta.opcoes.splice(i, 1);

    if (this.pergunta.opcoes.length === 0) {
      this.addOpcaoResposta(0);
      this.onInputFocus(0);
    } else if (this.pergunta.opcoes[0].outros) {
      this.addOpcaoResposta(-1);
    }
  }

  public initTipoResposta(tiposRespostas: string[], tipoEscolhido?: string | undefined) {
    if (tiposRespostas) {
      if (tiposRespostas.length > 0) {
        return !!tipoEscolhido ? tipoEscolhido : tiposRespostas[0];
      } else {
        return !!tipoEscolhido ? tipoEscolhido : "TEXT";
      }
    } else {
      return 'TEXT'
    }
  }

}
