import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {HttpService} from "../../../../services/http/http.service";
import {PerguntaPesquisaEntity} from "../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

@Component({
  selector: 'modal-busca-perguntas-pesquisa-score',
  templateUrl: './modal-busca-perguntas-pesquisa-score.component.html',
  styleUrls: ['./modal-busca-perguntas-pesquisa-score.component.scss']
})
export class ModalBuscaPerguntasPesquisaScoreComponent implements OnInit {
  @ViewChild('modal_busca_pergunta_score') modal!: ModalComponent;

  public idPesquisa: number | string = '';
  public listaPerguntasPesquisaModelo: PerguntaPesquisaEntity[] = [];
  public index: number = 0;
  public perguntaSelecionada: number | undefined;

  public selectList: PerguntaPesquisaEntity[] = [];

  @Output() save = new EventEmitter<{
    data: {},
    index: number
  }>();

  constructor(public http: HttpService) { }

  ngOnInit(): void {
  }

  open(){
    this.http.get('api/pergunta-pesquisa/pergunta-score/pesquisa/'+this.idPesquisa).subscribe(
      resp => {
        //@ts-ignore
        this.listaPerguntasPesquisaModelo = resp.data;

        // if (!!this.exibeSelecionada) {
        //   const index = this.listaPerguntasPesquisaModelo.findIndex(pergunta => pergunta.id === this.exibeSelecionada?.id);
        //
        //   if (index != -1) {
        //     // this.exibeSelecionada = this.listaPerguntasPesquisaModelo[index];
        //     this.listaPerguntasPesquisaModelo.splice(index, 1);
        //   }
        // }
      }
    );
    this.modal.open()
  }

  salvar(){
    this.save.emit({
      index: this.index,
      data: this.selectList
    });
  }

  close(save = false){

    if (save) {
      this.salvar();
      return;
    }

    this.modal.close();
  }

  changeSelect(pergunta: PerguntaPesquisaEntity) {
    if (this.selectList.findIndex(perg => perg.id === pergunta.id) === -1) {
      this.selectList.push(pergunta);
    } else {
      const index = this.selectList.findIndex(perg => perg.id === pergunta.id);
      this.selectList.splice(index, 1)
    }
  }

  checketInput(pergunta: any) {
    return (this.selectList.findIndex(perg => perg.id === pergunta) !== -1)
  }
}
