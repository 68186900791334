import { Injectable } from '@angular/core';
import {HttpService} from "../../../services/http/http.service";
import {RestReturn} from "../../../_core/config/rest-return";
import {HistoricoPesquisaEntity} from "../../_pesquisas/pages/historico-pesquisa/entity/historico-pesquisa-entity";
import {HistoricoPesquisaService} from "../../_pesquisas/pages/historico-pesquisa/service/historico-pesquisa.service";
import {Observable} from "rxjs";

export interface DadosPesquisa {
  status: 'CONCLUIDA' | 'EM_ANDAMENTO' | 'EM_CONSTRUCAO' | 'EM_ANALISE' | 'APROVADA' | 'REPROVADA',
  meta: string | number | null,
  qtdRespondido: number | string,
  quantColabAtivos: number | string,
  dataReferencia: string,
  grupos: string[],
  empresaNome: string
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpService,
    private service: HistoricoPesquisaService
  ) {}

  getLastPesq(): Observable<RestReturn<any>>{
    return this.http.get("api/dashboard/pesquisa/dados")
  }

  getPesquisa(id: number | string): Observable<RestReturn<DadosPesquisa[]>> {
    return this.http.get(
      `api/dashboard/pesquisa/dados?pesquisaAId=${id}`
    );
  }

  getPesquisaComparacao(data: {pesquisa: string | number, pesquisaComparacao: string | number}): Observable<RestReturn<DadosPesquisa[]>>{
    return this.http.get<RestReturn<any>>(
      `api/dashboard/pesquisa/dados?pesquisaAId=${data.pesquisa}&pesquisaBId=${data.pesquisaComparacao}`
    )
  }

  getGraph(id: number | string, perguntas?: string[], opcoes?: string[]): Observable<RestReturn<any>>{
    let stringPerguntas: string = '';
    let stringOpcoes: string = '';

    if (!!perguntas && !!opcoes) {
      if (Array.isArray(perguntas) && Array.isArray(opcoes)) {
        if (perguntas.length == opcoes.length) {

          perguntas.map((pergunta, indexOpcoes) => {
            stringPerguntas += pergunta + (indexOpcoes == pergunta.length ? '' : ',');
            stringOpcoes += opcoes[indexOpcoes] + (indexOpcoes == opcoes.length ? '' : ',');
          })
        }
      }

      return this.http.get<RestReturn<any>>(`api/dashboard/pesquisa/${id}?perguntas=${stringPerguntas}&opcoes=${stringOpcoes}`);

    }

    return this.http.get<RestReturn<any>>("api/dashboard/pesquisa/"+id);
  }

  getLastGraph(): Observable<RestReturn<any>>{
    return this.http.get("api/dashboard/pesquisa")
  }

  getComparaGraph(data: {
    pesquisa: string | number,
    pesquisaComparacao: string | number
  }, perguntas?: string[], opcoes?: string[]): Observable<RestReturn<any>>{
    let stringPerguntas: string = '';
    let stringOpcoes: string = '';

    if (!!perguntas && !!opcoes) {
      if (Array.isArray(perguntas) && Array.isArray(opcoes)) {
        if (perguntas.length == opcoes.length) {

          perguntas.map((pergunta, indexOpcoes) => {
            stringPerguntas += pergunta + (indexOpcoes == pergunta.length ? '' : ',');
            stringOpcoes += opcoes[indexOpcoes] + (indexOpcoes == opcoes.length ? '' : ',');
          })
        }
      }

      return this.http.get<RestReturn<any>>(
        `api/dashboard/pesquisa/comparar?pesquisaAId=${data.pesquisa}&pesquisaBId=${data.pesquisaComparacao}`+
        `&perguntas=${stringPerguntas}&opcoes=${stringOpcoes}`
      )

      // return this.http.get<RestReturn<any>>(`api/dashboard/pesquisa/${id}?perguntas=${stringPerguntas}&opcoes=${stringOpcoes}`);

    }

    return this.http.get<RestReturn<any>>(
      `api/dashboard/pesquisa/comparar?pesquisaAId=${data.pesquisa}&pesquisaBId=${data.pesquisaComparacao}`
    )
  }

}
