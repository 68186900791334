import { AbstractHttpService } from '@datagrupo/dg-crud';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfiguracoesEntity } from '../entity/configuracoes-entity';
import { Observable } from 'rxjs';
import { RestReturn } from 'src/app/_core/config/rest-return';
import {ConfigDgCrudService} from "../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class ConfiguracoesService extends AbstractHttpService<ConfiguracoesEntity>{

  constructor(
    http: HttpClient,
    actvateRoute: ActivatedRoute,
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl,'/api/sistema');

  }
}
