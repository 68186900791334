import {OpcaoPerguntaPesquisaEntity} from "../../../entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {AbstractPergunta} from "../../../entitys/pergunta/abstract-pergunta";
import {AbstractOpcaoRespostaEntity} from "../../../entitys/opcao-resposta/abstract-opcao-resposta-entity";

export class PerguntaPesquisaEntity extends AbstractPergunta {

  constructor(
    id?: number | string,
    perguntaOriginalId?: number | string,
    perguntaModeloId?: number | string,
    pergunta?: string,
    tipoResposta?: string,
    opcoes?: OpcaoPerguntaPesquisaEntity[],
    opcaoPerguntaPesquisaPredecessora?: AbstractOpcaoRespostaEntity,
    perguntaPesquisaPredecessora?: PerguntaPesquisaEntity,
    categoria?: string,
    status?: string[],
    podeEditar?: boolean,
    podeRemover?: boolean,
    perguntaObrigatoria?:  boolean,
    geraFiltro?: boolean,
    nomeFiltro?: string,
    grupo?: string,
    visibleByPredecessor?: boolean,
    fakeId?: number
  ) {
    super();
    this.id = id;
    this.perguntaOriginalId = perguntaOriginalId;
    this.perguntaModeloId = perguntaModeloId;
    this.pergunta = pergunta;
    this.tipoResposta = !!tipoResposta ? tipoResposta : '';
    this.opcoes = Array.isArray(opcoes) ? opcoes : [];
    this.categoria = categoria;
    this.status = !!status ? status : [];
    this.podeEditar = !!podeEditar;
    this.podeRemover = !!podeRemover;
    this.perguntaObrigatoria = !!perguntaObrigatoria;
    this.geraFiltro = typeof geraFiltro === "boolean" ? geraFiltro : false;
    this.nomeFiltro = !!nomeFiltro ? nomeFiltro : '';
    this.grupo = !!grupo ? grupo : '';
    this.opcaoPerguntaPesquisaPredecessora = opcaoPerguntaPesquisaPredecessora;
    this.perguntaPesquisaPredecessora = perguntaPesquisaPredecessora;
    this.visibleByPredecessor = true;
    this.fakeId = fakeId;
  }

  public id: number | string | undefined;
  public perguntaOriginalId: number | string | undefined;
  public perguntaModeloId: number | string | undefined;
  public pergunta: string | undefined;
  public tipoResposta: string | undefined;
  public opcoes: OpcaoPerguntaPesquisaEntity[] = [];
  public categoria: string | undefined;
  public status: string[] = [];
  public podeEditar: boolean | undefined;
  public podeRemover: boolean | undefined;
  public perguntaObrigatoria: boolean | undefined;
  public geraFiltro: boolean | undefined;
  public nomeFiltro: string | undefined;
  public grupo: string | undefined;
  public opcaoPerguntaPesquisaPredecessora: AbstractOpcaoRespostaEntity | undefined;
  public perguntaPesquisaPredecessora: PerguntaPesquisaEntity | undefined;
  public visibleByPredecessor: boolean;
  public fakeId: number | undefined
}
