import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'modal-settings-perguntas',
  templateUrl: './modal-settings-perguntas.component.html',
  styleUrls: ['./modal-settings-perguntas.component.scss']
})
export class ModalSettingsPerguntasComponent implements OnInit {

  @ViewChild('modal_settings_pergunta') modal!: ModalComponent;

  @Output() save = new EventEmitter<{
    data: {gerarFiltro: boolean, nome: string, grupo: string},
    index: number
  }>();

  public index: number = 0;

  public form: FormGroup = new FormGroup({
    geraFiltro: new FormControl(true),
    nomeFiltro: new FormControl('', [Validators.required]),
    grupo: new FormControl('')
  })

  constructor() { }

  ngOnInit(): void {
  }

  openModal(data?: {geraFiltro: boolean, nomeFiltro: string, grupo: string}){
    this.form.reset();

    if (!!data) {
      this.form.patchValue({
        ...data
      })
    }

    this.modal.open()
  }

  salvar(){
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.save.emit({
      index: this.index,
      data: this.form.value
    });
  }

  close(){
    this.modal.close();
  }
}
