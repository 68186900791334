<dg-modal title="" class="modal_busca_pergunta_score" size="md" #modal_busca_pergunta_score>
  <div dg-content>
    <table class="perguntas-table w-100">
      <tbody>
      <tr
        *ngFor="let pergunta of listaPerguntasPesquisaModelo; let i = index"
      >
        <td style="height: 90%" class="dt-pergunta">
          <label class="w-100 h-100" for="btn-pergunta-vincular-{{i}}">
            {{ pergunta.pergunta.pergunta }}
          </label>
        </td>
        <td style="height: 10%" class="dt-pergunta">
          <label for="btn-pergunta-vincular-{{i}}">
            <input
              [checked]="checketInput(pergunta.id)"
              (change)="changeSelect(pergunta)"
              type="checkbox"
              class="form-check-input dg-checkbox-as-radio lg danger"
              id="btn-pergunta-vincular-{{i}}"
              autocomplete="off"
            >
          </label>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="d-flex justify-content-center" dg-footer>
    <button class="dg-btn btn btn-danger m-1" (click)="close(true)">
      Fechar
    </button>
  </div>
</dg-modal>
