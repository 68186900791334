import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PerguntasEntity} from "../../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {TipoRespostaArray} from "../../../../../pages/_cadastros/enum/tipo-resposta";
import {AutocompleteComponent} from "angular-ng-autocomplete";
import {TipoRespostaService} from "../../../../../pages/_cadastros/pages/perguntas/services/tipo-resposta/tipo-resposta.service";
import {OpcaoRespostaService} from "../services/func-opcao-resposta/opcao-resposta.service";
import {InterfaceActionsFooter} from "../card-pergunta/card-pergunta.component";
import {OpcaoRespostaPerguntaEntity} from "../../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {AbstractCardPerguntaComponent} from "../abstract-card-pergunta/abstract-card-pergunta.component";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";

@Component({
  selector: 'app-card-pergunta-modelo',
  templateUrl: './card-pergunta-modelo.component.html',
  styleUrls: ['./card-pergunta-modelo.component.scss']
})
export class CardPerguntaModeloComponent extends AbstractCardPerguntaComponent implements OnInit {
  public form: FormGroup;
  public opcoesExibicao = '';
  public categoriaFocus = false;

  @Input() index: number = 0;
  @Input() perguntaModelo!: PerguntaPesquisaModeloEntity;
  @Input() pergunta: PerguntasEntity = <PerguntasEntity> new PerguntasEntity();
  @Input() dataAutoComplete: string[] = [];
  @Input() disabledInputCard: boolean = false;
  @Input() perguntaOpcao: { podeEditar: boolean, podeRemover: boolean, perguntaObrigatoria: boolean } = {
    podeEditar: false,
    podeRemover: false,
    perguntaObrigatoria: false
  }
  @Input() tipoResposta: string[] = [];
  public tipoRespostaFiltrado: {chave: string, valor: string}[] = TipoRespostaArray;

  // @Input() tipoResposta = TipoRespostaArray;

  @Output() change = new EventEmitter<{
    pergunta: PerguntasEntity,
    index: number,
    campo: string,
    opcaoPerguntaModelo: {podeEditar: boolean, podeRemover: boolean, perguntaObrigatoria: boolean}
  }>();
  @Output() excluir = new EventEmitter<number>();

  @ViewChild('autoCompleteModelo') autoComp: AutocompleteComponent | undefined;

  constructor(
    private formBuild: FormBuilder,
    protected tipoRespService: TipoRespostaService,
    public optionsServ: OpcaoRespostaService
  ) {
    super();
    this.form = this.formBuild.group({
      pergunta: ['', Validators.required],
      tipoResposta: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.form.get('tipoResposta')?.valueChanges.subscribe((tipoResp: string) => {
      this.opcoesExibicao = tipoResp;
      if (tipoResp === 'COMBOBOX' || tipoResp === 'CHECKBOX' || tipoResp === 'RADIOBUTTON' ) {
        if (this.pergunta.opcoes.length === 0) {
          // this.pergunta.opcoes.push({opcao: ''});
          this.pergunta.opcoes.push(<OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity());
        }
      }
    });

    let tipoResposta = this.initTipoResposta(this.tipoResposta, this.pergunta.tipoResposta);

    this.form.patchValue({
      pergunta: this.pergunta.pergunta,
      tipoResposta: tipoResposta,
    });

    this.changeValue('tipo_resposta')

    if (this.disabledInputCard) {
      this.form.disable()
    }

    this.optionsServ.pergunta = this.pergunta;
    this.optionsServ.index = this.index;

  }

  outputExcluir() {
    this.excluir.emit(this.index);
  }

  changeValue(campo: string){
    this.change.emit({
      pergunta: {
        ...this.pergunta,
        ...this.form.value
      },
      index: this.index,
      campo,
      opcaoPerguntaModelo: {
        podeEditar: !this.perguntaOpcao.podeEditar,
        podeRemover: !this.perguntaOpcao.podeRemover,
        perguntaObrigatoria: !this.perguntaOpcao.perguntaObrigatoria
      }
    })
  }

  @HostListener('window:card-perguntas-verificar')
  verificarPergunta(){
    this.form.markAllAsTouched();
  }

  disableOpcaoResposta(opcao: string): boolean {
    if (this.tipoResposta.length === 0) {
      return false;
    } else if (Array.isArray(this.tipoResposta)) {
      return this.tipoResposta.findIndex((tipoR) => tipoR === opcao) === -1
    }

    return true;
  }

  // metodo sensível por favor, não mexa
  onCategoriaFocus(value: any) {

    if (value.sourceCapabilities === null) {
      this.categoriaFocus = false;
      this.autoComp?.close();
    }

    this.categoriaFocus = !!value;

  }

  isperguntaPredecessora(): boolean {
    return !!this.perguntaModelo?.perguntaModeloPredecessora && !!this.perguntaModelo?.opcaoRespostaPredecessora
  }
}
