import {AbstractEntity} from '@datagrupo/dg-crud';
import {EmpresasEntity} from "../../empresas/entity/empresas-entity";
import {PesquisaModeloEntity} from "../../pesquisa-modelo/entity/pesquisa-modelo-entity";
import {TipoPesquisaEntity} from "../../tipo-pesquisa/entity/tipo-pesquisa-entity";
import {statusPesquisaCenso} from "../../../enum/status-pesquisa-censo";
import {PerguntaPesquisaEntity} from "./pergunta-pesquisa-entity";
import {PerguntaPesquisaModeloEntity} from "../../pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";


export class PesquisaEntity extends AbstractEntity {

  constructor(
    id?: number,
    titulo?: string,
    dataCriacao?: string,
    dataFim?: string,
    dataInicio?: string,
    descricao?: string,
    emailsRespondentes?: string,
    empresa?: EmpresasEntity,
    pesquisaAnonima?: boolean,
    pesquisaModelo?: PesquisaModeloEntity,
    perguntaPesquisa?: PerguntaPesquisaEntity[],
    perguntas?: PerguntaPesquisaModeloEntity[],
    quantColabAtivos?: number,
    status?: statusPesquisaCenso,
    tipoPesquisa?: TipoPesquisaEntity,
    possuoDados?: boolean,
    restringirRespondentesListaEmail?: boolean,
    restringirRespondentesUsuariosEmpresa?: boolean,
    identificador?: string | number
  ) {
    super();
    this.id = id;
    this.titulo = titulo;
    this.dataCriacao = dataCriacao;
    this.dataFim = dataFim;
    this.dataInicio = dataInicio;
    this.descricao = descricao;
    this.emailsRespondentes = emailsRespondentes;
    this.empresa = empresa;
    this.pesquisaAnonima = !! pesquisaAnonima ? pesquisaAnonima : false;
    this.pesquisaModelo = !!pesquisaModelo ? pesquisaModelo : <PesquisaModeloEntity> new PesquisaModeloEntity();
    this.quantColabAtivos = quantColabAtivos;
    this.status = status;
    this.tipoPesquisa = tipoPesquisa;
    this.perguntaPesquisa = !!perguntaPesquisa ? perguntaPesquisa : [];
    this.perguntas = !!perguntas ? perguntas : [];
    this.possuoDados = !! possuoDados ? possuoDados : false;
    this.restringirRespondentesListaEmail= !!restringirRespondentesListaEmail;
    this.restringirRespondentesUsuariosEmpresa= !!restringirRespondentesUsuariosEmpresa;
    this.identificador = identificador;
  }
    public id: number | undefined;
    public titulo: string | undefined;
    public dataCriacao: string | undefined;
    public dataFim: string | undefined;
    public dataInicio: string | undefined;
    public descricao: string | undefined;
    public emailsRespondentes: string | undefined;
    public empresa: EmpresasEntity | undefined;
    public pesquisaAnonima: boolean = false;
    public pesquisaModelo: PesquisaModeloEntity;
    public quantColabAtivos: number | undefined;
    public status: statusPesquisaCenso | undefined;
    public tipoPesquisa: TipoPesquisaEntity | undefined;
    public perguntaPesquisa: PerguntaPesquisaEntity[] = [];
    public perguntas: PerguntaPesquisaModeloEntity[] = [];
    public possuoDados: boolean | undefined = false;
    restringirRespondentesListaEmail: boolean;
    restringirRespondentesUsuariosEmpresa: boolean;
    public identificador: string | number | undefined;

}
