import { Injectable } from '@angular/core';
import {HttpService} from "../../../../../../services/http/http.service";
import {environment} from "../../../../../../../environments/environment";
import {RestReturn} from "../../../../../../_core/config/rest-return";
import {MatrizPesoModeloEntity} from "../../entity/matriz-peso-modelo-entity";

@Injectable({
  providedIn: 'root'
})
export class MatrizPesoModeloService {

  constructor(
    private http: HttpService
  ) { }

  getPergunta(perguntaScoreId: number | string) {
    return this.http.get<RestReturn<MatrizPesoModeloEntity>>(`api/matriz-peso-modelo/pergunta-score/${perguntaScoreId}`)
  }

  setPerguntaX(perguntaScoreId: number | string, perguntaModeloxId: number | string) {
    return this.http.post(`api/matriz-peso-modelo/pergunta-score/${perguntaScoreId}/pergunta-modelox/${perguntaModeloxId}`, {})
  }

  getAll(id: number | string) {
    return this.http.get('api/matriz-peso-modelo/matriz-peso-modelo-nome/'+id);
  }

  getPerguntasScoreY(id: number | string) {
    return this.http.get('api/pergunta/pergunta-score/pesquisa-modelo/'+id);
  }

  getPerguntasModelo(id: number | string) {
    return this.http.get('api/pergunta/pergunta-score/pesquisa-modelo/'+id);
  }

  salvaNome(data:{ nome: string, pesquisaModeloId: string | number }) {
    return this.http.post<RestReturn<{ nome: string, id?: string | number, pesquisaModeloId: string | number }>>('api/matriz-peso-modelo-nome', data);
  }

  saveCelula(data: {}) {
    return this.http.put('api/peso-modelo', data)
  }

}
