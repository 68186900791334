import {AbstractOpcaoRespostaEntity} from "../opcao-resposta/abstract-opcao-resposta-entity";
import {AbstractEntity} from '@datagrupo/dg-crud';


export abstract class AbstractPergunta extends AbstractEntity {
  public id?: number | string | undefined;
  public opcoes: AbstractOpcaoRespostaEntity[] = [];

  constructor() {
    super();
  }

}
