import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpService} from "../../../services/http/http.service";
import {SessionService} from "../../../services/session/session.service";
import {RestReturn} from "../../../_core/config/rest-return";
import {UsuarioService} from "../../_cadastros/pages/usuarios/services/usuario.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-meus-dados',
  templateUrl: './meus-dados.component.html',
  styleUrls: ['./meus-dados.component.scss']
})
export class MeusDadosComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cpf: new FormControl(''),
    perfil:  new FormControl('RESPONDENTE'),
    receberEmailsEmpresaAtualizada: new FormControl(true),
    receberEmailsEmpresaAtualizadaPorGestorTroca: new FormControl(true),
    receberEmailsEmpresaCadastrada: new FormControl(true),
    receberEmailsNotificacaoPesquisa: new FormControl(true),
    receberEmailsPesquisaAtualizada: new FormControl(true),
    receberEmailsPesquisaAtualizadaPorGestorEmpresa: new FormControl(true),
    receberEmailsPesquisaAtualizadaPorGestorTroca: new FormControl(true),
    receberEmailsPesquisaEmAnalise: new FormControl(true),
    receberEmailsPesquisaFinalizada: new FormControl(true),
    receberEmailsPesquisaIniciada: new FormControl(true),
    receberEmailsSistemaAtualizado: new FormControl(true),
    receberEmailsUsuarioAtualizado: new FormControl(true),
    receberEmailsUsuarioAtualizadoPorGestorEmpresa: new FormControl(true),
    receberEmailsUsuarioAtualizadoPorGestorTroca: new FormControl(true),
    receberEmailsUsuarioCadastrado: new FormControl(true),
    receberEmailsUsuarioCadastradoPorGestorEmpresa: new FormControl(true),
    receberEmailsUsuarioCadastradoPorGestorTroca: new FormControl(true)
  });

  constructor(
    private http: HttpService,
    public session: SessionService,
    private userSession: UsuarioService
  ) {
    http.get<RestReturn<any>>('api/usuario/'+session.user.id).subscribe(
      resp => {
        this.form.patchValue({
          nome: resp.data.nome,
          email: resp.data.email,
          empresa: resp.data.empresa.id,
          cpf: resp.data.cpf,
          perfil: resp.data.perfil,
          receberEmailsEmpresaAtualizada: resp.data.receberEmailsEmpresaAtualizada,
          receberEmailsEmpresaAtualizadaPorGestorTroca: resp.data.receberEmailsEmpresaAtualizadaPorGestorTroca,
          receberEmailsEmpresaCadastrada: resp.data.receberEmailsEmpresaCadastrada,
          receberEmailsNotificacaoPesquisa: resp.data.receberEmailsNotificacaoPesquisa,
          receberEmailsPesquisaAtualizada: resp.data.receberEmailsPesquisaAtualizada,
          receberEmailsPesquisaAtualizadaPorGestorEmpresa: resp.data.receberEmailsPesquisaAtualizadaPorGestorEmpresa,
          receberEmailsPesquisaAtualizadaPorGestorTroca: resp.data.receberEmailsPesquisaAtualizadaPorGestorTroca,
          receberEmailsPesquisaEmAnalise: resp.data.receberEmailsPesquisaEmAnalise,
          receberEmailsPesquisaFinalizada: resp.data.receberEmailsPesquisaFinalizada,
          receberEmailsPesquisaIniciada: resp.data.receberEmailsPesquisaIniciada,
          receberEmailsSistemaAtualizado: resp.data.receberEmailsSistemaAtualizado,
          receberEmailsUsuarioAtualizado: resp.data.receberEmailsUsuarioAtualizado,
          receberEmailsUsuarioAtualizadoPorGestorEmpresa: resp.data.receberEmailsUsuarioAtualizadoPorGestorEmpresa,
          receberEmailsUsuarioAtualizadoPorGestorTroca: resp.data.receberEmailsUsuarioAtualizadoPorGestorTroca,
          receberEmailsUsuarioCadastrado: resp.data.receberEmailsUsuarioCadastrado,
          receberEmailsUsuarioCadastradoPorGestorEmpresa: resp.data.receberEmailsUsuarioCadastradoPorGestorEmpresa,
          receberEmailsUsuarioCadastradoPorGestorTroca: resp.data.receberEmailsUsuarioCadastradoPorGestorTroca
        })
      }
    )
  }

  ngOnInit(): void {

  }

  salvar() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const data = {
      id: this.session.user.id,
      ...this.form.value
    }

    this.userSession.meuDados(data).subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: 'Dados alterados'
        })
      }
    )
  }

}
