import {AbstractEntity, DgTableColumn} from '@datagrupo/dg-crud';
import {SegmentoEmpresaEntity} from "../../segmento-empresa/segmento-empresa-entity";

export class EmpresasEntity extends AbstractEntity{

  constructor(
    id?: number | string,
    nomeFantasia?: string,
    cnpj?: string,
    quantFunc?: number,
    tipoContratacao?: string,
    razaoSocial?: string,
    cep?: string,
    logradouro?: string,
    complemento?: string,
    bairro?: string,
    cidade?: string,
    uf?: string,
    nomeRepLegal?: string,
    cpfRepLegal?: string,
    empNomeGE?: string,
    empEmailGE?: string,
    empCelGE?: string,
    modeloPadraoCenso?: {id: number},
    modeloPadraoCensoPD?: {id: number},
    modeloPadraoDiagnostico?: {id: number},
    setor?: {id: number | string},
    segmentoEmpresa?: any
  ) {
    super();
    this.id = id;
    this.cnpj = cnpj;
    this.quantFunc = quantFunc;
    this.tipoContratacao = tipoContratacao;
    this.nomeFantasia = nomeFantasia;
    this.razaoSocial = razaoSocial;
    this.cep = cep;
    this.logradouro = logradouro;
    this.complemento = complemento;
    this.bairro = bairro;
    this.cidade = cidade;
    this.uf = uf;
    this.nomeRepLegal = nomeRepLegal;
    this.cpfRepLegal = cpfRepLegal;
    this.empNomeGE = empNomeGE;
    this.empEmailGE = empEmailGE;
    this.empCelGE = empCelGE;
    this.modeloPadraoCenso = !!modeloPadraoCenso ? modeloPadraoCenso : { id: '' };
    this.modeloPadraoCensoPD = !!modeloPadraoCensoPD ? modeloPadraoCensoPD : { id: '' };
    this.modeloPadraoDiagnostico = !!modeloPadraoDiagnostico ? modeloPadraoDiagnostico : { id: '' };
    this.setor = !!setor ? setor : { id: '' };
    this.segmentoEmpresa = segmentoEmpresa;
  }

  public id: number | string | undefined;

  @DgTableColumn({columnName: 'Nome'})
  public nomeFantasia: string | undefined;

  @DgTableColumn({columnName: 'Quant. Funcionários'})
  public quantFunc: number | undefined;

  @DgTableColumn({columnName: 'Tipo contratação'})
  public tipoContratacao: string | undefined;

  @DgTableColumn({columnName: 'CNPJ', mask: 'cnpj'})
  public cnpj: string | number | undefined;

  @DgTableColumn({columnName: 'Nome gestor'})
  public empNomeGE: string | undefined;
  public razaoSocial: string | undefined;
  public cep: string | undefined;
  public logradouro: string | undefined;
  public complemento: string | undefined;
  public bairro: string | undefined;
  public cidade: string | undefined;
  public uf: string | undefined;
  public nomeRepLegal: string | undefined;
  public cpfRepLegal: string | undefined;
  public empEmailGE: string | undefined;
  public empCelGE: string | undefined;
  public modeloPadraoCenso: {id: number | string} = {id: ''}
  public modeloPadraoCensoPD: {id: number | string} = {id: ''}
  public modeloPadraoDiagnostico: {id: number | string} = {id: ''}
  public setor: {id: number | string } = {id: ''}
  public segmentoEmpresa: SegmentoEmpresaEntity | any | undefined;
}
