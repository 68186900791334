import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PesoIdealPesquisaComponent } from './peso-ideal-pesquisa/peso-ideal-pesquisa.component';
import {UiModule} from "../../../../components/ui/ui.module";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    PesoIdealPesquisaComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        MatInputModule,
        FormsModule,
        NgxMaskModule
    ]
})
export class PesoIdealPesquisaModule { }
