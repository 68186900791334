import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AnalisarPesquisaComponent} from "./analisar-pesquisa-edit/analisar-pesquisa.component";
import {UiModule} from "../../../../components/ui/ui.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DirectivesModule} from "../../../../directives/directives.module";



@NgModule({
  declarations: [
    AnalisarPesquisaComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        DragDropModule,
        MatIconModule,
        MatButtonModule,
        DgCrudModule,
        MatTooltipModule,
        DirectivesModule
    ]
})
export class AnalisarPesquisaModule { }
