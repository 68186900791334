import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {PesquisaModeloEntity} from "../../../../_cadastros/pages/pesquisa-modelo/entity/pesquisa-modelo-entity";
import {Router} from "@angular/router";
import {RespostaPerguntaPesquisa} from "../../responder/responder-pesquisa/entity/resposta-pergunta-pesquisa";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {OpcaoPerguntaPesquisaEntity} from "../../../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {ResponderPesquisaEntity} from "../../responder/responder-pesquisa/entity/responder-pesquisa-entity";

@Component({
  selector: 'app-preview-pesquisa-modelo',
  templateUrl: './preview-pesquisa-modelo.component.html',
  styleUrls: ['./preview-pesquisa-modelo.component.scss']
})
export class PreviewPesquisaModeloComponent implements OnInit {

  public entity: ResponderPesquisaEntity = new ResponderPesquisaEntity();
  public perguntaPesquisa: PerguntaPesquisaEntity[] = [];

  public data: any;
  @ViewChild('textDescricao') textDescricao!: HTMLDivElement;

  constructor(
    private router: Router,
    private rerender: Renderer2
  ) {
    if (window.sessionStorage.getItem('entity')) {
      let pesquisa = <PesquisaModeloEntity> JSON.parse(<string>window.sessionStorage.getItem('entity'));
      this.entity.titulo = pesquisa.titulo;
      this.entity.descricao = pesquisa.descricao;

      this.entity.perguntaPesquisa = pesquisa.perguntas.map((pergunta) => {

        return this.criaPerguntasDoModelo(pergunta)
      });

    } else {
      Swal.fire({
        icon: 'error',
        title: 'A previa da pesquisa que você procura não existe mais.'
      }).then(() => {
        this.router.navigate(['/']);
      })
    }
  }

  ngOnInit(): void {
    this.entity.respostas = this.entity.perguntaPesquisa.map((pergunta) => {

      let opcao = undefined;

      if (pergunta.tipoResposta === 'CHECKBOX' || pergunta.tipoResposta === 'COMBOBOX' || pergunta.tipoResposta === 'RADIOBUTTON') {
        opcao = [{
          opcaoPerguntaPesquisaId: '',
          detalhes: ''
        }]
      }

      return new RespostaPerguntaPesquisa(
        Number(pergunta.id),
        '',
        [],
        pergunta.perguntaObrigatoria
      )
    });


    setTimeout(() => this.setTextEditor(), 10)
  }

  criaPerguntasDoModelo(perguntaPesquisa: PerguntaPesquisaModeloEntity): PerguntaPesquisaEntity{

    const opcoes = <OpcaoPerguntaPesquisaEntity[]> perguntaPesquisa.pergunta.opcoes.map(opcao => {

      return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
        undefined,
        opcao.opcao,
        opcao.outros,
        undefined,
      );
    });

    return <PerguntaPesquisaEntity> new PerguntaPesquisaEntity(
      undefined,
      perguntaPesquisa.pergunta.id,
      perguntaPesquisa.id,
      perguntaPesquisa.pergunta.pergunta,
      perguntaPesquisa.pergunta.tipoResposta,
      !!opcoes ? opcoes : [],
      undefined,
      undefined,
      !!perguntaPesquisa.pergunta.categoria ? perguntaPesquisa.pergunta.categoria : '',
      ['ORIGINAL'],
      !!perguntaPesquisa.podeEditar,
      !!perguntaPesquisa.podeRemover,
      perguntaPesquisa.perguntaObrigatoria
    );
  }

  setTextEditor() {
    //@ts-ignore
    const navigate = this.textDescricao.nativeElement;
    this.rerender.setProperty(navigate, 'innerHTML', this.entity?.descricao || '')
  }
}
