import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {PesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pesquisa-entity";


export class PerguntaScorePesquisaEntity {

  constructor(
    id?: number,
    dimensao?: string,
    pergunta?: string,
    perguntaPesquisa?: PerguntaPesquisaEntity,
    listPerguntaPesquisa?: PerguntaPesquisaEntity[],
    pontuacao?: number,
    pontuacaoMax?: number,
    resposta?: 'NAO_APLICA'| 'SIM'| 'NAO'| 'INDEFINIDO',
    sugestao?: string,
    pesquisa?: PesquisaEntity,
    edit?: boolean
  ) {
    this.id = id
    this.dimensao = dimensao
    this.pergunta = pergunta
    this.perguntaPesquisa = perguntaPesquisa
    this.pontuacao = pontuacao
    this.pontuacaoMax = pontuacaoMax
    this.resposta = !!resposta ? resposta : 'INDEFINIDO';
    this.edit = !!edit
    this.sugestao = sugestao
    this.pesquisa = pesquisa;
    this.listPerguntaPesquisa = Array.isArray(listPerguntaPesquisa) ? listPerguntaPesquisa : [];
  }

  public id:  number | undefined;
  public dimensao:  string | undefined;
  public pergunta:  string | undefined;
  public perguntaPesquisa:  PerguntaPesquisaEntity | undefined;
  public listPerguntaPesquisa:  PerguntaPesquisaEntity[] = [];
  public pontuacao:  number | undefined;
  public pontuacaoMax:  number | undefined;
  public resposta: 'NAO_APLICA'| 'SIM'| 'NAO'| 'INDEFINIDO';
  public edit:  boolean | undefined;
  public sugestao:  string | undefined;
  public pesquisa: PesquisaEntity | undefined
}
