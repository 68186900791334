export enum TipoResposta {
  TEXT = 'Resposta curta',
  BIG_TEXT = 'Parágrafo',
  NUMBER = 'Números',
  CHECKBOX = 'Caixa de seleção',
  RADIOBUTTON = 'Múltipla escolha',
  COMBOBOX = 'Lista suspensa'
}

export const TipoRespostaArray = [
  {chave: 'TEXT', valor: TipoResposta.TEXT},
  {chave: 'BIG_TEXT', valor: TipoResposta.BIG_TEXT},
  {chave: 'NUMBER', valor: TipoResposta.NUMBER},
  {chave: 'CHECKBOX', valor: TipoResposta.CHECKBOX},
  {chave: 'RADIOBUTTON', valor: TipoResposta.RADIOBUTTON},
  {chave: 'COMBOBOX', valor: TipoResposta.COMBOBOX},
]

export function getTipoResposta(tipo: 'TEXT' | 'BIG_TEXT' | 'NUMBER' | 'CHECKBOX' | 'RADIOBUTTON' | 'COMBOBOX' | string) {
  if (
    tipo === 'TEXT' || tipo === 'BIG_TEXT' || tipo === 'NUMBER' ||
    tipo === 'CHECKBOX' || tipo === 'RADIOBUTTON' || tipo === 'COMBOBOX'
  ) {
    return TipoResposta[tipo];
  }
  console.error('A string apresentada')
  return null;
}
