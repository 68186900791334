import { Injectable } from '@angular/core';
import {HttpService} from "../../../../../services/http/http.service";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {MatrizPesoPesquisaEntity} from "../entity/matriz-peso-pesquisa-entity";

@Injectable({
  providedIn: 'root'
})
export class MatrizPesoPesquisaService {

  constructor(private http: HttpService) { }

  setPerguntaX(perguntaScoreId: number | string, perguntaPesquisaxId: number | string) {
    return this.http.post(`api/matriz-peso-pesquisa/pergunta-score/${perguntaScoreId}/pergunta-pesquisax/${perguntaPesquisaxId}`, {})
  }


  getPergunta(perguntaScoreId: number | string) {
    return this.http.get<RestReturn<MatrizPesoPesquisaEntity>>(`api/matriz-peso-pesquisa/pergunta-score/${perguntaScoreId}`)
  }

  saveCelula(data: {}) {
    return this.http.put('api/peso-pesquisa', data)
  }
}
