export class OpcaoRespostaJpdEntity {

  constructor(
    opcaoPerguntaPesquisaId: number | any,
    quantidade: string
  ) {
    this.opcaoPerguntaPesquisaId = opcaoPerguntaPesquisaId
    this.quantidade = !!quantidade ? quantidade : '';
  }

  public opcaoPerguntaPesquisaId: number | any | undefined;
  public quantidade!: string;
}
