import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HistoricoPesquisaMainComponent} from './historico-pesquisa-main/historico-pesquisa-main.component';
import {UiModule} from "../../../../components/ui/ui.module";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {DgCrudModule} from '@datagrupo/dg-crud';
import {MatChipsModule} from "@angular/material/chips";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    HistoricoPesquisaMainComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatTableModule,
    MatIconModule,
    SweetAlert2Module,
    DgCrudModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class HistoricoPesquisaModule {
}
