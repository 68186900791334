<ul
  class="app-menu"
  [class.navbar-nav]="!isDropdown"
  [class.dropdown-menu]="isDropdown"
>

  <li
    *ngFor="let m of menu"
    [class.dropdown]="m.subMenu"
    class="nav-item"
  >
    <a
      *ngIf="!m.subMenu; else tamplateSubMenu"
      routerLink="{{m.url}}"
      [class.dropdown-item]="isDropdown"
      [class.nav-link]="!isDropdown"
    >
      <mat-icon>{{m.icon}}</mat-icon>
      {{m.nome}}
    </a>

    <ng-template #tamplateSubMenu>

    </ng-template>

  </li>

</ul>
