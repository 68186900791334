
export class RespostaPerguntaPesquisa {

  public respostaOk = false;

  constructor(
    perguntaPesquisaId?: number,
    resposta?: string,
    respostaOpcao?: {
      detalhes: string,
      opcaoPerguntaPesquisaId: number | any
    }[],
    perguntaObrigatoria?: boolean,
    perguntaOriginalId?: number,
    fakeId?: number,
  ) {
    this.perguntaPesquisaId = perguntaPesquisaId;
    this.resposta = resposta;
    this.respostaOpcao = respostaOpcao;
    this.perguntaObrigatoria = !!perguntaObrigatoria;
    this.perguntaOriginalId = perguntaOriginalId;
    this.respostaOk = !perguntaObrigatoria;
    this.fakeId = fakeId;
  }

  public perguntaPesquisaId: number | undefined;
  public resposta: string | undefined;
  public respostaOpcao?: {
    detalhes: string,
    opcaoPerguntaPesquisaId: number | any
  }[] | undefined;
  public perguntaObrigatoria: boolean = false;
  public perguntaOriginalId: number | undefined;
  public fakeId: number | undefined;
}
