import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {TokenService} from "../services/token.service";
import {SessionService} from "../../../services/session/session.service";

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected tokenService: TokenService,
    private session: SessionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    this.session.currentTitlePage = '';

    // abrindo e verificando token
    if (!window.localStorage.getItem('accessToken')) {
      this.verificaUrlRetornoPesquisa();
      this.session.logout();
    }

    const dataToken = this.tokenService.abrirToken();

    if (!dataToken.profile) {
      window.localStorage.clear()
      this.verificaUrlRetornoPesquisa();
      this.router.navigate(['auth/login']);
    } else {
      this.session.user.perfil = dataToken.profile;
    }

    if (
      this.session.user.nome === undefined || this.session.user.id === undefined ||
      this.session.user.email === undefined || this.session.user.empresa === undefined ||
      this.session.user.termoUsoSistema === undefined
    ) {
      if (!window.localStorage.getItem('user')) {
        // window.localStorage.clear()
        this.verificaUrlRetornoPesquisa();
        this.router.navigate(['auth/login']);
      } else {
        const user = JSON.parse(String(window.localStorage.getItem('user')))

        if (
          user.id === undefined || user.nome === undefined
          || user.email === undefined || user.termoUsoSistema === undefined
        ) {
          this.session.logout();
        }

        this.session.user.id = user.id;
        this.session.user.nome = user.nome;
        this.session.user.email = user.email;
        this.session.user.empresa = user.empresa;
        this.session.user.termoUsoSistema = user.termoUsoSistema
      }
    }

    return true;
  }

  private verificaUrlRetornoPesquisa() {
    const path = window.location.pathname.split('/');
    path.pop()

    if (path.join('/') === '/realizar/pesquisa') {
      window.sessionStorage.setItem('redirect', window.location.pathname)
    }
  }

}
