import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {HttpService} from "../../../../services/http/http.service";

@Component({
  selector: 'modal-busca-perguntas-score',
  templateUrl: './modal-busca-perguntas-score.component.html',
  styleUrls: ['./modal-busca-perguntas-score.component.scss']
})
export class ModalBuscaPerguntasScoreComponent implements OnInit {

  @ViewChild('modal_busca_pergunta_score') modal!: ModalComponent;

  public idPesquisa: number | string = '';
  public listaPerguntasPesquisaModelo: PerguntaPesquisaModeloEntity[] = [];
  public index: number = 0;

  public selectList: PerguntaPesquisaModeloEntity[] = [];

  @Output() save = new EventEmitter<{
    data: {},
    index: number
  }>();

  constructor(public http: HttpService) {}

  ngOnInit(): void {
  }

  open(){
    this.http.get('api/pergunta-modelo/pergunta-score/pesquisa-modelo/'+this.idPesquisa).subscribe(
      resp => {
        //@ts-ignore
        this.listaPerguntasPesquisaModelo = resp.data;
      }
    );
    this.modal.open()
  }

  salvar(){
    this.save.emit({
      index: this.index,
      data: this.selectList
    });
  }

  close(save = false){

    if (save) {
      this.salvar();
      return;
    }

    this.modal.close();
  }

  changeSelect(pergunta: PerguntaPesquisaModeloEntity) {
    if (this.selectList.findIndex(perg => perg.id === pergunta.id) === -1) {
      this.selectList.push(pergunta);
    } else {
      const index = this.selectList.findIndex(perg => perg.id === pergunta.id);
      this.selectList.splice(index, 1)
    }
  }

  checketInput(pergunta: any) {
    return (this.selectList.findIndex(perg => perg.id === pergunta) !== -1)
  }
}
