<nav id="header-menu" class="navbar navbar-expand-md navbar-light">
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#div-headerMenu"
      aria-controls="div-headerMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="div-headerMenu">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li
          class="nav-item"
          *ngFor="let m of menu"
          [class.dropdown]="m.subMenu"
        >
          <a
            class="nav-link"
            *ngIf="!m.subMenu; else dropDownTemplate"
            routerLink="{{m.url}}"
          >
            {{m.nome}}
          </a>

          <ng-template #dropDownTemplate>
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{m.nome}}
              <mat-icon style="display: inline-flex; align-items: center; padding-left: .5em">expand_more</mat-icon>
            </a>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="pt-1 pb-1" *ngFor="let submenu of m.subMenu">
                <a class="dropdown-item nav-link" [routerLink]="submenu.url">{{ submenu.nome }}</a>
              </li>
            </ul>
          </ng-template>
        </li>

        <li class="nav-item">
          <a
            check-perfil
            [perfil]="['GESTOREMPRESA', 'GESTORTROCA']"
            class="nav-link"
            (click)="util.homeUrl()"
          >
            Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a
            check-perfil
            [perfil]="['GESTOREMPRESA', 'GESTORTROCA']"
            class="nav-link"
            routerLink="/score"
          >
            Score
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
