import {Injectable, ViewChild} from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {TipoPesquisaEntity} from "../../entity/tipo-pesquisa-entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {SwalComponent} from "@sweetalert2/ngx-sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class ActionsTableTipoPesquisaService extends AbstractActionsTableService{

  public form = new FormGroup({
    codigo: new FormControl('', [Validators.required]),
    nome: new FormControl('', [Validators.required])
  })

  @ViewChild('swallTipoPesquisa') public readonly swall!: SwalComponent;

  constructor() {
    super();
  }

  list(): InterfaceCustomActions[] {
    return [
      {name: 'Editar', action:(data:TipoPesquisaEntity) => {
          window.dispatchEvent(new CustomEvent('editar-tipo-pesquisa', { detail: data}));
        },
        permission: true
      }
    ]
  }
}
