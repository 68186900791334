import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'card-pergunta-label-acossiacao-pergunta-predecessora',
  templateUrl: './card-pergunta-label-acossiacao-pergunta-predecessora.component.html',
  styleUrls: ['./card-pergunta-label-acossiacao-pergunta-predecessora.component.scss']
})
export class CardPerguntaLabelAcossiacaoPerguntaPredecessoraComponent implements OnInit {

  @Input() pergunta : string | undefined;
  @Input() opcao : string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
