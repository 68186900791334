import {PerguntasEntity} from "../../perguntas/entity/perguntas-entity";

interface ListaOpcao {
  id: number,
  opcao: string
}

interface ListPesoModelos {
  id: number,
  matrizPesoModeloId: number,
  opcaoRespostaXId: number,
  opcaoRespostaXTitulo: string
  opcaoRespostaYId: number,
  opcaoRespostaYTitulo: string
  perguntaScoreModeloId: 407
  perguntaXId: number,
  perguntaYId: number,
  peso: null | string | number
}

export class MatrizPesoModeloEntity {

  constructor(
    listOpcaoX?: ListaOpcao[],
    listOpcaoY?: ListaOpcao[],
    listPesoModelos?: ListPesoModelos[],
    perguntaModeloXId?: number | string,
    perguntaScoreModeloYId?: number | string,
    perguntaScorePergunta?: number | string,
    listPerguntaModeloYDTOs?: {id: any, pergunta: string}[]
  ) {
    this.listOpcaoX = listOpcaoX;
    this.listOpcaoY = listOpcaoY;
    this.listPesoModelos = listPesoModelos;
    this.perguntaModeloXId = perguntaModeloXId;
    this.perguntaScoreModeloYId = perguntaScoreModeloYId;
    this.perguntaScorePergunta = perguntaScorePergunta;
    this.listPerguntaModeloYDTOs = Array.isArray(listPerguntaModeloYDTOs) ? listPerguntaModeloYDTOs : [];
  }

  public listOpcaoX: ListaOpcao[] | undefined;
  public listOpcaoY: ListaOpcao[] | undefined;
  public listPesoModelos: ListPesoModelos[] | undefined;
  public perguntaModeloXId: any;
  public perguntaScoreModeloYId: number | string | undefined;
  public perguntaScorePergunta: number | string | undefined;
  public listPerguntaModeloYDTOs: {id: any, pergunta: string}[] = [];
}
