import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {TokenService} from "../services/token.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected tokenService: TokenService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {

    if (window.localStorage.getItem('accessToken')) {
      this.router.navigate(['home']);
      // if (this.tokenService.checkExp(window.localStorage.getItem('accessToken'))) {
      //   this.router.navigate(['home']);
      // }
    }



    return true;
  }

}
