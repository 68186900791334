import { Injectable } from '@angular/core';
import {HttpService} from "../../../services/http/http.service";
import {PerguntaScoreService} from "../../_pesquisas/pages/pergunta-score/services/pergunta-score.service";
import {PerguntaScorePesquisaEntity} from "../../_pesquisas/pages/pergunta-score/entity/pergunta-score-pesquisa-entity";
import {RestReturn} from "../../../_core/config/rest-return";

@Injectable({
  providedIn: 'root'
})
export class DashboardScoreService {

  constructor(
    private http: HttpService,
    private scoreService: PerguntaScoreService
  ) { }

  getScorePesquisa(id?: number | string) {
    if (!!id) {
      return this.http.get(`api/score/pontuacao/pergunta-pesquisa/pergunta-score-pesquisa/pesquisa/${id}`)
    } else {
      return this.http.get(`api/score/pontuacao/pergunta-pesquisa/pergunta-score-pesquisa/pesquisa`)
    }
  }

  getPerguntasScore(id?: number | string) {
    if (!!id) {
      return this.scoreService.findOne(id)
    } else {
      return this.http.get(`api/pergunta-score-pesquisa/configurar/pesquisa`)
    }
  }

  saveResposta(data: Object) {
    return this.http.put<RestReturn<any>>('api/score/resposta/pergunta-score-pesquisa', data)
  }

}
