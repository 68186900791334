import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractInsertEdit} from "@datagrupo/dg-crud";
import {PesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pesquisa-entity";
import {
  ModalBuscaPerguntasComponent
} from "../../../../../components/ui/modals/modal-busca-perguntas/modal-busca-perguntas.component";
import {ActivatedRoute, Router} from "@angular/router";
import {EditarPesquisaService} from "../../editar-pesquisa/service/editar-pesquisa.service";
import {Location} from "@angular/common";
import {HttpService} from "../../../../../services/http/http.service";
import {SessionService} from "../../../../../services/session/session.service";
import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {PerguntasEntity} from "../../../../_cadastros/pages/perguntas/entity/perguntas-entity";
import {OpcaoPerguntaPesquisaEntity} from "../../../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import {HelpPerguntasService} from "../../../../../services/helpers/help-perguntas.service";
import {
  ModalSettingsPerguntasComponent
} from "../../../../../components/ui/modals/modal-settings-perguntas/modal-settings-perguntas.component";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";
import {
  ModalRelacaoPerguntaPesquisaPredecessoraComponent,
  retornoperguntaPesquisaPredecessora
} from "../../../../../components/ui/modals/modal-relacao-pergunta-pesquisa-predecessora/modal-relacao-pergunta-pesquisa-predecessora.component";
import {addPerguntaPesquisa} from "../../../helpers/addPerguntaPesquisa";
import {addPerguntaPesqusaBanco} from "../../../helpers/add-pergunta-banco";
import {copyPerguntaPesquisa} from "../../../helpers/copyPerguntaPesquisa";
import {ajustFakeIdPredecessorByListPerguntas, fakeIdPerguntas} from "../../../helpers/fake-id";

@Component({
  selector: 'app-analisar-pesquisa-edit',
  templateUrl: './analisar-pesquisa.component.html',
  styleUrls: ['./analisar-pesquisa.component.scss']
})
export class AnalisarPesquisaComponent extends AbstractInsertEdit<PesquisaEntity> implements OnInit {

  private originalPesquisa: any;

  public analisar = false;

  @ViewChild('modal') modal!: ModalBuscaPerguntasComponent;
  @ViewChild('modalSettings') modalSettings!: ModalSettingsPerguntasComponent;
  @ViewChild('ModalPerguntaPredecessora') modalPerguntaPredecessora!: ModalRelacaoPerguntaPesquisaPredecessoraComponent;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected service: EditarPesquisaService,
    protected location: Location,
    public http: HttpService,
    public session: SessionService,
    private help: HelpPerguntasService,
    config: ConfigDgCrudService
  ) {
    super(service, config);

    this.analisar = window.location.pathname.split('/')[2] === 'analisar-pesquisa';

    if (this.analisar) {
      if (!session.checkPerfil('GESTORTROCA')) {
        router.navigate(['/realizar/historico-de-pesquisa']);
      }
    }

    // this.voltarconfig = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = <PesquisaEntity> new PesquisaEntity();
  }

  beforeSaveEntity(): boolean {
    if (this.verificaPerguntaTipoInvalido()) {
      Swal.fire({
        icon: 'error',
        title: 'Existem perguntas com o tipo de resposta inválida.'
      })
      return false;
    }

    if (this.verificaPerguntaPreenchimentoInvalido()) {
      Swal.fire({
        icon: 'error',
        title: 'Existem perguntas ou opções de resposta em branco.',
        confirmButtonColor: '#2c516e'
      })
      return false;
    }

    if (this.verificaPerguntaOpcaoRepetida()) {
      Swal.fire({
        icon: 'error',
        title: 'Existem perguntas com opções de resposta repetidas.'
      })


      return false;
    }

    this.entity.perguntaPesquisa.map((pergunta, index) => {
      pergunta.status.map((status, indexStatus) => {

        this.entity.perguntaPesquisa[index].status[indexStatus] = status.split(' ').join('_')
      });

      return pergunta;
    })

    return true;
  }

  afterFetchEntity() {
    this.originalPesquisa = { ...this.entity };

    //@ts-ignore
    if (this.entity.status === 'EM_ANDAMENTO' || this.entity.status === 'CONCLUIDA') {
      this.router.navigate(['/pesquisa/historico'])
    }

    this.entity.perguntaPesquisa.map((perg, index) => {
      if (!perg.fakeId) this.entity.perguntaPesquisa[index].fakeId = fakeIdPerguntas(this.entity.perguntaPesquisa)
    })

    ajustFakeIdPredecessorByListPerguntas(this.entity.perguntaPesquisa);

  }

  salvar(endpoint?: CustomEvent | string) {
    if (Number(this.entity.tipoPesquisa?.codigo) == 3) {
      super.salvar('diagnostico/analise');
    } else {
      super.salvar('censo/analise');
    }
  }

  change(data: { pergunta: PerguntaPesquisaEntity, index: number, campo: string }) {
    if (data.campo === 'pergunta') {
      this.entity.perguntaPesquisa[data.index].pergunta = data.pergunta.pergunta;
    }
    if (data.campo === 'categoria') {
      this.entity.perguntaPesquisa[data.index].categoria = data.pergunta.categoria;
    }
    if (data.campo === 'tipo_resposta') {
      this.entity.perguntaPesquisa[data.index].tipoResposta = data.pergunta.tipoResposta;
    }
    if (data.campo === 'editada') {
      this.entity.perguntaPesquisa[data.index].status = data.pergunta.status;
    }
    if (data.campo === 'modelo_perguntaObrigatoria') {
      this.entity.perguntaPesquisa[data.index].perguntaObrigatoria = data.pergunta.perguntaObrigatoria;
    }
  }

  restaurarPergunta(data: { pergunta: PerguntaPesquisaEntity, index: number, statusMode: string }) {

    if (data.statusMode === 'ORIGINAL-ALTERADA') {
      const indexPergunta =
        this.entity.pesquisaModelo.perguntas.findIndex((pergunta) =>
          pergunta.pergunta.id === data.pergunta.perguntaOriginalId);

      if (indexPergunta !== -1) {
        this.entity.perguntaPesquisa[data.index] = this.criaPerguntasDoModelo(this.entity.pesquisaModelo.perguntas[indexPergunta]);
      }
    } else {
      this.http.getById<RestReturn<PerguntasEntity>>('api/pergunta', String(data.pergunta.perguntaOriginalId)).subscribe(next => {

        const opcoes = <OpcaoPerguntaPesquisaEntity[]> next.data?.opcoes.map((opcao, indexOpcPergunta) => {

          return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
            undefined,
            opcao.opcao,
            opcao.outros,
            Number(opcao.id),
          );
        });

        this.entity.perguntaPesquisa[data.index] = new PerguntaPesquisaEntity(
          undefined,
          next.data?.id,
          undefined,
          next.data?.pergunta,
          next.data?.tipoResposta,
          opcoes,
          undefined,
          undefined,
          next.data?.categoria,
          ['ADICIONADA', 'BANCO DE DADOS'],
          next.data?.podeEditar,
          next.data?.podeRemover,
          false
        );
      })
    }
  }

  criaPerguntasDoModelo(perguntaPesquisa: PerguntaPesquisaModeloEntity): PerguntaPesquisaEntity{

    const opcoes = <OpcaoPerguntaPesquisaEntity[]> perguntaPesquisa.pergunta.opcoes.map((opcao, index: number) => {

      return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
        undefined,
        opcao.opcao,
        opcao.outros,
        Number(opcao.id),
      );
    });

    return <PerguntaPesquisaEntity> new PerguntaPesquisaEntity(
      undefined,
      perguntaPesquisa.pergunta.id,
      perguntaPesquisa.id,
      perguntaPesquisa.pergunta.pergunta,
      perguntaPesquisa.pergunta.tipoResposta,
      !!opcoes ? opcoes : [],
      undefined,
      undefined,
      !!perguntaPesquisa.pergunta.categoria ? perguntaPesquisa.pergunta.categoria : '',
      ['ORIGINAL'],
      !!perguntaPesquisa.podeEditar,
      !!perguntaPesquisa.podeRemover,
      perguntaPesquisa.perguntaObrigatoria
    );
  }

  addPergunta = (index?: number) => addPerguntaPesquisa(this.entity.perguntaPesquisa, index)

  addPerguntaBanco = (data: { perguntas: PerguntasEntity[]; index: number }) => addPerguntaPesqusaBanco(data, this.entity.perguntaPesquisa)

  copyPergunta = (index: number) => copyPerguntaPesquisa(index, this.entity.perguntaPesquisa)

  openModal(index: number) {
    this.modal.listaPerguntasInseridas = this.entity.perguntaPesquisa;
    this.modal.openModal(index, this.entity.pesquisaModelo.tipoPesquisa.tipoResposta)
  }

  removePergunta(index: number) {
    this.entity.perguntaPesquisa.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.entity.pesquisaModelo.podeReordenar) {
      if (event.currentIndex < this.entity.pesquisaModelo.perguntas.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Não é possível reordenar as perguntas originais desta pesquisa',
          confirmButtonColor: '#EC536C',
          timer: 5000
        })
      } else {
        moveItemInArray(this.entity.perguntaPesquisa, event.previousIndex, event.currentIndex);
      }
    } else {
      moveItemInArray(this.entity.perguntaPesquisa, event.previousIndex, event.currentIndex);
    }
  }

  aprovarPesquisa(){
    //@ts-ignore
    this.entity.status = 'APROVADA'

    this.salvar();
  }

  rejeitarPesquisa(){
    //@ts-ignore
    this.entity.status = 'REPROVADA'

    this.salvar();
  }

  verificaPerguntaOpcaoRepetida() {
    let result = false;

    this.entity.perguntaPesquisa.map((pergunta, index) => {
      pergunta.opcoes.map(opcao => {
        const buscaOpc = this.entity.perguntaPesquisa[index].opcoes.filter( opc => opc.opcao === opcao.opcao)

        if (buscaOpc.length > 1) {
          result = true;
        }
      })
    })

    return result;
  }

  verificaPerguntaTipoInvalido(){
    if (!this.entity.pesquisaModelo.tipoPesquisa) {
      return true;
    }

    if (!Array.isArray(this.entity.pesquisaModelo.tipoPesquisa.tipoResposta)) {
      return true;
    }

    if (this.entity.pesquisaModelo.tipoPesquisa.tipoResposta.length > 0) {
      const perguntasInvalidas = this.entity.perguntaPesquisa.findIndex((pergunta, index) => {

        return this.entity.pesquisaModelo.tipoPesquisa.tipoResposta.findIndex(tipoResposta => {
          return tipoResposta === pergunta.tipoResposta
        }) !== -1
      });

      return perguntasInvalidas === -1;
    } else {
      return false;
    }
  }

  verificaPerguntaPreenchimentoInvalido(){
    const perguntasInvalidas = this.entity.perguntaPesquisa.findIndex((pergunta, index) => {


      if (pergunta.pergunta?.trim() === '' || pergunta.pergunta === undefined) {
        return true;
      }

      if (
        pergunta.tipoResposta === 'CHECKBOX' ||
        pergunta.tipoResposta === 'COMBOBOX' ||
        pergunta.tipoResposta === 'RADIOBUTTON'
      ) {
        const opcInvalida = pergunta.opcoes.findIndex(opcao => {
          return (opcao.opcao.trim() === '' || opcao.opcao === undefined) && !opcao.outros
        })

        return opcInvalida !== -1;
      } else {
        return false
      }

    });

    return perguntasInvalidas !== -1;
  }

  openModalSettings(index: number){
    this.modalSettings.index = index;

    this.modalSettings.openModal({
      geraFiltro: !!this.entity.perguntaPesquisa[index].geraFiltro,
      nomeFiltro: String(this.entity.perguntaPesquisa[index].nomeFiltro),
      grupo: String(this.entity.perguntaPesquisa[index].grupo)
    });
  }

  salvarPerguntaBanco(index: number) {
    this.help.salvarPerguntaIndividual(this.entity.perguntaPesquisa[index]).subscribe(
      resp => {
        // @ts-ignore
        // this.entity.perguntaPesquisa[index] = this.help.pergunta_to_perguntaPesquisa(resp.data)[0];
        this.entity.perguntaPesquisa.splice(index, 1, this.help.pergunta_to_perguntaPesquisa(resp.data)[0]);
      }
    )
  }

  saveConfigFiltros(ev: any) {
    this.entity.perguntaPesquisa[ev.index].geraFiltro = !!ev.data.geraFiltro;
    this.entity.perguntaPesquisa[ev.index].nomeFiltro = ev.data.nomeFiltro;
    this.entity.perguntaPesquisa[ev.index].grupo = ev.data.grupo;

    this.modalSettings.close();
  }

  exibirBtnSalvar(index: number){

    if (this.entity.perguntaPesquisa[index].status.findIndex(stts => stts === 'ALTERADA') != -1) {
      return false;
    }

    return !!this.entity.perguntaPesquisa[index].perguntaOriginalId;
  }

  addPerguntaPredecessora(index: number) {
    this.modalPerguntaPredecessora.open(this.entity?.perguntaPesquisa[index], index)
  }

  callbackPerguntaPredecessora(ev: retornoperguntaPesquisaPredecessora) {
    this.entity.perguntaPesquisa[ev.index]['opcaoPerguntaPesquisaPredecessora'] = ev.opcaoRespostaPredecessora;
    this.entity.perguntaPesquisa[ev.index]['perguntaPesquisaPredecessora'] = ev.perguntaPredecessora;
  }

  verifyExibRelacionarPredecessor(pergunta: PerguntaPesquisaEntity): boolean {
    return pergunta.podeEditar || pergunta?.status.findIndex(e => e == 'NOVA') >= 0
  }
}
