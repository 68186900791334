import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CheckPerfilDirective} from "./check-perfil/check-perfil.directive";



@NgModule({
  declarations: [
    CheckPerfilDirective
  ],
  imports: [
    CommonModule
  ],
  providers: [
    CheckPerfilDirective
  ],
  exports: [
    CheckPerfilDirective
  ]
})
export class DirectivesModule { }
