import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MeusDadosComponent} from './meus-dados/meus-dados.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UiModule} from "../../components/ui/ui.module";
import {NgxMaskModule} from "ngx-mask";
import {DgCrudModule} from "@datagrupo/dg-crud";
import {HttpClientModule} from "@angular/common/http";


@NgModule({
  declarations: [
    MeusDadosComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UiModule,
    NgxMaskModule,
    HttpClientModule
  ]
})
export class MeusDadosModule {
}
