<table mat-table [dataSource]="dataSource" class="mt-3 crud-table">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container
    *ngFor="let col of columns"
    matColumnDef="{{col.varName}}"
  >
    <th style="{{'width:'+ (col.width ? col.width : 'auto')}}" mat-header-cell *matHeaderCellDef> {{col.header}} </th>
    <td
      mat-cell
      *matCellDef="let element"
      [innerHTML]="col.resource ? resourceCel(col.resource(element[col.varName], element)) :
        col.mask ? (element[col.varName] | mask: defauldMask(col.mask)) : element[col.varName]" >
    </td>
  </ng-container>

  <ng-container *ngIf="actions === true" matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Ações </th>
    <td
      mat-cell
      *matCellDef="let element"
      >
      <button mat-raised-button class="button-menu" [matMenuTriggerFor]="beforeMenu"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <a
          [class.d-none]="disabledCustomButton('editar', element)"
          mat-menu-item
          (click)="irParaEditar(element)"
          [routerLink]="irParaEditar(element, true)"
        >Editar</a>
        <a [class.d-none]="disabledCustomButton('deletar', element)" mat-menu-item (click)="excluir(element.id)" >Excluir</a>
        <a
          mat-menu-item
          *ngFor="let custoButton of customActions"
          (click)="custoButton.action(element)"
          [class.d-none]="disabledCustomButton(custoButton.permission, element)"
        >{{custoButton.name}}</a>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr (dblclick)="customDbClick.observers.length > 0 ? customDbClick.emit(row) : irParaEditar(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
