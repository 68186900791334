<div id="page-score">

  <!-- titulo -->
  <div class="container mb-5">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-inline-block align-items-center">
        <h2 style="color: #5B626B">Painel Score</h2>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-inline-flex justify-content-end align-items-center">
        <button
          style="width: auto"
          class="btn dg-btn btn-purple-outline-fundo-branco d-inline-flex align-items-center"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span>Selecionar pesquisa</span>
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- MENSAGEMS -->
  <div *ngIf="(showMessageDimensoes || showMessagePerguntas)" class="container dashboard-page mt-5">
    <!--  <div *ngIf="false" class="container dashboard-page mt-5">-->
    <div class="row" *ngIf="(!showMessageDimensoes && !showMessagePerguntas)">
      <div class="col-12" style="display: flex; height: 8rem; align-items: center; justify-content: center">
        <mat-spinner></mat-spinner>
      </div>
    </div>

    <div class="row" *ngIf="(showMessageDimensoes && !showMessagePerguntas)">
      <div class="col-12 message-error-dimensoes">
        <h2 style="color: #5B626B">Dados Incompletos</h2>
        <h3 class="fs-12">{{dimensoesMesage}}</h3>
        <button
          class="btn dg-btn btn-purple"
          routerLink="/pesquisa/configurar-perguntas-score/{{currentPesquisa}}"
          check-perfil
          perfil="GESTORTROCA"
        >
          Configurar score
        </button>
      </div>
    </div>

    <div class="row" *ngIf="(showMessageDimensoes && showMessagePerguntas && !showMessagePesquisaJdp)">
      <div class="col-12 message-error-dimensoes">
        <h2 style="color: #5B626B">Dados Incompletos</h2>
        <h3 class="fs-12">Não há Score configurado</h3>
        <button
          class="btn dg-btn btn-purple"
          routerLink="/pesquisa/configurar-perguntas-score/{{currentPesquisa}}"
          check-perfil
          perfil="GESTORTROCA"
        >
          Configurar score
        </button>
      </div>
    </div>

    <div class="row" *ngIf="(showMessageDimensoes && showMessagePerguntas && showMessagePesquisaJdp)">
      <div class="col-12 message-error-dimensoes">
        <h2 style="color: #5B626B">Tipo de Pesquisa Incompatível</h2>
        <h3 class="fs-12">{{ this.dimensoesMesage }}</h3>

      </div>
    </div>

  </div>

  <!-- dimensão principal -->
  <div class="container mb-5" *ngIf="dimensaoDestaque && verifyNumberTotal() && !showMessageDimensoes">
    <div class="row align-items-center">
      <div class="col-2 progress-spinner">
        <mat-progress-spinner
          class="example-margin"
          color="accent"
          mode="determinate"
          [value]="pontuacaoTotal"
          [diameter]="70"
        >
        </mat-progress-spinner>

        <div class="col-12">
          <h1>
            {{ajustaDecimal(pontuacaoTotal)}}
          </h1>
          <p>
            baseado na última pesquisa sobre Representatividade
          </p>
        </div>
      </div>

      <div class="col-10">
        <div class="row">
          <div class="col-12">
            <dg-card class="custom-dg-card card-grey">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <section class="justify-content-between d-inline-flex w-100">
                      <span style="width: auto">Representatividade</span>
                      <span style="width: auto; text-align: justify">{{ajustaDecimal(dimensaoDestaque.pontuacaoPorcentagemRelacaoPontuacaoMax)}}%</span>
                    </section>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <section>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style="{{'width: '+ ajustaDecimal(dimensaoDestaque.pontuacaoPorcentagemRelacaoPontuacaoMax)}}%"
                          aria-valuenow="100"
                        ></div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </dg-card>
          </div>
        </div>

        <div class="row row-legendas-representatividade">
          <div class="col-3" *ngFor="let PerguntaScore of dimensaoDestaque.dimensao.listPerguntaScorePesquisaDTO; let i = index">
            <div class="row">
              <div class="col-8" style="display: inline-flex; justify-content: start">
                <span class="legenda">{{PerguntaScore.pergunta}}</span>
              </div>
              <div class="col-4" style="display: inline-flex; justify-content: end">
                <span>{{ajustaDecimal(PerguntaScore.pontuacao)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- demais dimensões -->
  <div class="container mt-5 mb-5" *ngIf="!showMessageDimensoes">

    <div class="row row-dimensao">

      <div
        *ngFor="let dimencao of dimencoes"
        class="col-4 dimensao"
      >
        <div class="row dados-dimensao">
          <div class="col-12">
            <section class="justify-content-between d-inline-flex w-100">
              <span style="width: auto">{{dimencao.dimensao.nome}}</span>
              <span class="percentual">{{ajustaDecimalPontos(dimencao.pontuacaoPorcentagemRelacaoPontuacaoMax)}}%</span>
            </section>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <section>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="{{'width: '+ dimencao.pontuacaoPorcentagemRelacaoPontuacaoMax}}%"
                  aria-valuenow="100"
                ></div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- PERGUNTAS  -->
  <div class="container-fluid mt-5" *ngIf="!(showMessageDimensoes && showMessagePerguntas)">
    <div class="row" style="margin: 1em">
      <div class="col-12">
        <dg-card>
          <div>
            <table class="crud-table">
              <thead>
              <tr>
                <th>Dimensão</th>
                <th>Questão</th>
                <th>Respostas</th>
                <th>Pontuação</th>
              </tr>
              </thead>
              <tbody>
              <tr
                *ngFor="let scoreP of perguntasScore; let i = index"
              >
                <td style="width: 20%">{{scoreP.dimensao}}</td>
                <td style="width: 50%">
                  {{scoreP.pergunta}}
                </td>
                <td style="width: 25%">
                  <mat-radio-group [(ngModel)]="scoreP.resposta" (ngModelChange)="saveResposta(scoreP)" aria-label="Select an option" class="w-100 d-inline-flex justify-content-between">
                    <mat-radio-button class="danger" value="SIM">Sim</mat-radio-button>
                    <mat-radio-button value="NAO">Não</mat-radio-button>
                    <mat-radio-button value="NAO_APLICA">Não se aplica</mat-radio-button>
                  </mat-radio-group>
                </td>
                <td style="text-align: center">
                  {{ ajustaDecimalPontos(scoreP.pontuacaoMax) }}
                </td>
              </tr>
              <tr *ngIf="showMessagePerguntas">
                <td colspan="5">
                  Nenhuma pergunta cadastrada no Score
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </dg-card>
      </div>
    </div>
  </div>
</div>
