import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EditarPesquisaComponent} from "./editar-pesquisa-edit/editar-pesquisa.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UiModule} from "../../../../components/ui/ui.module";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {DgCrudModule} from '@datagrupo/dg-crud';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {DirectivesModule} from "../../../../directives/directives.module";
import {AngularEditorModule} from "@kolkov/angular-editor";



@NgModule({
  declarations: [
    EditarPesquisaComponent
  ],
    imports: [
        CommonModule,
        DragDropModule,
        UiModule,
        MatIconModule,
        MatButtonModule,
        DgCrudModule,
        ReactiveFormsModule,
        MatTooltipModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        DirectivesModule,
        AngularEditorModule
    ]
})
export class EditarPesquisaModule { }
