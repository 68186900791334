import {AbstractOpcaoRespostaEntity} from "./abstract-opcao-resposta-entity";


export class OpcaoRespostaPerguntaEntity extends AbstractOpcaoRespostaEntity {

  constructor(
    id?: number | string | undefined,
    opcao?: string,
    outros?: boolean
  ) {
    super();
    this.id = id;
    this.opcao = !!opcao ? opcao : '';
    this.outros = !!outros;
  }

  public id: number | string | undefined;
  public opcao: string = '';
  public outros: boolean;
  public fakeId: number | undefined
}
