import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {OpcaoPerguntaPesquisaEntity} from "../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {
  PerguntaPesquisaModeloEntity
} from "../../_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {fakeIdPerguntas} from "./fake-id";


export function PerguntaModeloToPerguntaPesquisa(
  perguntaModelo: PerguntaPesquisaModeloEntity,
  listPerguntaPesquisa: PerguntaPesquisaEntity[],
  perguntaPesquisa?: PerguntaPesquisaEntity
): PerguntaPesquisaEntity {

  const opcoes = <OpcaoPerguntaPesquisaEntity[]>perguntaModelo.pergunta.opcoes.map((opcao, index) => {

    return <OpcaoPerguntaPesquisaEntity>new OpcaoPerguntaPesquisaEntity(
      undefined,
      opcao.opcao,
      opcao.outros,
      !!opcao.id ? opcao.id : undefined,
      opcao.fakeId
    );
  });

  const perguntaPred = perguntaModelo.perguntaModeloPredecessora;

  const newPredecessora = !perguntaPred ? undefined : new PerguntaPesquisaEntity(
    undefined,
    perguntaPred?.pergunta.id,
    perguntaPred?.id,
    perguntaPred?.pergunta.pergunta,
    perguntaPred?.pergunta.tipoResposta,
    (<OpcaoPerguntaPesquisaEntity[]>perguntaPred.pergunta.opcoes.map((opcao, index) => {

      return <OpcaoPerguntaPesquisaEntity>new OpcaoPerguntaPesquisaEntity(
        undefined,
        opcao.opcao,
        opcao.outros,
        !!opcao.id ? opcao.id : 'undefined',
        opcao.fakeId
      );
    }) || []),
    perguntaPred?.opcaoRespostaPredecessora,
    //@ts-ignore
    perguntaPred?.perguntaModeloPredecessora,
    '',
    ['ORIGINAL'],
    perguntaPred?.podeEditar,
    perguntaPred?.podeRemover,
    perguntaPred?.perguntaObrigatoria,
    perguntaPred?.geraFiltro,
    !!perguntaPred.nomeFiltro ? perguntaPred.nomeFiltro : perguntaPred.pergunta.pergunta,
    !!perguntaPred.grupo ? perguntaPred.grupo : perguntaPred.pergunta.grupo,
    true,
    perguntaPred.fakeId
  )

  return <PerguntaPesquisaEntity>new PerguntaPesquisaEntity(
    perguntaPesquisa?.id,
    perguntaModelo.pergunta.id,
    perguntaModelo.id,
    perguntaModelo.pergunta.pergunta,
    perguntaModelo.pergunta.tipoResposta,
    !!opcoes ? opcoes : [],
    perguntaModelo?.opcaoRespostaPredecessora,
    newPredecessora,
    !!perguntaModelo.pergunta.categoria ? perguntaModelo.pergunta.categoria : '',
    ['ORIGINAL'],
    !!perguntaModelo.podeEditar,
    !!perguntaModelo.podeRemover,
    perguntaModelo.perguntaObrigatoria,
    perguntaModelo.geraFiltro,
    !!perguntaModelo.nomeFiltro ? perguntaModelo.nomeFiltro : perguntaModelo.pergunta.pergunta,
    !!perguntaModelo.grupo ? perguntaModelo.grupo : perguntaModelo.pergunta.grupo,
    true,
    perguntaModelo.fakeId || fakeIdPerguntas(listPerguntaPesquisa)
  );
}
