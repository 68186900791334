<div id="responeder-pesquisa" class="container" *ngIf="!load">

  <!-- CARD APRESENTAÇÃO -->
  <div class="row justify-content-center">
    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 d-inline-flex">
      <div class="col-1 cont-label-perguntas"></div>
      <dg-card class="custom-dg-card card-purple col-11">
        <div class="card-body container">
          <h2 style="color: #5B626B" class="color-white pb-0 mb-0">{{ entity.titulo }}</h2>
          <div class="exibi-texto-editor w-100" #textDescricao>
          </div>
        </div>
        <div class="card-footer">
          <hr style="color: white">
          <div class="w-100 d-inline-flex justify-content-between">
          </div>
        </div>
      </dg-card>
    </div>
  </div>

  <!-- CARD QUANTIDADE RESPONDENTES -->
  <div class="row justify-content-center">
    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 d-inline-flex">
      <div class="col-1 cont-label-perguntas"></div>
      <dg-card class="col-11 mt-3">
        <div class="card-body container">
         <div class="row">
           <div class="col-9" style="display: inline-flex; justify-content: start; align-items: center">
             <p style="text-align: start" class="fs-12 color-purple f-bold">Quantas pessoas colaboraram com essa pesquisa?</p>
           </div>
           <div class="col-3">
             <input type="text" [(ngModel)]="entity.quantidadeRespostas" class="form-control" id="quantidade">
           </div>
         </div>
        </div>
      </dg-card>
    </div>
  </div>

  <!-- CARDS PERGUNTAS -->
  <div
    class="row justify-content-center"
    *ngFor="let pergunta of entity.perguntaPesquisa; let i = index"
  >
    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 d-inline-flex">
      <div class="col-1 cont-label-perguntas">
        <div class="label-pergunta">
          <p>Pergunta {{ (i + 1) }} de {{ entity.perguntaPesquisa.length }}</p>
        </div>
      </div>
      <card-resposta-jpd
        class="form-check-label col-11 mt-3"
        [index]="i"
        [pergunta]="pergunta"
        (change)="changeValues($event)"
        [resposta]="entity.respostas[i]"
      ></card-resposta-jpd>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="container footer mt-5 pb-5">
    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 d-inline-flex">
        <div class="col-1 cont-label-perguntas"></div>
        <div class="col-11 d-inline-flex justify-content-end">
          <button (click)="openSwall()" class="btn dg-btn btn-info">
            enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<swal
  #swallTermo
  title="Para responder, é necessário concordar com a política de privacidade"
  confirmButtonText="concordar e enviar"
  cancelButtonText="discordar"
  [showCancelButton]="true"
  (confirm)="salvar('api/form/enviar')"

>
  <ng-container *swalPortal="swalTargets.content">
    <a
      class="link-termo"
      matTooltip="A página será aberta em outra aba"
      target="_blank"
      routerLink="/termos_condicoes/politica_de_privacidade"
    >
      Política de privacidade
    </a>
  </ng-container>
</swal>
