import { Injectable } from '@angular/core';
import {AbstractHttpService} from '@datagrupo/dg-crud';
import {PesquisaModeloEntity} from "../../entity/pesquisa-modelo-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs";
import {PerguntasEntity} from "../../../perguntas/entity/perguntas-entity";
import {PerguntaPesquisaModeloEntity} from "../../entity/pergunta-pesquisa-modelo-entity";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class PesquisaModeloService extends AbstractHttpService<PesquisaModeloEntity>{

  constructor(
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/pesquisamodelo')
  }


}
