import {Injectable} from '@angular/core';

export interface InterfaceMenuList {
  url?: string,
  nome: string,
  icon: string,
  subMenu?: InterfaceMenuList[]
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() {
  }

  public getMenu(typeMenu: string): InterfaceMenuList[] {
    if (typeMenu === 'admin') {
      return this.adminMenu();
    } else {
      return this.userMenu();
    }
  }

  /**
   *  menu GESTOR TROCA
   * @private
   */
  private userMenu() {
    const menuList: InterfaceMenuList[] = [
      {
        nome: 'Criar pesquisa', icon: '', subMenu: [
          {nome: 'Pesquisa completa', icon: '', url: '/cadastros/diagnostico/novo'},
          {nome: 'Perfil de diversidade', icon: '', url: '/cadastros/censo/novo'},
          {nome: 'Possuo os dados', icon: '', url: '/cadastros/censo-possuo_dados/novo'}
        ]
      },
      {nome: 'Histórico de pesquisa', icon: '', url: '/pesquisa/historico'},
      // {nome: 'Score', icon: '', url: '/score'},
      // {nome: 'Dashboard', icon: '', url: '/home/clear'}
    ]

    return menuList;
  }

  private adminMenu() {
    const menuList: InterfaceMenuList[] = [
      {nome: 'teste', icon: 'home', url: '#'}
    ]

    return menuList;
  }
}
