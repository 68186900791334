import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {PerguntaScorePesquisaModeloEntity} from "../../entity/pergunta-score-pesquisa-modelo-entity";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScoreModeloService extends AbstractHttpService<PerguntaScorePesquisaModeloEntity>{

  constructor(
    public crudConfig: ConfigDgCrudService
  ) {
    super(crudConfig, environment.apiUrl, '/api/pergunta-score-modelo')
  }

  // findOne(id: number | string, endpoint: string): Observable<PerguntaScorePesquisaModeloEntity> {
  //   return super.findOne(id, 'pesquisa-modelo');
  // }

  saveList(listScore: PerguntaScorePesquisaModeloEntity[]) {
    return this.http.post(this.addEndpoint('lista'), listScore);
  }

  save(data: PerguntaScorePesquisaModeloEntity, endpoint: string | boolean): Observable<PerguntaScorePesquisaModeloEntity> {

    if (data.id) {
      return this.update(data, endpoint)
    }

    return super.save(data, endpoint);
  }
}
