import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EditarPesquisaComponent} from "./pages/editar-pesquisa/editar-pesquisa-edit/editar-pesquisa.component";
import {HistoricoPesquisaMainComponent} from "./pages/historico-pesquisa/historico-pesquisa-main/historico-pesquisa-main.component";
import {PermissionGuard} from "../../_core/guards/guard-permission/permission.guard";
import {AnalisarPesquisaComponent} from "./pages/analisar-pesquisa/analisar-pesquisa-edit/analisar-pesquisa.component";
import {SessionGuard} from "../../_core/guards/guard-session/session.guard";
import {
  ResponderPesquisaJpdComponent
} from "./pages/responder/JPD/responder-pesquisa-jpd/responder-pesquisa-jpd.component";
import {
  PerguntaScoreInsertEditComponent
} from "./pages/pergunta-score/pergunta-score-insert-edit/pergunta-score-insert-edit.component";
import {
  MatrizPesoPesquisaComponent
} from "./pages/matriz-peso-pesquisa/matriz-peso-pesquisa/matriz-peso-pesquisa.component";
import {
  PesoIdealPesquisaComponent
} from "./pages/peso-ideal-pesquisa/peso-ideal-pesquisa/peso-ideal-pesquisa.component";

const routes: Routes = [

  {
    path: 'editar-pesquisa',
    component: EditarPesquisaComponent,
    data: { perfil: ['GESTOREMPRESA', 'GESTORTROCA'] },
    canActivate: [PermissionGuard]
  },
  {
    path: 'editar-pesquisa',
    data: { perfil: ['GESTOREMPRESA', 'GESTORTROCA'] },
    canActivate: [PermissionGuard],
    children: [
      { path: ':id', component: EditarPesquisaComponent},
    ]
  },

  {
    path: 'configurar-perguntas-score',
    children: [
      { path: ':id', component: PerguntaScoreInsertEditComponent},
    ],
    canActivate: [SessionGuard]
  },

  {
    path: 'analisar-pesquisa',
    component: AnalisarPesquisaComponent,
    data: { perfil: ['GESTOREMPRESA', 'GESTORTROCA'] },
    canActivate: [PermissionGuard]
  },
  {
    path: 'analisar-pesquisa',
    data: { perfil: ['GESTOREMPRESA', 'GESTORTROCA'] },
    canActivate: [PermissionGuard],
    children: [
      { path: ':id', component: AnalisarPesquisaComponent},
    ]
  },

  {
    path: 'historico',
    data: { perfil: ['GESTOREMPRESA', 'GESTORTROCA'] },
    component: HistoricoPesquisaMainComponent,
    canActivate: [PermissionGuard]
  },

  {
    path: 'realizar/possuo-dados',
    component: ResponderPesquisaJpdComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'realizar/possuo-dados',
    children: [
      { path: ':id', component: ResponderPesquisaJpdComponent},
    ],
    canActivate: [SessionGuard]
  },

  /* MATRIZ PESO MODELO */
  {
    path: 'configurar-matriz-peso',
    data: { perfil: 'GESTORTROCA' },
    canActivate: [PermissionGuard],
    children: [
      { path: ':idPesquisa/:id', component: MatrizPesoPesquisaComponent },
    ]
  },


  /* PESO IDEAL PESQUISA */
  {
    path: 'configurar-peso-ideal',
    data: { perfil: 'GESTORTROCA' },
    canActivate: [PermissionGuard],
    children: [
      { path: ':id', component: PesoIdealPesquisaComponent },
    ]
  },

  {
    path: '**',
    redirectTo: 'mensagens',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PesquisasRoutingModule { }

class Token {
  constructor(d: {
    id?: number | string
  }) {
    this.id = !!d.id ? Number(d.id) : ''
  }
  public id: number | string | undefined
}
