import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatrizPesoPesquisaComponent} from "./matriz-peso-pesquisa/matriz-peso-pesquisa.component";
import {FormsModule} from "@angular/forms";
import {UiModule} from "../../../../components/ui/ui.module";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [
    MatrizPesoPesquisaComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        UiModule,
        MatTooltipModule
    ]
})
export class MatrizPesoPesquisaModule { }
