import { Inject, Injectable } from '@angular/core';
import {AbstractHttpService} from '@datagrupo/dg-crud';
import {EmpresasEntity} from "../entity/empresas-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../../environments/environment";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class EmpresasService extends AbstractHttpService<EmpresasEntity>{

  protected constructor(
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/empresa')
  }
}
