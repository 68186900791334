import {Injectable} from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from "@datagrupo/dg-crud";
import {PesquisaModeloEntity} from "../../entity/pesquisa-modelo-entity";
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {HttpService} from "../../../../../../services/http/http.service";
import {PesquisaModeloService} from "../pesquisa-modelo-service/pesquisa-modelo.service";
import {ScoreModeloService} from "../score-modelo-service/score-modelo.service";

@Injectable({
  providedIn: 'root'
})
export class ActionsTablePesquisaModeloService extends AbstractActionsTableService {

  constructor(
    public service: PesquisaModeloService,
    public serviceScore: ScoreModeloService,
    private router: Router,
    private http: HttpService
  ) {
    super();
  }

  list(): InterfaceCustomActions[] {
    return [
      {
        name: 'Ver pesquisa',
        permission: (row: PesquisaModeloEntity) => {
          return true;
        },
        action: this.previewPesquisa
      },
      {
        name: 'Duplicar modelo',
        permission: (row: PesquisaModeloEntity) => {
          return true;
        },
        action: (row: PesquisaModeloEntity) => this.duplicarModelo(row)
      },
      {
        name: 'Configurar score',
        action: (pesquisa: PesquisaModeloEntity) => this.router.navigate([`/cadastros/score-modelo/${pesquisa?.id}`]),
        permission: (pesquisa: PesquisaModeloEntity) => pesquisa.tipoPesquisa.codigo != '1'
      }
    ]
  }

  previewPesquisa(row: PesquisaModeloEntity) {
    window.open(`pesquisa/preview-modelo`)?.sessionStorage.setItem('entity', JSON.stringify(row))
  }

  duplicarModelo(pesquisa: PesquisaModeloEntity) {

    const pesquisaCopia = {...pesquisa};

    Swal.fire({
      title: 'Nome da pesquisa modelo',
      input: 'text',
      inputValue: pesquisaCopia.nome + ' (cópia)',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Copiar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return login.trim() !== '' ? login : false;
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {

        const data = <PesquisaModeloEntity>{
          id: pesquisaCopia.id,
          nome: result.value
        }

        this.service.save(data, 'duplicate').subscribe(
          resp => {

            window.dispatchEvent(new CustomEvent('dg-table-atualizar-event'));

            Swal.fire({
              icon: 'success',
              title: 'Pesquisa Modelo Duplicaca com sucesso'
            })
          }
        );
      }
    })
  }

}
