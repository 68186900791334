<div class="container" id="preview-pesquisa-modelo">

  <!-- CARD APRESENTAÇÃO -->
  <div class="row justify-content-center">
    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 d-inline-flex">
      <div class="col-1 cont-label-perguntas"></div>
      <dg-card class="custom-dg-card card-purple col-11">
        <div class="card-body container">
          <h2 style="color: #5B626B" class="color-white pb-0 mb-0">{{ entity?.titulo }}</h2>
          <div class="exibi-texto-editor mb-2 mt-2 w-100" #textDescricao>
          </div>
        </div>
      </dg-card>
    </div>
  </div>


  <!-- CARDS PERGUNTAS -->
  <div
    class="row justify-content-center"
    *ngFor="let pergunta of entity.perguntaPesquisa; let i = index"
  >
    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 d-inline-flex">
      <div class="col-1 cont-label-perguntas">
        <div class="label-pergunta">
          <p>Pergunta {{ (i + 1) }} de {{ entity.perguntaPesquisa.length }}</p>
        </div>
      </div>
      <card-resposta
        class="col-11 mt-3"
        [pergunta]="pergunta"
        [resposta]="entity.respostas[i]"
        [index]="i"
      ></card-resposta>
    </div>
  </div>
</div>
