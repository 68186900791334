import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {InterfaceMenuList} from "../../../../_core/menu/menu.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {

  @Input() menu: InterfaceMenuList[] | any = [];
  @Input() isDropdown: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {}

}
