import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PesquisasRoutingModule} from "./pesquisas-routing.module";
import {PreviewPesquisaModule} from "./pages/preview-pesquisa/preview-pesquisa.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {EditarPesquisaModule} from "./pages/editar-pesquisa/editar-pesquisa.module";
import {HistoricoPesquisaModule} from "./pages/historico-pesquisa/historico-pesquisa.module";
import {PreviewPesquisaModeloModule} from "./pages/preview-pesquisa-modelo/preview-pesquisa-modelo.module";
import {UiModule} from "../../components/ui/ui.module";
import {AnalisarPesquisaModule} from "./pages/analisar-pesquisa/analisar-pesquisa.module";
import {ResponderPesqModule} from "./pages/responder/responder-pesquisa/responder-pesq.module";
import {ResponderPesqJpdModule} from "./pages/responder/JPD/responder-pesq-jpd.module";
import {RespondidoSucessoComponent} from "./pages/responder/respondido-sucesso/respondido-sucesso.component";
import {FormsModule} from "@angular/forms";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {MatTooltipModule} from "@angular/material/tooltip";
import {PerguntaScoreModule} from "./pages/pergunta-score/pergunta-score.module";
import {MatrizPesoPesquisaModule} from "./pages/matriz-peso-pesquisa/matriz-peso-pesquisa.module";
import {PesoIdealPesquisaModule} from "./pages/peso-ideal-pesquisa/peso-ideal-pesquisa.module";


@NgModule({
  declarations: [
    RespondidoSucessoComponent,
  ],
  imports: [
    CommonModule,
    PesquisasRoutingModule,
    HistoricoPesquisaModule,
    ResponderPesqModule,
    ResponderPesqJpdModule,
    EditarPesquisaModule,
    AnalisarPesquisaModule,
    PreviewPesquisaModule,
    PreviewPesquisaModeloModule,
    DragDropModule,
    UiModule,
    AnalisarPesquisaModule,
    FormsModule,
    SweetAlert2Module,
    MatTooltipModule,
    PerguntaScoreModule,
    MatrizPesoPesquisaModule,
    PesoIdealPesquisaModule
  ]
})
export class PesquisasModule {
}
