import { Component, OnInit } from '@angular/core';
import {MatrizPesoPesquisaService} from "../services/matriz-peso-pesquisa.service";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {ActivatedRoute} from "@angular/router";
import {HttpService} from "../../../../../services/http/http.service";
import {MatrizPesoPesquisaEntity} from "../entity/matriz-peso-pesquisa-entity";
import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {Location} from "@angular/common";

@Component({
  selector: 'app-matriz-peso-pesquisa',
  templateUrl: './matriz-peso-pesquisa.component.html',
  styleUrls: ['./matriz-peso-pesquisa.component.scss']
})
export class MatrizPesoPesquisaComponent implements OnInit {

  private entityId: string | number = '';
  private pesquisaId: string | number = '';
  public entity!: MatrizPesoPesquisaEntity;
  public listPerguntas: PerguntaPesquisaEntity[] = [];

  constructor(
    public service: MatrizPesoPesquisaService,
    public activatedRoute: ActivatedRoute,
    public http: HttpService,
    public location: Location
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.idPesquisa) {
        this.entityId = params.id;
        this.pesquisaId = params.idPesquisa;

        http.get<RestReturn<PerguntaPesquisaEntity[]>>('api/pergunta-pesquisa/pergunta-score/pesquisa/'+params.idPesquisa).subscribe(
          resp => {
            this.listPerguntas = !!resp.data ? resp.data : [];
            console.log(resp);
          }
        )

        service.getPergunta(params.id).subscribe(
          resp => {
            if (!!resp.data) {
              this.entity = resp.data
            }
          }
        );
      }
    });
  }

  ngOnInit(): void {
  }

  alteraPerguntaX() {

    this.entity.listPesoPesquisa = undefined;

    this.service.setPerguntaX(String(this.entityId), Number(this.entity.perguntaPesquisaXId)).subscribe(
      resp => {
        //@ts-ignore
        this.entity = resp.data;
        //@ts-ignore
        // this.entityVerify = {...resp.data};
      }
    )
  }

  salveCelula(d: any) {
    this.service.saveCelula(d.data).subscribe()
  }

  viewTootipPerguntas(): string{

    const listTooltop = this.entity.listPerguntaPesquisaYDTOs.map(perg => perg.pergunta);

    return listTooltop.join('\n');
  }
}
