<div class="card dg-card-pergunta" style="width: 100%;">

  <div class="card-body mt-3">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <div class="mb-4">
            <input
              type="text"
              placeholder="Escreva aqui a pergunta"
              (ngModelChange)="changeValue('pergunta')"
              formControlName="pergunta"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div class="mb-4">
            <select class="form-select bg-purple" (ngModelChange)="changeValue('tipo_resposta')" formControlName="tipoResposta" aria-label="Default select example">
              <option disabled>Selecione uma forma de resposta!</option>
              <option
                *ngFor="let tipo of tipoRespostaFiltrado"
                value="{{tipo.chave}}"
                [class.d-none]="disableOpcaoResposta(tipo.chave)"
              > {{tipo.valor}} </option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="container">
    <div class="row">
      <div *ngIf="opcoesExibicao === 'TEXT'" class="col-12">
        <mat-form-field style="width: 100%" appearance="fill">
          <input matInput type="text" placeholder="Resposta de texto simples" value="">
        </mat-form-field>
      </div>
      <div *ngIf="opcoesExibicao === 'BIG_TEXT'" class="col-12">
        <mat-form-field style="width: 100%" appearance="fill">
          <input matInput type="text" placeholder="Resposta de texto longo" value="">
        </mat-form-field>
      </div>
      <div *ngIf="opcoesExibicao === 'NUMBER'" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <mat-form-field style="width: 100%" appearance="fill">
          <input matInput type="number" placeholder="1 ou 1.5" value="">
        </mat-form-field>
      </div>
      <div
        *ngIf="opcoesExibicao === 'RADIOBUTTON' || opcoesExibicao === 'CHECKBOX' || opcoesExibicao === 'COMBOBOX'"
        class="col-12"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <div
          cdkDrag
          class="col-12 d-inline-flex align-items-center"
          *ngFor="let opcao of pergunta.opcoes; let i = index"
          [cdkDragDisabled]="opcao.outros"
        >
          <div class="dragDrop-placeholder" *cdkDragPlaceholder></div>
          <mat-icon cdkDragHandle style="max-width: 5%; color: #c5c5c5">drag_indicator</mat-icon>
          <div class="d-inline-flex align-items-center">
            <mat-radio-button disabled *ngIf="opcoesExibicao === 'RADIOBUTTON'">
            </mat-radio-button>
            <mat-checkbox disabled *ngIf="opcoesExibicao === 'CHECKBOX'">
            </mat-checkbox>
          </div>
          <mat-form-field
            class="roxo"
            appearance="fill"
            style="display: inline; width: 90%"
          >
            <input
              (keypress)="addOpcaoRespostaKeyPress($event, i)"
              [disabled]="(opcao.outros === true)"
              (paste)="colarOpcao($event, i)"
              matInput
              id="opcaoResposta-{{index}}-{{i}}"
              style="width: 100%"
              placeholder="Resposta"
              [(ngModel)]="pergunta.opcoes[i].opcao"
            >
            <button
              matSuffix
              *ngIf="(opcao.outros !== true)"
              mat-icon-button
              aria-label="Clear"
              class="color-roxo"
              (click)="deleteOpcaoResposta(i)"
            >
              <mat-icon class="color-roxo">delete</mat-icon>
            </button>
            <button
              matSuffix
              *ngIf="(opcao.outros !== true)"
              mat-icon-button
              aria-label="Clear"
              (click)="addOpcaoResposta(i)"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              matSuffix
              *ngIf="(opcao.outros === true)"
              mat-icon-button
              aria-label="Clear"
              (click)="alterOutros(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- adicionar outros -->
    <div
      *ngIf="(checkOutros() && (opcoesExibicao === 'RADIOBUTTON' ||
        opcoesExibicao === 'CHECKBOX' || opcoesExibicao === 'COMBOBOX'
      ))"
      class="row">
        <span
          (click)="alterOutros()"
          class="color-purple"
          style="text-decoration: underline; cursor: pointer; padding-left: 5em"
        >
          Adicionar opção "Outros"
        </span>
    </div>
  </div>

  <div class="card-footer">
    <div class="container-fluid container-pergunta-footer p-0">
      <div class="row">
        <div class="col-categoria col-xl-5 col-lg-12 col-md-12 col-sm-12 mt-2">
          <ng-autocomplete
            placeholder="Categoria"
            class="form-control dg-auto-complete w-100"
            [(ngModel)]="pergunta.categoria"
            (ngModelChange)="changeValue('categoria')"
            [data]="dataAutoComplete"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            (focusout)="onCategoriaFocus(false)"
            (focusin)="onCategoriaFocus($event)"
          >
          </ng-autocomplete>
        </div>

        <div class="col-checkbox col-xl-3 col-lg-5 col-md-12 col-sm-12 mt-2">
          <div class="form-check align-items-center d-inline-flex justify-content-between">
            <input (ngModelChange)="changeValue('podeEditar')" [(ngModel)]="pergunta.podeEditar" class="form-check-input purple md" type="checkbox" value="" id="cadastro_permite-editar-{{index}}">
            <label style="margin-left: .5em" for="cadastro_permite-editar-{{index}}">
              Permitido alterar dados
            </label>
          </div>
        </div>

        <div class="col-checkbox col-xl-3 col-lg-6 col-md-12 col-sm-12 mt-2">
          <div class="form-check align-items-center d-inline-flex justify-content-between">
            <input [(ngModel)]="pergunta.podeRemover" (ngModelChange)="changeValue('podeRemover')" class="form-check-input purple md" type="checkbox" value="" id="cadastro_permite-remover-{{index}}">
            <label style="margin-left: .5em" for="cadastro_permite-remover-{{index}}">
              Permitido remover pergunta
            </label>
          </div>
        </div>

        <div class="col-icons col-xl-1 col-lg-1 col-md-12 col-sm-12 mt-2">
          <mat-icon (click)="outputExcluir()" class="actions-footer color-purple" svgIcon="lixeira"></mat-icon>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="ng-autocomplete">

  <ng-template #itemTemplate let-item>
    <a class="color-black" [innerHTML]="item"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="'Nenhuma categoria encontrada | Cadastrando nova caterogia'"></div>
  </ng-template>
</div>
