import { Injectable } from '@angular/core';
import {AbstractHttpService, interfFindAll} from '@datagrupo/dg-crud';
import {UsuarioEntity} from "../entity/usuario-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../../environments/environment";
import {EmpresasService} from "../../empresas/services/empresas.service";
import {Observable} from "rxjs";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {HistoricoPesquisaEntity} from "../../../../_pesquisas/pages/historico-pesquisa/entity/historico-pesquisa-entity";
import {HttpService} from "../../../../../services/http/http.service";
import {SessionService} from "../../../../../services/session/session.service";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends AbstractHttpService<UsuarioEntity>{

  constructor(
    http: HttpClient,
    public empresasService: EmpresasService,
    protected httpService: HttpService,
    protected session: SessionService,
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/usuario');
  }

  findAll(data?: interfFindAll): Observable<UsuarioEntity> {

    if (this.session.checkPerfil('GESTOREMPRESA')) {
      return this.httpService.get<UsuarioEntity>('api/usuario/porempresa/'+this.session.user.empresa?.id);
    }

    return super.findAll(data);
  }

  alterarSenha(data: {id: number | string, password: string}): Observable<RestReturn<any[]>> {
  const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

  return this.http.put<RestReturn<any[]>>(environment.apiUrl + '/api/usuario/gestor/alterarsenha', data, { headers: { Authorization } })
  }

  public aceitartermo(){
    return this.http.put<RestReturn<any[]>>(environment.apiUrl +'/api/usuario/aceitartermo', {})
  }

  public meuDados(data: any) {
    return this.http.put<RestReturn<any[]>>(environment.apiUrl +'/api/usuario/meusdados', data)
  }

}
