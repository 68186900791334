import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponderPesquisaComponent } from './insert-component/responder-pesquisa.component';
import {DgCrudModule} from "@datagrupo/dg-crud";
import {UiModule} from "../../../../../components/ui/ui.module";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {RouterModule} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    ResponderPesquisaComponent
  ],
  imports: [
    CommonModule,
    DgCrudModule,
    UiModule,
    SweetAlert2Module,
    RouterModule,
    MatTooltipModule
  ]
})
export class ResponderPesqModule { }
