import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataTableComponent} from './dg-table/data-table/data-table.component';
import {MatTableModule} from '@angular/material/table';
import {NgxMaskModule} from 'ngx-mask'
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from "@angular/material/icon";
import {DgCrudModule} from '@datagrupo/dg-crud';
import {ModalComponent} from './modals/modal/modal.component';
import {RouterModule} from "@angular/router";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatSelectModule} from '@angular/material/select'
import {
  CardComponent,
  CardFooterComponent,
  CardPerguntaComponent,
  CardRespostaComponent,
  CardPerguntaPesquisaComponent
} from "./cards";
import {ModalBuscaPerguntasComponent} from './modals/modal-busca-perguntas/modal-busca-perguntas.component';
import {
  ModalBuscaPerguntasModeloComponent
} from './modals/modal-busca-perguntas-modelo/modal-busca-perguntas-modelo.component';
import {
  CardPerguntaEditarComponent
} from './cards-custom/perguntas/card-pergunta-editar/card-pergunta-editar.component';
import {PaginationComponent} from './dg-table/pagination/pagination.component';
import {
  CardPerguntaModeloComponent
} from './cards-custom/perguntas/card-pergunta-modelo/card-pergunta-modelo.component';
import {ChartBarComponent} from '../graphics/graphic-bar/chart-bar/chart-bar.component';
import {ChartColumnComponent} from '../graphics/graphic-column/chart-column/chart-column.component';
import {ChartDonutComponent} from '../graphics/graphic-donut/chart-donut/chart-donut.component';
import {ChartPieComponent} from '../graphics/graphic-pie/chart-pie/chart-pie.component';
import {FiltroHomeComponent} from './filtro-home/filtro-home/filtro-home.component';
import {CardRespostaJpdComponent} from './cards-custom/respostas/card-resposta-jpd/card-resposta-jpd.component';
import {ModalSettingsPerguntasComponent} from './modals/modal-settings-perguntas/modal-settings-perguntas.component';
import {InputFileComponent} from './input-file/input-file.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
  ModalBuscaPerguntasScoreComponent
} from './modals/modal-busca-perguntas-score/modal-busca-perguntas-score.component';
import {
  ModalBuscaPerguntasMatrizComponent
} from './modals/modal-busca-perguntas-matriz/modal-busca-perguntas-matriz.component';
import {
  ModalBuscaPerguntasMatrizXComponent
} from './modals/modal-busca-perguntas-matriz-x/modal-busca-perguntas-matriz-x.component';
import {PesoTableModeloComponent} from './peso-table/peso-table-modelo/peso-table-modelo.component';
import { PesoTablePesquisaComponent } from './peso-table/peso-table-pesquisa/peso-table-pesquisa.component';
import { ModalBuscaPerguntasPesquisaScoreComponent } from './modals/modal-busca-perguntas-pesquisa-score/modal-busca-perguntas-pesquisa-score.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ModalBuscaPerguntasPredecessorasComponent } from './modals/modal-busca-perguntas-predecessoras/modal-busca-perguntas-predecessoras.component';
import { ModalRelacaoPerguntaModeloPredecessoraComponent } from './modals/modal-relacao-pergunta-modelo-predecessora/modal-relacao-pergunta-modelo-predecessora.component';
import { ModalRelacaoPerguntaPesquisaPredecessoraComponent } from './modals/modal-relacao-pergunta-pesquisa-predecessora/modal-relacao-pergunta-pesquisa-predecessora.component';
import { CardPerguntaLabelAcossiacaoPerguntaPredecessoraComponent } from './cards-custom/perguntas/card-pergunta-label-acossiacao-pergunta-predecessora/card-pergunta-label-acossiacao-pergunta-predecessora.component';
import { CardRespostaPreviewComponent } from './cards-custom/respostas/card-resposta-preview/card-resposta-preview.component';
import { ModalRelacaoPerguntaPesquisaEditarPredecessoraComponent } from './modals/modal-relacao-pergunta-pesquisa-editar-predecessora/modal-relacao-pergunta-pesquisa-editar-predecessora.component';

@NgModule({
  declarations: [
    CardComponent,
    DataTableComponent,
    CardFooterComponent,
    ModalComponent,
    CardRespostaComponent,
    CardPerguntaComponent,
    CardPerguntaPesquisaComponent,
    ModalBuscaPerguntasComponent,
    ModalBuscaPerguntasModeloComponent,
    CardPerguntaEditarComponent,
    PaginationComponent,
    CardPerguntaModeloComponent,
    ChartBarComponent,
    ChartColumnComponent,
    ChartDonutComponent,
    ChartPieComponent,
    FiltroHomeComponent,
    CardRespostaJpdComponent,
    ModalSettingsPerguntasComponent,
    InputFileComponent,
    ModalBuscaPerguntasScoreComponent,
    ModalBuscaPerguntasMatrizComponent,
    ModalBuscaPerguntasMatrizXComponent,
    PesoTableModeloComponent,
    PesoTablePesquisaComponent,
    ModalBuscaPerguntasPesquisaScoreComponent,
    ModalBuscaPerguntasPredecessorasComponent,
    ModalRelacaoPerguntaModeloPredecessoraComponent,
    ModalRelacaoPerguntaPesquisaPredecessoraComponent,
    CardPerguntaLabelAcossiacaoPerguntaPredecessoraComponent,
    CardRespostaPreviewComponent,
    ModalRelacaoPerguntaPesquisaEditarPredecessoraComponent,
  ],
    imports: [
        CommonModule,
        MatTableModule,
        NgxMaskModule.forRoot(),
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        DgCrudModule,
        RouterModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        DragDropModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTooltipModule
    ],
    exports: [
        CardComponent,
        CardFooterComponent,
        DataTableComponent,
        CardRespostaComponent,
        CardPerguntaComponent,
        CardPerguntaPesquisaComponent,
        CardPerguntaEditarComponent,
        CardPerguntaModeloComponent,

        ModalComponent,
        ModalBuscaPerguntasComponent,
        ModalBuscaPerguntasModeloComponent,

        PaginationComponent,

        ChartBarComponent,
        ChartColumnComponent,
        ChartDonutComponent,
        ChartPieComponent,

        FiltroHomeComponent,
        CardRespostaJpdComponent,
        ModalSettingsPerguntasComponent,
        InputFileComponent,
        ModalBuscaPerguntasScoreComponent,
        ModalBuscaPerguntasMatrizComponent,
        ModalBuscaPerguntasMatrizXComponent,
        PesoTableModeloComponent,
        PesoTablePesquisaComponent,
        ModalBuscaPerguntasPesquisaScoreComponent,
        ModalBuscaPerguntasPredecessorasComponent,
        ModalRelacaoPerguntaModeloPredecessoraComponent,
        ModalRelacaoPerguntaPesquisaPredecessoraComponent,
        CardRespostaPreviewComponent,
        ModalRelacaoPerguntaPesquisaEditarPredecessoraComponent
    ]
})
export class UiModule {
}
