import {PerguntaPesquisaEntity} from "../../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {RespostaJpdEntity} from "./resposta-jpd-entity";
import {AbstractResponderPesquisaEntity} from "../../entity/abstract-responder-pesquisa-entity";


export class ResponderJpdEntity extends AbstractResponderPesquisaEntity{

  constructor(
    id?: number,
    titulo?: string,
    descricao?: string,
    perguntaPesquisa?: PerguntaPesquisaEntity[],
    dataCriacao?: string,
    respostas?: RespostaJpdEntity[],
    quantidadeRespostas?: string
  ) {
    super();
    this.id = id;
    this.perguntaPesquisa = Array.isArray(perguntaPesquisa) ? perguntaPesquisa : [];
    this.titulo = titulo;
    this.descricao = descricao;
    this.respostas = Array.isArray(respostas) ? respostas : [];
    this.pesquisaSnapshotId = id;
    this.dataCriacao = dataCriacao;
    this.quantidadeRespostas = quantidadeRespostas;
  }

  public respostas: RespostaJpdEntity[] = [];
  public quantidadeRespostas: string | undefined = undefined;
}
