<dg-modal title="" id="modalAddPerguntas" size="xl" #modal_add_pergunta>
  <div dg-content>
    <div class="row mb-4">
      <div class="col-9 offset-3">
        <ng-autocomplete
          placeholder="Procurar pergunta"
          class="form-control dg-auto-complete w-100"
          (inputChanged)='onChangeAutoComplete($event)'
          [data]="dataAutoComplete"
          [notFoundTemplate]="notFoundTemplate"
        >
        </ng-autocomplete>
      </div>
    </div>

    <div class="row">

      <div class="col-3">
        <h3>Categorias</h3>
        <table style="width: 100%">
          <tbody>
          <tr style="visibility: hidden">
            <td class="td-categorias">
              <label for="categoria-pergunta-todos">
                <input
                  (change)="getPorCategoria()"
                  type="radio"
                  name="categorias-select"
                  value="autoComplete"
                  [(ngModel)]="categoriaSelecionada"
                  class="form-check-input"
                  id="categoria-pergunta-autoComplete"
                  autocomplete="off"
                >
              </label>
            </td>
          </tr>
          <tr>
            <td class="td-categorias">
              <label for="categoria-pergunta-todos">
                <input
                  (change)="getPorCategoria()"
                  type="radio"
                  name="categorias-select"
                  value="todos"
                  [(ngModel)]="categoriaSelecionada"
                  class="form-check-input"
                  id="categoria-pergunta-todos"
                  autocomplete="off"
                >
              </label>
            </td>
            <td class="td-categorias">
              <label for="categoria-pergunta-todos" style="width: 100%; height: 100%">
                Todos
              </label>
            </td>
          </tr>
          <tr *ngFor="let categoria of listaCategorias; let i = index">
            <td class="td-categorias">
              <label for="categoria-pergunta-{{i}}">
                <input
                  (change)="getPorCategoria()"
                  type="radio"
                  value="{{categoria}}"
                  [(ngModel)]="categoriaSelecionada"
                  name="categorias-select"
                  class="form-check-input"
                  id="categoria-pergunta-{{i}}"
                  autocomplete="off"
                >
              </label>
            </td>
            <td class="td-categorias">
              <label for="categoria-pergunta-{{i}}" style="width: 100%; height: 100%">
                {{ categoria != null && categoria[0] != null ? categoria[0].toUpperCase() + categoria.substr(1) : 'Sem categorias' }}
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-9">
        <h3>Selecione as perguntas que deseja incluir na pesquisa.</h3>

        <table class="crud-table perguntas-table w-100">
          <tbody>
          <tr
            *ngFor="let pergunta of listaPerguntas; let i = index"
            [class.d-none]="disableTipoResposta(pergunta.tipoResposta)"
          >
            <td class="dt-pergunta">
              <label [class.label-disabled]="disabledImported(pergunta.id)" for="btn-check-{{i}}" style="width: 100%; height: 100%">
                {{ pergunta.pergunta }}
              </label>
            </td>
            <td class="td-input">
              <label for="btn-check-{{i}}">
                <input
                  [disabled]="disabledImported(pergunta.id)"
                  [checked]="checketInput(pergunta.id) || disabledImported(pergunta.id)"
                  (change)="changeSelect(pergunta)"
                  type="checkbox"
                  class="form-check-input dg-checkbox-as-radio lg danger"
                  id="btn-check-{{i}}"
                  autocomplete="off"
                >
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center" dg-footer>
    <button (click)="importarPergunta()" class="dg-btn btn btn-danger m-1">
      OK
    </button>
    <button (click)="modal.close()" class="dg-btn btn btn-secondary m-1">
      Limpar
    </button>
  </div>
</dg-modal>

<div class="ng-autocomplete">
  <ng-template #notFoundTemplate>
    <div [innerHTML]="'Nenhuma pergunta encontrada'"></div>
  </ng-template>
</div>
