import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-respondido-sucesso',
  templateUrl: './respondido-sucesso.component.html',
  styleUrls: ['./respondido-sucesso.component.scss']
})
export class RespondidoSucessoComponent implements OnInit {

  public titulo = '';

  constructor() {
    if(window.sessionStorage.getItem('nome-pesquisa-respondida')) {
      this.titulo = String(window.sessionStorage.getItem('nome-pesquisa-respondida'));
    }
  }

  ngOnInit(): void {}

}
