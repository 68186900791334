import { Injectable } from '@angular/core';
import {AbstractHttpService} from '@datagrupo/dg-crud';
import { ResponderPesquisaEntity } from "../entity/responder-pesquisa-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {HttpService} from "../../../../../../services/http/http.service";
import {SessionService} from "../../../../../../services/session/session.service";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs";
import {AbstractResponderPesquisaEntity} from "../../entity/abstract-responder-pesquisa-entity";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class ResponderPesquisaService extends AbstractHttpService<ResponderPesquisaEntity>{

  constructor(
    public http: HttpClient,
    public actvateRoute: ActivatedRoute,
    private httpService: HttpService,
    private session: SessionService,
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '')
  }

  public findOne(id: number | string, endpoint: string): Observable<any>
  {
    return this.httpService.getById('api/form', id)
  }

  // public update(data: any, endpoint: string | boolean): Observable<ResponderPesquisaEntity> {
  //   const url = this.addEndpoint('api/form/enviar');
  //
  //   data = {
  //     ...data,
  //     pesquisaSnapshotId: data.id,
  //   }
  //
  //   return this.http.post<any>(url, data);
  // }



  public update(data: ResponderPesquisaEntity, endpoint: string | boolean): Observable<ResponderPesquisaEntity> {
    const url = this.addEndpoint('api/form/enviar');

    const listPerguntas = data.perguntaPesquisa.filter(perg => perg.visibleByPredecessor);

    data.respostas.map((resp, i) => {
      const index = listPerguntas.findIndex(perg => resp.perguntaPesquisaId == perg.id)

      if (index < 0) {
        delete data.respostas[i]
      }
    })

    data.respostas = data.respostas.filter(resp => !!resp)

    const newData = {
      ...data,
      id: data.id,
      respondenteId: this.session.user.id,
      pesquisaSnapshotId: data.id
    }

    return this.http.post<any>(url, newData);
  }
}
