import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss']
})
export class CardFooterComponent implements OnInit {

  constructor() { }

  @Input() direction: 'center' | 'start' | 'end' = 'end';

  ngOnInit(): void {
  }

}
