import { Injectable } from '@angular/core';
import {HttpService} from "../../../../../services/http/http.service";
import {Observable} from "rxjs";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {ListaValorIdealPesquisa} from "../entity/lista-valor-ideal-pesquisa";

@Injectable({
  providedIn: 'root'
})
export class PesoIdealPesquisaService {

  constructor(private http: HttpService) { }


  get(id: number): Observable<RestReturn<ListaValorIdealPesquisa>> {
    return this.http.get(`api/matriz-ideal-pesquisa/pergunta-score/${id}`)
  }

  put(data: object) : Observable<RestReturn<any>>{
    return this.http.put('api/valor-ideal-pesquisa', data)
  }
}
