<dg-modal
  #modal
  title=""
  size="md"
  [afterClose]="_close"
>
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Pergunta predecessora</label>
          <select formControlName="pergunta" (change)="changePergunta()" class="form-select">
            <option value="" selected disabled>Selecione uma pergunta predecessora</option>
            <option
              *ngFor="let pergunta of perguntas"
              [value]="pergunta.pergunta.id"
              [disabled]="disablePergunta(pergunta)"
            >
              {{pergunta.pergunta.pergunta}}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-2" *ngIf="!!form.value.pergunta">
        <div class="col-12">
          <label class="form-label">Opção predecessora</label>
          <select formControlName="opcao" class="form-select">
            <option value="" selected disabled>Selecione uma opção predecessora</option>
            <option *ngFor="let opcao of opcoes" [value]="opcao.id">
              {{opcao.opcao}}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>

  <div dg-footer>
    <div class="d-inline-flex justify-content-center w-100">
      <button class="btn m-1 btn-danger" (click)="verifySalvar()">Salvar</button>
      <button class="btn m-1 btn-secondary" (click)="modal.close()">Sair</button>
    </div>
  </div>

</dg-modal>
