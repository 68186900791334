import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {DashboardScoreService} from "../services/dashboard-score.service";
import {ActivatedRoute} from "@angular/router";
import {PerguntaScorePesquisaEntity} from "../../_pesquisas/pages/pergunta-score/entity/pergunta-score-pesquisa-entity";
import Swal from "sweetalert2";
import {SessionService} from "../../../services/session/session.service";



interface dimencoes {
  dimensao: {
    nome: string,
    listPerguntaScorePesquisaDTO: {pergunta: string, pontuacao: number}[],
  },
  pontuacaoPorcentagem: number,
  pontuacaoTotalDimensao: number,
  pontuacaoPorcentagemRelacaoPontuacaoMax: number
}

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit, AfterContentChecked {

  public pontuacaoTotal: number = NaN;
  public buffer: number = 10;
  public color: 'accent' | 'primary' = 'accent';

  public showDimensoesDestaque = false;

  public currentPesquisa: number | string = '';

  public dimencoes: dimencoes[] = [];
  public dimensaoDestaque: dimencoes | undefined;
  public perguntasScore: PerguntaScorePesquisaEntity[] = []

  public showMessageDimensoes = false
  public dimensoesMesage: string = ''

  public showMessagePerguntas = false;
  public showMessagePesquisaJdp = false;

  constructor(
    public service: DashboardScoreService,
    public activatedRoute: ActivatedRoute,
    public session: SessionService
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (!!params.pesquisa) {
        if (params.pesquisa != this.currentPesquisa ) {

          this.currentPesquisa = params.pesquisa;
          this.getById(params.pesquisa);

        }

      } else if (this.currentPesquisa != 'last' && !this.session.issetPesquisa()) {
        this.currentPesquisa = 'last';

        this.getLast()
      }
    })
  }

  ajustaDecimalPontos(d: number | string | undefined){
    const numero = Number(d);

    return (numero).toFixed(2)
  }

  saveResposta(perguntaScore: PerguntaScorePesquisaEntity) {
    this.service.saveResposta({
      id: perguntaScore.id,
      resposta: perguntaScore.resposta
    }).subscribe(
      (resp) => {

        this.perguntasScore.map((pergunta, index) => {
          if (pergunta.resposta === 'NAO_APLICA') {
            this.perguntasScore[index].pontuacaoMax = 0;

            return
          }

          if (resp.data) {
            if (typeof resp.data === "number") {
              this.perguntasScore[index].pontuacaoMax = resp.data
            }
          }
        })

        this.service.getScorePesquisa(this.currentPesquisa !== 'last' ? this.currentPesquisa : undefined).subscribe(
          resp => {
            //@ts-ignore
            this.dimencoes = resp.data.listScoreDimensaoDTO
            //@ts-ignore
            this.pontuacaoTotal = resp.data.pontuacaoTotal
          }
        )
      }
    );
  }

  ajustaDecimal(n: number | undefined): string {
    if (!!n) {
      return String(n.toFixed(2))
    }
    return '0.00';
  }

  verifyNumberTotal() {

    if (this.pontuacaoTotal == 0) {
      return true;
    }
    return !!Number(this.pontuacaoTotal);
  }

  // RESULTADOS NEGADOS

  errorDimensoes(code: string) {
    switch (code) {
      case 'SCR1004':
        this.dimensoesMesage = 'Você possui perguntas score, que possuem perguntas da pesquisa, sem uma matriz ideal'
        break
      case 'SCR1006':
        this.dimensoesMesage = 'É necessário cadastrar um valor ideal para cada pergunta do score que foi associada a uma pergunta da pesquisa'
        break
      case 'SCR1007':
        this.dimensoesMesage = 'É necessário preencher cada opção da Matriz Ideal para que o Score possa ser calculado'
        break
      case 'SCR1008':
        this.dimensoesMesage = 'Pesquisa não possui Matriz Peso associada a ela'
        break
      case 'SCR1010':
        this.dimensoesMesage = 'Existem matrizes de peso não configuradas, portanto não é possivel gerar score'
        break
      case 'SCR1011':
        this.dimensoesMesage = 'Existem matrizes de peso com valores nulos, portanto não é possível gerar score'
        break
      case 'SCR1012':
        this.dimensoesMesage = 'Você não possui nenhuma pergunta cadastrada no score'
        break
      case 'SCR1013':
        this.showMessagePesquisaJdp = true;
        this.dimensoesMesage = 'Não é possível ter score para pesquisa do tipo possuo dados'
        break
      default:
        break
    }

    this.showMessageDimensoes = true;
  }

  // BUSCAS DE DADOS

  getById(idPesquisa: number | string) {
    this.service.getScorePesquisa(idPesquisa).subscribe(
      resp => {
        this.dimencoes = [];

        //@ts-ignore
        if (!resp.data) {
          //@ts-ignore
          this.errorDimensoes(resp.code)
          return;
        }

        this.showMessageDimensoes = false;

        //@ts-ignore
        let listScoreDimensaoDTO: dimencoes[] = resp.data.listScoreDimensaoDTO

        //@ts-ignore
        listScoreDimensaoDTO.map((d) => {

          if (String(d.dimensao.nome).toUpperCase() === 'REPRESENTATIVIDADE') {
            this.dimensaoDestaque = d;
          } else {
            this.dimencoes.push(d);
          }
        });

        //@ts-ignore
        this.pontuacaoTotal = resp.data.pontuacaoTotal
      },
      error => {
        Swal.close()
        this.errorDimensoes(error.error.errorCode)
        return;
      }
    )
    this.service.getPerguntasScore(idPesquisa).subscribe(
      resp => {
        this.perguntasScore = [];
        //@ts-ignore
        if (Array.isArray(resp.data)) {
          //@ts-ignore
          let arr: PerguntaScorePesquisaEntity[] = resp.data;

          arr.map(pergunta => {
            if (pergunta.listPerguntaPesquisa.length == 0) {
              this.perguntasScore.push(pergunta)
            }
          });

          if (this.perguntasScore.length == 0) {
            this.showMessagePerguntas = true;
          }

          return;
        }

        this.showMessagePerguntas = true;

        //@ts-ignore
        // this.perguntasScore = resp.data;
      }
    )
  }

  getLast() {
    this.service.getScorePesquisa().subscribe(
      resp => {
        this.dimencoes = [];

        //@ts-ignore
        if (!resp.data) {
          //@ts-ignore
          this.errorDimensoes(resp.code)
          return;
        }

        this.showMessageDimensoes = false;

        //@ts-ignore
        let listScoreDimensaoDTO: dimencoes[] = resp.data.listScoreDimensaoDTO

        //@ts-ignore
        listScoreDimensaoDTO.map((d) => {
          debugger
          if (String(d.dimensao.nome).toUpperCase() === 'REPRESENTATIVIDADE') {
            console.log(d.dimensao);
            this.dimensaoDestaque = d;
          } else {
            this.dimencoes.push(d);
          }
        });

        //@ts-ignore
        this.pontuacaoTotal = resp.data.pontuacaoTotal
      },
      error => {
        Swal.close()
        this.errorDimensoes(error.error.errorCode)
        return;
      }
    )
    this.service.getPerguntasScore().subscribe(
      resp => {
        this.perguntasScore = [];
        //@ts-ignore
        if (Array.isArray(resp.data)) {
          //@ts-ignore
          let arr: PerguntaScorePesquisaEntity[] = resp.data;

          arr.map(pergunta => {
            if (pergunta.listPerguntaPesquisa.length == 0) {
              this.perguntasScore.push(pergunta)
            }
          });

          if (this.perguntasScore.length == 0) {
            this.showMessagePerguntas = true;
          }

          return;
        }

        this.showMessagePerguntas = true;

        //@ts-ignore
        // this.perguntasScore = resp.data;
      }
    )
  }
}
