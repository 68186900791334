import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AbstractInsertEdit} from "@datagrupo/dg-crud";
import {ResponderJpdEntity} from "../entity/responder-jpd-entity";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {SessionService} from "../../../../../../services/session/session.service";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {ResponderJpdService} from "../services/responder-jpd.service";
import {RespostaJpdEntity} from "../entity/resposta-jpd-entity";
import {
  OpcaoRespostaJpdEntity
} from "../entity/opcao-resposta-jpd-entity";
import Swal from "sweetalert2";
import {TipoResposta} from "../../../../../_cadastros/enum/tipo-resposta";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";

@Component({
  selector: 'app-responder-pesquisa-jpd',
  templateUrl: './responder-pesquisa-jpd.component.html',
  styleUrls: ['./responder-pesquisa-jpd.component.scss']
})
export class ResponderPesquisaJpdComponent extends AbstractInsertEdit<ResponderJpdEntity> implements OnInit  {

  public load = true;
  public titulo = '';

  public verificarPerguntas = false;

  @ViewChild('swallTermo') swal!: SwalComponent;
  @ViewChild('textDescricao') textDescricao!: HTMLDivElement;

  constructor(
    public route: ActivatedRoute,
    public service: ResponderJpdService,
    protected location: Location,
    public session: SessionService,
    public readonly swalTargets: SwalPortalTargets,
    private router: Router,
    private rerender: Renderer2,
    config: ConfigDgCrudService
  ) {
    super(service, config);
    this.subscribeEntityAfterSave = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  voltar () {
    window.sessionStorage.setItem('nome-pesquisa-respondida', String(this.entity.titulo))
    this.router.navigate(['pesquisa/respondida-com-sucesso'])
  }

  afterFetchEntity() {

    this.load = false;

    this.entity.respostas = this.entity.perguntaPesquisa.map((pergunta) => {

      let opcoes: OpcaoRespostaJpdEntity[] = [];

      if (pergunta.tipoResposta === 'CHECKBOX' || pergunta.tipoResposta === 'COMBOBOX' || pergunta.tipoResposta === 'RADIOBUTTON') {

        pergunta.opcoes.map((opcao) => {
          opcoes.push( new OpcaoRespostaJpdEntity(
            opcao.id,
            ''
          ))
        })
      }

      return new RespostaJpdEntity(
        pergunta.pergunta,
        Number(pergunta.id),
        opcoes,
        pergunta.perguntaObrigatoria,
        false
      )
    });

    this.entity.pesquisaSnapshotId = this.entity.id;
    setTimeout(() => this.setTextEditor(), 10)
  }

  initNewEntity(): void {
    this.entity = new ResponderJpdEntity();
  }

  async changeValues(data: {
    resposta: RespostaJpdEntity,
    indexOpcao: number,
    index: number,
    campo: string,
    replaceQuantidades?: boolean
  }) {
    if (data.campo === 'percentual') {

      this.entity.respostas[data.index].percentual = data.resposta.percentual;

      if (data.resposta.percentual) {
        // loop em opções para verificar numeros
        this.entity.respostas[data.index].respostaOpcao.map((opcao, index) => {
          // verificando length
          if (String(data.resposta.respostaOpcao[index].quantidade).length > 4) {

            if (String(data.resposta.respostaOpcao[index].quantidade).indexOf(".") != -1) {

              if (String(data.resposta.respostaOpcao[index].quantidade).length == 5) {

                if (String(data.resposta.respostaOpcao[index].quantidade).indexOf(".") != 2) {

                  this.entity.respostas[data.index].respostaOpcao[index].quantidade =
                    String(data.resposta.respostaOpcao[index].quantidade).slice(0,2)
                    + '.' +
                    String(data.resposta.respostaOpcao[index].quantidade).slice(2,4);
                }
              }

            } else {
              this.entity.respostas[data.index].respostaOpcao[index].quantidade =
                String(data.resposta.respostaOpcao[index].quantidade).slice(0,2)
                + '.' +
                String(data.resposta.respostaOpcao[index].quantidade).slice(2,4);
            }

          } else if (String(data.resposta.respostaOpcao[index].quantidade).length == 4) {
            this.entity.respostas[data.index].respostaOpcao[index].quantidade =
              String(data.resposta.respostaOpcao[index].quantidade).slice(0,2)
              + '.' +
              String(data.resposta.respostaOpcao[index].quantidade).slice(2,4);
          }

        });
      } else {
        this.entity.respostas[data.index].respostaOpcao.map((opcao, index) => {
          this.entity.respostas[data.index].respostaOpcao[index].quantidade =
            this.entity.respostas[data.index].respostaOpcao[index].quantidade.replace('.', '')
        });
      }
    }
    if (data.campo === 'opcao') {

      this.entity.respostas[data.index].respostaOpcao[data.indexOpcao].quantidade = data.resposta.respostaOpcao[data.indexOpcao].quantidade;
    }
    if (data.campo === 'number') {
      this.entity.respostas[data.index].resposta = data.resposta.resposta
    }

    if (this.entity.respostas[data.index]?.perguntaObrigatoria && this.verificarPerguntas) {

      const indicador = this.verificaPerguntaObrigatoria(data.resposta, data.index);

      if (!!indicador) {
        this.entity.respostas[data.index].respostaOk = false;
        this.entity.respostas[data.index].messageError = indicador;
      } else {
        this.entity.respostas[data.index].respostaOk = true;
      }
    }
  }

  openSwall() {
    if (this.verificaAntesSalvar()) {
      // this.salvar('api/form/enviar');
      this.swal.fire();
    }
  }

  verificaAntesSalvar(): boolean {
    this.verificarPerguntas = true;

    if (!this.entity.quantidadeRespostas || this.entity.quantidadeRespostas.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: "Informe o numero de pessoas que responderam essa pesquisa"
      })
      return false;
    }

    const perguntasObrigatorias = this.entity.respostas.findIndex((resposta, index) => {
      if (this.verificaPerguntaObrigatoria(resposta, index)) {
        this.indicaErroResposta();
        this.entity.respostas[index].respostaOk = false;
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas obrigatórias com respostas fora do esperado'
        });
        return true;
      }

      return false
    });

    return perguntasObrigatorias == -1;
  }

  beforeSaveEntity(): boolean {
    this.entity.respostas = this.limpaRespostas(this.entity.respostas);
    this.entity.respostas.map((resosta, index) => {
      this.setZero(resosta, index)
    })

    return true;
  }

  verificaPerguntaObrigatoria(resposta: RespostaJpdEntity, index: number): false | string {
    let contadorResposta = 0;

    resposta.respostaOpcao.map((opcao, indexOpcao) => {
      contadorResposta = contadorResposta + Number(opcao.quantidade);
    })

    if (!this.entity.perguntaPesquisa[index].perguntaObrigatoria) {
      return false;
    }

    if (this.entity.perguntaPesquisa[index].tipoResposta === 'CHECKBOX') {

      if (resposta.percentual) {
        if (contadorResposta < 100) {
          return 'Uma pergunta percentual(%) não pode somar menos de 100%';
        }
      } else {
        if (contadorResposta < Number(this.entity.quantidadeRespostas)) {
          return `Uma pergunta do tipo <b>Caixa de seleção</b> não pode somar um numero menor que o numero de respostas (<b>${this.entity.quantidadeRespostas}</b>)`;
        }
      }

    } else if (this.entity.perguntaPesquisa[index].tipoResposta === 'NUMBER') {
      if (resposta.resposta == '0') {
        return false;
      } else if (!resposta.resposta) {
        return 'Pergunta pbrigatória';
      } else if (String(resposta.resposta)?.trim() == '') {
        return 'Pergunta pbrigatória';
      }
      return false

    } else {

      if (resposta.percentual) {
        if (contadorResposta != 100) {
          if (contadorResposta < 100) {
            return 'Uma pergunta percentual(%) não pode somar menos de 100%';
          }

          // @ts-ignore
          const tipoResposta = TipoResposta[this.entity.perguntaPesquisa[index].tipoResposta]

          return `Uma pergunta percentual(%) do tipo <b>${tipoResposta}</b> não pode somar mais de 100%`;
        }
      } else {
        if (contadorResposta != Number(this.entity.quantidadeRespostas)) {
          // @ts-ignore
          const tipoResposta = TipoResposta[this.entity.perguntaPesquisa[index].tipoResposta]

          return `Uma pergunta do tipo <b>${tipoResposta}</b> não pode somar um numero diferente do numero de respostas (<b>${this.entity.quantidadeRespostas}</b>)`;
        }
      }
    }

    return false;
  }

  indicaErroResposta() {
    this.entity.respostas.map((resposta, index) => {
      const indicador = this.verificaPerguntaObrigatoria(resposta, index);

      if (!!indicador) {
        this.entity.respostas[index].respostaOk = false;
        this.entity.respostas[index].messageError = indicador;
      } else {
        this.entity.respostas[index].respostaOk = true;
      }
    });
  }

  setZero(resposta: RespostaJpdEntity, index: number) {
    resposta.respostaOpcao.map((opcao, indexOpcao) => {

      if (String(opcao.quantidade).trim() === '') {
        this.entity.respostas[index].respostaOpcao[indexOpcao].quantidade = '0';
      }
    })
  }

  limpaRespostas(respostas: RespostaJpdEntity[]):RespostaJpdEntity[] {
    return respostas.map((resposta) => {
      delete resposta.respostaOk;
      delete resposta.messageError;

      return resposta;
    });
  }

  setTextEditor() {
    //@ts-ignore
    const navigate = this.textDescricao.nativeElement;
    this.rerender.setProperty(navigate, 'innerHTML', this.entity?.descricao || '')
  }
}
