import {AfterContentChecked, AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AbstractEntity} from '@datagrupo/dg-crud';
import {CrudDataTableComponent} from '@datagrupo/dg-crud';

@Component({
  selector: 'table-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, AfterContentChecked {

  public currentPage = 0;

  @Input('table') table: CrudDataTableComponent | any;
  @Input('entity') entity: AbstractEntity | undefined;

  constructor() {}

  ngOnInit(): void {
  }

  disabled(data: 'anterior' | 'proximo') {
    if (!!this.entity?.configEntityTable) {
      if (!!this.entity?.configEntityTable.pagination) {
        if (data === 'anterior') {
          return this.entity?.configEntityTable?.pagination.page == 0
        } else {
          return (Number(this.entity?.configEntityTable?.pagination.page) + 1) == this.entity?.configEntityTable?.pagination.totalPages;
        }
      }
    }

    return false;
  }

  verificarMenores(value: number) {
    if (!!this.entity?.configEntityTable) {
      if (!!this.entity?.configEntityTable.pagination) {
        if (this.entity?.configEntityTable?.pagination.page) {
          return ((Number(this.entity.configEntityTable.pagination.page) + 1) - value) > 0
        }
      }
    }

    return false;
  }

  verificarMaiores(value: number) {
    if (!!this.entity?.configEntityTable) {
      if (!!this.entity?.configEntityTable.pagination) {
        if (
          this.entity?.configEntityTable?.pagination.page &&
          this.entity?.configEntityTable?.pagination.totalPages
        ) {
          return ((Number(this.entity.configEntityTable.pagination.page) + 1) + value) <= this.entity.configEntityTable.pagination.totalPages
        }
      }
    }

    return false;
  }

  ngAfterContentChecked() {
    if (!!this.entity?.configEntityTable) {
      if (!!this.entity?.configEntityTable.pagination) {
        if (this.entity?.configEntityTable?.pagination.page) {
          this.currentPage = Number(this.entity.configEntityTable.pagination.page)
        }
      }
    }
  }

  checkApresentacao() {
    return !!this.entity?.configEntityTable?.pagination?.page;
  }

  changePagination(value: number) {
    if (!!this.entity?.configEntityTable) {
      if (!!this.entity?.configEntityTable.pagination) {
        if (value >= Number(this.entity?.configEntityTable?.pagination?.totalPages)) {
          return;
        }
        if (value < 0) {
          return;
        }

        if (this.entity?.configEntityTable?.pagination.page) {
          this.entity.configEntityTable.pagination.page = value
        }

        this.table.pesquisar();
      }
    }
  }

}
