import {
  OpcaoRespostaJpdEntity
} from "./opcao-resposta-jpd-entity";

export class RespostaJpdEntity {


  constructor(
    pergunta?: string,
    perguntaPesquisaId?: number,
    respostaOpcao?: OpcaoRespostaJpdEntity[],
    perguntaObrigatoria?: boolean,
    percentual?: boolean,
    resposta?: string
  ) {
    this.perguntaPesquisaId = perguntaPesquisaId;
    this.pergunta = pergunta;
    this.respostaOpcao = Array.isArray(respostaOpcao) ? respostaOpcao : [];
    this.perguntaObrigatoria = !!perguntaObrigatoria;
    this.respostaOk = true;
    this.percentual = !!percentual ? percentual : false;
    this.resposta = resposta
  }

  public respostaOk?: boolean = false;
  public messageError?: string | undefined = undefined;

  private pergunta: string | undefined;
  public perguntaPesquisaId: number | undefined;
  public respostaOpcao: OpcaoRespostaJpdEntity[] = [];
  public perguntaObrigatoria: boolean = false;
  public quantidade: string | undefined;
  public percentual: boolean = false;
  public resposta: string | undefined;
}
