import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../session/session.service";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    public router: Router,
    public session: SessionService,
    public activatedRoute: ActivatedRoute
  ) { }

  homeUrl() {
    if (this.session.issetPesquisa() && this.session.issetcomparacao()) {

      const pesquisa = this.session.getDachboardPesquisa();
      const comparacao = this.session.getDachboardComparacao();

      let queryParams = {
        pesquisa: pesquisa.pesquisa,
        tipoPesquisa: pesquisa.tipoPesquisa,
        empresa: pesquisa.empresa,
        tipoPesquisaComparacao: comparacao.tipoPesquisaComparacao,
        pesquisaComparacao: comparacao.pesquisaComparacao,
        grupos: this.session.issetGrupos() ? JSON.stringify(this.session.getDachboardGrupos()) : null,
        perguntas: <any> null,
        opcoes: <any> null
      };

      if (this.session.issetGrupos()) {
        queryParams.grupos = JSON.stringify(this.session.getDachboardGrupos())
      }

      if (this.session.issetFiltros()) {
        queryParams.perguntas = this.session.getDachboardFiltros().perguntas
        queryParams.opcoes = this.session.getDachboardFiltros().opcoes
      }

      if (this.router.url.split('?')[0] === '/home') {
        window.location.href = window.location.origin + '/home';
      } else {
        this.router.navigate(
          ['/home'],
          {
            relativeTo: this.activatedRoute,
            queryParams: {...queryParams},
            queryParamsHandling: 'merge',
            replaceUrl: true
          }
        )
      }
      return;

      // atuar para comparação
    } else if (this.session.issetPesquisa()) {
      const pesquisa = this.session.getDachboardPesquisa();
      let queryParams = {
        pesquisa: pesquisa.pesquisa,
        tipoPesquisa: pesquisa.tipoPesquisa,
        empresa: pesquisa.empresa,
        grupos: <any> null,
        perguntas: <any> null,
        opcoes: <any> null
      };

      if (this.session.issetGrupos()) {
        queryParams.grupos = JSON.stringify(this.session.getDachboardGrupos())
      }

      if (this.session.issetFiltros()) {
        const filtros = this.session.getDachboardFiltros()
        queryParams.perguntas = filtros.perguntas;
        queryParams.opcoes = filtros.opcoes;
      }

      if (this.router.url.split('?')[0] === '/home') {
        window.location.href = window.location.origin + '/home';
      } else {
        this.router.navigate(
          ['/home'],
          {
            relativeTo: this.activatedRoute,
            queryParams: {...queryParams},
            queryParamsHandling: 'merge',
            replaceUrl: true
          }
        )
      }
      return;
    }

    this.router.navigate(['/']);
  }
}
