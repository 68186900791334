import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {PesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pesquisa-entity";
import {SessionService} from "../../../../../services/session/session.service";
import {ResponderPesquisaEntity} from "../../responder/responder-pesquisa/entity/responder-pesquisa-entity";
import {RespostaPerguntaPesquisa} from "../../responder/responder-pesquisa/entity/resposta-pergunta-pesquisa";
import {
  ajustFakeIdPredecessorByListPerguntas,
  fakeIdOpcoes,
  fakeIdPerguntas,
  inspectFakeIdPesquisa
} from "../../../helpers/fake-id";

@Component({
  selector: 'app-preview-pesquisa',
  templateUrl: './preview-pesquisa.component.html',
  styleUrls: ['./preview-pesquisa.component.scss']
})
export class PreviewPesquisaComponent implements OnInit {

  public entity: ResponderPesquisaEntity = <ResponderPesquisaEntity> new ResponderPesquisaEntity();
  public load = false;
  public pesquisaAnonima = true;

  @ViewChild('textDescricao') textDescricao!: HTMLDivElement;

  constructor(
    private router: Router,
    public session: SessionService,
    private rerender: Renderer2
  ) {
    if (window.sessionStorage.getItem('entity')) {
      let pesquisa = <PesquisaEntity> JSON.parse(<string>window.sessionStorage.getItem('entity'));
      this.entity.titulo = pesquisa.titulo;
      this.entity.descricao = pesquisa.descricao;
      this.pesquisaAnonima = pesquisa.pesquisaAnonima;

      fakeIdPerguntas(pesquisa.perguntaPesquisa)
      fakeIdOpcoes(pesquisa.perguntaPesquisa)
      ajustFakeIdPredecessorByListPerguntas(pesquisa.perguntaPesquisa)

      this.entity.perguntaPesquisa = pesquisa.perguntaPesquisa;

    } else {
      Swal.fire({
        icon: 'error',
        title: 'A previa da pesquisa que você procura não existe mais.'
      }).then(() => {
        this.router.navigate(['/']);
      })
    }
  }

  ngOnInit(): void {
    this.load = true;

    this.entity.respostas = this.entity.perguntaPesquisa.map((pergunta) => {

      let opcao = undefined;

      if (pergunta.tipoResposta === 'CHECKBOX' || pergunta.tipoResposta === 'COMBOBOX' || pergunta.tipoResposta === 'RADIOBUTTON') {
        opcao = [{
          opcaoPerguntaPesquisaId: '',
          detalhes: ''
        }]
      }

      return new RespostaPerguntaPesquisa(
        Number(pergunta.id),
        '',
        [],
        pergunta.perguntaObrigatoria,
        pergunta.fakeId
      )
    });



    setTimeout(() => this.setTextEditor(), 10)
  }

  changeValues(data: {resposta: RespostaPerguntaPesquisa, index: number, campo: string}) {
    if (data.campo === 'resposta') {
      this.entity.respostas[data.index].resposta = data.resposta.resposta
    }

    if (data.campo === 'opcao') {
      this.entity.respostas[data.index].respostaOpcao = data.resposta.respostaOpcao;
    }
  }

  countAll() {
    return (this.entity.perguntaPesquisa || []).filter(perg => perg.visibleByPredecessor).length
  }

  currentLabel = (i: number) => {
    return this.entity.perguntaPesquisa.filter((perg, index) => {
      if (index <= i) {
        if (!perg.perguntaPesquisaPredecessora || (!!perg.perguntaPesquisaPredecessora && perg.visibleByPredecessor)) {
          return true
        }
      }
      return false
    }).length;
  }

  apresentarPergunta($event: boolean, index: number) {
    this.entity.perguntaPesquisa[index].visibleByPredecessor = $event
  }

  setTextEditor() {
    //@ts-ignore
    const navigate = this.textDescricao.nativeElement;
    this.rerender.setProperty(navigate, 'innerHTML', this.entity?.descricao || '')
  }

}
