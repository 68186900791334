<div id="page-pergunta-matriz-peso-pesquisa" *ngIf="!!entity">

  <div class="container mb-3">
    <div class="row">
      <div class="col-6">
        <label class="form-label color-purple f-bold fs-11">
          Pergunta score(Y)
        </label>
        <div
          class="form-select div-as-select"
          [matTooltip]="viewTootipPerguntas()"
        >
          <span>
            {{ entity.listPerguntaPesquisaYDTOs[0].pergunta }}
          </span>
        </div>
      </div>

      <div class="col-6">
        <label class="form-label color-purple f-bold fs-11">
          Pergunta score(X)
        </label>
        <select
          [(ngModel)]="entity.perguntaPesquisaXId"
          (ngModelChange)="alteraPerguntaX()"
          class="form-select bg-purple"
          style="background-color: white !important;"
          aria-label="Default select example"
        >
          <option disabled value="null">Selecione um setor</option>
          <option
            *ngFor="let pergunta of listPerguntas"
            value="{{pergunta.id}}"
          > {{pergunta.pergunta}} </option>
        </select>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <peso-table-pesquisa [entity]="entity" (saveCelula)="salveCelula($event)">
        </peso-table-pesquisa>
      </div>
    </div>
  </div>
</div>

<div style="background: none; border: none" class="card-footer justify-content-end d-flex">
  <div class="container">
    <div class="row">
      <div class="btn-wrapper text-end text-sm-justify col-12">
        <button (click)="location.back()" class="btn dg-btn btn-purple">
          Voltar
        </button>
      </div>
    </div>
  </div>
</div>
