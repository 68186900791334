import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewPesquisaComponent } from './preview-pesquisa/preview-pesquisa.component';
import {UiModule} from "../../../../components/ui/ui.module";
import { PreviewJpdComponent } from './preview-jpd/preview-jpd.component';



@NgModule({
  declarations: [
    PreviewPesquisaComponent,
    PreviewJpdComponent
  ],
  imports: [
    CommonModule,
    UiModule
  ]
})
export class PreviewPesquisaModule { }
