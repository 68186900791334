import {PerguntasEntity} from "../../perguntas/entity/perguntas-entity";
import {OpcaoRespostaPerguntaEntity} from "../../../entitys/opcao-resposta/opcao-resposta-pergunta-entity";

interface InterfacePerguntaPesquisaModeloEntity {
  id?: number,
  podeEditar?: boolean,
  podeRemover?: boolean,
  perguntaObrigatoria?: boolean,
  pergunta: PerguntasEntity,
  geraFiltro?: boolean,
  nomeFiltro?: string
  perguntaModeloPredecessora?: PerguntaPesquisaModeloEntity,
  opcaoRespostaPredecessora?: OpcaoRespostaPerguntaEntity,
  fakeId?: number
}

export class PerguntaPesquisaModeloEntity {

  constructor(data?: InterfacePerguntaPesquisaModeloEntity) {
    this.id = data?.id;
    this.podeEditar = data?.podeEditar;
    this.podeRemover = data?.podeRemover;
    this.perguntaObrigatoria = data?.perguntaObrigatoria;
    this.geraFiltro = typeof data?.geraFiltro === "boolean" ? data.geraFiltro : undefined;
    this.nomeFiltro = data?.nomeFiltro;
    this.perguntaModeloPredecessora = data?.perguntaModeloPredecessora;
    this.opcaoRespostaPredecessora = data?.opcaoRespostaPredecessora;
    this.fakeId = data?.fakeId;

    if (data?.pergunta) {
      this.pergunta = data.pergunta;
    } else {
      this.pergunta = <PerguntasEntity> new PerguntasEntity();
    }
  }

  public id: number | undefined;
  public podeEditar: boolean | undefined;
  public podeRemover: boolean | undefined;
  public pergunta: PerguntasEntity = <PerguntasEntity> new PerguntasEntity();
  perguntaObrigatoria?: boolean
  public geraFiltro: boolean | undefined;
  public nomeFiltro: string | undefined;
  public grupo: string | undefined;
  perguntaModeloPredecessora: PerguntaPesquisaModeloEntity | undefined;
  opcaoRespostaPredecessora: OpcaoRespostaPerguntaEntity | undefined;
  public fakeId: number | undefined

}
