<dg-card *ngIf="!!entity.listPesoPesquisa">
  <div class="card-body">
    <table class="crud-table w-100">
      <thead>
      <tr>
        <th>
          x
        </th>
        <th
          *ngFor="let coluna of entity.listOpcaoX; let i = index"
          [matTooltip]="coluna.opcao.toUpperCase()"
        >
          Nivel {{i + 1}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        *ngFor="let linha of entity.listOpcaoY; let i = index"
      >
        <td class="dt-opcao">
          {{linha.opcao}}
        </td>
        <td *ngFor="let coluna of entity.listOpcaoX; let i = index"
            style="padding: 1em;"
        >
          <mat-form-field appearance="fill">
            <input
              matInput
              [(ngModel)]="entity.listPesoPesquisa[recolheIndexCelula(coluna.opcao, linha.opcao)].peso"
              (ngModelChange)="emitSave(coluna.opcao, linha.opcao)"
              mask="separator.0" thousandSeparator="" separatorLimit="999"
            >
          </mat-form-field>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</dg-card>
