<div class="container-fluid" id="page-score-pesquisa-insert-edit">
  <div class="row">
    <div class="col-12">
      <h2 style="color: #5B626B">Perguntas Score Pesquisa</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <dg-card>
        <div class="card-body" style="min-height: 18rem">
          <div class="row">
            <div class="col-12" style="padding: 0">
              <table class="crud-table w-100" >
                <thead>
                <tr>
                  <th class="dimensao">
                    Dimensão
                  </th>
                  <th class="pergunta">Pergunta</th>
                  <th class="pontuacao">Pontuação máxima</th>
                  <th class="perguntaModelo">pergunta</th>
                  <th class="nao_se_aplica">Não se aplica</th>
                  <th class="action">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let score of listTamplateScore; let i = index" [class.disabled-row]="score.resposta === 'NAO_APLICA'">
                  <td *ngIf="!score.edit" class="dimensao">{{score.dimensao}}</td>
                  <td *ngIf="!!score.edit" class="dimensao">
                    <mat-form-field appearance="fill">
                    <textarea
                      matInput
                      placeholder="Dimensão"
                      [(ngModel)]="score.dimensao"
                      (ngModelChange)="salvarScore(i)"
                      rows="auto"
                    >
                    </textarea>
                    </mat-form-field>
                  </td>

                  <td *ngIf="!score.edit" class="pergunta">{{score.pergunta}}</td>
                  <td *ngIf="!!score.edit" class="pergunta">
                    <mat-form-field class="example-full-width" appearance="fill">
                    <textarea
                      matInput
                      placeholder="Pergunta"
                      [(ngModel)]="score.pergunta"
                      (ngModelChange)="salvarScore(i)"
                      rows="auto"
                    ></textarea>
                      <mat-icon class="icon-action-text" (click)="score.edit = false" matSuffix>lock</mat-icon>
                    </mat-form-field>
                  </td>

                  <td class="pontuacao" *ngIf="(score.listPerguntaPesquisa.length == 0) || score.resposta === 'NAO_APLICA'">{{ajustaDecimalPontos(score.pontuacaoMax)}}</td>
                  <td class="pontuacao" *ngIf="(score.listPerguntaPesquisa.length > 0) && !(score.resposta === 'NAO_APLICA')">
                    <mat-form-field appearance="fill">
                      <input
                        matInput
                        [(ngModel)]="score.pontuacaoMax"
                        (ngModelChange)="alteraPontos($event, i)"
                        mask="separator.20" thousandSeparator="" separatorLimit="999"
                      >
                    </mat-form-field>
                  </td>

                  <td class="perguntaModelo">
                    <button
                      [disabled]="score.resposta === 'NAO_APLICA'"
                      [class.disabled]="(score.listPerguntaPesquisa.length == 0)"
                      class="button-pergunta" mat-mini-fab
                      (click)="openBuscaPerguntas(i)"

                    >
                      <mat-icon class="icon-pergunta">info</mat-icon>
                    </button>
                  </td>

                  <td class="nao_se_aplica">
                    <button
                      [class.checkedNaoAplica]="score.resposta === 'NAO_APLICA'"
                      class="button-nao_se_aplica" mat-mini-fab
                      (click)="naoSeAplica(i)"
                    >
                      <mat-icon class="icon-nao_se_aplica">block</mat-icon>
                    </button>
                  </td>

                  <td class="action">
                    <button class="btn button-menu" [matMenuTriggerFor]="menu">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                      <button
                        [disabled]="score.resposta === 'NAO_APLICA'"
                        *ngIf="(score.listPerguntaPesquisa.length == 0)"
                        mat-menu-item
                        (click)="openBuscaPerguntas(i)"
                        [style.opacity]="(score.resposta === 'NAO_APLICA' ? .5 : 1)"
                      >
                        Vincular a pergunta
                      </button>
                      <button
                        [disabled]="score.resposta === 'NAO_APLICA'"
                        *ngIf="(score.listPerguntaPesquisa.length > 0)"
                        mat-menu-item
                        (click)="desvincularPergunta(i)"
                        [style.opacity]="(score.resposta === 'NAO_APLICA' ? .5 : 1)"
                      >
                        Desvincular a pergunta
                      </button>

                      <a
                        *ngIf="(score.listPerguntaPesquisa.length > 0) && !(score.resposta === 'NAO_APLICA')"
                        routerLink="/pesquisa/configurar-matriz-peso/{{entityId}}/{{score.id}}"
                        style="text-decoration: none"
                      >
                        <button mat-menu-item>
                          Matriz de peso
                        </button>
                      </a>
                      <button *ngIf="score.resposta === 'NAO_APLICA'" [style.opacity]=".5" mat-menu-item>
                        Matriz de peso
                      </button>

                      <a
                        *ngIf="(score.listPerguntaPesquisa.length > 0) && !(score.resposta === 'NAO_APLICA')"
                        routerLink="/pesquisa/configurar-peso-ideal/{{score.id}}"
                        style="text-decoration: none"
                      >
                        <button mat-menu-item>
                          Valor ideal
                        </button>
                      </a>
                      <button *ngIf="score.resposta === 'NAO_APLICA'" [style.opacity]=".5" mat-menu-item>
                        Valor ideal
                      </button>

                      <button
                        [disabled]="score.resposta === 'NAO_APLICA'"
                        (click)="score.edit = true"
                        mat-menu-item
                        [style.opacity]="(score.resposta === 'NAO_APLICA' ? .5 : 1)"
                      >
                        Editar pergunta
                      </button>
                      <button
                        (click)="removerPrtguntaScore(score.id, i)"
                        mat-menu-item
                      >
                        Remover pergunta
                      </button>
                    </mat-menu>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 d-inline-flex justify-content-end">
              <button
                class="btn dg-btn btn-danger"
                (click)="addPerguntaScore()"
              >
                Adicionar pergunta
              </button>
            </div>
          </div>
        </div>
      </dg-card>
    </div>
  </div>
</div>

<div style="background: none; border: none" class="card-footer justify-content-end d-flex">
  <div class="container">
    <div class="row">
      <div class="btn-wrapper text-end text-sm-justify col-12">
        <button (click)="location.back()" class="btn dg-btn btn-purple">
          Voltar
        </button>
      </div>
    </div>
  </div>
</div>

<modal-busca-perguntas-pesquisa-score (save)="calbackBuscaPergunta($event)" title="" #mudalBuscaPerguntas>
</modal-busca-perguntas-pesquisa-score>

