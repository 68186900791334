import {OpcaoRespostaPerguntaEntity} from "../../../entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {AbstractPergunta} from "../../../entitys/pergunta/abstract-pergunta";
import {DgTableColumn} from '@datagrupo/dg-crud';
import { categorias, tipoResposta } from '../services/source-table';

export class PerguntasEntity extends AbstractPergunta {

    constructor(
      id?: number | string,
      pergunta?: string,
      tipoResposta?: string,
      opcoes?: OpcaoRespostaPerguntaEntity[],
      opcaoRespostaPredecessora?: OpcaoRespostaPerguntaEntity,
      perguntaPredecessora?: PerguntasEntity,
      categoria?: string,
      pontuacao?: number,
      podeEditar?: boolean,
      podeRemover?: boolean,
      geraFiltro?: boolean,
      nomeFiltro?: string,
      grupo?: string
    ) {
        super();
        this.id = id;
        this.pergunta = pergunta;
        this.tipoResposta = !!tipoResposta ? tipoResposta : '';
        this.opcoes = opcoes ? opcoes : [];
        this.categoria = categoria;
        this.pontuacao = pontuacao;
        this.podeEditar = podeEditar;
        this.podeRemover = podeRemover;
        this.geraFiltro = typeof geraFiltro === "boolean" ? geraFiltro : true;
        this.nomeFiltro = nomeFiltro;
        this.grupo = grupo;
        this.opcaoRespostaPredecessora = opcaoRespostaPredecessora;
        this.perguntaPredecessora = perguntaPredecessora;
    }

    @DgTableColumn({ columnName: 'código da pergunta' })
    public id: number | string | undefined;

    @DgTableColumn({ columnName: 'Perguntas' })
    public pergunta: string | undefined;

    @DgTableColumn({ columnName: 'Tipo de Resposta', resource: tipoResposta })
    public tipoResposta:  string | undefined;

    public opcoes: OpcaoRespostaPerguntaEntity[];

    public opcaoRespostaPredecessora: OpcaoRespostaPerguntaEntity | undefined;

    public perguntaPredecessora: PerguntasEntity | undefined;

    @DgTableColumn({ columnName: 'categoria', resource: categorias })
    public categoria: string | undefined;

    // @DgTableColumn({ columnName: 'pontuacao' })
    public pontuacao: number | undefined;

    public podeEditar: boolean | undefined;
    public podeRemover: boolean | undefined;
    public geraFiltro: boolean | undefined;
    public nomeFiltro: string | undefined;
    public grupo: string | undefined;
}
