import { Injectable } from '@angular/core';
import {PerguntasEntity} from "../../../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import {OpcaoRespostaPerguntaEntity} from "../../../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {AbstractPergunta} from "../../../../../../pages/_cadastros/entitys/pergunta/abstract-pergunta";

@Injectable({
  providedIn: 'root'
})
export class OpcaoRespostaService {

  pergunta!: AbstractPergunta;
  index: number = 0;

  constructor() { }

  checkOutros(){
    return this.pergunta.opcoes.findIndex(perg => perg.outros === true) === -1;
  }

  onInputFocus(index: number) {
    let indexCard = this.index;

    setTimeout(function() {
      const element = document.getElementById(`opcaoResposta-${Number(indexCard)}-${index}`);
      if(element)
        element.focus();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.checkOutros()) {
      moveItemInArray(this.pergunta.opcoes, event.previousIndex, event.currentIndex);

    } else {
      const outros = this.pergunta.opcoes.findIndex(perg => perg.outros === true);

      if (outros === event.currentIndex) {
        Swal.fire({
          title: 'Não é possível substituir a opção "Outros..."',
          icon: 'error',
          timer: 10000
        })
      } else {
        moveItemInArray(this.pergunta.opcoes, event.previousIndex, event.currentIndex);
      }
    }
  }

  addOpcaoResposta(index: number, opcao = ''){
    if (this.pergunta.opcoes.length === (index + 1)) {
      // this.pergunta.opcoes.push({opcao: opcao});
      this.pergunta.opcoes.push(<OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity());
    } else {
      this.pergunta.opcoes.splice(
        (index+1), 0,
        <OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity(undefined, opcao))
    }

    this.onInputFocus(index+1);
  }

  addOpcaoRespostaKeyPress(ev: KeyboardEvent, index: number){
    if (ev.key === 'Enter') {
      if (this.pergunta.opcoes.length === (index + 1)) {
        this.addOpcaoResposta(index);
      } else if(this.pergunta.opcoes[(index + 1)].outros){
        this.addOpcaoResposta(index);
      }
    }
  }

  colarOpcao(event: ClipboardEvent, index: number) {
    let pastedText = event.clipboardData?.getData('text');

    if (pastedText) {
      if (pastedText.indexOf("\r\n") > -1) {
        const listOptInsert = pastedText.split("\r\n")

        listOptInsert.map((opc, indexMap) => {
          if(opc.trim() !== ''){

            if (indexMap == 0) {
              this.pergunta.opcoes[index].opcao = opc;
            } else {
              this.addOpcaoResposta((index + (indexMap - 1)), opc);
            }
          }
        });

        return false
      }
    }

    return true;
  }

  alterOutros(index?: number){
    if (index){
      this.pergunta.opcoes.splice(index, 1);
    } else {
      this.pergunta.opcoes.push(
        <OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity(undefined, 'Outros...', true)
      )
    }
  }

  deleteOpcaoResposta(i: number): void {
    this.pergunta.opcoes.splice(i, 1);

    if (this.pergunta.opcoes.length === 0) {
      this.addOpcaoResposta(0);
      this.onInputFocus(0);
    } else if (this.pergunta.opcoes[0].outros) {
      this.addOpcaoResposta(-1);
    }
  }
}
