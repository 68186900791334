import {PerguntasEntity} from "../../_cadastros/pages/perguntas/entity/perguntas-entity";
import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {fakeIdPerguntas} from "./fake-id";
import {perguntaToPerguntaPesquisa} from "./pergunta-to-pergunta-pesquisa";


export function addPerguntaPesqusaBanco(data: {
  perguntas: PerguntasEntity[],
  index: number
}, perguntas: PerguntaPesquisaEntity[]) {
  const listAddPerguntaBanco: PerguntaPesquisaEntity[] = perguntaToPerguntaPesquisa(data.perguntas)

  listAddPerguntaBanco.map((perguntaPesquisa, indexInsert) => {
    perguntaPesquisa.fakeId = fakeIdPerguntas(perguntas)
    perguntas.splice((data.index + indexInsert + 1), 0, perguntaPesquisa);
  });
}
