<dg-modal title="" id="modalSettingsPerguntas" size="md" #modal_settings_pergunta>
  <div dg-content>

    <form [formGroup]="form">
      <div class="row mb-2">
        <div class="col-12 d-inline-flex justify-content-center">
          <mat-slide-toggle formControlName="geraFiltro" class="color-purple">
            <b class="fs-12">Gerar filtro da pergunta</b>
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <label class="form-label">Nome do filtro</label>
          <input formControlName="nomeFiltro" type="text" class="form-control">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <label class="form-label">Grupo</label>
          <input formControlName="grupo" type="text" class="form-control">
        </div>
      </div>
    </form>

  </div>
  <div class="d-flex justify-content-center" dg-footer>
    <button class="dg-btn btn btn-danger m-1" (click)="salvar()">
      Salvar
    </button>
    <button class="dg-btn btn btn-secondary m-1" (click)="close()">
      Descartar
    </button>
  </div>
</dg-modal>
