import { AfterViewChecked, AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { RestReturn } from '../../../../_core/config/rest-return';
import { HttpService } from 'src/app/services/http/http.service';
import {Chart} from "highcharts";

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements AfterViewInit {

  @Input() dataGraphic: any;
  @Input() id: string = 'chart-bar';

  public dataGraphicBar: any


  constructor() {}

  ngAfterViewInit(): void {
    if (window.document.getElementById(this.id)) {
      Highcharts.chart(this.id, this.dataGraphic);
    }
  }
}
