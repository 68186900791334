import {AbstractEntity, DgActionsTable, DgTableColumn} from '@datagrupo/dg-crud';
import {
  ActionsTableHistoricoPesquisaService
} from "../service/action-table-historiso-pesquisa/actions-table-historico-pesquisa.service";

const statusLabelStyle = {
  CONCLUIDA: '<span class="status-label primary" style="width: 70px">Concluída</span>',
  EM_ANDAMENTO: '<span class="status-label success" style="width: 91px">Em Andamento</span>',
  EM_CONSTRUCAO: '<span class="status-label black" style="width: 83px">Em Contrução</span>',
  EM_ANALISE: '<span class="status-label warning" style="width: 70px">Em análise</span>',
  APROVADA: '<span class="status-label purple" style="width: 68px">Aprovada</span>',
  REPROVADA: '<span class="status-label danger" style="width: 70px">Reprovada</span>',
}

const funcRespondido = (qtdRespondido: number, row: any) => {
  if (!!row.quantColabAtivos || !!qtdRespondido) {
    if (typeof row.quantColabAtivos === "number") {
      return `<span data-bs-toggle="tooltip" data-bs-placement="top" title="${qtdRespondido} pessoas responderam">${Math.round(((qtdRespondido / row.quantColabAtivos) * 100))}%</span>`;
    }
  }
  return `<span data-bs-toggle="tooltip" data-bs-placement="top" title="0 pessoas responderam">0%</span>`;
}

@DgActionsTable(ActionsTableHistoricoPesquisaService)
export class HistoricoPesquisaEntity extends AbstractEntity {

  constructor(
    id?: number,
    identificador?: string,
    titulo?: string,
    tipoPesquisa?: string,
    status?: string,
    pesquisaAnonima?: boolean,
    empresaId?: number,
    nomeFantasiaEmpresa?: string,
    dataCriacao?: string,
    dataInicio?: string,
    dataFim?: string,
    pesquisaModeloNome?: string,
    quantColabAtivos?: number,
    quantRespostas?: number,
    meta?: number,
    qtdRespondido?: any,
    codigoTipoPesquisa?: string | number
  ) {
    super();
    this.id = id;
    this.identificador = identificador;

    this.nomeFantasiaEmpresa = nomeFantasiaEmpresa;
    this.pesquisaModeloNome = pesquisaModeloNome;
    this.titulo = titulo;
    this.tipoPesquisa = tipoPesquisa;

    this.dataCriacao = dataCriacao;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.status = status;
    this.meta = meta;
    this.pesquisaAnonima = !!pesquisaAnonima;
    this.qtdRespondido = qtdRespondido;
    this.quantColabAtivos = quantColabAtivos;
    this.codigoTipoPesquisa = codigoTipoPesquisa;
    this.empresaId = empresaId;
  }

  public id: number | undefined;
  public identificador: string | undefined;

  @DgTableColumn({columnName: 'Empresa'})
  public nomeFantasiaEmpresa: string | undefined

  @DgTableColumn({columnName: 'Modelo'})
  public pesquisaModeloNome: string | undefined;

  @DgTableColumn({columnName: 'Titulo'})
  public titulo: string | undefined;

  @DgTableColumn({columnName: 'Tipo'})
  public tipoPesquisa: string | undefined;

  @DgTableColumn({columnName: 'Data Criação', resource: (cel: string) => {
    return cel.replace(' ', '<br>')
  }})
  public dataCriacao: string | undefined;

  @DgTableColumn({columnName: 'Data Inicial', resource: (cel: string) => {
    return cel.replace(' ', '<br>')
  }})
  public dataInicio: string | undefined;

  @DgTableColumn({columnName: 'Data Final', resource: (cel: string) => {
    return cel.replace(' ', '<br>')
  }})
  public dataFim: string | undefined;

  @DgTableColumn({columnName: 'Status', resource: statusLabelStyle})
  public status: string | undefined;

  @DgTableColumn({columnName: '% Meta', resource: (cel: any) => {
      return !!cel ? String(cel) + '%' : '--';
    }})
  public meta: number | undefined;

  public pesquisaAnonima: boolean;

  @DgTableColumn({ columnName: '% Resp.', resource: funcRespondido })
  public qtdRespondido: any;

  public quantColabAtivos: number | undefined;

  public empresaId: number | string | undefined;

  public codigoTipoPesquisa: string | number | undefined;
}
