import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RespostaPerguntaPesquisa} from "../../../../../pages/_pesquisas/pages/responder/responder-pesquisa/entity/resposta-pergunta-pesquisa";
import {AbstractCardRespostaComponent} from "../abstract-card-resposta/abstract-card-resposta.component";
import {PerguntaPesquisaEntity} from "../../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

@Component({
  selector: 'card-resposta',
  templateUrl: './card-resposta.component.html',
  styleUrls: [
    '../scss/custom-cards-responder.scss',
    './card-resposta.component.scss'
  ]
})
export class CardRespostaComponent extends AbstractCardRespostaComponent implements OnInit {

  @Input() resposta: RespostaPerguntaPesquisa = <RespostaPerguntaPesquisa> new RespostaPerguntaPesquisa();
  @Input() preview: boolean = false

  @Output('visibleByPredecessor') visible = new EventEmitter<boolean>()

  public show: boolean = true;
  public touched = false;

  public valueSelect = '';
  public valueOutros = '';

  constructor() {
    super()
  }

  @HostListener('window:verifica-perguntas-obrigatorias', ['$event'])
  verificaPerguntasObrigatorias() {
    if (this.pergunta.perguntaObrigatoria) {
      if (
        this.pergunta.tipoResposta === 'CHECKBOX' ||
        this.pergunta.tipoResposta === 'COMBOBOX' ||
        this.pergunta.tipoResposta === 'RADIOBUTTON'
      ) {
        if (!this.resposta.respostaOpcao) {
          this.perguntaObrigatoriaPendente = true;
          return
        }

        if (!Array.isArray(this.resposta.respostaOpcao)) {
          this.perguntaObrigatoriaPendente = true;
          return
        }

        if (this.resposta.respostaOpcao.length <= 0) {
          this.perguntaObrigatoriaPendente = true;
          return
        }

        if (this.resposta.respostaOpcao[0].opcaoPerguntaPesquisaId === '') {
          this.perguntaObrigatoriaPendente = true;
        }
      } else if(this.pergunta.tipoResposta === 'NUMBER') {
        const respostaString = String(this.resposta.resposta);

        this.perguntaObrigatoriaPendente = (
          respostaString === "+" ||
          respostaString === "e" ||
          respostaString === "," ||
          respostaString === "." ||
          respostaString === "-" ||
          respostaString === ""
        );
      } else {
        if (this.resposta.resposta?.trim() === '') {
          this.perguntaObrigatoriaPendente = true;
        }
      }
    }
  }

  ngOnInit(): void {
    this.show = !this.pergunta.perguntaPesquisaPredecessora;
    this.visible.emit(!this.pergunta.perguntaPesquisaPredecessora);
  }

  changeValue(campo: string){
    this.change.emit({
      resposta: this.resposta,
      index: this.index,
      campo
    })
  }

  changeRadio(id: any) {
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        this.resposta.respostaOpcao = [{
          opcaoPerguntaPesquisaId: id,
          detalhes: ''
        }]
        this.perguntaObrigatoriaPendente = false;

        this.dispacherVisiblePerguntaSucessora()
        this.touched = true;
        this.resposta.respostaOk = true;
      }
    }
  }

  verificarInput() {
    if (this.resposta.resposta !== '') {
      this.perguntaObrigatoriaPendente = false;
    }
    this.changeValue('resposta');
  }

  changeCheckbox(id: any) {
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        if (this.resposta.respostaOpcao.findIndex(resp => resp.opcaoPerguntaPesquisaId === id) === -1) {
          this.resposta.respostaOpcao.push({
            detalhes: '',
            opcaoPerguntaPesquisaId: id
          });

          this.perguntaObrigatoriaPendente = false;
          this.resposta.respostaOk = true;
        } else {
          const index = this.resposta.respostaOpcao.findIndex(resp => resp.opcaoPerguntaPesquisaId === id);
          this.resposta.respostaOpcao.splice(index, 1);

          if (this.resposta.respostaOpcao.length == 0) {
            this.resposta.respostaOk = false;
          }
        }

        this.changeValue('opcao');
        this.dispacherVisiblePerguntaSucessora()
        this.touched = true;
      }
    }
  }

  changeSelect() {
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        if (this.resposta.respostaOpcao.length <= 0) {
          this.resposta.respostaOpcao = [{
            opcaoPerguntaPesquisaId: this.valueSelect,
            detalhes: ''
          }]
        } else {
          this.resposta.respostaOpcao[0].opcaoPerguntaPesquisaId = this.valueSelect;
          this.perguntaObrigatoriaPendente = false;
        }
        this.changeValue('opcao');
        this.dispacherVisiblePerguntaSucessora()
        this.touched = true;
        this.resposta.respostaOk = true;
      }
    }
  }

  outrosCheckBox(data: any, id: any){
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        const index = this.resposta.respostaOpcao.findIndex(resp => resp.opcaoPerguntaPesquisaId === id);
        this.resposta.respostaOpcao[index].detalhes = data;
        this.perguntaObrigatoriaPendente = false;

        this.changeValue('opcao');
      }
    }
  }

  outrosRadio(data: any, id: any) {
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        this.resposta.respostaOpcao[0].detalhes = data;
        this.perguntaObrigatoriaPendente = false;

        this.changeValue('opcao');
      }
    }
  }

  disableOutrosCheckbox(id: any): boolean {
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        return (this.resposta.respostaOpcao.findIndex(resp => resp.opcaoPerguntaPesquisaId === id) === -1)
      }
    }

    return false;
  }

  disableOutrosSelect(id: any): boolean {
    if (this.resposta.respostaOpcao) {
      if (Array.isArray(this.resposta.respostaOpcao)) {
        if (!!this.resposta.respostaOpcao[0].opcaoPerguntaPesquisaId) {
          return !(this.resposta.respostaOpcao[0].opcaoPerguntaPesquisaId === id)
        }
      }
    }

    return false;
  }

  // bloqueio pergunta predecessora

  @HostListener('window:visible-pergunta-sucessora', ['$event'])
  visiblePerguntaSucessora(ev: CustomEvent) {
    if (this.preview) {
      this.visiblePerguntaSucessoraPreview(ev);
      return;
    }

    const perguntaPred = <PerguntaPesquisaEntity>ev.detail.pergunta
    const respostaPred = <RespostaPerguntaPesquisa>ev.detail.resposta
    if (!!this.pergunta.perguntaPesquisaPredecessora) {

      if (this.pergunta.perguntaPesquisaPredecessora.id == perguntaPred.id) {

        const index = respostaPred.respostaOpcao?.findIndex(resp => resp.opcaoPerguntaPesquisaId == this.pergunta.opcaoPerguntaPesquisaPredecessora?.id)

        if (typeof index != "number") {
          this.dispacherVisiblePerguntaSucessoraCascata();
          this.visible.emit(false)
          this.show = false
          return;
        }

        const result = index >= 0

        if (result) {
          this.dispacherVisiblePerguntaSucessora()
        } else {
          this.dispacherVisiblePerguntaSucessoraCascata();
        }

        this.visible.emit(result)
        this.show = result;
      }

    }
  }

  visiblePerguntaSucessoraPreview(ev: CustomEvent) {
    const perguntaPred = <PerguntaPesquisaEntity>ev.detail.pergunta
    const respostaPred = <RespostaPerguntaPesquisa>ev.detail.resposta
    if (!!this.pergunta.perguntaPesquisaPredecessora) {

      if (this.pergunta.perguntaPesquisaPredecessora.fakeId == perguntaPred.fakeId) {

        const index = respostaPred.respostaOpcao?.findIndex(resp => resp.opcaoPerguntaPesquisaId == this.pergunta.opcaoPerguntaPesquisaPredecessora?.id)

        if (typeof index != "number") {
          this.dispacherVisiblePerguntaSucessoraCascata();
          this.visible.emit(false)
          this.show = false
          return;
        }

        const result = index >= 0

        if (result) {
          this.dispacherVisiblePerguntaSucessora()
        } else {
          this.dispacherVisiblePerguntaSucessoraCascata();
        }

        this.visible.emit(result)
        this.show = result;
      }

    }
  }

  @HostListener('window:visible-pergunta-sucessora-cascata', ['$event'])
  visiblePerguntaSucessoraCascata(ev: CustomEvent) {
    const perguntaPred = <PerguntaPesquisaEntity>ev.detail.pergunta
    if (!!this.pergunta.perguntaPesquisaPredecessora) {

      if (this.pergunta.perguntaPesquisaPredecessora.id == perguntaPred.id) {
        this.visible.emit(false)
        this.show = false

        this.dispacherVisiblePerguntaSucessoraCascata();
      }
    }
  }

  dispacherVisiblePerguntaSucessora() {
    window.dispatchEvent(new CustomEvent(
      'visible-pergunta-sucessora',
      {
        detail: {
          pergunta: this.pergunta,
          resposta: this.resposta
        }
      }
    ))
  }

  dispacherVisiblePerguntaSucessoraCascata() {
    window.dispatchEvent(new CustomEvent(
      'visible-pergunta-sucessora-cascata',
      {
        detail: {
          pergunta: this.pergunta
        }
      }
    ))
  }

  @HostListener('window:markAsTouched-pergunta')
  markAsTouched() {
    if (this.pergunta.visibleByPredecessor) {
      this.touched = true
    }
  }

  inputFocusout(pergunta: PerguntaPesquisaEntity) {
    if (this.disableCard) {
      this.resposta.resposta = '';
      return;
    }

    this.resposta.respostaOk = !!this.resposta.resposta
    this.touched = true;
  }

}
