import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {fakeIdPerguntas, inspectFakeIdPesquisa} from "./fake-id";


export function addPerguntaPesquisa(perguntas: PerguntaPesquisaEntity[], index?: number) {
  const novaPergunta = <PerguntaPesquisaEntity>new PerguntaPesquisaEntity();
  novaPergunta.status = ['ADICIONADA', 'NOVA'];
  novaPergunta.fakeId = fakeIdPerguntas(perguntas);


  if (typeof index === 'number') {
    perguntas.splice((index + 1), 0, novaPergunta);
  } else {
    perguntas.push(novaPergunta);
  }
}
