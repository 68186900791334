import {AbstractEntity, DgActionsTable, DgTableColumn} from '@datagrupo/dg-crud';
import {PerguntaPesquisaModeloEntity} from "./pergunta-pesquisa-modelo-entity";
import {TipoPesquisaEntity} from "../../tipo-pesquisa/entity/tipo-pesquisa-entity";
import {
  ActionsTablePesquisaModeloService
} from "../services/actions-table-pesquisa-modelo/actions-table-pesquisa-modelo.service";
import {PerguntaScorePesquisaModeloEntity} from "./pergunta-score-pesquisa-modelo-entity";

interface InterfaceMatrizPesoModeloNome {
  nome: string,
  id?: number | string,
  pesquisaModeloId: number | string
}

@DgActionsTable(ActionsTablePesquisaModeloService)
export class PesquisaModeloEntity extends AbstractEntity {

  constructor(
    id?: number,
    nome?: string,
    tipoPesquisa?: TipoPesquisaEntity,
    perguntas?: PerguntaPesquisaModeloEntity[],
    perguntasScore?: PerguntaScorePesquisaModeloEntity[],
    titulo?: string,
    descricao?: string,
    podeEditar?: boolean,
    podeRemover?: boolean,
    podeReordenar?: boolean,
    podeAdicionarBanco?: boolean,
    podeAdicionarNova?: boolean,
    matrizPesoModeloNome?: InterfaceMatrizPesoModeloNome
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.tipoPesquisa = !!tipoPesquisa ? tipoPesquisa : <TipoPesquisaEntity> new TipoPesquisaEntity();
    this.perguntas = perguntas ? perguntas : [<PerguntaPesquisaModeloEntity> new PerguntaPesquisaModeloEntity()];
    this.titulo = titulo;
    this.descricao = descricao;
    this.podeEditar = podeEditar;
    this.podeRemover = podeRemover;
    this.podeReordenar = podeRemover;
    this.podeAdicionarBanco = !!podeAdicionarBanco;
    this.podeAdicionarNova = !!podeAdicionarNova;
    this.matrizPesoModeloNome = matrizPesoModeloNome;
  }

  @DgTableColumn({columnName: 'nome do modelo'})
  public nome: string | undefined;

  public perguntas: PerguntaPesquisaModeloEntity[] = []
  @DgTableColumn({columnName: 'tipo da pesquisa', resource: (e:{ id?: number, nome?: string }) => {
      return e.nome;
    }})
  public tipoPesquisa: TipoPesquisaEntity = <TipoPesquisaEntity> new TipoPesquisaEntity();

  @DgTableColumn({columnName: 'código do modelo'})
  public id: number | undefined;

  public titulo: string | undefined;
  public descricao: string | undefined;
  public podeEditar: boolean | undefined;
  public podeRemover: boolean | undefined;
  public podeReordenar: boolean | undefined;
  public podeAdicionarNova: boolean | undefined;
  public podeAdicionarBanco: boolean | undefined;
  public matrizPesoModeloNome: InterfaceMatrizPesoModeloNome | undefined;
  perguntasScore: PerguntaScorePesquisaModeloEntity[] | undefined;

}
