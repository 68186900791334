import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {
  OpcaoRespostaPerguntaEntity
} from "../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";

export interface retornoperguntaModeloPredecessora {
  perguntaPredecessora: PerguntaPesquisaModeloEntity,
  opcaoRespostaPredecessora: OpcaoRespostaPerguntaEntity,
  index: number
}

@Component({
  selector: 'modal-relacao-pergunta-modelo-predecessora',
  templateUrl: './modal-relacao-pergunta-modelo-predecessora.component.html',
  styleUrls: ['./modal-relacao-pergunta-modelo-predecessora.component.scss']
})
export class ModalRelacaoPerguntaModeloPredecessoraComponent implements OnInit {

  @Input('listPerguntas') listPerguntas: PerguntaPesquisaModeloEntity[] = [];
  @ViewChild('modal') modal!: ModalComponent;
  @Output('salvar') salvar = new EventEmitter<retornoperguntaModeloPredecessora>()

  private entity: PerguntaPesquisaModeloEntity | undefined | null;
  private index: number | null = null;

  public perguntas: PerguntaPesquisaModeloEntity[] = [];
  public opcoes: OpcaoRespostaPerguntaEntity[] = [];

  public form = new FormGroup({
    pergunta: new FormControl('', [Validators.required]),
    opcao: new FormControl('', [Validators.required])
  })

  constructor() {}

  ngOnInit(): void {}

  open(perguntaFilha: PerguntaPesquisaModeloEntity, index: number) {
    if (!perguntaFilha) return
    if (index < 0) return

    this.entity = perguntaFilha;
    this.index = index;

    this.perguntas = this.listPerguntas.filter(perg => {

      return perg.pergunta.tipoResposta == 'COMBOBOX' ||
        perg.pergunta.tipoResposta == 'RADIOBUTTON' ||
        perg.pergunta.tipoResposta =='CHECKBOX'
    })

    if (perguntaFilha.perguntaModeloPredecessora && perguntaFilha.opcaoRespostaPredecessora) {
      this.form.patchValue({
        pergunta: perguntaFilha.perguntaModeloPredecessora.id || '',
        opcao: perguntaFilha.opcaoRespostaPredecessora.id || ''
      })

      this.changePergunta();
    }

    this.modal.open()
  }

  changePergunta() {
    const perguntaId = this.form.value.pergunta;
    const index = this.perguntas.findIndex(perg => perg.pergunta.id == perguntaId)

    this.opcoes = this.perguntas[index].pergunta.opcoes;
  }

  verifySalvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    const form = this.form.value;

    const indexPergunta = this.perguntas.findIndex(perg => perg.pergunta.id == form.pergunta)
    const indexOpcao = this.opcoes.findIndex(opcao => opcao.id == form.opcao)

    if (indexPergunta >= 0 && indexOpcao >= 0) {
      this.salvar.emit({
        perguntaPredecessora: this.perguntas[indexPergunta],
        opcaoRespostaPredecessora: this.opcoes[indexOpcao],
        index: typeof this.index == 'number' ? this.index : -1
      })
      this.modal.close()
    }
  }

  _close = () => {
    this.form.reset({
      pergunta: '',
      opcao: ''
    })
    this.entity = null;
  }

  disablePergunta(pergunta: PerguntaPesquisaModeloEntity): boolean {
    if (!this.entity) return true;

    return this.entity.pergunta.id == pergunta.pergunta?.id;
  }
}
