import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerguntaScoreInsertEditComponent } from './pergunta-score-insert-edit/pergunta-score-insert-edit.component';
import {UiModule} from "../../../../components/ui/ui.module";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {NgxMaskModule} from "ngx-mask";



@NgModule({
  declarations: [
    PerguntaScoreInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    NgxMaskModule
  ]
})
export class PerguntaScoreModule { }
