<input
  #fileUpload
  type="file"
  (change)="onFileSelected($event)"
  style="display: none"
  accept="application/pdf"
>

<div class="file-upload">

  <button [disabled]="load" mat-mini-fab color="primary" class="upload-btn"
          (click)="fileUpload.click()">
    <mat-icon>attach_file</mat-icon>
<!--    <mat-spinner></mat-spinner>-->
  </button>

  {{fileName || legenda}}
</div>
