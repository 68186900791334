import {AbstractEntity} from "@datagrupo/dg-crud";
import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

export abstract class AbstractResponderPesquisaEntity extends AbstractEntity {

  public respostaOk = false;

  constructor() {
    super();
  }

  public id: number | undefined;
  public perguntaPesquisa: PerguntaPesquisaEntity[] = [];
  public titulo: string | undefined;
  public descricao: string | undefined;
  public pesquisaSnapshotId: number | undefined;
  public dataCriacao: string | undefined;

}
