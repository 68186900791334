import {AbstractEntity, DgActionsTable, DgTableColumn} from '@datagrupo/dg-crud';
import {ActionsTableTipoPesquisaService} from "../services/action-table-usuarios/actions-table-tipo-pesquisa.service";

@DgActionsTable(ActionsTableTipoPesquisaService)
export class TipoPesquisaEntity extends AbstractEntity {

  constructor(
    id?: number,
    nome?: string,
    codigo?: string,
    tipoResposta?: string[]
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.codigo = codigo;
    this.tipoResposta = Array.isArray(tipoResposta) ? tipoResposta : []
  }

  @DgTableColumn({columnName: 'Código do Tipo de pesquisa'})
  public id: number | undefined;

  @DgTableColumn({columnName: 'Nome'})
  public nome: string | undefined;

  @DgTableColumn({columnName: 'Código'})
  public codigo: string | undefined;
  public tipoResposta: string[] = [];
}
