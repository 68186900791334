import { Injectable } from '@angular/core';
import {AbstractHttpService} from '@datagrupo/dg-crud';
import {TipoRespostaEntity} from "../../entity/tipo-resposta-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs";
import {RestReturn} from "../../../../../../_core/config/rest-return";
import {InterfaceTiposResposta} from "../perguntas.service";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class TipoRespostaService extends AbstractHttpService<TipoRespostaEntity>{

  constructor(
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/resposta/tipo');
  }
  getTipos():Observable<RestReturn<TipoRespostaEntity[]>> {
    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.get<RestReturn<TipoRespostaEntity[]>>(environment.apiUrl + '/api/resposta/tipo', { headers: { Authorization } })
  }

  deleteById(id: string):Observable<RestReturn<TipoRespostaEntity[]>> {

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.delete<RestReturn<TipoRespostaEntity[]>>(`${environment.apiUrl}/api/pergunta/opcao/${id}`, { headers: { Authorization } })
  }
}
