import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

interface InterfacePreToken {
  sub: string | number,
  exp: number,
  profile?: string[]
}

interface InterfaceToken {
  sub: string | number,
  exp: number,
  profile?: 'RESPONDENTE' | 'GESTOREMPRESA' | 'GESTORTROCA'
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }

  public abrirToken(token?: string): InterfaceToken {
    if (!token) {
      token = String(window.localStorage.getItem('accessToken'));
    }
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  public checkExp(tokenExp: string | any) {

    if (!tokenExp) {
      return false
    }

    const exp = tokenExp * 1000;

    return exp > new Date().getTime();


  }

  public getToken(){
    return window.localStorage.getItem('accessToken');
  }

  refreshToken(token: string) {
    return this.http.post(environment.apiUrl + '/auth/refresh', {
      accessToken: token,
      refreshToken: String(window.localStorage.getItem('refreshToken'))
    }, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem('accessToken');
    window.localStorage.setItem('accessToken', token);
  }
}
