import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PesoIdealPesquisaService} from "../service/peso-ideal-pesquisa.service";
import {ValorIdealModeloEntity} from "../../../../_cadastros/pages/pesquisa-modelo/entity/valor-ideal-modelo-entity";
import {ListaValorIdealPesquisa} from "../entity/lista-valor-ideal-pesquisa";
import {ValorIdealPesquisaEntity} from "../entity/valor-ideal-pesquisa-entity";
import {Location} from "@angular/common";

@Component({
  selector: 'app-peso-ideal-pesquisa',
  templateUrl: './peso-ideal-pesquisa.component.html',
  styleUrls: ['./peso-ideal-pesquisa.component.scss']
})
export class PesoIdealPesquisaComponent implements OnInit {

  public entityId!: number;
  public entity!: ListaValorIdealPesquisa;


  constructor(
    public activatedRoute: ActivatedRoute,
    public service: PesoIdealPesquisaService,
    public location: Location
  ) {
    this.activatedRoute.params.subscribe(params => {

      if (params.id) {
        this.entityId = params.id;

        this.service.get(params.id).subscribe(
          resp => {
            if (!!resp.data) {
              this.entity = resp.data
            }
          }
        )
      }
    })
  }

  ngOnInit(): void {
  }

  alteraValor(valorIdeal: ValorIdealPesquisaEntity) {
    const data = {
      id: valorIdeal.id,
      matrizIdealPesquisa: { id: valorIdeal?.matrizIdealPesquisaId },
      opcaoPerguntaPesquisa: { id: valorIdeal.opcaoPerguntaPesquisaYId},
      perguntaScorePesquisa: { id: valorIdeal.perguntaScorePesquisaId },
      valorIdeal: valorIdeal.valorIdeal
    }

    this.service.put(data).subscribe(
      resp => {
        console.log(resp.data);
      }
    )
  }

}
