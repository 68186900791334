import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {fakeIdPerguntas} from "./fake-id";
import {OpcaoPerguntaPesquisaEntity} from "../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";

export function copyPerguntaPesquisa(index: number, perguntas: PerguntaPesquisaEntity[]) {

  const pergunta = <PerguntaPesquisaEntity>{...perguntas[index]};
  const novaPergunta = new PerguntaPesquisaEntity();

  if (pergunta.pergunta?.slice(-1) === '?') {
    novaPergunta.pergunta = pergunta.pergunta?.slice(0, pergunta.pergunta?.length - 1) + ' (cópia)'
  } else {
    novaPergunta.pergunta = pergunta.pergunta + ' (cópia)';
  }

  novaPergunta.status = ['ADICIONADA', 'NOVA'];

  novaPergunta.categoria = pergunta.categoria;
  novaPergunta.perguntaObrigatoria = !!pergunta.perguntaObrigatoria;
  novaPergunta.podeEditar = true;
  novaPergunta.podeRemover = true;
  novaPergunta.tipoResposta = pergunta.tipoResposta;
  novaPergunta.fakeId = fakeIdPerguntas(perguntas)

  pergunta.opcoes.map((opc => {
    novaPergunta.opcoes.push(
      new OpcaoPerguntaPesquisaEntity(undefined, opc.opcao, !!opc.outros, undefined)
    )
  }));

  perguntas.splice((index + 1), 0, novaPergunta);
}
