import {PerguntaPesquisaEntity} from "../../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {
  RespostaPerguntaPesquisa
} from "./resposta-pergunta-pesquisa";
import {AbstractResponderPesquisaEntity} from "../../entity/abstract-responder-pesquisa-entity";


export class ResponderPesquisaEntity extends AbstractResponderPesquisaEntity {
  constructor(
    id?: number,
    titulo?: string,
    descricao?: string,
    perguntaPesquisa?: PerguntaPesquisaEntity[],
    dataCriacao?: string,
    respostas?: RespostaPerguntaPesquisa[]
  ) {
    super();
    this.id = id;
    this.perguntaPesquisa = Array.isArray(perguntaPesquisa) ? perguntaPesquisa : [];
    this.titulo = titulo;
    this.descricao = descricao;
    this.respostas = Array.isArray(respostas) ? respostas : [];
    this.pesquisaSnapshotId = id;
    this.dataCriacao = dataCriacao;
  }

  public respostas: RespostaPerguntaPesquisa[] = [];
}
