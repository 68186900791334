import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {InterfaceMenuList} from "../../../_core/menu/menu.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../services/session/session.service";
import {UtilService} from "../../../services/helpers/util/util.service";

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

  @Input() menu: InterfaceMenuList[] = [];

  constructor(
    public router: Router,
    public session: SessionService,
    public activatedRoute: ActivatedRoute,
    public util: UtilService
  ) { }

  ngOnInit(): void {
  }
}
