import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {PerguntaModeloToPerguntaPesquisa} from "./perguntaModelo-to-perguntaPesquisa";
import {RestReturn} from "../../../_core/config/rest-return";
import {PerguntasEntity} from "../../_cadastros/pages/perguntas/entity/perguntas-entity";
import {
  OpcaoPerguntaPesquisaEntity
} from "../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {PesquisaModeloEntity} from "../../_cadastros/pages/pesquisa-modelo/entity/pesquisa-modelo-entity";
import {HttpService} from "../../../services/http/http.service";
import Swal from "sweetalert2";
import {fakeIdPerguntas} from "./fake-id";


export function RestaurarPerguntaPesquisa(
  data: { pergunta: PerguntaPesquisaEntity, index: number, statusMode: string },
  perguntas: PerguntaPesquisaEntity[],
  perguntasModelo: PesquisaModeloEntity[],
  pesquisaModelo: PesquisaModeloEntity,
  http: HttpService
) {
  const indexModelo = perguntasModelo.findIndex((modelo) => modelo.id === pesquisaModelo?.id)

  //const fakeId = data.pergunta.fakeId || fakeIdPerguntas(perguntas)

  if (data.statusMode === 'ORIGINAL-ALTERADA') {
    const indexPergunta = perguntasModelo[indexModelo].perguntas.findIndex((pergunta) =>
      pergunta.pergunta.id === data.pergunta.perguntaOriginalId)

    if (indexPergunta !== -1) {
      perguntas[data.index] =
        PerguntaModeloToPerguntaPesquisa(
          perguntasModelo[indexModelo].perguntas[indexPergunta], perguntas, perguntas[data.index]
        );


      //perguntas[data.index].fakeId = fakeId;

      perguntas[data.index].opcoes.map((opc, indexOpc) => {
        const indexOpcModelo = perguntasModelo[indexModelo].perguntas[indexPergunta].pergunta.opcoes
          .findIndex(opcModelo => opcModelo.id == opc.opcaoOriginalId)

        if (indexOpcModelo != -1) {


          perguntas[data.index].opcoes[indexOpc].fakeId =
            perguntasModelo[indexModelo].perguntas[indexPergunta].pergunta.opcoes[indexOpcModelo].fakeId
        }
      })
    }
  } else {
    http.getById<RestReturn<PerguntasEntity>>('api/pergunta', String(data.pergunta.perguntaOriginalId)).subscribe(next => {

      const opcoes = <OpcaoPerguntaPesquisaEntity[]>next.data?.opcoes.map((opcao, indexOpcPergunta) => {

        return <OpcaoPerguntaPesquisaEntity>new OpcaoPerguntaPesquisaEntity(
          undefined,
          opcao.opcao,
          opcao.outros,
          Number(opcao.id),
        );
      });

      perguntas[data.index] = new PerguntaPesquisaEntity(
        undefined,
        next.data?.id,
        undefined,
        next.data?.pergunta,
        next.data?.tipoResposta,
        opcoes,
        undefined,
        undefined,
        next.data?.categoria,
        ['ADICIONADA', 'BANCO DE DADOS'],
        next.data?.podeEditar,
        next.data?.podeRemover,
        false
      );
    })
  }
}
