<div class="page-peso-ideal-pesquisa">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10">
        <h2 style="color: #5B626B">Matriz Valor Ideal Pesquisa</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <dg-card class="col-10" *ngIf="entity">
        <div class="card-body">
          <table class="crud-table w-100">
            <thead>
            <tr>
              <th style="width: 75%;">Opção</th>
              <th style="width: 15%; text-align: center">Valor ideal</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let peso of entity.listValorIdealPesquisas; let i = index">
              <td style="width: 75%;">{{peso.opcaoPerguntaPesquisaYTitulo}}</td>
              <!--                <td style="width: 15%;">{{peso.valorIdeal | json }}</td>-->
              <td style="width: 15%;">
                <mat-form-field appearance="fill">
                  <input
                    style="text-align: center"
                    matInput
                    [(ngModel)]="peso.valorIdeal"
                    (ngModelChange)="alteraValor(peso)"
                    mask="separator.20" thousandSeparator="" separatorLimit="999"
                  >
                </mat-form-field>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </dg-card>
    </div>
  </div>
</div>
<div style="background: none; border: none" class="card-footer justify-content-end d-flex">
  <div class="container">
    <div class="row">
      <div class="btn-wrapper text-end text-sm-justify col-11">
        <button (click)="location.back()" class="btn dg-btn btn-purple">
          Voltar
        </button>
      </div>
    </div>
  </div>
</div>
