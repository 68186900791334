<!-- TITULO E BTN FILTROS -->
<div check-perfil [perfil]="['GESTORTROCA', 'GESTOREMPRESA']" class="container dashboard-page">
  <div class="row align-items-center">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div class="col-12 d-inline-block align-items-center">
        <span  style="color: #5B626B; font-size: 1.7em" class="font-poppins">Dashboard</span>
      </div>
      <div *ngIf="!!dataPesquisa[0]" check-perfil [perfil]="['GESTORTROCA']" class="col-12 d-inline-block align-items-center">
        <span style="font-size: 1em" class="font-poppins color-purple f-bold">{{ dataPesquisa[0].empresaNome }}</span>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-inline-flex justify-content-end align-items-center">

      <mat-form-field appearance="fill" *ngIf="listGroup.length > 0">
        <mat-label>Grupos</mat-label>
        <mat-select
          multiple
          (ngModelChange)="changeArrayGraph($event)"
          [(ngModel)]="selectedGroup"
        >
          <mat-option *ngFor="let grupos of listGroup" [value]="grupos">{{grupos}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button
        style="width: auto"
        class="btn dg-btn btn-purple-outline-fundo-branco d-inline-flex align-items-center"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <span>Filtrar</span>
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>



  </div>
</div>

<!-- CARDS DE DADOS DA PESQUISA -->
<div *ngIf="!!exibirCards" class="container dashboard-page">

  <div
    *ngIf="exibirCards === 'comparacao' || exibirCards === 'pesquisa'"
    class="row mt-3 row-cards-info-pesquisa justify-content-center"
    [class.comparacao]="exibirCards === 'comparacao'"
  >
    <div class="col-lg-2-4 col-md-4 col-sm-12 mb-3">
      <dg-card>
        <div class="container-fluid">
          <div class="row">

            <div class="col-12 d-flex justify-content-center">
              <span class="f-bold color-purple">Status</span>
            </div>

            <hr>

            <div class="col-12 d-flex justify-content-center">
              <span
                class="status-label primary"
                [class.primary]="dataPesquisa[0].status === 'CONCLUIDA'"
                [class.success]="dataPesquisa[0].status === 'EM_ANDAMENTO'"
                [class.black]="dataPesquisa[0].status === 'EM_CONSTRUCAO'"
                [class.warning]="dataPesquisa[0].status === 'EM_ANALISE'"
                [class.purple]="dataPesquisa[0].status === 'APROVADA'"
                [class.danger]="dataPesquisa[0].status === 'REPROVADA'"
              >
                {{status(dataPesquisa[0].status)}}
              </span>
            </div>

            <hr *ngIf="exibirCards === 'comparacao'">

            <div style="margin: 0"  class="col-12 d-flex justify-content-center" *ngIf="exibirCards === 'comparacao'">
              <span
                class="status-label primary"
                [class.primary]="dataPesquisa[1].status === 'CONCLUIDA'"
                [class.success]="dataPesquisa[1].status === 'EM_ANDAMENTO'"
                [class.black]="dataPesquisa[1].status === 'EM_CONSTRUCAO'"
                [class.warning]="dataPesquisa[1].status === 'EM_ANALISE'"
                [class.purple]="dataPesquisa[1].status === 'APROVADA'"
                [class.danger]="dataPesquisa[1].status === 'REPROVADA'"
              >
                {{status(dataPesquisa[1].status)}}
              </span>
            </div>
          </div>
        </div>
      </dg-card>
    </div>

    <div class="col-lg-2-4 col-md-4 col-sm-12 mb-3">
      <dg-card>
        <div class="container-fluid">
          <div class="row">

            <div class="col-12 d-flex justify-content-center">
              <span matTooltip="Pessoas ativas na empresa" matTooltipPosition="above" class="f-bold color-purple">Pessoas ativas</span>
            </div>

            <hr>
            <div class="col-12 d-flex justify-content-center">
              <h3>{{dataPesquisa[0].quantColabAtivos}}</h3>
            </div>

            <hr *ngIf="exibirCards === 'comparacao'">

            <div class="col-12 d-flex justify-content-center" *ngIf="exibirCards === 'comparacao'">
              <h3>{{dataPesquisa[1].quantColabAtivos}}</h3>
            </div>
          </div>
        </div>
      </dg-card>
    </div>

    <div class="col-lg-2-4 col-md-4 col-sm-12 mb-3">
      <dg-card>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <span class="f-bold color-purple">Respostas esperadas</span>
            </div>

            <hr>

            <div class="col-12 d-flex justify-content-center">
              <h3>{{percentualRespondido(dataPesquisa[0].meta)}}</h3>
            </div>

            <hr *ngIf="exibirCards === 'comparacao'">

            <div class="col-12 d-flex justify-content-center" *ngIf="exibirCards === 'comparacao'">
              <h3>{{percentualRespondido(dataPesquisa[1].meta)}}</h3>
            </div>
          </div>
        </div>
      </dg-card>
    </div>

    <div class="col-lg-2-4 col-md-4 col-sm-12 mb-3">
      <dg-card>
        <div class="container-fluid">
          <div class="row">

            <div class="col-12 d-flex justify-content-center">
              <span class="f-bold color-purple">Respostas</span>
            </div>

            <hr>

            <div class="col-12 d-flex justify-content-center">
              <h3>
                {{dataPesquisa[0].qtdRespondido}}
              </h3>
            </div>

            <hr *ngIf="exibirCards === 'comparacao'">

            <div class="col-12 d-flex justify-content-center" *ngIf="exibirCards === 'comparacao'">
              <h3>
                {{dataPesquisa[1].qtdRespondido}}
              </h3>
            </div>
          </div>
        </div>
      </dg-card>
    </div>

    <div class="col-lg-2-4 col-md-4 col-sm-12 mb-3">
      <dg-card>
        <div class="container-fluid">
          <div class="row">

            <div class="col-12 d-flex justify-content-center">
              <span class="f-bold color-purple">Mês de referencia</span>
            </div>

            <hr>

            <div class="col-12 d-flex justify-content-center">
              <h3>{{dataPesquisa[0].dataReferencia}}</h3>
            </div>

            <hr *ngIf="exibirCards === 'comparacao'">

            <div class="col-12 d-flex justify-content-center" *ngIf="exibirCards === 'comparacao'">
              <h3>{{dataPesquisa[1].dataReferencia}}</h3>
            </div>
          </div>
        </div>
      </dg-card>
    </div>
  </div>

</div>

<!-- GRAFICOS DAS PESQUISAS -->
<div *ngIf="apresentacao === 'valores'" class="container dashboard-page">
  <div class="row" *ngIf="graphExibir.length > 0">
    <div
      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-md-2 mt-sm-2"
      *ngFor="let data of graphExibir; let i = index"
    >
      <dg-card>
        <app-chart-pie [id]="criarId(i)" [dataGraphic]="ajustaData(data)"></app-chart-pie>
      </dg-card>
    </div>
  </div>
</div>

<!-- SEM PESQUISAS PARA APRESENTAR -->
<div *ngIf="(apresentacao === 'mensagem' && session.checkPerfil('RESPONDENTE'))" class="container dashboard-page mt-5">
  <div class="row">
    <div class="col-12">
      <dg-card class="sem-pesquisa custom-dg-card card-purple w-100">
        <div class="card-header" style="border: none; background: none">
          <img src="assets/img/logo-justa-U-branco.png">
        </div>
        <div class="card-body">
         <div class="container">
           <div class="row">
             <h1>Seja Bem vindo a Troca!</h1>
           </div>
           <div class="row">
             <div class="col-12">
               <p check-perfil>
                 Solicite o link para sua pesquisa.
               </p>
             </div>
           </div>
         </div>
        </div>
      </dg-card>
    </div>
  </div>
</div>

<!-- SEM PESQUISAS PARA APRESENTAR -->
<div *ngIf="(apresentacao === 'mensagem' && session.checkPerfil(['GESTOREMPRESA', 'GESTORTROCA']))" class="container dashboard-page mt-5">
  <div class="row">
    <div class="col-12">
      <dg-card class="sem-pesquisa custom-dg-card card-purple w-100">
        <div class="card-header" style="border: none; background: none">
          <img src="assets/img/logo-justa-U-branco.png">
        </div>
        <div class="card-body">
         <div class="container">
           <div class="row">
             <h1>{{codeMessage.title}}</h1>
           </div>
           <div class="row">
             <div class="col-12">
               <p>{{codeMessage.text}}</p>
             </div>
           </div>
         </div>
        </div>
      </dg-card>
    </div>
  </div>
</div>

<!-- RETORNO DA PESQUISA SEM GRAFICOS -->
<div *ngIf="apresentacao === 'loader'" class="container dashboard-page">
  <div class="row">
    <div class="col-12" style="display: flex; height: 8rem; align-items: center; justify-content: center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

