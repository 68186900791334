import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PerguntasEntity} from "../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {RestReturn} from "../../../../_core/config/rest-return";
import {ModalComponent} from "../modal/modal.component";
import {HttpService} from "../../../../services/http/http.service";
import {PerguntaPesquisaModeloEntity} from "../../../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {PerguntaPesquisaEntity} from "../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

@Component({
  selector: 'modal-busca-perguntas',
  templateUrl: './modal-busca-perguntas.component.html',
  styleUrls: ['./modal-busca-perguntas.component.scss']
})
export class ModalBuscaPerguntasComponent implements OnInit {

  @Output() addPerguntas = new EventEmitter<{
    perguntas: PerguntasEntity[],
    index: number
  }>();

  @Input('index') index!: number;
  // lista de perguntas já inseridas na pesquisa
  public listaPerguntasInseridas: PerguntaPesquisaEntity[] = [];
  // tipos de resposta aceitos
  public tipoResposta: string[] = [];

  public dataAutoComplete: string[] = [];

  constructor(
    private http: HttpService
  ) { }

  // lista de perguntas apresentadas no modal
  public listaPerguntas: PerguntasEntity[] = [];
  // lista de pergutnas selecionadas para inserir
  private listSelecaoInsert: PerguntasEntity[] = [];
  // lista de categorias
  public listaCategorias: string[] = [];

  @ViewChild('modal_add_pergunta') modal!: ModalComponent;

  public categoriaSelecionada: string = 'todos';
  ngOnInit(): void {
    this.http.get<RestReturn<string[]>>('api/pergunta/categorias').subscribe({
      next: (resp) => {
        this.listaCategorias = <string[]>resp.data;
      }
    });
  }

  /**
   * Funções do modal
   */

  openModal(
    index: any,
    tipoResposta: string[]
  ) {
    this.tipoResposta = tipoResposta;
    this.listSelecaoInsert = <PerguntasEntity[]>[];
    this.categoriaSelecionada = 'todos';
    this.index = index;
    this.getPorCategoria().then(() => {
      this.modal.open();
    });
  }

  async getPorCategoria() {
    if (this.categoriaSelecionada === 'todos' || this.categoriaSelecionada === '') {
      await this.http.get<RestReturn<PerguntasEntity[]>>('api/pergunta?unpaged=true').subscribe({
        next: (resp) => {
          if (this.categoriaSelecionada === '') {
            this.listaPerguntas = <PerguntasEntity[]> resp?.data?.filter((pergunta) => pergunta.categoria === null)
          } else {
            this.listaPerguntas = <PerguntasEntity[]> resp?.data
          }
        }
      });
    } else {
      await this.http.get<RestReturn<PerguntasEntity[]>>(`api/pergunta/porcategoria/${this.categoriaSelecionada}`).subscribe({
        next: (resp) => {
          this.listaPerguntas = <PerguntasEntity[]> resp?.data
        }
      });
    }
  }

  changeSelect(pergunta: PerguntasEntity) {
    if (this.listSelecaoInsert.findIndex(perg => perg.id === pergunta.id) === -1) {
      this.listSelecaoInsert.push(pergunta);
    } else {
      const index = this.listSelecaoInsert.findIndex(perg => perg.id === pergunta.id);
      this.listSelecaoInsert.splice(index, 1)
    }
  }

  onChangeAutoComplete(event: any) {

    this.categoriaSelecionada = 'autoComplete';

    this.http.get<RestReturn<PerguntasEntity[]>>(`api/pergunta/pesquisarpergunta?pesquisa=${event}`).subscribe({
      next: (resp) => {
        this.dataAutoComplete = <string[]> resp.data?.map((pergunta: PerguntasEntity) => {
          return pergunta.pergunta;
        });
        this.listaPerguntas =  <PerguntasEntity[]> resp.data
      }
    });
  }

  importarPergunta() {
    const newList = this.listSelecaoInsert.map((pergunta: PerguntasEntity) => {
      return <PerguntaPesquisaModeloEntity> new PerguntaPesquisaModeloEntity({
        pergunta: pergunta
      })
    });

    this.addPerguntas.emit({
      perguntas: this.listSelecaoInsert,
      index: this.index
    })

    this.modal.close();
  }

  disabledImported(perguntaOriginalId: any) {
    return this.listaPerguntasInseridas.findIndex((perguntaPesq, index) => {
      return perguntaPesq.perguntaOriginalId === perguntaOriginalId
    }) !== -1
  }

  checketInput(pergunta: any) {
    return (this.listSelecaoInsert.findIndex((perg) => perg.id === pergunta) !== -1)
  }

  disableTipoResposta(tipoResposta: string | undefined): boolean {
    if (typeof  tipoResposta !== "string") {
      return true;
    }
    if (this.tipoResposta.length === 0) {
      return false;
    } else if (Array.isArray(this.tipoResposta)) {
      return this.tipoResposta.findIndex((tipoR) => tipoR === tipoResposta) === -1
    }

    return true;
  }

}
