import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../../../services/session/session.service";
import Swal from "sweetalert2";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UsuarioService} from "../../../pages/_cadastros/pages/usuarios/services/usuario.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UtilService} from "../../../services/helpers/util/util.service";

@Component({
  selector: 'header-principal',
  templateUrl: './header-principal.component.html',
  styleUrls: ['./header-principal.component.scss']
})
export class HeaderPrincipalComponent implements OnInit {

  @ViewChild('swallAlterSenha') swallSenha!: SwalComponent

  public form = new FormGroup({
    oldPassword: new FormControl(''),
    newPassword: new FormControl('', [Validators.required]),
    confirmNewPassword: new FormControl('', [Validators.required])
  });

  public viewPass = {
    oldPass: false,
    newPass: false,
    confirmPass: false
  }

  constructor(
    public router: Router,
    public session: SessionService,
    public readonly swalTargets: SwalPortalTargets,
    private http: UsuarioService,
    public activatedRoute: ActivatedRoute,
    public util: UtilService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    Swal.fire({
      title: 'Sair',
      text: 'Deseja sair do Alline',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#7A6FBE',
      cancelButtonColor: '#0DCAF0',
      cancelButtonText: 'Ficar',
      confirmButtonText: 'Sair'
    }).then((result) => {
      if (result.isConfirmed) {
        this.session.logout()
      }
    })
  }

  openAlterPass():void {
    this.form.reset();
    this.swallSenha.fire()
  }

  verify = () => {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    if (this.form.value.newPassword !== this.form.value.confirmNewPassword) {

      this.form.controls['confirmNewPassword'].setErrors({ notEquivalent: true })
      this.form.controls['newPassword'].setErrors({ notEquivalent: true })

      return false;
    }

    this.alterarSenha();

    return false;
  }

  alterarSenha() {
    this.http.update({id: Number(this.session.user.id), ...this.form.value}, 'alterarsenha').subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: 'Senha alterada com sucesso',
          confirmButtonColor: '#2c516e'
        })
      },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Senha atual incorreta!',
          confirmButtonColor: '#2c516e'
        }).then(() => {
          this.form.patchValue({ oldPassword: '' })
          this.form.controls['oldPassword'].setErrors({ incorrectPass: true })
          this.swallSenha.fire();
        })
      }
    )
  }

  tipoPesquisa() {
    // Swal.fire({
    //   title: 'Adicionar tipo de pesquisa',
    //   input: 'select',
    //   inputAttributes: {
    //     autocapitalize: 'off'
    //   },
    //   inputOptions: {
    //     censo: 'Censo',
    //     diagnostico: 'Diagnóstico'
    //   },
    //   showCancelButton: true,
    //   confirmButtonText: 'Salvar',
    //   cancelButtonText: 'Cancelar',
    //   showLoaderOnConfirm: true,
    //   confirmButtonColor: '#741A99',
    //   preConfirm: (variavelQualquerNome) => {
    //     alert()
    //
    //   },
    //   allowOutsideClick: () => !Swal.isLoading()
    // }).then((result) => {
    //   alert('aqui acaba');
    // })
  }
}
