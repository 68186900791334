import {PerguntasEntity} from "../../_cadastros/pages/perguntas/entity/perguntas-entity";
import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {OpcaoPerguntaPesquisaEntity} from "../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";


export function perguntaToPerguntaPesquisa(perguntas: PerguntasEntity | PerguntasEntity[]): PerguntaPesquisaEntity[] {

  const perguntasAdd: PerguntaPesquisaEntity[] = []

  if (Array.isArray(perguntas)) {
    perguntas.map((pergunta, index) => {

      const opcoes = <OpcaoPerguntaPesquisaEntity[]> pergunta.opcoes.map((opcao, indexOpcPergunta) => {

        return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
          undefined,
          opcao.opcao,
          opcao.outros,
          Number(opcao.id),
        );
      });

      const addPergunta =  new PerguntaPesquisaEntity(
        undefined,
        pergunta.id,
        undefined,
        pergunta.pergunta,
        pergunta.tipoResposta,
        opcoes,
        undefined,
        undefined,
        pergunta.categoria,
        ['ADICIONADA', 'BANCO DE DADOS'],
        pergunta.podeEditar,
        pergunta.podeRemover,
        false,
        !!pergunta.geraFiltro,
        !!pergunta.nomeFiltro ? pergunta.nomeFiltro : pergunta.pergunta,
        !!pergunta.grupo ? pergunta.grupo : ''
      );

      delete addPergunta.configEntityTable;

      perguntasAdd.push(addPergunta);
    });
  } else {

    const opcoes = <OpcaoPerguntaPesquisaEntity[]> perguntas.opcoes.map((opcao, indexOpcPergunta) => {

      return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
        undefined,
        opcao.opcao,
        opcao.outros,
        Number(opcao.id),
      );
    });

    const addPergunta = new PerguntaPesquisaEntity(
      undefined,
      perguntas.id,
      undefined,
      perguntas.pergunta,
      perguntas.tipoResposta,
      opcoes,
      undefined,
      undefined,
      perguntas.categoria,
      ['ADICIONADA', 'BANCO DE DADOS'],
      perguntas.podeEditar,
      perguntas.podeRemover,
      false,
      !!perguntas.geraFiltro,
      !!perguntas.nomeFiltro ? perguntas.nomeFiltro : perguntas.pergunta,
      !!perguntas.grupo ? perguntas.grupo : ''
    );

    delete addPergunta.configEntityTable;

    perguntasAdd.push(addPergunta);
  }

  return perguntasAdd;
}
