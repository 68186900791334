import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {

  @Input() public legenda = 'Selecione um arquivo'

  @Output('selectFile') selectFile!: EventEmitter<any>

  public load = false;

  public fileName = '';

  constructor() { }

  ngOnInit(): void {
  }

  onFileSelected(event: any){

    const file:File = event.target.files[0];

    if (file) {

      this.load = true;

      this.fileName = file.name;

      this.selectFile.emit(file);
    }
  }

}
