<!-- TITULO -->
<div class="container">
  <div class="row">
    <div class="col-1 cont-label-perguntas"></div>
    <div class="col-10 col-center"><h2 style="color: #5B626B">
      Editar Pesquisa
    </h2>
    </div>
    <div class="col-1 col-right"></div>
  </div>
</div>

<!-- apresentacao -->
<div class="container" *ngIf="(momento === 'editar')">

  <div class="row mt-3">
    <div class="col-1 cont-label-perguntas"></div>
    <div class="col-10 col-center">
      <dg-card
        class="custom-dg-card col-11 card-purple"
      >
        <form [formGroup]="formApresentacao">
          <div style="padding: 1em 2em" class="card-body container">
            <div class="row">
              <div class="col-12">
                <input formControlName="titulo" class="form-control form-control-lg" type="text" placeholder="Título">
              </div>
            </div>
            <div class="row">
              <div class="col-12">

                <angular-editor formControlName="descricao" [config]="editorConfig"></angular-editor>

              </div>
            </div>
          </div>
        </form>
      </dg-card>
    </div>
    <div class="col-1 col-right"></div>
  </div>
</div>

<!-- perguntas -->
<div
  *ngIf="(!!entity && momento === 'editar')"
  class="container"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <div
    *ngFor="let pergunta of entity.perguntaPesquisa; let i = index"
    cdkDrag
    class="row"
  >
    <div class="col-12">
      <div class="row mb-3 mt-3">
        <div class="dragDrop-placeholder" style="height: 15rem" *cdkDragPlaceholder></div>
        <div class="col-1 cont-label-perguntas">
          <div class="label-pergunta" [class.removida]="cardPerguntaPesquisa.removida">
            <p>Pergunta {{ (i+1) }} de {{ entity.perguntaPesquisa.length }}</p>
          </div>
        </div>

        <div class="col-10 col-center card-perguntas">
          <card-pergunta-editar
            #cardPerguntaPesquisa
            [index]="i"
            [pergunta]="pergunta"
            [listPreguntas]="entity.perguntaPesquisa"
            [perguntaOpcao]="{ podeEditar: !!pergunta.podeEditar, podeRemover: !!pergunta.podeRemover, perguntaObrigatoria: !!pergunta.perguntaObrigatoria }"
            [tipoResposta]="entity.pesquisaModelo.tipoPesquisa.tipoResposta"
            (change)="change($event)"
            (restaurarPergunta)="restaurarPergunta($event, i)"
            (excluir)="removePergunta($event)"
          >
            <mat-icon dragHand cdkDragHandle style="max-width: 5%; color: #c5c5c5">drag_handle</mat-icon>
          </card-pergunta-editar>
        </div>

        <div class="col-1 col-right">
          <div class="actions-col">
            <button
              *ngIf="verifyExibRelacionarPredecessor(pergunta)"
              [matTooltip]="'Pergunta predecessora'"
              class="button-card"
              (click)="addPerguntaPredecessora(i)"
              mat-icon-button
            >
              <mat-icon>youtube_searched_for</mat-icon>
            </button>
            <button
              *ngIf="(entity.pesquisaModelo?.podeAdicionarNova && (
              entity.pesquisaModelo.podeReordenar
                ? entity.pesquisaModelo.podeReordenar
                : i >= (entity.pesquisaModelo.perguntas.length - 1)
              ))"
              class="button-card"
              (click)="addPergunta(i)"
              mat-icon-button
              [matTooltip]="'Criar uma nova pergunta'"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              *ngIf="(entity.pesquisaModelo?.podeAdicionarBanco && (
              entity.pesquisaModelo.podeReordenar
                ? entity.pesquisaModelo.podeReordenar
                : i >= (entity.pesquisaModelo.perguntas.length - 1)
              ))"
              class="button-card"
              (click)="openModal(i)"
              mat-icon-button
              [matTooltip]="'Buscar pergunta no banco'"
            >
              <mat-icon>search</mat-icon>
            </button>
            <button
              *ngIf="(entity.pesquisaModelo?.podeAdicionarBanco)"
              [matTooltip]="'Copiar Pergunta'"
              class="button-card"
              (click)="copyPergunta(i)"
              mat-icon-button
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <button
              [matTooltip]="'Configurações de filtro da pergunta'"
              class="button-card"
              (click)="openModalSettings(i)"
              [hidden]="(entity.tipoPesquisa?.codigo == '1' || (pergunta.tipoResposta === 'TEXT' || pergunta.tipoResposta === 'BIG_TEXT'))"
              mat-icon-button
              check-perfil
              [perfil]="'GESTORTROCA'"
            >
              <mat-icon>filter_alt</mat-icon>
            </button>
            <button
              check-perfil
              perfil="GESTORTROCA"
              [matTooltip]="'Salvar pergunta no banco de dados'"
              class="button-card"
              (click)="salvarPerguntaBanco(i)"
              mat-icon-button
              [hidden]="exibirBtnSalvar(i)"
            >
              <mat-icon>save_as</mat-icon>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- concluir -->
<div class="container" *ngIf="momento === 'concluir'">

  <div class="row mb-3">
    <div class="col-12">
      <dg-card>
        <div class="card-body container">
          <div class="row mt-1">
            <div class="col-12">
              <p class="f-bold fs-12">
                Empresa: <span class="color-purple">
                {{!!entity?.empresa?.nomeFantasia ? entity?.empresa?.nomeFantasia : '--'}}
              </span>
              </p>
            </div>
          </div>
        </div>
      </dg-card>
    </div>
  </div>

  <form [formGroup]="formConcluir">
    <div class="row mb-3 mt-3">
      <div class="col-6">
        <dg-card>
          <div class="container p-5">
            <label class="form-label color-purple f-bold fs-11">
              Pesquisa Anônima
            </label>
            <p>
              Pesquisar sem identificar os membros
              pesquisados
            </p>
            <input formControlName="pesquisaAnonima" [value]="true" type="radio" class="form-check-input dg-checkbox-as-radio lg purple">
          </div>
        </dg-card>
      </div>
      <div class="col-6">
        <dg-card>
          <div class="container p-5">
            <label class="form-label color-purple f-bold fs-11">
              Pesquisa Identificada
            </label>
            <p>
              Pesquisar identificando cada um dos membros
              pesquisados
            </p>
            <input formControlName="pesquisaAnonima" [value]="false" type="radio" class="form-check-input dg-checkbox-as-radio lg purple">
          </div>
        </dg-card>
      </div>
    </div>

    <div class="row mt-3">
      <dg-card>
        <div class="card-body">
          <div class="row">

            <div class="col-6">
              <div class="row mt-3">
                <div class="col-6">
                  <label class="form-label">Data de início da pesquisa</label>
                  <input formControlName="dataInicio" type="datetime-local" class="form-control">
                </div>
                <div class="col-6">
                  <label class="form-label">Data de fim da pesquisa</label>
                  <input formControlName="dataFim" type="datetime-local" class="form-control">
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <label class="form-label">Quantidade de colaboradores ativos</label>
                  <input min="1" formControlName="quantColabAtivos" type="number" class="form-control">
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row mt-3">
                <div class="col-12">
                  <label class="form-label">Inserir Emails dos Respondentes (separados por ponto e virgula ';')</label>
                  <textarea formControlName="emailsRespondentes" class="form-control" rows="6"></textarea>
                </div>
              </div>

              <div [hidden]="formConcluir.value.pesquisaAnonima" class="row mt-3">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="form-check" matTooltip="Apenas os usuários presentes nesta lista podem responder a pesquisa">
                    <input formControlName="restringirRespondentesListaEmail" class="form-check-input purple md" type="checkbox" id="restringirRespondentesListaEmail">
                    <label style="margin-left: .5em" for="restringirRespondentesListaEmail">
                      Restringir à lista de e-mail
                    </label>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end">
                  <div class="form-check" matTooltip="Apenas usuários presentes na empresa podem responder a pesquisa">
                    <input formControlName="restringirRespondentesUsuariosEmpresa" class="form-check-input purple md" type="checkbox" id="restringirRespondentesUsuariosEmpresa">
                    <label style="margin-left: .5em" for="restringirRespondentesUsuariosEmpresa">
                      Restringir à empresa
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </dg-card>
    </div>
  </form>
</div>

<!-- footer -->
<div class="container footer">
  <div class="row mt-3">
    <div *ngIf="momento !== 'concluir'"  class="col-1 cont-label-perguntas">
    </div>

    <div *ngIf="momento === 'editar'" class="col-10 col-center btn-wrapper d-inline-flex justify-content-end">
      <button (click)="previewPesquisa()" class="btn dg-btn btn-purple">
        Ver pesquisa
      </button>
      <button (click)="nextMomento('concluir')" class="btn dg-btn btn-info">
        Avançar
      </button>
    </div>

    <div *ngIf="momento === 'concluir'" class="col-12">
      <div class="row">
        <div class="col-6">
          <button (click)="backMomento()" class="btn dg-btn btn-cinza-outline">
            Voltar
          </button>
          <button (click)="previewPesquisa()" class="btn dg-btn btn-purple">
            Ver pesquisa
          </button>
        </div>
        <div class="col-6 btn-wrapper d-inline-flex justify-content-end">
          <button (click)="salvar('salvar')" class="btn dg-btn btn-purple">
            Salvar
          </button>
          <button (click)="salvar('concluir')" class="btn dg-btn btn-info">
            Salvar e Concluir
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="momento !== 'concluir'"  class="col-1 col-right"></div>
  </div>
</div>

<!-- modal -->
<modal-busca-perguntas
  #modal
  (addPerguntas)="addPerguntaBanco($event)"
>
</modal-busca-perguntas>

<modal-settings-perguntas #modalSettings (save)="saveConfigFiltros($event)"></modal-settings-perguntas>


<modal-relacao-pergunta-pesquisa-editar-predecessora
  #ModalPerguntaPredecessora
  [listPerguntas]="(entity?.perguntaPesquisa || [])"
  (salvar)="callbackPerguntaPredecessora($event)"
>
</modal-relacao-pergunta-pesquisa-editar-predecessora>
