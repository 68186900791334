import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponderPesquisaJpdComponent } from './responder-pesquisa-jpd/responder-pesquisa-jpd.component';
import {DgCrudModule} from "@datagrupo/dg-crud";
import {UiModule} from "../../../../../components/ui/ui.module";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {RouterModule} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    ResponderPesquisaJpdComponent
  ],
    imports: [
        CommonModule,
        DgCrudModule,
        UiModule,
        SweetAlert2Module,
        RouterModule,
        MatTooltipModule,
        FormsModule
    ]
})
export class ResponderPesqJpdModule { }
