<div class="container meus-dados">
  <div class="row justify-content-center">
    <div>
      <h1 style="color: #5B626B">Meus Dados</h1>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="row">
      <dg-card>
        <div class="card-body mt-5">
          <div class="container">

            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12  col-xs-12">
                <div class="mb-4">
                  <label class="form-label">Nome</label>
                  <input type="text" formControlName="nome" class="form-control">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12  col-xs-12">
                <div class="mb-4">
                  <label class="form-label">E-mail</label>
                  <input disabled type="email" [value]="session.user.email" class="form-control" >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12  col-xs-12">
                <div class="mb-4">
                  <label class="form-label">Empresa</label>
                  <input disabled type="text" [value]="session.user.empresa?.nomeFantasia" class="form-control" >
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12  col-xs-12">
                <label class="form-label">CPF (opcional)</label>
                <input type="text" formControlName="cpf" mask="000.000.000-00" class="form-control">
              </div>

            </div>


            <div class="row lista-emails">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h3>Empresa</h3>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsEmpresaAtualizada"
                    id="input-receberEmailsEmpresaAtualizada"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsEmpresaAtualizada">
                    Receber emails empresa atualizada
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsUsuarioCadastrado"
                    id="input-receberEmailsUsuarioCadastrado"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsUsuarioCadastrado">
                    Receber e mails usuário cadastrado
                  </label>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h3>Usuário</h3>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsUsuarioAtualizado"
                    id="input-receberEmailsUsuarioAtualizado"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsUsuarioAtualizado">
                    Receber emails usuário atualizado
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsUsuarioAtualizadoPorGestorEmpresa"
                    id="input-receberEmailsUsuarioAtualizadoPorGestorEmpresa"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsUsuarioAtualizadoPorGestorEmpresa">
                    Receber emails usuário atualizado por gestor empresa
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsUsuarioCadastradoPorGestorEmpresa"
                    id="input-receberEmailsUsuarioCadastradoPorGestorEmpresa"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsUsuarioCadastradoPorGestorEmpresa">
                    Receber emails usuário cadastrado por gestor empresa
                  </label>
                </div>
              </div>
            </div>

            <hr>

            <div class="row lista-emails">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h3>Pesquisa</h3>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsPesquisaIniciada"
                    id="input-receberEmailsPesquisaIniciada"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsPesquisaIniciada">
                    Receber emails pesquisa iniciada
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsNotificacaoPesquisa"
                    id="input-receberEmailsNotificacaoPesquisa"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsNotificacaoPesquisa">
                    Receber emails notificação pesquisa
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsPesquisaAtualizada"
                    id="input-receberEmailsPesquisaAtualizada"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsPesquisaAtualizada">
                    Receber emails pesquisa atualizada
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsPesquisaAtualizadaPorGestorEmpresa"
                    id="input-receberEmailsPesquisaAtualizadaPorGestorEmpresa"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsPesquisaAtualizadaPorGestorEmpresa">
                    Receber emails pesquisa atualizada por gestor empresa
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsPesquisaEmAnalise"
                    id="input-receberEmailsPesquisaEmAnalise"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsPesquisaEmAnalise">
                    Receber emails pesquisa em análise
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsPesquisaFinalizada"
                    id="input-receberEmailsPesquisaFinalizada"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsPesquisaFinalizada">
                    Receber emails pesquisa finalizada
                  </label>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h3>Gestor Troca</h3>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsEmpresaAtualizadaPorGestorTroca"
                    id="input-receberEmailsEmpresaAtualizadaPorGestorTroca"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsEmpresaAtualizadaPorGestorTroca">
                    Receber emails empresa atualizada por gestor troca
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsEmpresaCadastrada"
                    id="input-receberEmailsEmpresaCadastrada"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsEmpresaCadastrada">
                    Receber emails empresa cadastrada
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsSistemaAtualizado"
                    id="input-receberEmailsSistemaAtualizado"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsSistemaAtualizado">
                    Receber emails sistema atualizado
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsUsuarioAtualizadoPorGestorTroca"
                    id="input-receberEmailsUsuarioAtualizadoPorGestorTroca"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsUsuarioAtualizadoPorGestorTroca">
                    Receber emails usuário atualizado por gestor troca
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsPesquisaAtualizadaPorGestorTroca"
                    id="input-receberEmailsPesquisaAtualizadaPorGestorTroca"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsPesquisaAtualizadaPorGestorTroca">
                    Receber emails pesquisa atualizada por gestor troca
                  </label>
                </div>
                <div class="col-12 form-check">
                  <input
                    class="form-check-input purple md"
                    type="checkbox"
                    formControlName="receberEmailsUsuarioCadastradoPorGestorTroca"
                    id="input-receberEmailsUsuarioCadastradoPorGestorTroca"
                  >
                  <label class="form-check-label" style="margin-left: .5em" for="input-receberEmailsUsuarioCadastradoPorGestorTroca">
                    Receber emails usuário cadastrado por gestor troca
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <card-footer direction="center">
          <button (click)="salvar()" class="btn dg-btn btn-info">
            Salvar
          </button>
        </card-footer>
      </dg-card>
    </div>
  </form>
</div>
