import {HostListener, Injectable} from '@angular/core';
import {Router} from "@angular/router";

interface InterceUsuarios{
  id?: number | string | undefined,
  nome: string | undefined,
  perfil: 'RESPONDENTE' | 'GESTOREMPRESA' | 'GESTORTROCA' | string | undefined,
  email: string | undefined,
  termoUsoSistema: boolean | undefined,
  empresa: {
    id: number | string | undefined,
    nomeFantasia: string | undefined,
    modeloPadraoCenso: number | string | undefined,
    modeloPadraoCensoPD: number | string | undefined,
    modeloPadraoDiagnostico: number | string | undefined
  } | undefined
}

export interface InterfaceDashboardPesquisa {
  pesquisa?: dashboardPesquisa,
  comparacao?: dashboardComparacao,
  grupos?: string[] | undefined,
  filtros?: dashboardFiltros | undefined
}

interface dashboardPesquisa {
  empresa: number | string | undefined,
  tipoPesquisa: number | string | undefined,
  pesquisa: number | string | undefined
}
interface dashboardComparacao {
  tipoPesquisaComparacao: number | string | undefined,
  pesquisaComparacao: number | string | undefined
}

export interface dashboardFiltros {
  perguntas: string,
  opcoes: string
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public currentTitlePage = '';

  public user: InterceUsuarios = {
    id: undefined,
    nome: undefined,
    perfil: undefined,
    email: undefined,
    empresa: undefined,
    termoUsoSistema: undefined
  }

  public dashboardPesquisa: InterfaceDashboardPesquisa | undefined = undefined;

  constructor(protected router: Router) { }

  @HostListener('window:sistem-logout', ['$event'])
  public logout() {
    this.dashboardPesquisa = {};
    window.localStorage.setItem('dashboardPesquisa', 'null')
    window.localStorage.removeItem('dashboardPesquisa')
    window.localStorage.clear();
    window.location.href = `${window.location.origin}/auth/login`;
  }

  public checkPerfil(perfil: 'RESPONDENTE' | 'GESTOREMPRESA' | 'GESTORTROCA' | string[]) {
    if (typeof perfil === 'string') {
      return this.user.perfil === perfil

    } else if (Array.isArray(perfil)) {
      return perfil.findIndex( perfil => perfil === this.user.perfil) !== -1
    }

    return false
  }

  /**
   *  FUNÇÕES DE PESQUISA PARA DASHBOARD
   */
  resetDachboardPesquisa() {
    this.dashboardPesquisa = {
      pesquisa: {
        pesquisa: undefined,
        tipoPesquisa: undefined,
        empresa: undefined
      }
    }
  }

  /*
   * verifica se existem dados de pesquisa
   */
  issetAttPesquisa(): boolean{
    if (!!this.dashboardPesquisa) {
      return true;
    }

    if (!!window.localStorage.getItem('dashboardPesquisa')) {
      this.dashboardPesquisa = <InterfaceDashboardPesquisa> JSON.parse(String(window.localStorage.getItem('dashboardPesquisa')));

      return true;
    }

    return false;
  }

  /*
      METODOS DE PESQUISA
   */
  issetPesquisa(): boolean{
    if (!this.issetAttPesquisa()) {
      return false
    }

    return !!this.dashboardPesquisa?.pesquisa;
  }

  public getDachboardPesquisa(): dashboardPesquisa {
    if (this.issetPesquisa()) {
      if (!!this.dashboardPesquisa?.pesquisa) {
        return this.dashboardPesquisa?.pesquisa;
      }
    }

    return {
      pesquisa: undefined,
      empresa: undefined,
      tipoPesquisa: undefined
    };
  }

  public setDachboardPesquisa(data: dashboardPesquisa){
    this.dashboardPesquisa = {
      ...this.dashboardPesquisa,
      pesquisa: data,
      comparacao: undefined
    }

    window.localStorage.setItem('dashboardPesquisa', JSON.stringify(this.dashboardPesquisa));
  }

  /*
      METODOS DE COMPARAÇÃO
   */
  issetcomparacao(): boolean{
    if (!this.issetAttPesquisa()) {
      return false
    }

    if (!!this.dashboardPesquisa?.comparacao) {
      return true;
    }

    return false;
  }

  public getDachboardComparacao(): dashboardComparacao {
    if (this.issetcomparacao()) {
      if (!!this.dashboardPesquisa?.comparacao) {
        return this.dashboardPesquisa?.comparacao;
      }
    }

    return {
      pesquisaComparacao: undefined,
      tipoPesquisaComparacao: undefined
    };
  }

  public setDachboardComparacao(data: dashboardComparacao) {
    const dashboardPesquisa = this.dashboardPesquisa;

    this.dashboardPesquisa = {
      ...dashboardPesquisa,
      comparacao: data
    }

    window.localStorage.setItem('dashboardPesquisa', JSON.stringify(this.dashboardPesquisa));
  }

  /*
      METODOS DE GRUPO
   */
  issetGrupos(): boolean{
    if (!this.issetAttPesquisa()) {
      return false
    }

    if (!!this.dashboardPesquisa?.grupos) {
      return this.dashboardPesquisa?.grupos.length > 0;
    }

    return false;
  }

  public getDachboardGrupos(): string[] {
    if (this.issetPesquisa()) {
      if (!!this.dashboardPesquisa?.grupos) {
        return this.dashboardPesquisa.grupos;
      }
    }

    return [];
  }

  public setDachboardGrupos(data: string[]) {
    const dashboardPesquisa = this.dashboardPesquisa;

    this.dashboardPesquisa = {
      ...dashboardPesquisa,
      grupos: data
    }

    console.log('setDachboardGrupos', this.dashboardPesquisa);

    window.localStorage.setItem('dashboardPesquisa', JSON.stringify(this.dashboardPesquisa));
  }
  /*
      METODOS DE FILTROS(PERGUNTAS)
   */
  issetFiltros(): boolean{
    if (!this.issetAttPesquisa()) {
      return false
    }

    if (!!this.dashboardPesquisa?.filtros) {
      return (!!this.dashboardPesquisa.filtros.perguntas && !!this.dashboardPesquisa.filtros.opcoes);
    }

    return false;
  }

  public getDachboardFiltros(): dashboardFiltros {
    if (!this.issetFiltros()) {
      //@ts-ignore
      return <dashboardFiltros>{
        perguntas: undefined,
        opcoes: undefined
      }
    }

    return <dashboardFiltros> this.dashboardPesquisa?.filtros;
  }

  public setDachboardFiltros(perguntas: string, opcoes: string) {
    const dashboardPesquisa = this.dashboardPesquisa;

    this.dashboardPesquisa = {
      ...dashboardPesquisa,
      filtros: { perguntas, opcoes}
    }

    console.log('setDachboardGrupos', this.dashboardPesquisa);

    window.localStorage.setItem('dashboardPesquisa', JSON.stringify(this.dashboardPesquisa));
  }

  public resetDachboardFiltros() {
    delete this.dashboardPesquisa?.filtros
  }
}
