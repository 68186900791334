import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { RestReturn } from '../../../../_core/config/rest-return';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss']
})
export class ChartDonutComponent implements OnInit {

  public dataGraphicDonut:any;

  @Input() id:'chart-donut'='chart-donut';

  constructor(
    http: HttpService
  ) {
  }

  ngOnInit(): void {
  }
  
  private createChartDonut(): void {
    Highcharts.chart('chart-donut', this.dataGraphicDonut);
  }
}
