<div id="filtro-home">

  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="canvas">
      <div class="offcanvas-header">
        <button
          data-bs-dismiss="offcanvas"
          class="canvas-btn-close"
          aria-label="Close"
          mat-mini-fab
        >
          <mat-icon>close</mat-icon>
        </button>

      </div>

      <div class="root-offcanvas-body">

        <!-- FORMULÁRIO DE PESQUISA -->
        <form [formGroup]="form">
          <div class="offcanvas-body">

            <div class="container-fluid">

              <div class="row" [hidden]="(!session.checkPerfil('GESTORTROCA'))">
                <div class="col-12">
                  <div class="mb-2">
                    <select
                      (ngModelChange)="alterEmpresa()"
                      formControlName="empresa"
                      class="form-select dg-purple"
                    >
                      <option disabled value="">Empresa</option>
                      <option
                        *ngFor="let emp of listaEmpresa"
                        [value]="emp.id"
                      >{{emp.nomeFantasia}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="mb-2">
                    <select
                      [attr.disabled]="form.value.empresa === '' ? '' : null"
                      (ngModelChange)="alteraTipoPesquisa()"
                      formControlName="tipoPesquisa"
                      class="form-select dg-purple"
                    >
                      <option disabled selected value="">Tipo de pesquisa</option>
                      <option
                        *ngFor="let tipoP of listaTipoPesquisa"
                        [value]="tipoP.codigo"
                      >{{tipoP.nome}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="mb-4">
                    <select
                      (ngModelChange)="alterPesquisa()"
                      formControlName="pesquisa"
                      [attr.disabled]="(form.value.empresa === '' || form.value.tipoPesquisa === '') ? '' : null"
                      class="form-select dg-purple"
                    >
                      <option disabled selected value="">Data de pesquisa</option>
                      <option
                        [attr.disabled]="pesq.id == formComparacao.value.pesquisaComparacao ? '' : null"
                        *ngFor="let pesq of listaPesquisa"
                        [value]="pesq.id"
                      >
                        {{trataData(pesq.dataFim)}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <!-- FORMULÁRIO DE COMPARAÇÃO -->

        <form [formGroup]="formComparacao" [hidden]="page === 'score'">
          <div class="offcanvas-body">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="mt-4">
                    <div class="mb-4">
                      <label class="form-label color-purple f-bold fs-12">
                        Comparacao
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="mb-2">
                    <select
                      (ngModelChange)="alteraTipoPesquisaComparacao()"
                      formControlName="tipoPesquisaComparacao"
                      [attr.disabled]="form.valid ? null : ''"
                      class="form-select dg-purple"
                    >
                      <option value="">Tipo de pesquisa</option>
                      <option
                        *ngFor="let tipoP of listaTipoPesquisa"
                        [value]="tipoP.codigo"
                      >{{tipoP.nome}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="mb-2">
                    <select
                      formControlName="pesquisaComparacao"
                      (ngModelChange)="alterPesquisaComparacao()"
                      [attr.disabled]="(!form.valid || formComparacao.value.tipoPesquisaComparacao === '') ? '' : null"
                      class="form-select dg-purple"
                    >
                      <option disabled selected value="">Data de pesquisa</option>
                      <option
                        [class.disabled]="tipoPCompara.id == form.value.pesquisa"
                        [attr.disabled]="tipoPCompara.id == form.value.pesquisa ? '' : null"
                        *ngFor="let tipoPCompara of listaPesquisaComparacao"
                        [value]="tipoPCompara.id"
                      >{{trataData(tipoPCompara.dataFim)}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>


        <!-- filtros -->
        <div class="offcanvas-body container-filtros-perguntas" [hidden]="page === 'score'">
          <div class="container" *ngIf="(listaPerguntas.length > 0 && form.value.tipoPesquisa != 1)">
            <div class="row">
              <div class="col-12">
                <div class="mt-4">
                  <div class="mb-4">
                    <label class="form-label color-purple f-bold fs-12">
                      Filtros
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngFor="let pergunta of listaPerguntas"
            >
              <div class="col-12">
                <div class="mb-2 w-100">

                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{ (!!pergunta.nomeFiltro? pergunta.nomeFiltro : pergunta.pergunta) }}</mat-label>
                    <mat-select
                      multiple
                      (closed)="disparaFiltros()"
                      [(ngModel)]="selectedFiltrosPerguntas[retornIdPergunta(pergunta.id)]"
                      (selectionChange)="alteraFiltroPerguntas($event, pergunta.id)"
                    >
                      <mat-option
                        *ngFor="let opcaoP of pergunta.opcoes"
                        [value]="opcaoP.id"
                        class="w-100"
                      >
                        {{ opcaoP.outros ? 'outros' : opcaoP.opcao }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="showZeraFiltros()">
              <div class="col-12">
                <div class="mt-4">
                  <div class="mb-4">
                    <button (click)="limpaFiltros()" style="margin: 0" class="btn dg-btn btn-purple w-100">
                      Zerar Filtros
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

</div>


