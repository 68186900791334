<div class="pageConfig">
  <form [formGroup]="formulario">
    <div class="container mb-3">
      <h2 style="color: #5B626B">Tipo Pesquisa</h2>
      <div class="row">
        <div class="col-4">
          <dg-card>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="form-label">Modelo Censo Novo</label>
                  <select formControlName="modeloCensoPadrao" class="form-select dg-purple" name="tipo_censo"
                          id="tipo_censo_modelo">
                    <option value="">selecione</option>
                    <option *ngFor="let censoPesquisa of listCenso" value="{{censoPesquisa.id}}">
                      {{ censoPesquisa.titulo}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </dg-card>
        </div>
        <div class="col-4">
          <dg-card>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="form-label">Modelo Censo Já possuo Dados</label>
                  <select
                    formControlName="modeloCensoPossuoDadosPadrao"
                    class="form-select dg-purple"
                    name="tipo_censo"
                    id="tipo_censo_modelo_ja_possuo_dados">
                    <option value="">selecione</option>
                    <option *ngFor="let censoPD of listCensoPD" value="{{censoPD.id}}">
                      {{ censoPD.titulo }}
                  </select>
                </div>
              </div>
            </div>
          </dg-card>
        </div>
        <div class="col-4">
          <dg-card>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="form-label">Modelo Diagnóstico</label>
                  <select formControlName="modeloDiagnosticoPadrao" class="form-select dg-purple"
                          name="tipo_diagnostico" id="tipo_diagnostico_modelo">
                    <option value="">selecione</option>
                    <option *ngFor="let diagnostico of listDiagnostico" value="{{diagnostico.id}}">
                      {{ diagnostico.titulo }}
                  </select>
                </div>
              </div>
            </div>
          </dg-card>
        </div>
      </div>
    </div>

    <div class="container mb-3">
      <h2 style="color: #5B626B">Email</h2>
      <div class="row">
        <dg-card>
          <div class="card-body">
            <div class="row">
              <form action=""></form>
              <div class="col-6">
                <div class="mb-4">
                  <label class="form-label">Host</label>
                  <input type="text" class="form-control" formControlName="host">
                </div>
              </div>
              <br>

              <div class="col-6">
                <label class="form-label">Porta</label>
                <input type="text" class="form-control" formControlName="port">
              </div>
              <br>

              <div class="col-6">
                <label class="form-label">Usuário</label>
                <input type="text" class="form-control" formControlName="username">
              </div>
              <br>
              <br>

              <div class="col-6">
                <label class="form-label">Senha</label>
                <input type="text" class="form-control" formControlName="password">
              </div>
              <br>

              <div class="col-6" style="padding-top: 1em;">
                <label class="form-label">Email</label>
                <input type="email" class="form-control" formControlName="emailName">
              </div>
              <div class="btn-wrapper text-end text-sm-justify col-12">
                <mat-slide-toggle class="color-purple" formControlName="enviarEmails">Habilitar Email</mat-slide-toggle>
              </div>
            </div>
          </div>
        </dg-card>
      </div>
    </div>
  </form>

  <div class="container">
    <h2 style="color: #5B626B">Uploads</h2>
    <div class="row">

      <div class="col-6">
        <dg-card>
          <div class="card-header">
            Termo de uso da aplicação
          </div>
          <div class="card-body">
            <input
              #inputFileUploadTermo
              type="file"
              [(ngModel)]="fileUploadTermo"
              (change)="onSelectedTermoApplication($event)"
              style="display: none"
              accept="application/pdf"
            >
            <div class="row">
              <div class="file-upload col-11">

                <button [disabled]="disabledTermoApplication" mat-mini-fab class="upload-btn"
                        (click)="inputFileUploadTermo.click()">
                  <mat-icon>attach_file</mat-icon>
                  <!--    <mat-spinner></mat-spinner>-->
                </button>

                {{ nomeTermoApplication || 'Selecione uma novo Termo de uso da aplicação' }}
              </div>

              <div class="col-1 d-inline-flex align-items-center">
                <a routerLink="/termos_condicoes/termo_de_uso" target="_blank">
                  <mat-icon class="iconViewPdf">visibility</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </dg-card>
      </div>

      <div class="col-6">
        <dg-card>
          <div class="card-header">
            Politica de privacidade
          </div>
          <div class="card-body">
            <div class="row">
              <input
                #fileUploadPlitica
                type="file"
                [(ngModel)]="filePoliticaPrivacidade"
                (change)="onSelectedPoliticaPrivacidade($event)"
                style="display: none"
                accept="application/pdf"
              >

              <div class="file-upload col-11">

                <button [disabled]="disabledPoliticaPrivacidade" mat-mini-fab color="primary" class="upload-btn"
                        (click)="fileUploadPlitica.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>

                {{ nomePoliticaPrivacidade || 'Selecione uma nova politica de privacidade' }}
              </div>

              <div class="col-1 d-inline-flex align-items-center">
              <a routerLink="/termos_condicoes/politica_de_privacidade" target="_blank">
                <mat-icon class="iconViewPdf">visibility</mat-icon>
              </a>
              </div>
            </div>
          </div>
        </dg-card>
      </div>

    </div>
  </div>

  <div class="container footer" style="padding-top: 1em;">
    <div class="container-fluid">
      <div class="row">
        <div class="btn-wrapper text-end text-sm-justify col-12">
          <button class="btn dg-btn btn-purple" [disabled]="(disabledPoliticaPrivacidade || disabledTermoApplication)">
            Voltar
          </button>
          <button (click)="atualizar()" class="btn dg-btn btn-info"
                  [disabled]="(disabledPoliticaPrivacidade || disabledTermoApplication)">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
