import { Injectable } from '@angular/core';
import {AbstractHttpService} from '@datagrupo/dg-crud';
import {PesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pesquisa-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {HttpService} from "../../../../../services/http/http.service";
import {SessionService} from "../../../../../services/session/session.service";
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class EditarPesquisaService extends AbstractHttpService<PesquisaEntity>{

  constructor(
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/pesquisa')
  }

  public saveOrUpdate(data: PesquisaEntity, endpoint: string | boolean = false): Observable<PesquisaEntity> {

    if(!!endpoint) {
      if (typeof endpoint === 'string') {
        const endP = endpoint.split('/')

        if (!!endP[1]) {
          if (endP[1] === 'concluir') {
            return this.save(data, !!endpoint ? endpoint : endpoint);
          }
        }
      }
    }

    return this.update(data, !!endpoint ? endpoint : 'salvar');
  }
}
