<dg-modal title="" class="modalSettingsPerguntas" size="md" #modal_busca_pergunta_matriz>
  <div dg-content>

    <table class="crud-table perguntas-table w-100">
      <tbody>
      <tr
        *ngFor="let pergunta of listaPerguntasPesquisaModelo; let i = index"
      >
        <td class="dt-pergunta" (dblclick)="salvar(pergunta)">
          {{ pergunta.pergunta }}
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="d-flex justify-content-center" dg-footer>
    <button class="dg-btn btn btn-danger m-1" (click)="salvar()">
      Salvar
    </button>
    <button class="dg-btn btn btn-secondary m-1" (click)="close()">
      Descartar
    </button>
  </div>
</dg-modal>
