import { Injectable } from '@angular/core';
import {HttpService} from "../../../../../services/http/http.service";
import {PerguntaScorePesquisaEntity} from "../entity/pergunta-score-pesquisa-entity";

@Injectable({
  providedIn: 'root'
})
export class PerguntaScoreService{

  constructor(
    public http: HttpService
  ) { }

  findOne(id: number | string ){
    return this.http.get(`api/pergunta-score-pesquisa/configurar/pesquisa/${id}`)
  }

  save(data: PerguntaScorePesquisaEntity) {
    if (!!data.id) {
      return this.http.put('api/pergunta-score-pesquisa', data)
    }

    return this.http.post('api/pergunta-score-pesquisa', data);
  }

  delete(id: number | string){
    return this.http.delete('api/pergunta-score-pesquisa', id)
  }
}
