import Swal from 'sweetalert2';
import { Validators } from '@angular/forms';
import { ConfiguracoesEntity } from '../entity/configuracoes-entity';
import { Location } from '@angular/common';
import { ConfiguracoesService } from '../services/configuracoes.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { SessionService } from 'src/app/services/session/session.service';
import { RestReturn } from 'src/app/_core/config/rest-return';
import { PesquisaModeloEntity } from '../../_cadastros/pages/pesquisa-modelo/entity/pesquisa-modelo-entity';


@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.scss']
})
export class ConfiguracoesComponent implements OnInit {

  entity = <ConfiguracoesEntity>new ConfiguracoesEntity();

  public listCenso: PesquisaModeloEntity[] = [];
  public listCensoPD: PesquisaModeloEntity[] = [];
  public listDiagnostico: PesquisaModeloEntity[] = [];

  public censoPadrao: string | number = '';
  public censoPadraoPD: string | number = '';
  public diagnosticoPadrao: string | number = '';

  public filePoliticaPrivacidade!: File;
  public nomePoliticaPrivacidade = '';
  public disabledPoliticaPrivacidade = false;

  public fileUploadTermo!: File;
  public nomeTermoApplication = '';
  public disabledTermoApplication = false;


  formulario = new FormGroup({
    host: new FormControl('', Validators.required),
    port: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    emailName: new FormControl('', Validators.required),
    enviarEmails: new FormControl(false),
    modeloCensoPadrao: new FormControl(''),
    modeloCensoPossuoDadosPadrao: new FormControl(''),
    modeloDiagnosticoPadrao: new FormControl(''),

  });

  constructor(

    protected route: ActivatedRoute,
    protected service: ConfiguracoesService,
    protected location: Location,
    private http: HttpService,
    private session: SessionService,
    private router: Router
  ) {
    http.get<RestReturn<PesquisaModeloEntity[]>>('api/pesquisamodelo/censo/pesquisa').subscribe({
      next: (resp) => {
        this.listCenso = Array.isArray(resp.data) ? resp.data : [];
      }
    });
    http.get<RestReturn<PesquisaModeloEntity[]>>('api/pesquisamodelo/censo/possuodados').subscribe({
      next: (resp) => {
        this.listCensoPD = Array.isArray(resp.data) ? resp.data : [];
      }
    });
    http.get<RestReturn<PesquisaModeloEntity[]>>('api/pesquisamodelo/diagnostico').subscribe({
      next: (resp) => {
        this.listDiagnostico = Array.isArray(resp.data) ? resp.data : [];
      }
    });
  }

  ngOnInit(): void {
    this.fetchEntity();
  }

  fetchEntity() {
    this.http.get<RestReturn<ConfiguracoesEntity>>('api/sistema').subscribe((response) => {
      if (response.data) {
        this.entity = response.data;
      }
      this.afterFetchEntity();

    },
      error => {
        console.log(error.message);
      });


  }

  beforeSaveEntity() {
    if (!this.formulario.valid) {
      this.formulario.markAllAsTouched();
      return false;
    }

    const data = this.formulario.value;

    this.entity = {
      ...this.entity,
      ...data,
      modeloCensoPadrao: data.censoPadrao?.toString() !== '' ? { id: data.modeloCensoPadrao } : null,
      modeloCensoPossuoDadosPadrao: data.censoPadraoPD?.toString() !== '' ? { id: data.modeloCensoPossuoDadosPadrao } : null,
      modeloDiagnosticoPadrao: data.diagnosticoPadrao?.toString() !== '' ? { id: data.modeloDiagnosticoPadrao } : null,
    };

    console.log(this.entity)


    return true;
  }

  afterFetchEntity() {

    this.http.get('api/sistema/termodeuso').subscribe(
      resp => {
        // @ts-ignore
        const file = new File([resp.data], this.entity.termoDeUsoNome);

        this.nomeTermoApplication = file.name;

        this.disabledTermoApplication = false;
      },
      error => {
        this.disabledTermoApplication = false;
      }
    )

    this.http.get('api/sistema/politicadeprivacidade').subscribe(
      resp => {
        // @ts-ignore
        const file = new File([resp.data], this.entity.politicaDePrivacidadeNome);

        this.nomePoliticaPrivacidade = file.name;

        this.disabledPoliticaPrivacidade = false;
      },
      error => {
        this.disabledPoliticaPrivacidade = false;
      }
    )

    this.formulario.patchValue({
      host: this.entity.host,
      port: this.entity.port,
      username: this.entity.username,
      password: this.entity.password,
      emailName: this.entity.emailName,
      enviarEmails: !!this.entity.enviarEmails,
      modeloCensoPadrao: !!this.entity.modeloCensoPadrao?.id ? this.entity.modeloCensoPadrao?.id : '',
      modeloCensoPossuoDadosPadrao: !!this.entity.modeloCensoPossuoDadosPadrao?.id ? this.entity.modeloCensoPossuoDadosPadrao?.id : '',
      modeloDiagnosticoPadrao: !!this.entity.modeloDiagnosticoPadrao?.id ? this.entity.modeloDiagnosticoPadrao?.id : ''
    });
  }

  voltar() {
    this.router.navigate(['home'])
  }

  atualizar() {
    console.log(this.entity)
    if (this.beforeSaveEntity()) {
      this.http.put<RestReturn<ConfiguracoesEntity>>('api/sistema/atualizar', this.entity).subscribe(
        resp => {
          Swal.fire({
            title: 'Salvo',
            text: 'Salvo com sucesso!',
            icon: 'success',
            timer: 1500
          })
        },
      )
    }
  }

  onSelectedPoliticaPrivacidade(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {

      this.disabledPoliticaPrivacidade = true;

      this.nomePoliticaPrivacidade = file.name;

      const formData = new FormData();

      formData.append("politicaDePrivacidade", file);

      const upload$ = this.http.put("api/sistema/politicadeprivacidade", formData);

      upload$.subscribe(resp => {
        this.disabledPoliticaPrivacidade = false;
      },
        error => {
        this.disabledPoliticaPrivacidade = false;
        });
    }
  }

  onSelectedTermoApplication(event: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }

    //@ts-ignore
    const file: File = event.target.files[0];

    if (file) {

      this.disabledTermoApplication = true;

      this.nomeTermoApplication = file.name;

      const formData = new FormData();

      formData.append("termoDeUso", file);

      const upload$ = this.http.put("api/sistema/termodeuso", formData);

      upload$.subscribe(resp => {
        this.disabledTermoApplication = false;
      },
        error => {
        this.disabledTermoApplication = false;
        });
    }
  }
}
