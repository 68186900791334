import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractHttpService } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { PerguntasEntity } from '../entity/perguntas-entity';
import {Observable} from "rxjs";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";

export interface InterfaceTiposResposta {
  id: string | number,
  nome: string
}

@Injectable({
  providedIn: 'root'
})
export class PerguntasService extends AbstractHttpService<PerguntasEntity>{

  constructor(
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/pergunta')
  }

  aceitarPergunta(id: number) {
    const url = environment.apiUrl+'/api/pergunta/atualizarstatus/';
    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));
    const body = {id: id, status: 'AUTORIZADA'}

    return this.http.put(url, body, {headers: { Authorization }})
  }

  rejeitarPergunta(id: number) {
    const url = environment.apiUrl+'/api/pergunta/atualizarstatus/';
    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));
    const body = {id: id, status: 'NAO_AUTORIZADA'}

    return this.http.put(url, body, {headers: { Authorization }})
  }
}
