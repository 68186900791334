import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewPesquisaModeloComponent } from './preview-pesquisa-modelo/preview-pesquisa-modelo.component';
import {UiModule} from "../../../../components/ui/ui.module";



@NgModule({
  declarations: [
    PreviewPesquisaModeloComponent
  ],
  imports: [
    CommonModule,
    UiModule
  ]
})
export class PreviewPesquisaModeloModule { }
