import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {PerguntasEntity} from "../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {
  MatrizPesoModeloService
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/services/matriz-peso/matriz-peso-modelo.service";
import {HttpService} from "../../../../services/http/http.service";

@Component({
  selector: 'modal-busca-perguntas-matriz-x',
  templateUrl: './modal-busca-perguntas-matriz-x.component.html',
  styleUrls: ['./modal-busca-perguntas-matriz-x.component.scss']
})
export class ModalBuscaPerguntasMatrizXComponent implements OnInit {

  @ViewChild('modal_busca_pergunta_matriz') modal!: ModalComponent;

  public idPesquisa: number | string = '';
  public listaPerguntasPesquisaModelo: PerguntasEntity[] = [];
  public index: number = 0;
  public perguntaSelecionada!: PerguntaPesquisaModeloEntity;

  @Output() save = new EventEmitter<{
    data: {},
    index: number
  }>();

  constructor(public http: HttpService) {
  }

  ngOnInit(): void {
  }

  open(){
    this.listaPerguntasPesquisaModelo = [];
    this.http.get('api/pesquisamodelo/pergunta-modelo/score/pesquisa-modelo/'+this.idPesquisa).subscribe(
      resp => {
        //@ts-ignore
        console.log(resp.data)
        //@ts-ignore
        resp.data.map((perguntaM: PerguntaPesquisaModeloEntity, index) => {
          this.listaPerguntasPesquisaModelo.push(perguntaM.pergunta)
        });
      }
    )
    this.modal.open()
  }

  salvar(pergunta?: PerguntasEntity){

    if (!!pergunta) {
      this.save.emit({
        index: this.index,
        data: pergunta
      });

      return;
    }

    this.save.emit({
      index: this.index,
      data: this.perguntaSelecionada
    });
  }

  close(){
    this.modal.close();
  }

}
