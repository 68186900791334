import {Component, HostListener} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ){
    this.matIconRegistry.addSvgIcon(
      "+",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/+.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "editar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/editar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "restaurar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/restaurar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "fechar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/fechar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "lixeira",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/lixeira.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "lupa",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/lupa.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "view",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/view.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "logo_g_google",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/logo_google_g_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "logo_microsoft",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/logo_microsoft_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "perfil_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/icone_perfil.svg")
    );
  }


  // evento para reposicionar scroll em primeiro input errado da tela
  @HostListener('window:scroll-to-input-error', ['$event'])
  scrollToInputError() {
    const inputErrors = document.getElementsByClassName('ng-invalid');

    if (inputErrors.length > 0) {
      window.scrollTo(0, (inputErrors[0].scrollHeight - 50));
    }
  }
}
