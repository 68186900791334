import { Injectable } from '@angular/core';
import {AbstractHttpService, interfFindAll} from '@datagrupo/dg-crud';
import {HistoricoPesquisaEntity} from "../entity/historico-pesquisa-entity";
import {environment} from "../../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "../../../../../services/session/session.service";
import {Observable} from "rxjs";
import {HttpService} from "../../../../../services/http/http.service";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class HistoricoPesquisaService extends AbstractHttpService<HistoricoPesquisaEntity>{

  constructor(
    http: HttpClient,
    protected httpService: HttpService,
    protected session: SessionService,
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '/api/pesquisa');
  }


  findAll(data?: interfFindAll): Observable<HistoricoPesquisaEntity> {

    if (this.session.checkPerfil('GESTOREMPRESA')) {
      data = {
        ...data,
        options: {
          id: this.session.user.empresa?.id,
          endpoint: 'empresa'
        }
      }
    }

    return super.findAll(data);
  }

  findAllBySideBar(idEmpresa: number | string): Observable<RestReturn<HistoricoPesquisaEntity>> {
    return this.httpService.get<RestReturn<HistoricoPesquisaEntity>>('api/pesquisa/empresa/' + idEmpresa);
  }

  enviarPendentes(id: number | string) : Observable<RestReturn<any>>{
    return this.httpService.post('api/pesquisa/enviarpesquisa/respondentespendentes/'+id,{})
  }

  buscarEmailEnviarPesquisa(id: number | string){
    return this.httpService.getById<RestReturn<any>>('api/pesquisa/enviarpesquisa', String(id))
  }

  enviarEmailEnviarPesquisa(data: {id: number | string, listaEmails: string}){
    return this.httpService.post<RestReturn<any>>('api/pesquisa/enviarpesquisa', data)
  }

  alterarPrazo(data: { id: number | string, dataFim: string}) {
    return this.httpService.put<RestReturn<any>>('api/pesquisa/alterarprazo', data)
  }
}
