import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PerguntaPesquisaEntity} from "../../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

@Component({template: ''})
export abstract class AbstractCardRespostaComponent implements OnInit {

  @Input() pergunta: PerguntaPesquisaEntity = <PerguntaPesquisaEntity> new PerguntaPesquisaEntity();
  @Input() index: number = 0;
  @Output() change = new EventEmitter<any>();
  @Input() disableCard = false;

  public touched = false;
  public answerOk = false;
  public perguntaObrigatoria = false;


  public perguntaObrigatoriaPendente = false;

  protected constructor() {}

  ngOnInit() {

  }
}
