import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScoreComponent } from './score/score.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {UiModule} from "../../components/ui/ui.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {DirectivesModule} from "../../directives/directives.module";

@NgModule({
  declarations: [
    ScoreComponent
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        UiModule,
        MatProgressBarModule,
        MatRadioModule,
        MatIconModule,
        FormsModule,
        RouterModule,
        DirectivesModule
    ]
})
export class ScoreModule { }
