import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AbstractInsertEdit} from "@datagrupo/dg-crud";
import {ResponderPesquisaEntity} from "../entity/responder-pesquisa-entity";
import {PerguntaPesquisaEntity} from "../../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {RespostaPerguntaPesquisa} from "../entity/resposta-pergunta-pesquisa";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import {ActivatedRoute, Router} from "@angular/router";
import {ResponderPesquisaService} from "../service/responder-pesquisa.service";
import {Location} from "@angular/common";
import {SessionService} from "../../../../../../services/session/session.service";
import Swal from "sweetalert2";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-responder-pesquisa',
  templateUrl: './responder-pesquisa.component.html',
  styleUrls: [
    '../../scss/root-responder-pesquisa.scss',
    './responder-pesquisa.component.scss'
  ]
})
export class ResponderPesquisaComponent extends AbstractInsertEdit<ResponderPesquisaEntity> implements OnInit {
  perguntas: PerguntaPesquisaEntity[] = [];
  respostas: RespostaPerguntaPesquisa[] = [];

  load = true;

  public titulo = '';

  pesquisaAnonima = true;

  @ViewChild('swallTermo') swall!: SwalComponent;
  @ViewChild('textDescricao') textDescricao!: HTMLDivElement;

  constructor(
    public route: ActivatedRoute,
    public service: ResponderPesquisaService,
    protected location: Location,
    public session: SessionService,
    private router: Router,
    public readonly swalTargets: SwalPortalTargets,
    private rerender: Renderer2,
    config: ConfigDgCrudService
  ) {
    super(service, config);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.pesquisaAnonima = window.location.pathname.split('/')[3] === 'anonima'

    window.sessionStorage.removeItem('redirect');
  }

  voltar () {
    window.sessionStorage.setItem('nome-pesquisa-respondida', String(this.titulo))
    this.router.navigate(['pesquisa/respondida-com-sucesso'])
  }

  initNewEntity(): void {
  }

  afterFetchEntity() {
    this.load = false;
    this.entity.respostas = this.entity.perguntaPesquisa.map((pergunta) => {

      let opcao = undefined;

      if (pergunta.tipoResposta === 'CHECKBOX' || pergunta.tipoResposta === 'COMBOBOX' || pergunta.tipoResposta === 'RADIOBUTTON') {
        opcao = [{
          opcaoPerguntaPesquisaId: '',
          detalhes: ''
        }]
      }

      return new RespostaPerguntaPesquisa(
        Number(pergunta.id),
        '',
        [],
        pergunta.perguntaObrigatoria
      )
    });

    this.titulo = String(this.entity.titulo);

    setTimeout(() => this.setTextEditor(), 10)
    // this.setTextEditor()
  }

  verifyPesquisa(): boolean {

    const listPergValidat = this.entity.perguntaPesquisa.filter(
      perg => perg.perguntaObrigatoria && perg.visibleByPredecessor
    )

    let countErrors = false;

    listPergValidat.map(perg => {
      const index = this.entity.respostas.findIndex(resp => resp.perguntaPesquisaId == perg.id)

      const verificaRespostaPorTipo = ():boolean => {
        if (
          perg.tipoResposta == 'TEXT' ||
          perg.tipoResposta == 'BIG_TEXT'
        ) {
          return !!this.entity.respostas[index].resposta
        } else if (perg.tipoResposta == 'NUMBER') {
          if (this.entity.respostas[index].resposta == '') {
            return false;
          }
          return !isNaN(Number(this.entity.respostas[index].resposta));
        }

        return (this.entity.respostas[index].respostaOpcao || []).length > 0
      }

      if (verificaRespostaPorTipo()) {
        this.entity.respostas[index].respostaOk = true;
      } else {
        countErrors = true;
        this.entity.respostas[index].respostaOk = false;
      }
    })

    return !countErrors;
  }

  openSwal(){
    window.dispatchEvent(new CustomEvent('markAsTouched-pergunta'))

    if (this.verifyPesquisa()) {
      this.swall.fire();
    }
  }

  beforeSaveEntity(){
    return true;
    // TODO resolver essa bagaça
    // essa validação deveria estar em verifyPesquisa() para retornar com a função
    // de levar até a pergunta não respondida
    // const perguntasObrigatorias = this.entity.respostas.findIndex((resposta, index) => {
    //
    //   const indexPergunta = this.perguntas.findIndex(perg => perg.id == resposta.perguntaPesquisaId);
    //
    //   if (indexPergunta != -1) {
    //     if (!this.perguntas[indexPergunta].visibleByPredecessor) {
    //       return false;
    //     }
    //   }
    //
    //   if (resposta.perguntaObrigatoria) {
    //     if (
    //       this.entity.perguntaPesquisa[index].tipoResposta === 'CHECKBOX' ||
    //       this.entity.perguntaPesquisa[index].tipoResposta === 'COMBOBOX' ||
    //       this.entity.perguntaPesquisa[index].tipoResposta === 'RADIOBUTTON'
    //     ) {
    //       if (!Array.isArray(resposta.respostaOpcao)) {
    //         return false;
    //       }
    //       return resposta.respostaOpcao[0]?.opcaoPerguntaPesquisaId === '';
    //     } else if (this.entity.perguntaPesquisa[index].tipoResposta === 'NUMBER') {
    //       const respostaString = String(resposta.resposta);
    //
    //       return (
    //         respostaString === "+" ||
    //         respostaString === "e" ||
    //         respostaString === "," ||
    //         respostaString === "." ||
    //         respostaString === "-" ||
    //         respostaString === ""
    //       );
    //     } else {
    //       const result = resposta.resposta?.trim() === '';
    //       console.log(this.respostas)
    //       this.respostas[index]['respostaOk'] == false;
    //       return resposta.resposta?.trim() === ''
    //     }
    //   }
    //
    //   return false;
    // })
    //
    // if (perguntasObrigatorias !== -1) {
    //   window.dispatchEvent(new CustomEvent('verifica-perguntas-obrigatorias'));
    //   setTimeout(() => {
    //     const inputErrors = document.getElementsByClassName('obrigatoria-pendente');
    //
    //     if (inputErrors?.length > 0) {
    //       window.scrollTo(0, (inputErrors[0].scrollHeight - 50));
    //     }
    //   }, 100);
    //   return false;
    // }
    //
    // return true;
  }

  alterUser() {
    Swal.fire({
      title: 'Sair',
      text: 'Deseja sair do Alline',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#2c516e',
      cancelButtonColor: '#0DCAF0',
      cancelButtonText: 'Ficar',
      confirmButtonText: 'Sair'
    }).then((result) => {
      if (result.isConfirmed) {
        window.sessionStorage.setItem('redirect', window.location.pathname);
        this.session.logout()
      }
    })
  }

  changeValues(data: {resposta: RespostaPerguntaPesquisa, index: number, campo: string}) {
    if (data.campo === 'resposta') {
      this.entity.respostas[data.index].resposta = data.resposta.resposta
    }

    if (data.campo === 'opcao') {
      this.entity.respostas[data.index].respostaOpcao = data.resposta.respostaOpcao;
    }
  }

  countAll() {
    return (this.entity.perguntaPesquisa || []).filter(perg => perg.visibleByPredecessor).length
  }

  currentLabel = (i: number) => {
    return this.entity.perguntaPesquisa.filter((perg, index) => {
      if (index <= i) {
        if (!perg.perguntaPesquisaPredecessora || (!!perg.perguntaPesquisaPredecessora && perg.visibleByPredecessor)) {
          return true
        }
      }
      return false
    }).length;
  }

  setTextEditor() {
    //@ts-ignore
    const navigate = this.textDescricao.nativeElement;
    this.rerender.setProperty(navigate, 'innerHTML', this.entity?.descricao || '')
  }
}
