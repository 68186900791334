import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {
  PesquisaModeloService
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/services/pesquisa-modelo-service/pesquisa-modelo.service";
import {
  MatrizPesoModeloService
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/services/matriz-peso/matriz-peso-modelo.service";
import {PerguntasEntity} from "../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {PerguntaPesquisaEntity} from "../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

@Component({
  selector: 'modal-busca-perguntas-matriz',
  templateUrl: './modal-busca-perguntas-matriz.component.html',
  styleUrls: ['./modal-busca-perguntas-matriz.component.scss']
})
export class ModalBuscaPerguntasMatrizComponent implements OnInit {

  @ViewChild('modal_busca_pergunta_matriz') modal!: ModalComponent;

  public idPesquisa: number | string = '';
  public listaPerguntasPesquisaModelo: PerguntaPesquisaModeloEntity[] = [];
  public index: number = 0;
  public perguntaSelecionada!: PerguntaPesquisaModeloEntity;

  @Output() save = new EventEmitter<{
    data: {},
    index: number
  }>();

  constructor(public modeloService: MatrizPesoModeloService) {
  }

  ngOnInit(): void {
  }

  open(){
    this.modeloService.getPerguntasScoreY(this.idPesquisa).subscribe(
      resp => {
        //@ts-ignore
        this.listaPerguntasPesquisaModelo = resp.data;
      }
    )

    // this.modeloService.findOne(this.idPesquisa, '').subscribe(
    //   resp => {
        //@ts-ignore
        // this.listaPerguntasPesquisaModelo = resp.data.perguntas;
    //   }
    // )
    this.modal.open()
  }

  salvar(pergunta?: PerguntaPesquisaModeloEntity){

    if (!!pergunta) {
      this.save.emit({
        index: this.index,
        data: pergunta.pergunta
      });

      return;
    }

    this.save.emit({
      index: this.index,
      data: this.perguntaSelecionada
    });
  }

  close(){
    this.modal.close();
  }

}
