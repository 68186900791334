<div
  class="card dg-card card-responder-jpd w-100 card-responder-jpd-{{index}}"
  #cardResposta
>
  <div *ngIf="pergunta.perguntaObrigatoria" [class.obrigatoria-pendente]="!resposta.respostaOk" class="card-header d-inline-flex justify-content-center">
    <span
      *ngIf="(!resposta.messageError || resposta.respostaOk)"
    >Pergunta obrigatória</span>
    <span
      *ngIf="(!!resposta.messageError && !resposta.respostaOk)"
      [innerHTML]="resposta.messageError"
    >
    </span>
  </div>

  <div class="card-body">
    <div class="row">
      <p>{{pergunta.pergunta}}</p>
    </div>

    <div
      class="col-12"
      *ngIf="(pergunta.tipoResposta === 'CHECKBOX' || pergunta.tipoResposta === 'RADIOBUTTON' || pergunta.tipoResposta === 'COMBOBOX' )"
    >
      <div
        class="form-check col-12 mb-1 pb-1"
        style="border-bottom: solid 1px lightgray"
        *ngFor="let opcao of resposta.respostaOpcao; let indexOpcao = index"
      >
        <label
          class="form-check-label d-inline-flex w-100 align-items-center"
          for="opcao-resposta-{{index}}-{{indexOpcao}}"
          style="position: relative;"
        >
          <span class="col-10">{{ getNomeOpcao(opcao.opcaoPerguntaPesquisaId) }}</span>
          <div class="col-2">
            <div class="input-group">
              <input
                *ngIf="percentual"
                [disabled]="cardExibicao"
                type="text"
                class="form-control"
                id="opcao-resposta-{{index}}-{{indexOpcao}}"
                [(ngModel)]="opcao.quantidade"
                (ngModelChange)="changeValue('opcao', indexOpcao)"
                mask="separator.2" thousandSeparator="" separatorLimit="999"
              >
              <input
                *ngIf="!percentual"
                [disabled]="cardExibicao"
                type="text"
                class="form-control"
                id="opcao-resposta-{{index}}-{{indexOpcao}}"
                [(ngModel)]="opcao.quantidade"
                (ngModelChange)="changeValue('opcao', indexOpcao)"
                mask="000000000000"
                [validation]="false"
              >
              <span *ngIf="!!percentual" class="input-group-text color-purple" id="basic-addon2">%</span>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div *ngIf="pergunta.tipoResposta === 'NUMBER'" class="col-6">
      <mat-form-field style="width: 100%" appearance="fill">
        <input
          matInput
          [(ngModel)]="resposta.resposta"
          (ngModelChange)="changeValue('number', null)"
          type="number"
          placeholder="1 ou 1.5"
        >
      </mat-form-field>
    </div>

  </div>
  <div class="card-footer">
    <div class="row mt-3 mb-1">
      <div class="col-8">
        <p class="color-purple f-bold">
          {{ (!!pergunta.categoria && pergunta.categoria[0]) ? pergunta.categoria[0].toUpperCase() + pergunta.categoria.substr(1) : '' }}
        </p>
      </div>
      <div class="col-4 d-inline-flex">
        <input
          id="percentual-{{index}}"
          [disabled]="cardExibicao"
          [(ngModel)]="percentual"
          (ngModelChange)="changeValue('percentual', null)"
          class="form-check-input purple md pr-2"
          type="checkbox"
        >
        <label
          class="form-check-label"
          style="margin-left: .5rem"
          for="percentual-{{index}}"
        >
          <span class="color-purple f-bold">Responder em percentual</span>
        </label>
    </div>
  </div>
  </div>
</div>
