import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {ReplaceErrorList} from "./error-codes";
import Swal from "sweetalert2";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class CallbackMessageInterceptor implements HttpInterceptor {

  constructor(private _snackBar: MatSnackBar) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError( error => {

      if (error.error.errorCode) {
        let replace: any;

        if (ReplaceErrorList.hasOwnProperty(error.error.errorCode)) {
          replace = (ReplaceErrorList as any)[error.error.errorCode]
        } else {
          replace = { title: error.error.message }
        }

        Swal.fire({
          icon: 'error',
          timer: 10000,
          ...replace
        });
      } else {

        if (
          error instanceof HttpErrorResponse && !request.url.includes('auth/signin') &&
          error.status !== 401 && error.status !== 403 && !error.error.message) {
          this._snackBar.open("Um erro inesperado ocorreu, tente novamente mais tarde.", 'X', {
            duration: 3000
          });
        } else if ((error.status !== 401 || error.status !== 403) && !error.error.message){
          this._snackBar.open("Renovando sua chave de acesso!", 'X', {
            duration: 3000
          });
        } else if (error.error.message) {
          Swal.fire({
            title: error.error.message,
            icon: 'error',
            timer: 10000
          });
        }
      }

      return throwError(error);
    }));
  }
}
