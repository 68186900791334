<nav id="header-principal" class="navbar navbar-expand-sm navbar-dark">
  <div class="container-fluid">


    <a class="navbar-brand mr-auto logo" (click)="util.homeUrl()">
      <img style="margin: .5rem" src="assets/img/logo_justa4.png">
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#div-menuPrincipal"
      aria-controls="div-menuPrincipal"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span style="color: white !important;" class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="div-menuPrincipal">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 d-inline-flex align-items-center">
        <li
          check-perfil [perfil]="['GESTORTROCA', 'GESTOREMPRESA']"
          class="nav-item cadastro dropdown"
        >
          <a class="nav-link dropstart" href="#" id="navbarDropdown-dropCadastros" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="span-nav-link">Cadastrar</span>
            <mat-icon>expand_more</mat-icon>
          </a>

          <ul class="dropdown-menu" aria-labelledby="navbarDropdown-dropCadastros">
            <li check-perfil perfil="GESTORTROCA">
              <a class="dropdown-item" routerLink="/cadastros/pesquisa-modelo">
                Pesquisa Modelo
              </a>
            </li>

            <li check-perfil perfil="GESTORTROCA">
              <a class="dropdown-item" routerLink="/cadastros/tipo-pesquisa">
                Tipo de Pesquisa
              </a>
            </li>

            <li check-perfil perfil="GESTORTROCA">
              <a class="dropdown-item" routerLink="/cadastros/empresas">
                Empresas
              </a>
            </li>

            <li check-perfil [perfil]="['GESTORTROCA', 'GESTOREMPRESA']">
              <a class="dropdown-item" routerLink="/cadastros/usuarios">
                Usuários
              </a>
            </li>

            <li check-perfil perfil="GESTORTROCA">
              <a class="dropdown-item" routerLink="/cadastros/perguntas">
                Perguntas
              </a>
            </li>
          </ul>

        </li>

        <li class="nav-item icon dropdown">

          <a class="nav-link dropstart" href="#" id="navbarDropdown-dropUserPerfil" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon svgIcon="perfil_icon" class="icon-face"></mat-icon>
          </a>

          <ul class="dropdown-menu" aria-labelledby="navbarDropdown-dropUserPerfil">
            <li>
              <span (click)="router.navigate(['/meus-dados'])" class="dropdown-item">
                Meus dados
              </span>
            </li>

            <li>
              <span (click)="openAlterPass()" class="dropdown-item">
                Alterar senha
              </span>
            </li>

            <li check-perfil perfil="GESTORTROCA">
              <hr>
              <a routerLink="/configuracoes" class="dropdown-item">
                Configurações
              </a>
            </li>

            <li>
              <hr>
              <span class="dropdown-item sair" (click)="session.logout()" style="cursor: pointer">
                <mat-icon style="font-size: 1.3em">logout</mat-icon> Sair
              </span>
            </li>

          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<swal
  #swallAlterSenha
  cancelButtonText="Cancelar"
  [showCancelButton]="true"
  confirmButtonText="Ok"
  [preConfirm]="verify"
  confirmButtonColor="#EF426F"
>
  <ng-container *swalPortal="swalTargets.content">
    <form [formGroup]="form" class="sweet-alterar-senha">
      <div style="width: 95%; margin: auto">
        <div style="text-align: start; width: 100% !important;">
          <label class="form-label d-inline-flex justify-content-start">Senha atual</label>
        </div>

        <div class="input-group mb-3">
          <input
            type="{{!!viewPass.oldPass ? 'text' : 'password'}}"
            class="form-control"
            formControlName="oldPassword"
          >
          <span  *ngIf="!viewPass.oldPass"  class="input-group-text">
            <mat-icon class="iconViewPrass" (click)="viewPass.oldPass = !viewPass.oldPass">visibility</mat-icon>
          </span>
          <span  *ngIf="viewPass.oldPass"  class="input-group-text">
            <mat-icon class="iconViewPrass" (click)="viewPass.oldPass = !viewPass.oldPass">visibility_off</mat-icon>
          </span>
        </div>
      </div>

      <div style="width: 95%; margin: auto">
        <div style="text-align: start; width: 100% !important;">
          <label class="form-label d-inline-flex justify-content-start">Nova senha</label>
        </div>

        <div class="input-group mb-3">
          <input
            type="{{viewPass.newPass ? 'text' : 'password'}}"
            class="form-control"
            formControlName="newPassword"
          >
          <span  *ngIf="!viewPass.newPass"  class="input-group-text">
            <mat-icon class="iconViewPrass" (click)="viewPass.newPass = !viewPass.newPass">visibility</mat-icon>
          </span>
          <span  *ngIf="viewPass.newPass"  class="input-group-text">
            <mat-icon class="iconViewPrass" (click)="viewPass.newPass = !viewPass.newPass">visibility_off</mat-icon>
          </span>
        </div>
      </div>

      <div style="width: 95%; margin: auto">
        <div style="text-align: start; width: 100% !important;">
          <label class="form-label d-inline-flex justify-content-start">Repetir nova senha</label>
        </div>

        <div class="input-group mb-3">
          <input
            type="{{viewPass.confirmPass ? 'text' : 'password'}}"
            class="form-control"
            formControlName="confirmNewPassword"
          >
          <span  *ngIf="!viewPass.confirmPass"  class="input-group-text">
            <mat-icon class="iconViewPrass" (click)="viewPass.confirmPass = !viewPass.confirmPass">visibility</mat-icon>
          </span>
          <span  *ngIf="viewPass.confirmPass"  class="input-group-text">
            <mat-icon class="iconViewPrass" (click)="viewPass.confirmPass = !viewPass.confirmPass">visibility_off</mat-icon>
          </span>
        </div>
      </div>
    </form>
  </ng-container>
</swal>
