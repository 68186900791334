import {Directive, ElementRef, Input} from '@angular/core';
import {SessionService} from "../../services/session/session.service";

@Directive({
  selector: '*[check-perfil]'
})
export class CheckPerfilDirective {

  @Input('perfil') perfil:
    'RESPONDENTE' | 'GESTOREMPRESA' | 'GESTORTROCA' |
    string[] = 'RESPONDENTE';

  constructor(
    private elRef: ElementRef,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    const el = this.elRef.nativeElement;

    if (!this.sessionService.checkPerfil(this.perfil)) {
      el.style.display = 'none';
    }

  }
}
