import {AfterContentChecked, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PerguntaPesquisaEntity} from "../../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {TipoRespostaArray} from "../../../../../pages/_cadastros/enum/tipo-resposta";
import {TipoRespostaService} from "../../../../../pages/_cadastros/pages/perguntas/services/tipo-resposta/tipo-resposta.service";
import {OpcaoPerguntaPesquisaEntity} from "../../../../../pages/_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import {AbstractCardPerguntaComponent} from "../abstract-card-pergunta/abstract-card-pergunta.component";
import {fakeIdOpcoes, inspectFakeIdPesquisa} from "../../../../../pages/_pesquisas/helpers/fake-id";

@Component({
  selector: 'card-pergunta-editar',
  templateUrl: './card-pergunta-editar.component.html',
  styleUrls: ['./card-pergunta-editar.component.scss']
})
export class CardPerguntaEditarComponent extends AbstractCardPerguntaComponent implements OnInit, AfterContentChecked {

  public form: FormGroup;
  public opcoesExibicao = '';

  @Input() index: number = 0;
  @Input() pergunta: PerguntaPesquisaEntity = <PerguntaPesquisaEntity> new PerguntaPesquisaEntity();
  @Input() listPreguntas: PerguntaPesquisaEntity[] = [];
  @Input() dataAutoComplete: string[] = [];
  @Input() perguntaOpcao: { podeEditar: boolean, podeRemover: boolean, perguntaObrigatoria: boolean } = {
    podeEditar: false,
    podeRemover: false,
    perguntaObrigatoria: false
  }
  @Input() disabledInputCard: boolean = false;
  @Input() tipoResposta: string[] = [];

  public tipoRespostaFiltrado: {chave: string, valor: string}[] = TipoRespostaArray;
  public removida: boolean = false;
  public editada: boolean = false;
  public statusMode: string = '';

  @Output() change = new EventEmitter<{
    pergunta: PerguntaPesquisaEntity,
    index: number,
    campo: string
  }>();
  @Output() excluir = new EventEmitter<number>();

  @Output() restaurarPergunta = new EventEmitter<any>();

  constructor(
    private formBuild: FormBuilder,
    protected tipoRespService: TipoRespostaService
  ) {
    super();
    this.form = this.formBuild.group({
      pergunta: ['', Validators.required],
      tipoResposta: ['', Validators.required]
    });

    if (!Array.isArray(this.pergunta.opcoes)) {
      this.pergunta.opcoes = [];
    }

  }

  ngOnInit(): void {
    this.form.get('tipoResposta')?.valueChanges.subscribe((tipoResp: string) => {
      this.opcoesExibicao = tipoResp;
      if (tipoResp === 'COMBOBOX' || tipoResp === 'CHECKBOX' || tipoResp === 'RADIOBUTTON' ) {
        if (this.pergunta.opcoes?.length === 0) {
          const newOpcao = new OpcaoPerguntaPesquisaEntity()
          newOpcao.fakeId = fakeIdOpcoes(this.listPreguntas)
          this.pergunta.opcoes.push(newOpcao);
        }
      }
    });

    let tipoResposta = this.initTipoResposta(this.tipoResposta, this.pergunta.tipoResposta);

    this.pergunta.opcoes.map((opcao, index) => {
      if (!opcao.fakeId) {
        this.pergunta.opcoes[index].fakeId = fakeIdOpcoes(this.listPreguntas);
      }
    })

    // if (this.tipoResposta) {
    //   if (this.tipoResposta.length > 0) {
    //     tipoResposta = !!this.pergunta.tipoResposta ? this.pergunta.tipoResposta : this.tipoResposta[0];
    //   } else {
    //     tipoResposta = !!this.pergunta.tipoResposta ? this.pergunta.tipoResposta : "TEXT";
    //   }
    // }

    if (this.pergunta.status.findIndex(status => status === 'REMOVIDA') !== -1) {
      this.removida = true;
    }

    this.form.patchValue({
      pergunta: this.pergunta.pergunta,
      tipoResposta: tipoResposta,
    });

    this.changeValue('tipo_resposta')
  }

  ngAfterContentChecked(){
    this.statusMode = this.pergunta.status.map(status => status.split(' ').join('_')).join('-');

    if (
      this.statusMode === 'ORIGINAL' || this.statusMode === 'ORIGINAL-REMOVIDA' ||
      this.statusMode === 'ADICIONADA-BANCO_DE_DADOS'
    ) {
      this.disabledInputCard = true;
      this.form.disable();
    } else {
      this.disabledInputCard = false;
      this.form.enable();
    }
  }

  outputExcluir() {
    const perguntasSucessoras = this.listPreguntas.filter(perg => {
      if (!perg.perguntaPesquisaPredecessora) return false;

      return perg.perguntaPesquisaPredecessora.fakeId == this.pergunta.fakeId
    })

    if (perguntasSucessoras.length > 0) {
      this.verifySucessorByDisabled(() => {
        this.excluir.emit(this.index)
      })
    } else {
      this.excluir.emit(this.index);
    }
  }

  changeValue(campo: string){
    if (campo === 'removida') {
      this.verifySucessorByDisabled(() => {
        if (this.removida) {
          this.pergunta.status.push('REMOVIDA')
        } else {
          const index = this.pergunta.status.findIndex(status => status === 'REMOVIDA')

          this.pergunta.status.splice(index, 1);
        }
      })

      return;
    }

    if (campo === 'editada') {
      this.editada = !this.editada;
      if (this.editada) {
        this.pergunta.status.push('ALTERADA')
        // this.disabledInputCard = false;
      } else {
        const index = this.pergunta.status.findIndex(status => status === 'ALTERADA')

        this.pergunta.status.splice(index, 1);
        // this.disabledInputCard = true
      }
    }

    this.emitChange(campo)
  }

  @HostListener('window:card-perguntas-verificar')
  verificarPergunta(){
    this.form.markAllAsTouched();
  }

  emitChange(campo: string) {
    this.change.emit({
      pergunta: {
        ...this.pergunta,
        ...this.form.value,
        podeEditar: !this.perguntaOpcao.podeEditar,
        podeRemover: !this.perguntaOpcao.podeRemover,
        perguntaObrigatoria: !this.perguntaOpcao.perguntaObrigatoria
      },
      index: this.index,
      campo
    })
  }

  disableOpcaoResposta(opcao: string): boolean {
    if (this.tipoResposta.length === 0) {
      return false;
    } else if (Array.isArray(this.tipoResposta)) {
      return this.tipoResposta.findIndex((tipoR) => tipoR === opcao) === -1
    }

    return true;
  }

  verifySucessorByDisabled(callback: Function) {

    const perguntasSucessoras = this.listPreguntas.filter(perg => {
      if (!perg.perguntaPesquisaPredecessora) return false;

      return perg.perguntaPesquisaPredecessora.perguntaOriginalId == this.pergunta.perguntaOriginalId
    })

    if (perguntasSucessoras.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Perguntas Sucessoras',
        text: 'Uma ou mais perguntas estão condicionadas a aparecer a partir de uma resposta dessa pergunta.' +
          ' Escolha uma ação a ser tomada sobre essas perguntas',
        input: 'select',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        cancelButtonText: 'Sair',
        inputOptions: {
          removerRelacao: 'Remover Relação',
          removerPerguntas: 'Remover Perguntas'
        }
      }).then(resp => {
        if (resp.isConfirmed) {
          window.dispatchEvent(
            new CustomEvent(
              'remove-pergunta-sucessora-by-predecessora',
              {
                detail: {
                  perguntaOriginalId: this.pergunta.perguntaOriginalId,
                  action: resp.value
                }
              }
            )
          )

          callback()
        } else {
          this.removida = false
        }
      })
    } else {
      callback()
    }

    this.emitChange('removida')
  }

  @HostListener('window:remove-pergunta-sucessora-by-predecessora', ['$event'])
  removePerguntaSucessoraByPredecessora(ev: CustomEvent) {
    const perguntaOriginalId = ev.detail.perguntaOriginalId
    const action = ev.detail.action

    if (perguntaOriginalId == this.pergunta.perguntaPesquisaPredecessora?.perguntaOriginalId) {
      if (action == 'removerRelacao') {
        this.pergunta.perguntaPesquisaPredecessora = undefined;
        this.pergunta.opcaoPerguntaPesquisaPredecessora = undefined;
      } else {
        if (this.pergunta.status.findIndex(perg => perg == 'ORIGINAL') >= 0) {
          this.removida = true;
          this.changeValue('removida')
        } else {
          this.excluir.emit(this.index);
        }
      }
    }
  }

  override deleteOpcaoResposta(i: number): void {
    const index = this.listPreguntas.findIndex((perg) => {
      return perg.opcaoPerguntaPesquisaPredecessora?.fakeId == this.pergunta.opcoes[i].fakeId
    })

    if (index != -1) {
      Swal.fire({
        icon: 'warning',
        title: 'Perguntas Sucessoras',
        text: 'Uma ou mais perguntas estão condicionadas a aparecer a partir dessa resposta na pergunta.' +
          ' Escolha uma ação a ser tomada sobre essas perguntas',
        input: 'select',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        cancelButtonText: 'Sair',
        inputOptions: {
          removerRelacao: 'Remover Relação',
          removerPerguntas: 'Remover Perguntas'
        }
      }).then(resp => {
        if (resp.isConfirmed) {
          window.dispatchEvent(
            new CustomEvent(
              'remove-pergunta-sucessora-by-predecessora',
              {
                detail: {
                  perguntaOriginalId: this.pergunta.perguntaOriginalId,
                  action: resp.value
                }
              }
            )
          )

          super.deleteOpcaoResposta(i)
        }
      })

      return;
    }

    super.deleteOpcaoResposta(i)
  }

  override addOpcaoResposta(index: number, opcao = '') {
    const newOpcao = <OpcaoPerguntaPesquisaEntity> (!!opcao ? new OpcaoPerguntaPesquisaEntity(undefined, opcao) : new OpcaoPerguntaPesquisaEntity())

    if (!!this.pergunta.fakeId) {
      newOpcao.fakeId = fakeIdOpcoes(this.listPreguntas);
    }

    if (this.pergunta.opcoes.length === (index + 1)) {
      this.pergunta.opcoes.push(newOpcao);
    } else {
      this.pergunta.opcoes.splice((index+1), 0, newOpcao)
    }

    this.onInputFocus(index+1);
  }
}
