import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { RestReturn } from '../../../../_core/config/rest-return';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-chart-column',
  templateUrl: './chart-column.component.html',
  styleUrls: ['./chart-column.component.scss']
})
export class ChartColumnComponent implements OnInit {

  public dataGraphicColumn:any;

  @Input() id:'chart-column'='chart-column';

  constructor(
    http: HttpService
  ) {
  }

  ngOnInit(): void {
  }
  
  private createChartColumn(): void {
    
    Highcharts.chart('chart-column',this.dataGraphicColumn);
  }
}
