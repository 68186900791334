import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {TokenService} from "../../_core/guards/services/token.service";

@Injectable()
export class RenewTokenInterceptor implements HttpInterceptor {

  // atributos de controle do ciclo de renovação do token
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private tokenService: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = request;
    const token = this.tokenService.getToken();

    const urlPesquisaAnonima = window.location.pathname.split('/');
    urlPesquisaAnonima.pop();

    if (this.exceptUrl('/realizar/pesquisa/anonima') && this.exceptUrl('/pesquisa/realizar/possuo-dados/anonimo') ) {
      if (token != null) {
        request = request.clone({
          setHeaders: {Authorization: 'Bearer ' + token}
        });
      }
    }

    return next.handle(request).pipe(catchError(error => {

      // Verifica se o erro do tipo 401 ou 403 existe e inicia pluxo para requisição
      // de nova chave
      if (
        error instanceof HttpErrorResponse && !authReq.url.includes('auth/login') &&
        (error.status === 401 || error.status === 403)
      ) {
        return this.handle401Error(authReq, next);
      }

      return throwError(error);
    }));
  }

  exceptUrl(exceptUrl: string) {
    const currentUrl = window.location.pathname.split('/');
    currentUrl.pop();

    return currentUrl.join('/') !== exceptUrl;
  }

  /**
   *  Adiciona header 'Authorization' na requeste passada ao metodo
   * @param request
   * @param token
   * @private
   */
  private static addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      // iniciando processo de renovação de token
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // buscando token atual
      const token = this.tokenService.getToken();

      // renovar token somente caso o mesmo existe
      if (token)
        return this.tokenService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.tokenService.saveToken(token.accessToken);
            // window.sessionStorage.setItem('accessToken', token.accessToken);


            this.refreshTokenSubject.next(token.accessToken);


            return next.handle(RenewTokenInterceptor.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            window.dispatchEvent(new CustomEvent('sistem-logout'));
            return throwError(err);
          })
        );
    }

    // após renovação do token, é retornado o requeste para uma nova tentativa
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(RenewTokenInterceptor.addTokenHeader(request, token)))
    );
  }
}
