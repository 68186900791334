import {PerguntaPesquisaEntity} from "../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {debug} from "ng-packagr/lib/utils/log";
import {
  PerguntaPesquisaModeloEntity
} from "../../_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";

/**
 * PESQUISA
 */

// adiciona fakeId nas perguntas pesquisa
export function fakeIdPerguntas(perguntas: PerguntaPesquisaEntity[]):number {
  const fakeIdList: any[] = (perguntas || []).map(perg => perg.fakeId).filter(fakeId => !!fakeId)

  if (fakeIdList.length > 0) {
    let result = 0;

    fakeIdList.map((fakeId: number) => {
      if (fakeId >= result) result = fakeId
    })

    console.log(result + 1)

    return result + 1;
  }

  return 1;
}

// adiciona fakeId nas opções da pergunta pesquisa
export const fakeIdOpcoes = (perguntas: PerguntaPesquisaEntity[]): number => {
  let result: number = 0;

  (perguntas || []).map((pergunta, index) => {
    perguntas[index].opcoes.map(opcao => {
      if (!opcao.fakeId) return;
      if (opcao.fakeId >= result) result = opcao.fakeId;
    })
  })

  return result + 1;
}

// ajusta o fakeId das predecessoras de acordo com a pergunta pesquisa relacionada
export function ajustFakeIdPredecessorByListPerguntas(perguntas: PerguntaPesquisaEntity[]) {
  (perguntas || []).map((pergPredec, index) => {
    if (!pergPredec.perguntaPesquisaPredecessora) return;
    if (!!perguntas[index].perguntaPesquisaPredecessora?.fakeId) return;

    const i = perguntas.findIndex(
      perg => perg.perguntaModeloId == pergPredec.perguntaPesquisaPredecessora?.perguntaModeloId
    )

    if (i != -1) {
      // @ts-ignore
      perguntas[index].perguntaPesquisaPredecessora['fakeId'] = perguntas[i]?.fakeId
    }
  })
}

// ajusta o fakeId das opcoes predecessoras de acordo com a opção relacionada do modelo
export function ajustFakeIdOpcaoPredecessorByListPerguntas(perguntas: PerguntaPesquisaEntity[]) {
  (perguntas || []).map((pergPredec, indexRoot) => {
    if (!pergPredec.opcaoPerguntaPesquisaPredecessora) return;

    perguntas.map((perg, indexPerg) => {
      const indexOpc = perg.opcoes.findIndex(opc => opc.id == pergPredec.opcaoPerguntaPesquisaPredecessora?.id)

      if (indexOpc != -1) {
        // @ts-ignore
        perguntas[indexRoot].opcaoPerguntaPesquisaPredecessora['fakeId'] = perguntas[indexPerg].opcoes[indexOpc].fakeId
      }
    })
  })
}

export function inspectFakeIdPesquisa(perguntas: PerguntaPesquisaEntity | PerguntaPesquisaEntity[]) {

  function montaObj(perg: PerguntaPesquisaEntity) {
    const perguntaPesquisaPredecessora = {
      pergunta: perg.perguntaPesquisaPredecessora?.pergunta,
      fakeId: perg.perguntaPesquisaPredecessora?.fakeId,
    }
    const opcaoPerguntaPesquisaPredecessora = {
      pergunta: perg.opcaoPerguntaPesquisaPredecessora?.opcao,
      // @ts-ignore
      fakeId: perg.opcaoPerguntaPesquisaPredecessora?.fakeId,
    }

    return {
      pergunta: perg.pergunta,
      fakeId: perg.fakeId,
      perguntaPesquisaPredecessora,
      opcaoPerguntaPesquisaPredecessora,
      opcoes: perg.opcoes.map(opc => {
        return {
          opcao: opc.opcao,
          fakeId: opc.fakeId
        }
      })
    };
  }

  if (Array.isArray(perguntas)) {
    return perguntas.map(perg => {
      return montaObj(perg)
    })
  }

  return montaObj(perguntas)
}

/**
 * MODELO
 */

// adiciona fakeId nas perguntas pesquisa
export function fakeIdPerguntasModelo(perguntas: PerguntaPesquisaModeloEntity[]):number {
  const fakeIdList: any[] = perguntas.map(perg => perg.fakeId).filter(fakeId => !!fakeId)

  if (fakeIdList.length > 0) {
    let result = 0;

    fakeIdList.map((fakeId: number) => {
      if (fakeId >= result) result = fakeId
    })

    console.log(result + 1)

    return result + 1;
  }

  return 1;
}

// adiciona fakeId nas opções do modelo
export const fakeIdOpcoesModelo = (perguntas: PerguntaPesquisaModeloEntity[]): number => {
  let result: number = 0;

  (perguntas || []).map((pergunta, index) => {
    perguntas[index].pergunta.opcoes.map(opcao => {
      if (!opcao.fakeId) return;
      if (opcao.fakeId >= result) result = opcao.fakeId;
    })
  })

  return result + 1;
}

export function ajusteFakeIdModelo(perguntas: PerguntaPesquisaModeloEntity[]) {
  perguntas.map((perg, index) => {
    perguntas[index].fakeId = fakeIdPerguntasModelo(perguntas)
    perg.pergunta.opcoes.map((opc, indexOpc) => {
      perguntas[index].pergunta.opcoes[indexOpc].fakeId = fakeIdOpcoesModelo(perguntas)
    })
  })
}

// ajusta o fakeId das predecessoras de acordo com a pergunta modelo relacionada
export function ajustFakeIdPredecessorByListPerguntasModelo(perguntas: PerguntaPesquisaModeloEntity[]) {
  (perguntas || []).map((pergPredec, index) => {
    if (!pergPredec.perguntaModeloPredecessora) return;

    const i = perguntas.findIndex(
      (perg) => perg.pergunta.id == pergPredec.perguntaModeloPredecessora?.pergunta.id
    )

    if (i != -1) {
      // @ts-ignore
      perguntas[index].perguntaModeloPredecessora['fakeId'] = perguntas[i]?.fakeId
    }
  })
}

// ajusta o fakeId das opcoes predecessoras de acordo com a opção relacionada do modelo
export function ajustFakeIdOpcaoPredecessorByListPerguntasModelo(perguntas: PerguntaPesquisaModeloEntity[]) {
  perguntas.map((pergPredec, indexRoot) => {
    if (!pergPredec.opcaoRespostaPredecessora) return;

    perguntas.map((perg, indexPerg) => {
      const indexOpc = perg.pergunta.opcoes.findIndex(opc => opc.id == pergPredec.opcaoRespostaPredecessora?.id)

      if (indexOpc != -1) {
        // @ts-ignore
        perguntas[indexRoot].opcaoRespostaPredecessora['fakeId'] = perguntas[indexPerg].pergunta.opcoes[indexOpc].fakeId
      }
    })
  })
}



