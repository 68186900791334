import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private Authorization: string | null;
  private path: string;

  protected constructor(
    protected http: HttpClient
  ) {
    this.Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));
    this.path = environment.apiUrl;
  }

  public post<T>(path: string, data: object): Observable<T> {

    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.post<T>(newUrl, data, {headers: { Authorization }});
  }

  public put<T>(path: string, data: object): Observable<T> {

    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.put<T>(newUrl, data, {headers: { Authorization }});
  }

  public get<T>(path: string, params?: {[key: string]: any}): Observable<T> {

    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.get<T>(
      newUrl,
      { headers: { Authorization }, params: (params || {}) });
  }

  public getById<T>(path: string, id: number | string): Observable<T> {
    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.get<T>(newUrl + '/' + id);
  }

  public delete<T>(path: string, id: number | string): Observable<T> {
    const newUrl = this.addEndpoint(path);

    const Authorization = 'Bearer ' + String(window.localStorage.getItem('accessToken'));

    return this.http.delete<T>(newUrl + '/' + id);
  }

  addEndpoint(endpoint: string | any): string {
    return endpoint ? `${this.path}/${endpoint}` : this.path;
  }
}
