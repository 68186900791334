import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPrincipalComponent } from './header-principal/header-principal.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import {MatIconModule} from "@angular/material/icon";
import { MenuComponent } from './header-menu/menu/menu.component';
import {RouterModule} from "@angular/router";
import {DirectivesModule} from "../../directives/directives.module";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    HeaderPrincipalComponent,
    HeaderMenuComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    DirectivesModule,
    SweetAlert2Module,
    ReactiveFormsModule,
    FormsModule,
    SweetAlert2Module.forChild(),
  ],
  exports: [
    HeaderPrincipalComponent,
    HeaderMenuComponent
  ]
})
export class ComponentsLayoutModule { }
