import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {AbstractHttpService, AbstractEntity} from '@datagrupo/dg-crud';
import Swal from 'sweetalert2';
import {DomSanitizer} from "@angular/platform-browser";
import {PerguntasEntity} from "../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";

export interface InterfaceColumns{
  header: string,
  varName: string,
  resource?: Function | any,
  mask?: 'cpf' | 'cnpj' | 'cep' | string,
  width?: string
}

export interface InterfaceCustomActions {
  name: string,
  action: Function,
  permission?: boolean | string | Function
}

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  displayedColumns: string[] = [];
  dataSource = [];

  public realPermission: {editar: true, deletar: true} | {} = {editar: true, deletar: true}

  @Input() columns: InterfaceColumns[] = [];

  @Input() permissions: { adicionar: boolean, editar: boolean, deletar: boolean } | any = {adicionar: true, editar: true, deletar: true};

  @Input() service!: AbstractHttpService<any>;

  @Input() entityMapping!: AbstractEntity;

  @Input() actions: boolean = true;

  @Input() customActions: InterfaceCustomActions[] = [];

  @Output() customDbClick = new EventEmitter<any>();

  // @Input() customDbClick: Function | null = null;

  constructor(
    private route: Router,
    private sanitized: DomSanitizer
  ) {
    this.realPermission = {
      ...this.realPermission,
      ...this.permissions
    }
  }

  ngOnInit(): void {

    if (this.actions) {
      const newColuns = this.columns.map(column => column.varName);
      newColuns.push('actions');

      this.displayedColumns = newColuns;
    }

    this.getAll();
  }

  @HostListener('window:dg-table-atualizar-event', ['$event'])
  getAll(
  totalElements?: number,
  totalPages?: number,
  size?: number,
  number?: number
  ) {

    this.service.pesquisar({pagination: this.entityMapping?.configEntityTable?.pagination}).subscribe(resp => {
      if (resp.status) {
        this.dataSource = resp.data;
      }
    })
  }

  defauldMask(mask: string) {
    switch (mask) {
      case 'cpf':
        return '000.000.000-00';
      case 'cnpj':
        return '00.000.000/0000-00'
      default:
        return mask
    }
  }

  irParaEditar(element: any, returnUrl?: boolean): string | null{
    if (!this.disabledCustomButton('editar', element)) {
      if (returnUrl) {
        return `${window.location.pathname}/${element.id}`
      } else {
        this.route.navigate([`${window.location.pathname}/${element.id}`])
      }
    }
    return null
  }

  resourceCel(html: any){
    return this.sanitized.bypassSecurityTrustHtml(html);
  }

  excluir(id: string | number){
    Swal.fire({
      title: 'Excluir registro',
      text: "Deseja excluir este registro? Esta ação é permanente.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.delete(id)
          .subscribe(
            resp => {
              if (resp.status) {
                this.getAll();
                Swal.fire({
                  icon: 'success',
                  title: 'Deletado com sucesso!',
                  text: 'Este registro foi deletado.',
                  showConfirmButton: false,
                  timer: 1500,
                  confirmButtonColor: '#2c516e'
                }).then()
              } else {
                Swal.fire(
                  'Erro',
                  'Não foi possível remover esse registro.',
                  'success'
                )
              }
            },
            () => {
              Swal.fire(
                'Erro',
                'Não foi possível remover esse registro.',
                'success'
              )

            });
      }
    })
  }

  disabledCustomButton(value: boolean | string | any, data: any): boolean{
    if (value === undefined) {
      return false;
    }
    if (typeof value === 'boolean') {
      return !value;
    }
    if (typeof value === 'string') {
      if (typeof this.permissions[`${value}`] === "boolean") {

        return !this.permissions[`${value}`];
      } else if (typeof this.permissions[`${value}`] === "function") {

        return !this.permissions[`${value}`](data);
      }
    }
    if (typeof value === 'function') {
      return !value(data);
    }

    return false
  }
}
