import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SessionGuard} from "./_core/guards/guard-session/session.guard";
import {HomeComponent} from "./pages/home/home-component/home.component";
import {LayoutComponent} from "./layout/layout.component";
import {LoginGuard} from "./_core/guards/guard-login/login.guard";
import {ResponderPesquisaComponent} from "./pages/_pesquisas/pages/responder/responder-pesquisa/insert-component/responder-pesquisa.component";
import {PreviewPesquisaComponent} from "./pages/_pesquisas/pages/preview-pesquisa/preview-pesquisa/preview-pesquisa.component";
import {MeusDadosComponent} from "./pages/meus-dados/meus-dados/meus-dados.component";
import {ConfiguracoesComponent} from "./pages/configuracoes/configuracoes-insert/configuracoes.component";
import {
  PreviewPesquisaModeloComponent
} from "./pages/_pesquisas/pages/preview-pesquisa-modelo/preview-pesquisa-modelo/preview-pesquisa-modelo.component";
import {PermissionGuard} from "./_core/guards/guard-permission/permission.guard";
import {
  RespondidoSucessoComponent
} from "./pages/_pesquisas/pages/responder/respondido-sucesso/respondido-sucesso.component";
import {PreviewJpdComponent} from "./pages/_pesquisas/pages/preview-pesquisa/preview-jpd/preview-jpd.component";
import {ScoreComponent} from "./pages/score/score/score.component";



/** LOGIN E AUTENTICAÇÃO
 *  todas o escopo de rotas de AUTENTICAÇÃO DE USUÁRIO
 *  estão inclusas nesta MACRO-ROTA AUTH
 */
const auth = {
  path: 'auth',
    loadChildren: () =>
  import('./pages/_auth/auth.module').then((m) => m.AuthModule),
  canActivate: [LoginGuard]
};


/** PESQUISAS
 * Esse conjunto de rotas não compreende o cadastro de pesquisa,
 * o CADASTRO DE PESQUISA ESTÁ NO CONJUNTO DE ROTAS DE CADASTROS
 *
 * Abaixo são separadas as rodas internas do sistema das rotas anonimas
 * isso se deve pelo fato de não podermos ter identificadores de usuários
 * em rotas anonimas, alem de não ser utilizado o layout convencional
 * para telas internas do sistema
 */
const pesquisa = [
  // MACRO-ROTAS de PESQUISA
  {
    path: 'pesquisa',
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/_pesquisas/pesquisas.module').then((m) => m.PesquisasModule),
    canActivate: [SessionGuard]
  },
  // PREVIEW de PESQUISA
  {
    path: 'pesquisa/preview',
    component: PreviewPesquisaComponent,
    canActivate: [SessionGuard]
  },
  // PREVIEW de PESQUISA JPD
  {
    path: 'pesquisa/preview-possuo-dados',
    component: PreviewJpdComponent,
    canActivate: [SessionGuard]
  },
  // PREVIEW de PESQUISA
  {
    path: 'pesquisa/respondida-com-sucesso',
    component: LayoutComponent,
    children:[
      {path: '', component: RespondidoSucessoComponent},
    ],
  },
  // PREVIEW de PESQUISA MODELO
  {
    path: 'pesquisa/preview-modelo',
    component: PreviewPesquisaModeloComponent,
    canActivate: [SessionGuard]
  },
  // PESQUISAS ANONIMAS
  {
    path: 'realizar/pesquisa/anonima',
    component: ResponderPesquisaComponent,
  },
  {
    path: 'realizar/pesquisa/anonima',
    children: [
      { path: ':id', component: ResponderPesquisaComponent},
    ]
  },
  // PESQUISAS IDENTIFICADAS
  {
    path: 'realizar/pesquisa',
    component: ResponderPesquisaComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'realizar/pesquisa',
    children: [
      { path: ':id', component: ResponderPesquisaComponent},
    ],
    canActivate: [SessionGuard]
  }
];


const termosCondicoes = {
  path: 'termos_condicoes',
  loadChildren: () =>
    import('./pages/_termos-condicoes/termos-condicoes.module').then((m) => m.TermosCondicoesModule),
}



// MÓDULOS DE TELAS
const cadastros = {
  path: 'cadastros',
    component: LayoutComponent,
  loadChildren: () =>
    import('./pages/_cadastros/cadastros.module').then((m) => m.CadastrosModule),
  canActivate: [SessionGuard]
};

const routes: Routes = [

  auth,
  ...pesquisa,
  cadastros,
  termosCondicoes,
  {
    path: 'home',
    component: LayoutComponent,
    canActivate: [SessionGuard],
    children:[
      {path: '', component: HomeComponent},
    ]
  },

  {
    path: 'score',
    component: LayoutComponent,
    canActivate: [SessionGuard],
    children:[
      {path: '', component: ScoreComponent},
    ]
  },

  {
    path: 'configuracoes',
    component: LayoutComponent,
    data: {perfil: 'GESTORTROCA'},
    canActivate: [SessionGuard, PermissionGuard],
    children:[
      {path: '', component: ConfiguracoesComponent},
    ],
  },

  {
    path: 'meus-dados',
    component: LayoutComponent,
    canActivate: [SessionGuard],
    children:[
      {path: '', component: MeusDadosComponent},
    ],
  },

  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
