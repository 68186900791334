<div
  *ngIf="!!entity"
  class="container"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <div class="row">
    <div class="col-1 cont-label-perguntas"></div>
    <div class="col-10 col-center"><h2 style="color: #5B626B">
      {{ analisar ? 'Analisar' : 'Editar' }} Pesquisa
    </h2>
    </div>
    <div class="col-1 col-right"></div>
  </div>
  <div
    *ngFor="let pergunta of entity.perguntaPesquisa; let i = index"
    cdkDrag
    class="row"
  >
    <div class="col-12">
      <div class="row mb-3 mt-3">
        <div class="dragDrop-placeholder" style="height: 15rem" *cdkDragPlaceholder></div>
        <div class="col-1 cont-label-perguntas">
          <div class="label-pergunta" [class.removida]="cardPerguntaPesquisa.removida">
            <p>Pergunta {{ (i+1) }} de {{ entity.perguntaPesquisa.length }}</p>
          </div>
        </div>

        <div class="col-10 col-center card-perguntas">
          <card-pergunta-editar
            #cardPerguntaPesquisa
            [index]="i"
            [pergunta]="pergunta"
            [listPreguntas]="entity.perguntaPesquisa"
            [perguntaOpcao]="{ podeEditar: !!pergunta.podeEditar, podeRemover: !!pergunta.podeRemover, perguntaObrigatoria: !!pergunta.perguntaObrigatoria }"
            [tipoResposta]="entity.pesquisaModelo.tipoPesquisa.tipoResposta"
            (change)="change($event)"
            (restaurarPergunta)="restaurarPergunta($event)"
            (excluir)="removePergunta($event)"
          >
            <mat-icon dragHand cdkDragHandle style="max-width: 5%; color: #c5c5c5">drag_handle</mat-icon>
          </card-pergunta-editar>
        </div>

        <div class="col-1 col-right">
          <div class="actions-col">
<!--            <button-->
<!--              *ngIf="verifyExibRelacionarPredecessor(pergunta)"-->
<!--              [matTooltip]="'Pergunta predecessora'"-->
<!--              class="button-card"-->
<!--              (click)="addPerguntaPredecessora(i)"-->
<!--              mat-icon-button-->
<!--            >-->
<!--              <mat-icon>youtube_searched_for</mat-icon>-->
<!--            </button>-->
            <button
              *ngIf="(entity.pesquisaModelo?.podeAdicionarNova && (
              entity.pesquisaModelo.podeReordenar
                ? entity.pesquisaModelo.podeReordenar
                : i >= (entity.pesquisaModelo.perguntas.length - 1)
              ))"
              class="button-card"
              (click)="addPergunta(i)"
              mat-icon-button
              [matTooltip]="'Criar uma nova pergunta'"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              *ngIf="(entity.pesquisaModelo?.podeAdicionarBanco && (
              entity.pesquisaModelo.podeReordenar
                ? entity.pesquisaModelo.podeReordenar
                : i >= (entity.pesquisaModelo.perguntas.length - 1)
              ))"
              class="button-card"
              (click)="openModal(i)"
              mat-icon-button
              [matTooltip]="'Buscar pergunta no banco'"
            >
              <mat-icon>search</mat-icon>
            </button>
            <button
              *ngIf="(entity.pesquisaModelo?.podeAdicionarBanco)"
              [matTooltip]="'Copiar Pergunta'"
              class="button-card"
              (click)="copyPergunta(i)"
              mat-icon-button
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <button
              [matTooltip]="'Configurações de filtro da pergunta'"
              class="button-card"
              (click)="openModalSettings(i)"
              mat-icon-button
              [hidden]="(entity.tipoPesquisa?.codigo == '1' || (pergunta.tipoResposta === 'TEXT' || pergunta.tipoResposta === 'BIG_TEXT'))"
              check-perfil
              [perfil]="'GESTORTROCA'"
            >
              <mat-icon>filter_alt</mat-icon>
            </button>
            <button
              *ngIf="!!entity.pesquisaModelo?.podeAdicionarBanco"
              [matTooltip]="'Salvar pergunta no banco de dados'"
              class="button-card"
              (click)="salvarPerguntaBanco(i)"
              mat-icon-button
              [hidden]="exibirBtnSalvar(i)"
            >
              <mat-icon>save_as</mat-icon>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="container footer">
  <div class="row mt-3">

    <div class="col-1 cont-label-perguntas"></div>

    <div class="col-10 col-center">
      <div class="row">
        <div class="col-6">
          <button crud-voltar class="btn dg-btn btn-cinza-outline">
            Voltar
          </button>
        </div>

        <div class="col-6 btn-wrapper d-inline-flex justify-content-end">
          <button *ngIf="analisar" (click)="aprovarPesquisa()" class="btn dg-btn btn-danger">
            Aprovar
          </button>

          <button *ngIf="analisar" (click)="rejeitarPesquisa()" class="btn dg-btn btn-purple">
            Reprovar
          </button>

          <button crud-salvar class="btn dg-btn btn-info">
            Salvar
          </button>
        </div>
      </div>
    </div>

    <div class="col-1 col-right"></div>

  </div>
</div>

<modal-busca-perguntas
  #modal
  (addPerguntas)="addPerguntaBanco($event)"
>
</modal-busca-perguntas>


<modal-settings-perguntas #modalSettings (save)="saveConfigFiltros($event)"></modal-settings-perguntas>

<modal-relacao-pergunta-pesquisa-predecessora
  #ModalPerguntaPredecessora
  [listPerguntas]="(entity?.perguntaPesquisa || [])"
  (salvar)="callbackPerguntaPredecessora($event)"
>
</modal-relacao-pergunta-pesquisa-predecessora>
