import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractCardRespostaComponent} from "../abstract-card-resposta/abstract-card-resposta.component";
import {RespostaJpdEntity} from "../../../../../pages/_pesquisas/pages/responder/JPD/entity/resposta-jpd-entity";
import {
  OpcaoRespostaJpdEntity
} from "../../../../../pages/_pesquisas/pages/responder/JPD/entity/opcao-resposta-jpd-entity";

@Component({
  selector: 'card-resposta-jpd',
  templateUrl: './card-resposta-jpd.component.html',
  styleUrls: [
    '../scss/custom-cards-responder.scss',
    './card-resposta-jpd.component.scss'
  ]
})
export class CardRespostaJpdComponent extends AbstractCardRespostaComponent implements OnInit {

  @Input() resposta: RespostaJpdEntity = new RespostaJpdEntity();

  @Input() cardExibicao: boolean = false;

  public percentual: boolean = false;

  @ViewChild('cardResposta') cardResposta: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.perguntaObrigatoria = !!this.pergunta.perguntaObrigatoria;

    this.percentual = this.resposta.percentual;

    if (this.cardExibicao) {
      let opcoes: OpcaoRespostaJpdEntity[] = [];

      if (this.pergunta.tipoResposta === 'CHECKBOX' || this.pergunta.tipoResposta === 'COMBOBOX' || this.pergunta.tipoResposta === 'RADIOBUTTON') {

        this.pergunta.opcoes.map((opcao) => {
          opcoes.push(new OpcaoRespostaJpdEntity(
            opcao.opcaoOriginalId,
            ''
          ))
        })
      }

      this.resposta = new RespostaJpdEntity(
        this.pergunta.pergunta,
        Number(this.pergunta.id),
        opcoes,
        this.pergunta.perguntaObrigatoria,
        false
      )
    }
  }

  changeValue(campo: string, indexOpcao: number | null, ignoreVerify = false){
    this.change.emit({
      resposta: {...this.resposta, percentual: this.percentual},
      index: this.index,
      indexOpcao: indexOpcao,
      campo
    })
  }

  getNomeOpcao(opcaoId: number | string): string {

    let index = this.pergunta.opcoes.findIndex(opcoes => {
      return opcoes.id == opcaoId
    });

    if (index == -1) {

      if (this.pergunta.opcoes.findIndex(opcoes => {
        return opcoes.opcaoOriginalId == opcaoId
      }) == -1) {
        return 'opção desconhecida';
      }

      index = this.pergunta.opcoes.findIndex(opcoes => {
        return opcoes.opcaoOriginalId == opcaoId
      })

    }

    return this.pergunta.opcoes[index].opcao;
  }

  verifyPercentualNumber(campo: string) {
    return this.resposta.respostaOpcao.findIndex(opcao => opcao.quantidade?.length > 4) != -1
  }

  maxLength(index: number): number {
    // if () {
    //
    // }
    return 4;
  }

  replaceQuantidade(){
    const resposta = this.resposta.respostaOpcao.map((opcao, index) => {
      if (opcao.quantidade.trim().length > 4) {
        alert(opcao.quantidade)
        alert(opcao.quantidade.trim().length)
        const inteiro = opcao.quantidade.slice(0,2);
        const decimal = opcao.quantidade.slice(2, 4);

        alert(String(inteiro + '.' + decimal).trim());


        this.resposta.respostaOpcao[index].quantidade = opcao.quantidade.substr(0,3);
      }
    });

    this.change.emit({
      resposta: resposta,
      index: this.index,
      indexOpcao: null,
      campo: 'percentual',
      replaceQuantidades: true
    })
  }
}
