import {AbstractOpcaoRespostaEntity} from "./abstract-opcao-resposta-entity";


export class OpcaoPerguntaPesquisaEntity extends AbstractOpcaoRespostaEntity  {

  constructor(
    id?: number,
    opcao?: string,
    outros?: boolean,
    opcaoOriginalId?: number | string | undefined,
    fakeId?: number
  ) {
    super();
    this.opcao = !!opcao ? opcao : '';
    this.id = id;
    this.outros = outros;
    this.opcaoOriginalId = opcaoOriginalId;
    this.fakeId = fakeId;
  }

  public opcao: string = '';
  public id: number | undefined;
  public outros: boolean | undefined;
  public opcaoOriginalId: number | string | undefined;
  public fakeId: number | undefined;
}
