import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from "../../../services/session/session.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private session: SessionService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {

    if (route.data.perfil) {
      return this.session.checkPerfil(route.data.perfil);
    }

    return true;
  }

}
