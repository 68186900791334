<div class="container respondido-com-sucesso-page mt-5">
  <div class="row">
    <div class="col-12">
      <dg-card class="sem-pesquisa custom-dg-card card-purple w-100">
        <div class="card-header" style="border: none; background: none">
          <img src="../../../../../../assets/img/logo-justa-U-branco.png">
        </div>
        <div class="card-body">
          <div class="container">
            <div class="row">
              <h2 style="color: #5B626B" class="f-bold">{{titulo}}</h2>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  Sua resposta foi coletada
                </p>
              </div>
            </div>
          </div>
        </div>
      </dg-card>
    </div>
  </div>
</div>
