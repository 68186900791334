import { ConfiguracoesModule } from './pages/configuracoes/configuracoes.module';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes-insert/configuracoes.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { ReactiveFormsModule } from '@angular/forms';
import {ComponentsLayoutModule} from "./components/_layout/components-layout.module";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ConfigExternalLogin, ExecutSocialLoginModule, thirdPartyLoginAuthConfig} from "@datagrupo/ng-execut-social-login";

import { SocialLoginModule } from '@datagrupo/ng-social-login';
import {environment} from "../environments/environment";
import {SessionService} from "./services/session/session.service";
import {DirectivesModule} from "./directives/directives.module";
import {RenewTokenInterceptor} from "./interceptors/renew-token/renew-token.interceptor";
import {CallbackMessageInterceptor} from "./interceptors/callback-message/callback-message.interceptor";
import {MatIconModule} from "@angular/material/icon";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {PesquisasModule} from "./pages/_pesquisas/pesquisas.module";
import {HomeModule} from "./pages/home/home.module";
import { ChartModule } from 'angular-highcharts';
import {MeusDadosModule} from "./pages/meus-dados/meus-dados.module";
import { UiModule } from './components/ui/ui.module';
import {MatSidenavModule} from "@angular/material/sidenav";
import {ScoreModule} from "./pages/score/score.module";


const configExternalLogin: ConfigExternalLogin = {
  apiUrl: environment.apiUrl,
  googleClientId: environment.googleClientId,
  redirectMicrosoft: environment.redirectMicrosoft
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ComponentsLayoutModule,
    ExecutSocialLoginModule.forRoot(configExternalLogin),
    SocialLoginModule.initialize(thirdPartyLoginAuthConfig(environment.googleClientId)),
    MatSnackBarModule,
    HttpClientModule,
    DirectivesModule,
    SweetAlert2Module.forRoot(),
    PesquisasModule,
    HomeModule,
    ChartModule,
    MeusDadosModule,
    SweetAlert2Module.forRoot(),
    ConfiguracoesModule,
    ChartModule,
    UiModule,
    MatSidenavModule,
    ScoreModule
  ],
  providers: [
    SessionService,
    { provide: HTTP_INTERCEPTORS, useClass: RenewTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CallbackMessageInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
