import { Injectable } from '@angular/core';
import {AbstractHttpService} from "@datagrupo/dg-crud";
import {ResponderPesquisaEntity} from "../../responder-pesquisa/entity/responder-pesquisa-entity";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {HttpService} from "../../../../../../services/http/http.service";
import {SessionService} from "../../../../../../services/session/session.service";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs";
import {ResponderJpdEntity} from "../entity/responder-jpd-entity";
import {ConfigDgCrudService} from "../../../../../../services/config-dg-crud/config-dg-crud.service";

@Injectable({
  providedIn: 'root'
})
export class ResponderJpdService extends AbstractHttpService<ResponderJpdEntity>{

  constructor(
    public http: HttpClient,
    private httpService: HttpService,
    private session: SessionService,
    config: ConfigDgCrudService
  ) {
    super(config, environment.apiUrl, '')
  }

  public findOne(id: number | string, endpoint: string): Observable<any>
  {
    return this.httpService.getById('api/form', id)
  }

  public save(data: ResponderJpdEntity, endpoint: string): Observable<ResponderJpdEntity> {
    const url = this.addEndpoint(endpoint);

    const newData = {
      respondente: {
        id: this.session.user.id
      },
      respostas: data.respostas
    }


    // return this.http.post<T>(url, data, { headers: { Authorization }});
    return this.http.post<ResponderJpdEntity>(url, newData);
  }

  public update(data: any, endpoint: string | boolean): Observable<ResponderJpdEntity> {
    const url = this.addEndpoint('api/form/enviar');

    return this.http.post<any>(url, data);
  }
}
