import {AfterContentChecked, Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {InterfaceDashboardPesquisa, SessionService} from "../../../services/session/session.service";
import {DadosPesquisa, HomeService} from "../services/home.service";
import {Observable, Subscriber, Subscription} from "rxjs";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentChecked {

  private httpRequestDados: Subscription | undefined;
  private httpRequestGraph: Subscription | undefined;

  public dataGraph: any[] = [];
  public graphExibir: any[] = [];
  public dataPesquisa: DadosPesquisa[] = [];

  public exibirCards: false | 'pesquisa' | 'comparacao' = false;
  public apresentacao: 'valores'  | 'sem-pesquisa' | 'sem-valores' | 'mensagem' | 'loader' = 'mensagem';
  private existId: boolean = false;

  private rootUniqId = '';

  private contAtualizacao = 0;

  public listGroup: string[] = [];
  public selectedGroup: string[] = [];

  private currentPesquisa: number | string = '';
  private currentComparacao: number | string = '';
  private currentoutros: number | string = '';
  private currentFiltrosPerguntas: string = '';
  private currentFiltrosOpcoes: string = '';

  public codeMessage = {
    title: '',
    text: ''
  }

  constructor(
    public router: Router,
    public session: SessionService,
    private service: HomeService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  status(status: 'CONCLUIDA' | 'EM_ANDAMENTO' | 'EM_CONSTRUCAO' | 'EM_ANALISE' | 'APROVADA' | 'REPROVADA'){
    const statusLabelStyle = {
      CONCLUIDA: 'Concluída',
      EM_ANDAMENTO: 'Em Andamento',
      EM_CONSTRUCAO: 'Em Contrução',
      EM_ANALISE: 'Em análise',
      APROVADA: 'Aprovada',
      REPROVADA: 'Reprovada',
    }

    return statusLabelStyle[status];
  }

  percentualRespondido(resp?: number | string | null): string{
    return !!resp ? String(resp) : '--'
  }

  criarId(i: number): string {
    return 'chartid-' + this.contAtualizacao + '-' + this.rootUniqId +'-'+ String(i);
  }

  filterListGroup(grupos: string[]): string[] {
    return grupos.map((grupo: string) => {
      if (!!grupo) {
        if (grupo.trim() === '' || grupo.trim().toUpperCase() === 'OUTROS') {
          grupo = 'Outros'
        }
      } else {
        grupo = 'Outros'
      }

      return grupo;
    })
  }

  changeArrayGraph(inputGrupos: string[]) {
    let listGraph: any[] = [];
    this.contAtualizacao++;

    if (inputGrupos.length == 0) {
      listGraph = [...this.dataGraph];
    } else {
      inputGrupos.map((grupo: string) => {

        if (grupo.toUpperCase() === 'OUTROS') {
          listGraph = [
            ...listGraph,
            ...this.dataGraph.filter(graph => {
              return graph.grupos.findIndex((stringGrupo: string) => {
                return (stringGrupo === null || stringGrupo === '');
              }) != -1
            })
          ];
        } else {
          listGraph = [
            ...listGraph,
            ...this.dataGraph.filter(graph => {

              return graph.grupos.findIndex((stringGrupo: string) => stringGrupo?.toUpperCase() === grupo?.toUpperCase()) != -1
            })
          ];
        }
      })
    }

    if (this.session.issetPesquisa()) {

      this.activatedRoute.queryParams.subscribe(params => {
        if (!!params.pesquisa && params.pesquisaComparacao) {
          const pesquisa = this.session.getDachboardPesquisa()
          const comparacao = this.session.getDachboardComparacao()

          if (
            pesquisa.pesquisa == params.pesquisa &&
            comparacao.pesquisaComparacao == params.pesquisaComparacao
          ) {
            this.session.setDachboardGrupos(inputGrupos);
          }

          this.router.navigate(
            ['.'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {
                grupos: inputGrupos.length == 0 ? null : JSON.stringify(inputGrupos)
              },
              queryParamsHandling: 'merge',
            }
          );

        } else if (!!params.pesquisa) {
          const pesquisa = this.session.getDachboardPesquisa()

          if (pesquisa.pesquisa == params.pesquisa) {
            this.session.setDachboardGrupos(inputGrupos);
          }

          this.router.navigate(
            ['.'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {
                grupos: inputGrupos.length == 0 ? null : JSON.stringify(inputGrupos)
              },
              queryParamsHandling: 'merge',
            }
          );
        }
      }).unsubscribe()
    }

    this.graphExibir = [];

    setTimeout(() => this.graphExibir = listGraph, 100)
  }

  ngAfterContentChecked(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let grupos: string[] = [];

      if (!!params.grupos) {
        grupos = <string[]> JSON.parse(params.grupos);
      }

      if (!!params.pesquisa && params.pesquisaComparacao) {
        if (params.pesquisaComparacao != this.currentComparacao || (
          params.perguntas != this.currentFiltrosPerguntas ||
          params.opcoes != this.currentFiltrosOpcoes
        )) {
          this.currentFiltrosPerguntas = params.perguntas;
          this.currentFiltrosOpcoes = params.opcoes;
          this.currentComparacao = params.pesquisaComparacao;

          if (!!params.perguntas && !!params.opcoes) {
            this.comparacao(
              params,
              grupos,
              <string[]>JSON.parse(params.perguntas),
              <string[]>JSON.parse(params.opcoes)
            )
          } else {
            this.comparacao(params, grupos)
          }
        }

      } else if (!!params.pesquisa) {

        if (params.pesquisa != this.currentPesquisa || (
          params.perguntas != this.currentFiltrosPerguntas ||
          params.opcoes != this.currentFiltrosOpcoes
        )) {
          this.currentFiltrosPerguntas = params.perguntas;
          this.currentFiltrosOpcoes = params.opcoes;
          this.currentPesquisa = params.pesquisa;

          if (!!params.perguntas && !!params.opcoes) {
            this.pesquisa(
              params,
              grupos,
              <string[]>JSON.parse(params.perguntas),
              <string[]>JSON.parse(params.opcoes)
            )
          } else {
            this.pesquisa(params, grupos)
          }
        }
      }
    })
  }

  ajustaData(d: any): object{
    let data = JSON.stringify(d);

    return JSON.parse(data, function(key, value) {
      if (
        typeof value === "string" &&
        value.startsWith("REMOVE_QUOTE") &&
        value.endsWith("REMOVE_QUOTE")
      ) {
        value = value.replace(/REMOVE_QUOTE/g, '')
        return (0, eval)("(" + value + ")");
      }
      return value;
    });
  }

  filtraMensagensRetorno(code: string) {
    switch (code) {
      case 'C006':
        this.codeMessage = {
          title: 'Essa pesquisa ainda não gerou dados',
          text: 'Utilize uma pesquisa que já tenha sido respondida para ver resultados.'
        }
        break
      case 'C007':
        this.codeMessage = {
          title: 'Dados insuficientes',
          text: 'Não há dados suficientes para gerar gráficos.'
        }
        break
      case 'C008':
        this.codeMessage = {
          title: 'Essa filtragem não gerou dados',
          text: 'Não há dados para gerar gráficos após a filtragem'
        }
        break
      case 'C009':
        this.codeMessage = {
          title: 'Essa filtragem não gerou dados',
          text: 'Não há dados suficientes para gerar gráficos após a filtragem'
        }
        break
      case 'C010':
        this.codeMessage = {
          title: 'Dados insuficientes',
          text: 'Uma das pesquisas ainda não gerou dados'
        }
        break
      case 'C011':
        this.codeMessage = {
          title: 'Dados insuficientes',
          text: 'Uma das pesquisas não possui dados suficientes para a comparação'
        }
        break
      case 'C012':
        this.codeMessage = {
          title: 'Dados insuficientes após filtragem',
          text: 'Uma das pesquisas não possui dados para a comparação após a filtragem'
        }
        break
      case 'C013':
        this.codeMessage = {
          title: 'Dados insuficientes após filtragem',
          text: 'Uma das pesquisas não possui dados suficientes para a comparação após a filtragem'
        }
        break
      default:{
        this.codeMessage = {
          title: 'Seja Bem vindo a Troca',
          text: 'Nenhuma pesquisa encontrada no banco de dados'
        }

        console.log('erorrCode: ', code)
      }
    }

    this.apresentacao = 'mensagem';
  }

  /**
   * retirando , das opções de resposta
   * @param arr
   */
  retiraVirgulaString(arr?: string[]): string[] | undefined{
    if (!!arr) {
      if (Array.isArray(arr)) {
        return arr.map(str => {
          return str.replace(/\,/g, '*@:')
        })
      }
    }
    return;
  }

  /**
   *  GETS
   */

  pesquisa(pesquisa: any, grupos?: string[], perguntas?: string[], opcoes?: string[]) {
    if (!!pesquisa?.pesquisa) {
      this.contAtualizacao++;
      this.rootUniqId += String(pesquisa.pesquisa);
      this.contAtualizacao++;
      this.apresentacao = 'loader';

      // dados
      this.httpRequestDados = this.service.getPesquisa(pesquisa.pesquisa).subscribe(resp => {
        if (!!resp.data) {
          if (!!resp.data[0]) {
            this.dataPesquisa = resp.data
            this.exibirCards = "pesquisa";
            this.existId = true;

            if (!!resp.data[0].grupos) {
              this.listGroup = this.filterListGroup(resp.data[0].grupos)
            } else {
              this.listGroup = [];
            }

            if (this.session.issetGrupos()) {
              this.selectedGroup = this.session.getDachboardGrupos();
            }
          }
        }
      });

      // grafico
      this.httpRequestGraph = this.service.getGraph(pesquisa.pesquisa, this.retiraVirgulaString(perguntas), this.retiraVirgulaString(opcoes)).subscribe(
        resp => {
          if (!!resp.data) {

            if (resp.data.length > 0) {
              this.dataGraph = resp.data;
              this.apresentacao = 'valores';

              if (!!grupos) {
                this.selectedGroup = grupos;
                this.changeArrayGraph(grupos);
              }

              this.httpRequestDados?.unsubscribe()
              return;
            }
          }

          this.filtraMensagensRetorno(String(resp.code))
          // this.apresentacao = 'sem-valores';
        }
      );
    }
  }

  comparacao(params: any, grupos?: string[], perguntas?: string[], opcoes?: string[]) {
    this.contAtualizacao++;
    this.rootUniqId += String(params);

    if (!!params?.pesquisa && !!params?.pesquisaComparacao) {
      this.apresentacao = 'loader';

      this.httpRequestDados = this.service.getPesquisaComparacao({pesquisa: params.pesquisa, pesquisaComparacao: params.pesquisaComparacao}).subscribe(resp => {

        if (!!resp.data) {
          this.dataPesquisa = resp.data;
          this.exibirCards = 'comparacao';

          if (!!resp.data[0].grupos && !!resp.data[1].grupos) {
            const grupo1 = this.filterListGroup(resp.data[0].grupos)
            const grupo2 = this.filterListGroup(resp.data[1].grupos)

            const listaTemp = [...grupo1, ...grupo2];

            this.listGroup = [...new Set(listaTemp)]
          } else if (!!resp.data[0].grupos) {

            this.listGroup = this.filterListGroup(resp.data[0].grupos);
          }
        }
      });

      this.httpRequestGraph = this.service.getComparaGraph({
        pesquisa: params.pesquisa,
        pesquisaComparacao: params.pesquisaComparacao
      }, this.retiraVirgulaString(perguntas), this.retiraVirgulaString(opcoes)).subscribe(resp => {
        if (!!resp.data) {
          if (resp.data.length > 0) {
            this.dataGraph = resp.data;
            this.apresentacao = 'valores';

            if (!!grupos) {
              this.selectedGroup = grupos;
              this.changeArrayGraph(grupos);
            }

            return;
          }
        }

        this.filtraMensagensRetorno(String(resp.code))
        // this.apresentacao = 'sem-valores';
      });
    }
  }

  @HostListener('window:dashboard-drawer-pesquisa-last', ['$event'])
  getLast() {
    this.apresentacao = 'loader';
    this.rootUniqId += 'last';
    this.httpRequestDados = this.service.getLastPesq().subscribe(
      resp => {
        if (!!resp.data) {
          if (resp.data.length > 0) {
            this.dataPesquisa = resp.data
            this.exibirCards = "pesquisa";

            if (!!resp.data[0].grupos) {
              this.listGroup = this.filterListGroup(resp.data[0].grupos)
            } else {
              this.listGroup = [];
            }
          }
        }
      })

    this.httpRequestGraph = this.service.getLastGraph().subscribe(
      resp => {
        if (!!resp.data) {
          if (resp.data.length > 0) {
            this.dataGraph = resp.data;
            this.apresentacao = 'valores';
            this.changeArrayGraph([])

            return;
          }
        }

        this.filtraMensagensRetorno(String(resp.code))
        // this.apresentacao = 'sem-valores';
      }
    )
  }
}
