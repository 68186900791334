import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {PerguntasEntity} from "../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {HttpService} from "../../../../services/http/http.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  OpcaoRespostaPerguntaEntity
} from "../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";

export interface retornoPredecessora {
  perguntaPredecessora: PerguntasEntity,
  opcaoRespostaPredecessora: OpcaoRespostaPerguntaEntity
}

@Component({
  selector: 'modal-busca-perguntas-predecessoras',
  templateUrl: './modal-busca-perguntas-predecessoras.component.html',
  styleUrls: ['./modal-busca-perguntas-predecessoras.component.scss']
})
export class ModalBuscaPerguntasPredecessorasComponent implements OnInit {

  @Input('idPerguntaFilha') idPerguntaFilha: number | string = '';
  @ViewChild('modal') modal!: ModalComponent;
  @Output('salvar') salvar = new EventEmitter<retornoPredecessora>()

  public perguntas: PerguntasEntity[] = [];
  public opcoes: OpcaoRespostaPerguntaEntity[] = [];

  public form = new FormGroup({
    pergunta: new FormControl('', [Validators.required]),
    opcao: new FormControl('', [Validators.required])
  })

  constructor(public service: HttpService) {}

  ngOnInit(): void {}

  open() {
    this.service.get('api/pergunta', {unpaged: true, isSelectType: true}).subscribe(
      resp => {
        //@ts-ignore
        const perguntas = <PerguntasEntity[]>resp.data
        this.perguntas = perguntas.filter(perg => {
          return perg.id != this.idPerguntaFilha
        })
        this.modal.open()
      }
    )
  }

  changePergunta() {
    const perguntaId = this.form.value.pergunta;
    const index = this.perguntas.findIndex(perg => perg.id == perguntaId)

    this.opcoes = this.perguntas[index].opcoes;
  }

  verifySalvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    const form = this.form.value;

    const indexPergunta = this.perguntas.findIndex(perg => perg.id == form.pergunta)
    const indexOpcao = this.opcoes.findIndex(opcao => opcao.id == form.opcao)

    if (indexPergunta >= 0 && indexOpcao >= 0) {
      this.salvar.emit({
        perguntaPredecessora: this.perguntas[indexPergunta],
        opcaoRespostaPredecessora: this.opcoes[indexOpcao]
      })
      this.modal.close()
    }
  }

  _close = () => {
    this.form.reset({
      pergunta: '',
      opcao: ''
    })
  }
}
