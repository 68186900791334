import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MatrizPesoPesquisaEntity
} from "../../../../pages/_pesquisas/pages/matriz-peso-pesquisa/entity/matriz-peso-pesquisa-entity";

@Component({
  selector: 'peso-table-pesquisa',
  templateUrl: './peso-table-pesquisa.component.html',
  styleUrls: ['./peso-table-pesquisa.component.scss']
})
export class PesoTablePesquisaComponent implements OnInit {

  @Input() entity!: MatrizPesoPesquisaEntity;
  @Output() saveCelula = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  recolheIndexCelula(perguntaX: string, perguntaY: string): number {
    if (!!this.entity.listPesoPesquisa) {

      if (this.entity.listPesoPesquisa.length <= 0) {
        throw ('index de modelo não existe')
      }

      return this.entity.listPesoPesquisa.findIndex((peso, index) => {
        return peso.opcaoPerguntaPesquisaXTitulo === perguntaX && peso.opcaoPerguntaPesquisaYTitulo === perguntaY;
      })
    }

    return -1;
  }

  emitSave(perguntaX: string, perguntaY: string) {
    const index = this.recolheIndexCelula(perguntaX, perguntaY);

    if (!!this.entity.listPesoPesquisa) {
      if (index != -1) {

        const data = {
          id: this.entity.listPesoPesquisa[index].id,
          matrizPesoPesquisa: {id: this.entity.listPesoPesquisa[index].matrizPesoPesquisaId},
          opcaoPerguntaPesquisaX: {id: this.entity.listPesoPesquisa[index].opcaoPerguntaPesquisaXId },
          opcaoPerguntaPesquisaY: {id: this.entity.listPesoPesquisa[index].opcaoPerguntaPesquisaYId},
          perguntaScorePesquisaY: {id: this.entity.listPesoPesquisa[index].perguntaScorePesquisaId },
          perguntaPesquisaX: {id: this.entity.listPesoPesquisa[index].perguntaPesquisaXId}, // perguntaXId},
          perguntaPesquisaY: {id: this.entity.listPesoPesquisa[index].perguntaPesquisaYId},//perguntaYId},
          peso: this.entity.listPesoPesquisa[index].peso
        }

        this.saveCelula.emit({ data, index });
      }
    }
  }

}
