<div class="wrapper d-flex align-items-stretch">

  <!-- Page Content  -->
  <div id="content">

    <!--app-navbar></app-navbar-->
    <header-principal></header-principal>

    <app-header-menu check-perfil [perfil]="['GESTORTROCA', 'GESTOREMPRESA']" [menu]="menu"></app-header-menu>

    <app-filtro-home class= "filtroHome"></app-filtro-home>

    <div id='layout-appRoot'>
      <div class="faixa-fixa">
      </div>
      <div class="router-outlet-root">
        <router-outlet>
        </router-outlet>
      </div>
    </div>
  </div>
</div>
