import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MatrizPesoModeloEntity
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/entity/matriz-peso-modelo-entity";
import {
  MatrizPesoModeloService
} from "../../../../pages/_cadastros/pages/pesquisa-modelo/services/matriz-peso/matriz-peso-modelo.service";

@Component({
  selector: 'peso-table-modelo',
  templateUrl: './peso-table-modelo.component.html',
  styleUrls: ['./peso-table-modelo.component.scss']
})
export class PesoTableModeloComponent implements OnInit {

  @Input() entity: MatrizPesoModeloEntity = new MatrizPesoModeloEntity();
  @Output() saveCelula = new EventEmitter<any>();

  constructor(public service: MatrizPesoModeloService) { }

  ngOnInit(): void {}

  recolheIndexCelula(perguntaX: string, perguntaY: string): number {
    if (!!this.entity.listPesoModelos) {

      if (this.entity.listPesoModelos.length <= 0) {
        throw ('index de modelo não existe')
      }

      return this.entity.listPesoModelos.findIndex((peso, index) => {
        return peso.opcaoRespostaXTitulo === perguntaX && peso.opcaoRespostaYTitulo === perguntaY;
      })
    }

    return -1;
  }

  emitSave(perguntaX: string, perguntaY: string) {
    const index = this.recolheIndexCelula(perguntaX, perguntaY);

    if (!!this.entity.listPesoModelos) {
      if (index != -1) {

        const data = {
          id: this.entity.listPesoModelos[index].id,
          matrizPesoModelo: {id: this.entity.listPesoModelos[index].matrizPesoModeloId},
          opcaoRespostaX: {id: this.entity.listPesoModelos[index].opcaoRespostaXId},
          opcaoRespostaY: {id: this.entity.listPesoModelos[index].opcaoRespostaYId},
          perguntaScoreModelo: {id: this.entity.listPesoModelos[index].perguntaScoreModeloId},
          perguntaX: {id: this.entity.listPesoModelos[index].perguntaXId},
          perguntaY: {id: this.entity.listPesoModelos[index].perguntaYId},
          peso: this.entity.listPesoModelos[index].peso
        }

        this.saveCelula.emit({ data, index });
      }
    }


  }

}
