<div id="historico-pesquisa-main" class="container-fluid" style="padding: 0 2em;">
  <div class="row">
    <h2 style="color: #5B626B">Histórico de Pesquisa</h2>
  </div>
  <div class="row">

    <dg-card class="w-100 col-12" style="padding: 0;">
      <div class="container-fluid" style="padding: 0 !important">
        <div class="row" style="padding: 0 !important">
          <div

            class="col-12"
            [class.table-loading]="tableCrud.dataSource.length <= 0"
            [class.table-data]="tableCrud.dataSource.length > 0"
          >
            <crud-data-table
              style="width: auto;"
              #tableCrud
              [entityMap]="mapEntity"
              [service]="service"
              [showPagination]="false"
              [showDefaultAction]="false"
              [propsActionButton]="{classes:'btn'}"
              customIconActions="expand_more"
              [customDblclick]="true"
              [class]="session.checkPerfil('GESTOREMPRESA') ? 'dobraTamanho' : ''"
            >
            </crud-data-table>
          </div>
        </div>
      </div>

      <div class="container mt-3">
        <div class="row">
          <table-pagination #crudTable [table]="tableCrud" [entity]="mapEntity"></table-pagination>
        </div>
      </div>

    </dg-card>

  </div>
</div>

<!-- pendentes -->
<dg-modal
  class="modal-pendentes"
  #modal
  size="custom-modal-size"
  alignH="center"
  alignV="center"
  [bgClose]="true"
  [escClose]="true"
>
  <div class="row custom-header" dg-header>
    <div class="col-6">
      <h3 class="color-purple f-bold" style="text-align: start">Empresa</h3>
    </div>
    <div class="col-6">
      <h4 style="text-align: end">{{ modal.titleSpan }}</h4>
    </div>
  </div>

  <div dg-content>
    <div class="row" *ngFor="let pendentes of listaPendentes">
      <div class="col-12">
        <p>{{pendentes}}</p>
      </div>
    </div>
  </div>

  <div dg-footer class="row">
    <div class="col-12 d-inline-flex justify-content-between">
      <button (click)="enviarLembretePendentes(modal.data)" class="btn dg-btn btn-danger">Enviar Lembrete</button>
      <button (click)="modal.close()" class="btn dg-btn btn-danger">Ok</button>
    </div>
  </div>
</dg-modal>

<!-- excluir -->
<dg-modal
  class="modal-pendentes"
  #modalExcluir
  size="custom-modal-size"
  alignH="center"
  alignV="center"
  [bgClose]="true"
  [escClose]="true"
>
  <div class="row" dg-header>
    <div class="col-6">
      <h3 class="color-purple f-bold" style="text-align: start">Empresa</h3>
    </div>
    <div class="col-6">
      <h4 style="text-align: end">{{ modalExcluir.titleSpan }}</h4>
    </div>
  </div>

  <div dg-content style="margin-top: 0 !important;">

    <div class="row">
      <div class="col-12 justify-content-center align-items-center" style="padding-top: 0 !important; margin-top: 0">
        <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex; padding-top: 0 !important; margin-top: 0;">
          <div class="swal2-icon-content"><span style="color: #F5B225FF">!</span></div>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-3">
      <h2 style="text-align: center">Deseja excluir esta pesquisa?</h2>
      <p style="text-align: justify">(Ao excluir, o registro não é mais apresentando na tabela de histórico de pesquisa e
        todos os registros vinculados também são eliminados do sistema.)</p>
    </div>
  </div>

  <div dg-footer class="row">
    <div class="col-12 d-inline-flex justify-content-center">
      <button (click)="modalExcluir.close()" class="btn dg-btn btn-danger">Não, voltar!</button>
      <button (click)="excluirPesquisa(modalExcluir.data)" class="btn dg-btn btn-danger">Sim, excluir</button>
    </div>
  </div>
</dg-modal>

<!-- enviar -->
<dg-modal
  class="modal-enviar"
  #modalEnviar
  size="custom-modal-size"
  alignH="center"
  alignV="center"
  [bgClose]="true"
  [escClose]="true"
>
  <div class="row" dg-header>
    <div class="col-6">
      <h3 class="color-purple f-bold" style="text-align: start">Empresa</h3>
    </div>
    <div class="col-6">
      <h4 style="text-align: end">{{ modalEnviar.titleSpan }}</h4>
    </div>
  </div>

  <div dg-content style="margin-top: 0 !important;">

    <div class="container mt-3 ">
      <div class="row">
        <h2 style="text-align: center">Deseja enviar a pesquisa para os e-mails cadastrados?</h2>
      </div>
    </div>

    <div class="container mb-3">
      <div class="row">
        <mat-chip-list>
          <mat-chip
            style="background-color: rgba(122,111,190,0.4);"
            *ngFor="let i of listaEmailEnviar"
            (removed)="removeEmailEnviar(i)"
          >
            {{i}}
            <mat-icon matChipRemove >cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

  </div>

  <div dg-footer class="row">
    <form [formGroup]="formAddEmailEnviar">

      <div class="col-12">
        <label class="form-label">Adicionar Email</label>
      </div>

      <div class="col-12 mb-3 input-group mb-3">
        <input formControlName="email" (keypress)="keypressAddEmail($event)" type="email" class="form-control">
        <span (click)="addEmail()" class="input-group-text">
          <mat-icon>add_circle</mat-icon>
        </span>
      </div>
    </form>

    <hr>

    <div class="col-12 d-inline-flex justify-content-center">
      <button (click)="modalEnviar.close()" class="btn dg-btn btn-danger">Não, voltar</button>
      <button
        [disabled]="verificaDisableBtnEnviarPesquisa()"
        (click)="enviarPesquisa(modalEnviar.data, modalEnviar)"
        class="btn dg-btn btn-danger"
      >Sim, enviar</button>
    </div>
  </div>
</dg-modal>


<swal
  #swallEnviaPesquisa
  icon="success"
  title="A pesquisa foi enviada com sucesso."
  [showConfirmButton]="false"
  [allowOutsideClick]="false"
>
  <ng-container *swalPortal="swalTargets.content">
    <h3>{{linkPesquisa}}</h3>
    <div style="height: 1px; width: 1px; overflow: hidden">
      <input style="border: none" type="text" id="inputLink" [value]="linkPesquisa">
    </div>
  </ng-container>
  <ng-container *swalPortal="swalTargets.footer">
    <button (click)="copiarLink()" class="btn dg-btn btn-danger">Copiar link</button>
    <button (click)="closeMsgSuccessEnvio()" class="btn dg-btn btn-danger">Fechar</button>
  </ng-container>
</swal>

<swal
  #swallEstenderPesquisa
  title="Alterar Prazo da pesquisa"
  [showConfirmButton]="false"
  [allowOutsideClick]="false"
>
  <ng-container *swalPortal="swalTargets.content">
    <form [formGroup]="formAlterarPrazo">
      <label class="form-label">Estender pesquisa para</label>
      <input formControlName="dataFim" type="datetime-local" class="form-control">
    </form>
  </ng-container>
  <ng-container *swalPortal="swalTargets.footer">
    <button (click)="enviarNovaDataPesquisa()" class="btn dg-btn btn-danger">Alterar</button>
    <button (click)="swallEstenderPesquisa.close()" class="btn dg-btn btn-danger">Fechar</button>
  </ng-container>
</swal>
