import {AfterViewInit, Component, OnInit} from '@angular/core';
import {InterfaceMenuList, MenuService} from "../_core/menu/menu.service";
import {ActivatedRoute, ActivatedRouteSnapshot, Route, Router} from "@angular/router";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public titlePage = '';

  public readonly menu: InterfaceMenuList[] = [];

  constructor(
    public serviceMenu: MenuService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.menu = serviceMenu.getMenu('user');
  }

  ngOnInit(): void {

  }

}
