import { Component, Host, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  HistoricoPesquisaService
} from "../../../../pages/_pesquisas/pages/historico-pesquisa/service/historico-pesquisa.service";
import {EmpresasEntity} from "../../../../pages/_cadastros/pages/empresas/entity/empresas-entity";
import {TipoPesquisaService} from "../../../../pages/_cadastros/pages/tipo-pesquisa/services/tipo-pesquisa.service";
import {TipoPesquisaEntity} from "../../../../pages/_cadastros/pages/tipo-pesquisa/entity/tipo-pesquisa-entity";
import {dashboardFiltros, SessionService} from "../../../../services/session/session.service";
import {
  HistoricoPesquisaEntity
} from "../../../../pages/_pesquisas/pages/historico-pesquisa/entity/historico-pesquisa-entity";
import {PerguntaPesquisaEntity} from "../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {FiltroService} from "../service/filtro.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSelectChange} from "@angular/material/select";
import {MatOptionSelectionChange} from "@angular/material/core";


@Component({
  selector: 'app-filtro-home',
  templateUrl: './filtro-home.component.html',
  styleUrls: ['./filtro-home.component.scss']
})
export class FiltroHomeComponent implements OnInit {

  public listaEmpresa: EmpresasEntity[] = [];
  public listaTipoPesquisa: TipoPesquisaEntity[] =[];
  public listaPesquisa: HistoricoPesquisaEntity[] = [];
  public listaPesquisaComparacao: HistoricoPesquisaEntity[] = [];
  public listaPerguntas: PerguntaPesquisaEntity[] = [];

  public selectedFiltrosPerguntas: any = {};

  public page: "home" | 'score' = 'home'

  public form: FormGroup = new FormGroup({
    empresa: new FormControl('', [Validators.required]),
    tipoPesquisa: new FormControl('', [Validators.required]),
    pesquisa: new FormControl('', [Validators.required])
  });

  public formComparacao: FormGroup = new FormGroup({
    tipoPesquisaComparacao: new FormControl('', [Validators.required]),
    pesquisaComparacao: new FormControl('', [Validators.required])
  });

  constructor(
    private service: FiltroService,
    private serviceHist: HistoricoPesquisaService,
    private serviceTipoP: TipoPesquisaService,
    public session: SessionService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {

    if (!session.checkPerfil('RESPONDENTE')) {
      serviceTipoP.findAll().subscribe(resp => {
        //@ts-ignore
        this.listaTipoPesquisa = <TipoPesquisaEntity[]>resp.data;
      });
    }

    if (
      this.router.url.split('?')[0] === '/home' ||
      this.router.url.split('?')[0] === '/score'
    ) {
      if (this.router.url.split('?')[0] === '/score') {
        this.page = 'score';
      }

      if (session.user.perfil === 'GESTORTROCA') {
        this.perfilTroca();
      } else if (session.user.perfil === 'GESTOREMPRESA') {
        this.perfilEmpresa()
      }
    }
  }

  ngOnInit(): void {}

  perfilTroca() {
    // busca todos os registros
    this.service.getEmpresas().subscribe(resp => {
      //@ts-ignore
      this.listaEmpresa = Array.isArray(resp.data) ? resp.data : [];
    });

    if (!!window.location.href.split('?')[1]) {
      this.preencheCampoPorUrl();
    } else if (this.session.issetAttPesquisa()) {
      this.preencherCampos();
    } else {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('dashboard-drawer-pesquisa-last'))
      }, 50)
    }
  }

  perfilEmpresa() {
    //@ts-ignore
    this.listaEmpresa = [<EmpresasEntity> this.session.user.empresa]
    this.form.patchValue({
      empresa: this.session.user.empresa?.id
    });

    if (!!window.location.href.split('?')[1]) {
      this.preencheCampoPorUrl();
    } else if (this.session.issetAttPesquisa()) {
      this.preencherCampos();
    } else {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('dashboard-drawer-pesquisa-last'))
      }, 50)
    }
  }

  preencherCampos() {
    if (this.session.issetPesquisa() && this.session.issetcomparacao()) {

      const pesquisa = this.session.getDachboardPesquisa();
      const comparacao = this.session.getDachboardComparacao();
      const grupos = this.session.getDachboardGrupos();

      if (!!pesquisa.pesquisa) this.form.patchValue({ empresa: pesquisa.empresa })
      if (!!pesquisa.tipoPesquisa) this.form.patchValue({ tipoPesquisa: pesquisa.tipoPesquisa })
      if (!!pesquisa.pesquisa) this.form.patchValue({ pesquisa: pesquisa.pesquisa })

      if (!!comparacao.tipoPesquisaComparacao) this.formComparacao.patchValue({ tipoPesquisaComparacao: comparacao.tipoPesquisaComparacao })
      if (!!comparacao.pesquisaComparacao) this.formComparacao.patchValue({ pesquisaComparacao: comparacao.pesquisaComparacao })

      this.getListPesquisa();
      this.getListPesquisaComparacao();

      let filtros: dashboardFiltros | undefined = undefined;

      if (this.session.issetFiltros()) {
        this.getListPerguntas(String(pesquisa.pesquisa), String(comparacao.pesquisaComparacao), {
          perguntas: <string[]> JSON.parse(String(this.session.getDachboardFiltros().perguntas)),
          opcoes: <string[]> JSON.parse(String(this.session.getDachboardFiltros().opcoes))
        });

        filtros = <dashboardFiltros> {
          perguntas: String(this.session.getDachboardFiltros().perguntas),
          opcoes: String(this.session.getDachboardFiltros().opcoes)
        }
      } else {
        this.getListPerguntas(String(pesquisa.pesquisa), String(comparacao.pesquisaComparacao));
      }

      if (this.session.issetGrupos()) {
        this.dispararComparacao(this.session.getDachboardGrupos(), filtros);
      }

      this.dispararComparacao(undefined, filtros);

      // atuar para comparação
    } else if (this.session.issetPesquisa()) {
      const pesquisa = this.session.getDachboardPesquisa();

      if (!!pesquisa.pesquisa) this.form.patchValue({ empresa: pesquisa.empresa })
      if (!!pesquisa.tipoPesquisa) this.form.patchValue({ tipoPesquisa: pesquisa.tipoPesquisa })
      if (!!pesquisa.pesquisa) this.form.patchValue({ pesquisa: pesquisa.pesquisa })

      this.getListPesquisa();

      let filtros: dashboardFiltros | undefined = undefined;

      if (this.session.issetFiltros()) {
        this.getListPerguntas(String(pesquisa.pesquisa), undefined, {
          perguntas: <string[]> JSON.parse(String(this.session.getDachboardFiltros().perguntas)),
          opcoes: <string[]> JSON.parse(String(this.session.getDachboardFiltros().opcoes))
        });

        filtros = <dashboardFiltros> {
          perguntas: String(this.session.getDachboardFiltros().perguntas),
          opcoes: String(this.session.getDachboardFiltros().opcoes)
        }
      } else {
        this.getListPerguntas(String(pesquisa.pesquisa));
      }

      if (this.session.issetGrupos()) {
        this.dispararPesquisa(this.session.getDachboardGrupos(), filtros);
      }

      this.dispararPesquisa(undefined, filtros);
    }
  }

  preencheCampoPorUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (!!params.pesquisa && !!params.pesquisaComparacao) {
        if (!!params.pesquisa) this.form.patchValue({ empresa: params.empresa })
        if (!!params.tipoPesquisa) this.form.patchValue({ tipoPesquisa: params.tipoPesquisa })
        if (!!params.pesquisa) this.form.patchValue({ pesquisa: params.pesquisa })

        if (!!params.tipoPesquisaComparacao) this.formComparacao.patchValue({ tipoPesquisaComparacao: params.tipoPesquisaComparacao })
        if (!!params.pesquisaComparacao) this.formComparacao.patchValue({ pesquisaComparacao: params.pesquisaComparacao })

        this.getListPesquisa();
        this.getListPesquisaComparacao();

        if (!!params.perguntas && !!params.opcoes) {
          this.getListPerguntas(
            params.pesquisa,
            params.pesquisaComparacao,
            {
            perguntas: <string[]> JSON.parse(params.perguntas),
            opcoes: <string[]> JSON.parse(params.opcoes)
          });
        } else {
          this.getListPerguntas(params.pesquisa, params.pesquisaComparacao);
        }

      } else if (!!params.pesquisa) {
        this.form.patchValue({ empresa: params.empresa })

        if (!!params.tipoPesquisa) this.form.patchValue({ tipoPesquisa: params.tipoPesquisa })
        if (!!params.pesquisa) this.form.patchValue({ pesquisa: params.pesquisa })

        this.getListPesquisa();

        if (!!params.perguntas && !!params.opcoes) {
          this.getListPerguntas(params.pesquisa, undefined, {
            perguntas: <string[]> JSON.parse(params.perguntas),
            opcoes: <string[]> JSON.parse(params.opcoes)
          });
        } else {
          this.getListPerguntas(params.pesquisa)
        }
      }
    }).unsubscribe()
  }

  /**
   *  METODOS DE ALTERAÇÕES E ESCUTAS
   */
  alterEmpresa() {
    this.form.controls['tipoPesquisa'].reset('');
    this.form.controls['pesquisa'].reset('');
    this.formComparacao.controls['pesquisaComparacao'].reset('');
    this.formComparacao.controls['tipoPesquisaComparacao'].reset('');
    this.listaPerguntas = [];
    this.listaPesquisaComparacao = [];
    this.listaPesquisa = [];
  }

  alteraTipoPesquisa() {
    this.form.controls['pesquisa'].reset('');
    this.formComparacao.controls['pesquisaComparacao'].reset('');
    this.formComparacao.controls['tipoPesquisaComparacao'].reset('');
    this.listaPerguntas = [];
    this.listaPesquisaComparacao = [];
    this.listaPesquisa = [];

    this.getListPesquisa();
  }

  alterPesquisa() {
    setTimeout(() => {
      if (!this.form.valid) {
        return;
      }
      this.formComparacao.controls['pesquisaComparacao'].reset('');
      this.listaPerguntas = [];
      this.selectedFiltrosPerguntas = []

      this.dispararPesquisa();

    }, 100)
  }

  alteraTipoPesquisaComparacao() {
    this.formComparacao.controls['pesquisaComparacao'].reset('');

    this.getListPesquisaComparacao();
  }

  alterPesquisaComparacao() {
    setTimeout(() => {
      if (!this.form.valid || !this.formComparacao.valid) {
        return;
      }
      this.listaPerguntas = [];
      this.selectedFiltrosPerguntas = []

      this.dispararComparacao();

    }, 100)
  }

  alteraFiltroPerguntas(e: MatSelectChange, idPerggunta: string | number | undefined) {
    const value = e.value

    if (String(idPerggunta) in this.selectedFiltrosPerguntas) {

      this.selectedFiltrosPerguntas[String(idPerggunta)] = <[]> value;

    } else {
      this.selectedFiltrosPerguntas = {
        ...this.selectedFiltrosPerguntas,
        [String(idPerggunta)]: <[]> value
      }
    }

    // this.disparaFiltros();
  }

  /**
   * METODOS DE BUSCA
   */

  getListPesquisa() {
    const formData = this.form.value;

    if (!formData.empresa || !formData.tipoPesquisa) {
      return;
    }

    this.service.filtropesquisa(formData.empresa, formData.tipoPesquisa).subscribe(resp => {
      if (resp.data) {
        if (resp.data.length > 0) {
          this.listaPesquisa = [];
          setTimeout(() => {
            this.listaPesquisa = Array.isArray(resp.data) ? resp.data : [];
          }, 50)
        }
      }
    })
  }

  getListPesquisaComparacao() {
    const formData = this.form.value;
    const formDataCompara = this.formComparacao.value;

    if (!formData.empresa || !formDataCompara.tipoPesquisaComparacao) {
      return;
    }

    this.service.filtropesquisa(formData.empresa, formDataCompara.tipoPesquisaComparacao).subscribe(resp => {
      if (resp.data) {
        if (resp.data.length > 0) {
          this.listaPesquisaComparacao = [];
          setTimeout(() => {
            this.listaPesquisaComparacao = Array.isArray(resp.data) ? resp.data : [];
          }, 50)
        }
      }
    })
  }

  async getListPerguntas(idPesquisa: string, idComparacao?: string, filtros?: {perguntas: string[], opcoes: string[]}) {
    await this.service.filtropergunta(this.form.value.pesquisa, idComparacao).subscribe(
      resp => {
        this.listaPerguntas = <PerguntaPesquisaEntity[]> resp.data;

        if (!!filtros) {
          // const clearPerguntas: string[] = [...new Set(filtros.perguntas)];
          const objPerguntas: any = {};

          // cria array sem repetição
          [...new Set(filtros.perguntas)].map((cPergunta) => {

            const indexPergunta = this.listaPerguntas.findIndex(originalPerg => originalPerg.pergunta === cPergunta);

            if (indexPergunta == -1) {
              return;
            }
            const idPergunta = String(this.listaPerguntas.find(originalPerg => originalPerg.pergunta === cPergunta)?.id);

            objPerguntas[idPergunta] = [];

            filtros.perguntas.map((pergunta, index) => {
              if (pergunta === cPergunta) {
                const indexOpcao = this.listaPerguntas[indexPergunta].opcoes.findIndex(opc => opc.opcao === filtros.opcoes[index])

                if (indexOpcao == -1) {
                  return
                }

                objPerguntas[idPergunta].push(this.listaPerguntas[indexPergunta].opcoes[indexOpcao].id);
              }
            });
          })

          this.selectedFiltrosPerguntas = objPerguntas;
        }
    })
  }

  /**
   * METODOS DE DISPARO DE REQUISIÇÃO DE PESQUISA
   */

  dispararPesquisa(grupos?: string[], filtros?: {perguntas: string, opcoes: string}) {
    setTimeout(() => {
      const data = this.form.value;

      this.router.navigate(
        ['.'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            pesquisa: data.pesquisa,
            tipoPesquisa: data.tipoPesquisa,
            empresa: data.empresa,
            tipoPesquisaComparacao: null,
            pesquisaComparacao: null,
            grupos: !!grupos ? JSON.stringify(grupos) : null,
            perguntas: filtros ? filtros.perguntas : null,
            opcoes: filtros ? filtros.opcoes : null
          },
          queryParamsHandling: 'merge',
        }
      ).then(() => {
        this.session.setDachboardPesquisa({
          pesquisa: data.pesquisa,
          tipoPesquisa: data.tipoPesquisa,
          empresa: data.empresa
        });

        if (!filtros) {
          this.session.resetDachboardFiltros()
        }

        this.getListPerguntas(data.pesquisa);
      });

    }, 100)
  }

  dispararComparacao(grupos?: string[], filtros?: {perguntas: string, opcoes: string}) {
    setTimeout(() => {
      const data = this.formComparacao.value;
      const valuePesquisa = this.form.value;

      this.router.navigate(
        ['.'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            pesquisa: valuePesquisa.pesquisa,
            tipoPesquisa: valuePesquisa.tipoPesquisa,
            empresa: valuePesquisa.empresa,
            tipoPesquisaComparacao: data.tipoPesquisaComparacao,
            pesquisaComparacao: data.pesquisaComparacao,
            grupos: !!grupos ? JSON.stringify(grupos) : null,
            perguntas: filtros ? filtros.perguntas : null,
            opcoes: filtros ? filtros.opcoes : null
          },
          queryParamsHandling: 'merge',
        }
      ).then(() => {
        this.session.setDachboardComparacao({
          tipoPesquisaComparacao: data.tipoPesquisaComparacao,
          pesquisaComparacao: data.pesquisaComparacao
        });

        if (!filtros) {
          this.session.resetDachboardFiltros()
        }

        this.getListPerguntas(data.pesquisa, data.pesquisaComparacao);
      });

    }, 100)
  }

  disparaFiltros() {
    setTimeout(() => {
      const arrayPerguntas: string[] = [];
      const arrayOpcoes: string[] = [];

      Object.keys(this.selectedFiltrosPerguntas).map( (e: any) => {

        const indexPergunta = this.listaPerguntas.findIndex((pergunta) => pergunta.id == e);

        if (indexPergunta != -1) {
          this.selectedFiltrosPerguntas[e].map((opcao: string, index: number) => {
            const indexOpcao = this.listaPerguntas[indexPergunta].opcoes.findIndex( opc => String(opc.id) == opcao)

            if (indexOpcao != -1) {
              arrayPerguntas.push(String(this.listaPerguntas[indexPergunta].pergunta));
              arrayOpcoes.push(this.listaPerguntas[indexPergunta].opcoes[indexOpcao].opcao);
            }
          });
        }

      });

      this.router.navigate(
        ['.'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            perguntas: arrayPerguntas.length > 0 ? JSON.stringify(arrayPerguntas) : null,
            opcoes: arrayOpcoes.length > 0 ? JSON.stringify(arrayOpcoes) : null
          },
          queryParamsHandling: 'merge',
        }
      ).then(() => {
        this.session.setDachboardFiltros(JSON.stringify(arrayPerguntas), JSON.stringify(arrayOpcoes))
      });

    }, 100)
  }

  limpaFiltros(){
    this.selectedFiltrosPerguntas = {};

    if (this.session.user.perfil === 'GESTORTROCA') {
      this.perfilTroca();
    } else if (this.session.user.perfil === 'GESTOREMPRESA') {
      this.perfilEmpresa()
    }
  }

  /**
  * MOVER PARA HELP
  */
  trataData(d: any): string {
    var data = new Date(d),
      dia  = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0'+dia : dia,
      mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0'+mes : mes,
      anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF;
  }

  retornIdPergunta(id: any): string {
    return String(id);
  }

  showZeraFiltros() {
    return Object.keys(this.selectedFiltrosPerguntas).length > 0;
  }
}
