import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PerguntasEntity} from "../../../../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TipoRespostaArray} from "../../../../../pages/_cadastros/enum/tipo-resposta";
import {AutocompleteComponent} from "angular-ng-autocomplete";
import {OpcaoRespostaPerguntaEntity} from "../../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {AbstractCardPerguntaComponent} from "../abstract-card-pergunta/abstract-card-pergunta.component";

export interface InterfaceActionsFooter {
  excluir?: boolean,
  editar?: boolean,
  dezativar?: boolean,
  permiteEditar?: boolean,
  permiteRemover?: boolean
}

@Component({
  selector: 'card-pergunta',
  templateUrl: './card-pergunta.component.html',
  styleUrls: ['./card-pergunta.component.scss']
})
export class CardPerguntaComponent extends AbstractCardPerguntaComponent implements OnInit {
  public form: FormGroup;
  public opcoesExibicao = '';
  public categoriaFocus = false;

  @Input() index: number = 0;
  @Input() pergunta: PerguntasEntity = <PerguntasEntity> new PerguntasEntity();

  @Input() dataAutoComplete: string[] = [];

  @Input() perguntaOpcao: { podeEditar: boolean, podeRemover: boolean, perguntaObrigatoria: boolean } = {
    podeEditar: false,
    podeRemover: false,
    perguntaObrigatoria: false
  }

  @Input() tipoResposta: string[] = [];

  public tipoRespostaFiltrado: {chave: string, valor: string}[] = TipoRespostaArray;

  @Output() change = new EventEmitter<{
    pergunta: PerguntasEntity,
    index: number,
    campo: string
  }>();
  @Output() excluir = new EventEmitter<number>();

  @ViewChild('autoCompleteModelo') autoComp: AutocompleteComponent | undefined;

  constructor(
    private formBuild: FormBuilder
  ) {
    super();
    this.form = this.formBuild.group({
      pergunta: ['', Validators.required],
      tipoResposta: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form.get('tipoResposta')?.valueChanges.subscribe((tipoResp: string) => {
      this.opcoesExibicao = tipoResp;
      if (tipoResp === 'COMBOBOX' || tipoResp === 'CHECKBOX' || tipoResp === 'RADIOBUTTON' ) {
        if (this.pergunta.opcoes.length === 0) {
          this.pergunta.opcoes.push(<OpcaoRespostaPerguntaEntity> new OpcaoRespostaPerguntaEntity(undefined, ''));
        }
      }
    });

    let tipoResposta;

    if (this.tipoResposta) {
      if (this.tipoResposta.length > 0) {
        let selectTipoResposta = !!this.pergunta.tipoResposta ? this.pergunta.tipoResposta : this.tipoResposta[0];
        tipoResposta = selectTipoResposta;
        this.pergunta.tipoResposta = selectTipoResposta;
      } else {
        let selectTipoResposta = !!this.pergunta.tipoResposta ? this.pergunta.tipoResposta : "TEXT";
        tipoResposta = selectTipoResposta;
        this.pergunta.tipoResposta = selectTipoResposta;
      }
    }

    this.form.patchValue({
      pergunta: this.pergunta.pergunta,
      tipoResposta: tipoResposta,
    });

  }

  outputExcluir() {
    this.excluir.emit(this.index);
  }

  changeValue(campo: string){
    this.change.emit({
      pergunta: {
        ...this.pergunta,
        ...this.form.value
      },
      index: this.index,
      campo
    })
  }

  @HostListener('window:card-perguntas-verificar')
  verificarPergunta(){
    this.form.markAllAsTouched();
  }

  disableOpcaoResposta(opcao: string): boolean {
    if (this.tipoResposta.length === 0) {
      return false;
    } else if (Array.isArray(this.tipoResposta)) {
      return this.tipoResposta.findIndex((tipoR) => tipoR === opcao) === -1
    }

    return true;
  }

  // metodo sensível por favor, não mexa
  onCategoriaFocus(value: any) {

    if (value.sourceCapabilities === null) {
      this.categoriaFocus = false;
      this.autoComp?.close();
    }

    this.categoriaFocus = !!value;

  }

}
