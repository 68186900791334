import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ResponderJpdEntity} from "../../responder/JPD/entity/responder-jpd-entity";
import {Router} from "@angular/router";
import {SessionService} from "../../../../../services/session/session.service";
import {PesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pesquisa-entity";
import Swal from "sweetalert2";
import {OpcaoRespostaJpdEntity} from "../../responder/JPD/entity/opcao-resposta-jpd-entity";
import {RespostaJpdEntity} from "../../responder/JPD/entity/resposta-jpd-entity";
import {TipoResposta} from "../../../../_cadastros/enum/tipo-resposta";

@Component({
  selector: 'app-preview-jpd',
  templateUrl: './preview-jpd.component.html',
  styleUrls: ['./preview-jpd.component.scss']
})
export class PreviewJpdComponent implements OnInit {

  public entity: ResponderJpdEntity = new ResponderJpdEntity();
  public load = true;
  public pesquisaAnonima = true;

  public verificarPerguntas = false;
  @ViewChild('textDescricao') textDescricao!: HTMLDivElement;

  constructor(
    private router: Router,
    public session: SessionService,
    private rerender: Renderer2,
  ) {
    if (window.sessionStorage.getItem('entity')) {
      let pesquisa = <PesquisaEntity> JSON.parse(<string>window.sessionStorage.getItem('entity'));

      this.entity.titulo = pesquisa.titulo;
      this.entity.perguntaPesquisa = pesquisa.perguntaPesquisa;
      this.entity.descricao = pesquisa.descricao;

      this.entity.respostas = pesquisa.perguntaPesquisa.map((pergunta) => {

        let opcoes: OpcaoRespostaJpdEntity[] = [];

        if (pergunta.tipoResposta === 'CHECKBOX' || pergunta.tipoResposta === 'COMBOBOX' || pergunta.tipoResposta === 'RADIOBUTTON') {

          pergunta.opcoes.map((opcao) => {
            opcoes.push( new OpcaoRespostaJpdEntity(
              opcao.opcaoOriginalId,
              ''
            ))
          })
        }

        return new RespostaJpdEntity(
          pergunta.pergunta,
          Number(pergunta.id),
          opcoes,
          pergunta.perguntaObrigatoria,
          false
        )
      });

      this.load = false;

    } else {
      Swal.fire({
        icon: 'error',
        title: 'A previa da pesquisa que você procura não existe mais.'
      }).then(() => {
        this.router.navigate(['/']);
      })
    }
  }

  ngOnInit(): void {
    setTimeout(() => this.setTextEditor(), 10)
  }

  changeValues(data: {
    resposta: RespostaJpdEntity,
    indexOpcao: number,
    index: number,
    campo: string,
    replaceQuantidades?: boolean
  }) {

    if (data.campo === 'percentual') {

      this.entity.respostas[data.index].percentual = data.resposta.percentual;

      if (data.resposta.percentual) {
        // loop em opções para verificar numeros
        this.entity.respostas[data.index].respostaOpcao.map((opcao, index) => {
          // verificando length
          if (String(data.resposta.respostaOpcao[index].quantidade).length > 4) {

            if (String(data.resposta.respostaOpcao[index].quantidade).indexOf(".") != -1) {

              if (String(data.resposta.respostaOpcao[index].quantidade).length == 5) {

                if (String(data.resposta.respostaOpcao[index].quantidade).indexOf(".") != 2) {

                  this.entity.respostas[data.index].respostaOpcao[index].quantidade =
                    String(data.resposta.respostaOpcao[index].quantidade).slice(0,2)
                    + '.' +
                    String(data.resposta.respostaOpcao[index].quantidade).slice(2,4);
                }
              }

            } else {
              this.entity.respostas[data.index].respostaOpcao[index].quantidade =
                String(data.resposta.respostaOpcao[index].quantidade).slice(0,2)
                + '.' +
                String(data.resposta.respostaOpcao[index].quantidade).slice(2,4);
            }

          } else if (String(data.resposta.respostaOpcao[index].quantidade).length == 4) {
            this.entity.respostas[data.index].respostaOpcao[index].quantidade =
              String(data.resposta.respostaOpcao[index].quantidade).slice(0,2)
              + '.' +
              String(data.resposta.respostaOpcao[index].quantidade).slice(2,4);
          }

        });
      } else {
        this.entity.respostas[data.index].respostaOpcao.map((opcao, index) => {
          this.entity.respostas[data.index].respostaOpcao[index].quantidade =
            this.entity.respostas[data.index].respostaOpcao[index].quantidade.replace('.', '')
        });
      }
    }
    if (data.campo === 'opcao') {

      this.entity.respostas[data.index].respostaOpcao[data.indexOpcao].quantidade = data.resposta.respostaOpcao[data.indexOpcao].quantidade;
    }

    if (this.entity.respostas[data.index]?.perguntaObrigatoria && this.verificarPerguntas) {

      const indicador = this.verificaPerguntaObrigatoria(data.resposta, data.index);

      if (!!indicador) {
        this.entity.respostas[data.index].respostaOk = false;
        this.entity.respostas[data.index].messageError = indicador;
      } else {
        this.entity.respostas[data.index].respostaOk = true;
      }

      // if (this.entity.respostas[data.index].percentual) {
      //   let contadorResposta = 0;
      //
      //   this.entity.respostas[data.index].respostaOpcao.map((opcao, indexOpcao) => {
      //
      //     if (String(opcao.quantidade).trim() !== '') {
      //       contadorResposta = contadorResposta + Number(opcao.quantidade);
      //     }
      //   })
      //
      //   this.entity.respostas[data.index].respostaOk = !(contadorResposta < 100)
      // }
    }
  }

  verificaPerguntaObrigatoria(resposta: RespostaJpdEntity, index: number): false | string {
    let contadorResposta = 0;

    resposta.respostaOpcao.map((opcao, indexOpcao) => {
      contadorResposta = contadorResposta + Number(opcao.quantidade);
    })

    if (!this.entity.perguntaPesquisa[index].perguntaObrigatoria) {
      return false;
    }

    if (this.entity.perguntaPesquisa[index].tipoResposta === 'CHECKBOX') {

      if (resposta.percentual) {
        if (contadorResposta < 100) {
          return 'Uma pergunta percentual(%) não pode somar menos de 100%';
        }
      } else {
        if (contadorResposta < Number(this.entity.quantidadeRespostas)) {
          return `Uma pergunta do tipo <b>Caixa de seleção</b> não pode somar um numero menor que o numero de respostas (<b>${this.entity.quantidadeRespostas}</b>)`;
        }
      }

    } else if (this.entity.perguntaPesquisa[index].tipoResposta === 'NUMBER') {
      alert('criar verificação number');
    } else {

      if (resposta.percentual) {
        if (contadorResposta != 100) {
          if (contadorResposta < 100) {
            return 'Uma pergunta percentual(%) não pode somar menos de 100%';
          }

          // @ts-ignore
          const tipoResposta = TipoResposta[this.entity.perguntaPesquisa[index].tipoResposta]

          return `Uma pergunta percentual(%) do tipo <b>${tipoResposta}</b> não pode somar mais de 100%`;
        }
      } else {
        if (contadorResposta != Number(this.entity.quantidadeRespostas)) {
          // @ts-ignore
          const tipoResposta = TipoResposta[this.entity.perguntaPesquisa[index].tipoResposta]

          return `Uma pergunta do tipo <b>${tipoResposta}</b> não pode somar um numero diferente do numero de respostas (<b>${this.entity.quantidadeRespostas}</b>)`;
        }
      }
    }

    return false;
  }

  setTextEditor() {
    //@ts-ignore
    const navigate = this.textDescricao.nativeElement;
    this.rerender.setProperty(navigate, 'innerHTML', this.entity?.descricao || '')
  }
}
