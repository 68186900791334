import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeComponent} from "./home-component/home.component";
import { UiModule } from 'src/app/components/ui/ui.module';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import {NgxMaskModule} from "ngx-mask";
import {DirectivesModule} from "../../directives/directives.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [HomeComponent],
    imports: [
        CommonModule,
        UiModule,
        MatIconModule,
        HttpClientModule,
        NgxMaskModule,
        DirectivesModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule
    ]
})
export class HomeModule { }
