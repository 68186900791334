import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractInsertEdit} from '@datagrupo/dg-crud';
import {PesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pesquisa-entity";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {HttpService} from "../../../../../services/http/http.service";
import {SessionService} from "../../../../../services/session/session.service";
import {EditarPesquisaService} from "../service/editar-pesquisa.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {PerguntasEntity} from "../../../../_cadastros/pages/perguntas/entity/perguntas-entity";
import {
  OpcaoPerguntaPesquisaEntity
} from "../../../../_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {
  PerguntaPesquisaModeloEntity
} from "../../../../_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {
  ModalBuscaPerguntasComponent
} from "../../../../../components/ui/modals/modal-busca-perguntas/modal-busca-perguntas.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpPerguntasService} from "../../../../../services/helpers/help-perguntas.service";
import {
  ModalSettingsPerguntasComponent
} from "../../../../../components/ui/modals/modal-settings-perguntas/modal-settings-perguntas.component";
import {ConfigDgCrudService} from "../../../../../services/config-dg-crud/config-dg-crud.service";
import {PerguntaModeloToPerguntaPesquisa} from "../../../helpers/perguntaModelo-to-perguntaPesquisa";
import {
  ajusteFakeIdModelo, ajustFakeIdOpcaoPredecessorByListPerguntas, ajustFakeIdOpcaoPredecessorByListPerguntasModelo,
  ajustFakeIdPredecessorByListPerguntas, ajustFakeIdPredecessorByListPerguntasModelo,
  fakeIdOpcoes,
  fakeIdOpcoesModelo,
  fakeIdPerguntas, inspectFakeIdPesquisa
} from "../../../helpers/fake-id";
import {addPerguntaPesquisa} from "../../../helpers/addPerguntaPesquisa";
import {
  ModalRelacaoPerguntaPesquisaPredecessoraComponent, retornoperguntaPesquisaPredecessora
} from "../../../../../components/ui/modals/modal-relacao-pergunta-pesquisa-predecessora/modal-relacao-pergunta-pesquisa-predecessora.component";
import {addPerguntaPesqusaBanco} from "../../../helpers/add-pergunta-banco";
import {copyPerguntaPesquisa} from "../../../helpers/copyPerguntaPesquisa";
import {RestaurarPerguntaPesquisa} from "../../../helpers/restaurar-pergunta-pesquisa";
import {DefaultKolkovTextEditor} from "../../../../../_core/config/default-kolkov-text-editor";

@Component({
  selector: 'app-editar-pesquisa',
  templateUrl: './editar-pesquisa.component.html',
  styleUrls: ['./editar-pesquisa.component.scss']
})
export class EditarPesquisaComponent extends AbstractInsertEdit<PesquisaEntity> implements OnInit {

  private originalPesquisa: any;

  public nomeFantasiaEmpresa: string = '';

  editorConfig = DefaultKolkovTextEditor;

  public formConcluir = new FormGroup({
    pesquisaAnonima: new FormControl(true, [Validators.required]),
    quantColabAtivos: new FormControl('', [Validators.required]),
    dataFim: new FormControl('', [Validators.required]),
    dataInicio: new FormControl('', [Validators.required]),
    emailsRespondentes: new FormControl(''),
    restringirRespondentesListaEmail: new FormControl(false),
    restringirRespondentesUsuariosEmpresa: new FormControl(false)
  });
  public formApresentacao = new FormGroup({
    titulo: new FormControl('', [Validators.required]),
    descricao: new FormControl('', [Validators.required])
  })

  // momentos do andamento
  public momento: 'editar' | 'concluir' = 'editar';
  // ultimo momento gravado
  public lastMomento: 'editar' = 'editar';

  @ViewChild('modal') modal!: ModalBuscaPerguntasComponent;
  @ViewChild('modalSettings') modalSettings!: ModalSettingsPerguntasComponent;
  @ViewChild('ModalPerguntaPredecessora') modalPerguntaPredecessora!: ModalRelacaoPerguntaPesquisaPredecessoraComponent;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected service: EditarPesquisaService,
    protected location: Location,
    public http: HttpService,
    public session: SessionService,
    private help: HelpPerguntasService,
    config: ConfigDgCrudService
  ) {
    super(service, config);
    this.nomeFantasiaEmpresa = !!this.session.user.empresa?.nomeFantasia ? this.session.user.empresa.nomeFantasia : '';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = <PesquisaEntity>new PesquisaEntity();
  }

  beforeSaveEntity(): boolean {
    if (!this.formConcluir.valid) {
      this.formConcluir.markAllAsTouched();
      return false;
    }

    if (!this.formApresentacao.valid) {
      this.momento = 'editar';
      this.formApresentacao.markAllAsTouched();
      return false;
    }

    this.entity.perguntaPesquisa.map((pergunta, index) => {
      pergunta.status.map((status, indexStatus) => {

        this.entity.perguntaPesquisa[index].status[indexStatus] = status.split(' ').join('_')
      });

      return pergunta;
    })

    this.entity = {
      ...this.entity,
      ...this.formConcluir.value,
      ...this.formApresentacao.value
    }

    console.log(this.entity);

    return true;
  }

  afterFetchEntity() {
    this.originalPesquisa = {...this.entity};

    //@ts-ignore
    if (this.entity.status === 'EM_ANDAMENTO' || this.entity.status === 'CONCLUIDA') {
      this.router.navigate(['/pesquisa/historico'])
    }

    this.formConcluir.patchValue({
      pesquisaAnonima: this.entity.pesquisaAnonima,
      quantColabAtivos: this.entity.quantColabAtivos,
      dataFim: this.entity.dataFim,
      dataInicio: this.entity.dataInicio,
      emailsRespondentes: this.entity.emailsRespondentes,
      restringirRespondentesListaEmail: this.entity.restringirRespondentesListaEmail,
      restringirRespondentesUsuariosEmpresa: this.entity.restringirRespondentesUsuariosEmpresa
    });

    this.formApresentacao.patchValue({
      titulo: this.entity.titulo,
      descricao: this.entity.descricao
    });

    ajusteFakeIdModelo(this.entity.pesquisaModelo.perguntas)
    ajustFakeIdPredecessorByListPerguntasModelo(this.entity.pesquisaModelo.perguntas)
    ajustFakeIdOpcaoPredecessorByListPerguntasModelo(this.entity.pesquisaModelo.perguntas)

    this.entity.perguntaPesquisa.map((perg, index) => {
      this.entity.pesquisaModelo.perguntas.findIndex((pergModelo, indexModelo) => {
          if (perg.perguntaModeloId != pergModelo.id) return;

          this.entity.perguntaPesquisa[index].fakeId = this.entity.pesquisaModelo.perguntas[indexModelo].fakeId;

          perg.opcoes.map((opcao, indexOpc) => {
            const indexOpcModelo = this.entity.pesquisaModelo.perguntas[indexModelo].pergunta.opcoes.findIndex(
              opcModelo => opcModelo.id == opcao.opcaoOriginalId
            )

            if (indexOpcModelo != -1) {
              this.entity.perguntaPesquisa[index].opcoes[indexOpc].fakeId =
                this.entity.pesquisaModelo.perguntas[indexModelo].pergunta.opcoes[indexOpcModelo].fakeId
            }
          })
        }
      )
    })

    this.entity.perguntaPesquisa.map((perg, index) => {
      if (!perg.fakeId) this.entity.perguntaPesquisa[index].fakeId = fakeIdPerguntas(this.entity.perguntaPesquisa)

      perg.opcoes.map((opc, indexOpc) => {
        if (!opc.fakeId) this.entity.perguntaPesquisa[index].opcoes[indexOpc].fakeId = fakeIdOpcoes(this.entity.perguntaPesquisa)
      })
    })

    ajustFakeIdPredecessorByListPerguntas(this.entity.perguntaPesquisa);
    ajustFakeIdOpcaoPredecessorByListPerguntas(this.entity.perguntaPesquisa);

  }

  salvar(endpoint?: CustomEvent | string) {
    if (Number(this.entity.tipoPesquisa?.codigo) == 3) {
      if (endpoint === 'salvar') {
        super.salvar('diagnostico');
      } else {
        super.salvar('diagnostico/concluir');
      }
    } else {
      if (endpoint === 'salvar') {
        super.salvar('censo');
      } else {
        super.salvar('censo/concluir');
      }

    }
  }

  change(data: { pergunta: PerguntaPesquisaEntity, index: number, campo: string }) {
    if (data.campo === 'pergunta') {
      this.entity.perguntaPesquisa[data.index].pergunta = data.pergunta.pergunta;
    }
    if (data.campo === 'categoria') {
      this.entity.perguntaPesquisa[data.index].categoria = data.pergunta.categoria;
    }
    if (data.campo === 'tipo_resposta') {
      this.entity.perguntaPesquisa[data.index].tipoResposta = data.pergunta.tipoResposta;
    }
    if (data.campo === 'editada') {
      this.entity.perguntaPesquisa[data.index].status = data.pergunta.status;
    }
    if (data.campo === 'modelo_perguntaObrigatoria') {
      this.entity.perguntaPesquisa[data.index].perguntaObrigatoria = data.pergunta.perguntaObrigatoria;
    }
  }

  restaurarPergunta = (
    data: { pergunta: PerguntaPesquisaEntity; index: number; statusMode: string },
    index: number
  ) => {

    RestaurarPerguntaPesquisa(
      data,
      this.entity.perguntaPesquisa,
      [this.entity.pesquisaModelo],
      this.entity.pesquisaModelo,
      this.http
    );

    this.entity.perguntaPesquisa[index].id = data.pergunta.id

    setTimeout(() => {
      this.entity.perguntaPesquisa[index].opcoes.map((opc, indexOpc) => {
        const indexOpcOriginal = data.pergunta.opcoes.findIndex(opcOriginal => opc.fakeId == opcOriginal.fakeId)

        if (indexOpcOriginal != -1) {
          //@ts-ignore
          if (!this.entity.perguntaPesquisa[index].geraFiltro) delete this.entity.perguntaPesquisa[index].nomeFiltro
          if (!data.pergunta.opcoes[indexOpcOriginal].id) return;
          this.entity.perguntaPesquisa[index].opcoes[indexOpc].id = data.pergunta.opcoes[indexOpcOriginal].id
        }
      })
    }, 10)
  }

  _restaurarPergunta(data: { pergunta: PerguntaPesquisaEntity, index: number, statusMode: string }) {

    if (data.statusMode === 'ORIGINAL-ALTERADA') {
      const indexPergunta =
        this.entity.pesquisaModelo.perguntas.findIndex((pergunta) =>
          pergunta.pergunta.id === data.pergunta.perguntaOriginalId);

      if (indexPergunta !== -1) {
        this.entity.perguntaPesquisa[data.index]
          = PerguntaModeloToPerguntaPesquisa(this.entity.pesquisaModelo.perguntas[indexPergunta], this.entity.perguntaPesquisa);
      }
    } else {
      this.http.getById<RestReturn<PerguntasEntity>>('api/pergunta', String(data.pergunta.perguntaOriginalId)).subscribe(next => {

        const opcoes = <OpcaoPerguntaPesquisaEntity[]> next.data?.opcoes.map((opcao, indexOpcPergunta) => {

          return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
            undefined,
            opcao.opcao,
            opcao.outros,
            Number(opcao.id),
          );
        });

        this.entity.perguntaPesquisa[data.index] = new PerguntaPesquisaEntity(
          undefined,
          next.data?.id,
          undefined,
          next.data?.pergunta,
          next.data?.tipoResposta,
          opcoes,
          undefined,
          undefined,
          next.data?.categoria,
          ['ADICIONADA', 'BANCO DE DADOS'],
          next.data?.podeEditar,
          next.data?.podeRemover,
          false
        );
      })
    }
  }

  addPergunta = (index?: number) => addPerguntaPesquisa(this.entity.perguntaPesquisa, index)

  addPerguntaBanco = (data: { perguntas: PerguntasEntity[]; index: number }) => addPerguntaPesqusaBanco(data, this.entity.perguntaPesquisa)

  openModal(index: number) {
    this.modal.listaPerguntasInseridas = this.entity.perguntaPesquisa;
    this.modal.openModal(index, this.entity.pesquisaModelo.tipoPesquisa.tipoResposta)
  }

  removePergunta(index: number) {
    this.entity.perguntaPesquisa.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.entity.pesquisaModelo.podeReordenar) {
      if (event.currentIndex < this.entity.pesquisaModelo.perguntas.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Não é possível reordenar as perguntas originais desta pesquisa',
          confirmButtonColor: '#EC536C',
          timer: 5000
        })
      } else {
        moveItemInArray(this.entity.perguntaPesquisa, event.previousIndex, event.currentIndex);
      }
    } else {
      moveItemInArray(this.entity.perguntaPesquisa, event.previousIndex, event.currentIndex);
    }
  }

  nextMomento(novoMomento: 'editar' | 'concluir') {

    if (this.momento === 'editar') {

      if (this.verificaPerguntaTipoInvalido()) {
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas com o tipo de resposta inválida.'
        })
        return;
      }

      if (this.verificaPerguntaPreenchimentoInvalido()) {
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas ou opções de resposta em branco.',
          confirmButtonColor: '#2c516e'
        })
        return;
      }

      if (this.verificaPerguntaOpcaoRepetida()) {
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas com opções de resposta repetidas.'
        })
        return;
      }
    }


    this.momento = novoMomento

    if (novoMomento !== 'concluir') {
      this.lastMomento = novoMomento;
      window.scrollTo(0, 0)
    }
  }

  backMomento() {
    if (this.momento === 'editar') {

      if (this.verificaPerguntaTipoInvalido()) {
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas com o tipo de resposta inválida.'
        })
        return;
      }

      if (this.verificaPerguntaPreenchimentoInvalido()) {
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas ou opções de resposta em branco.',
          confirmButtonColor: '#2c516e'
        })
        return;
      }

      if (this.verificaPerguntaOpcaoRepetida()) {
        Swal.fire({
          icon: 'error',
          title: 'Existem perguntas com opções de resposta repetidas.'
        })
        return;
      }
    }

    window.scrollTo(0, 0)
    this.momento = this.lastMomento;
  }

  verificaPerguntaPreenchimentoInvalido() {
    const perguntasInvalidas = this.entity.perguntaPesquisa.findIndex((pergunta, index) => {


      if (pergunta.pergunta?.trim() === '' || pergunta.pergunta === undefined) {
        return true;
      }

      if (
        pergunta.tipoResposta === 'CHECKBOX' ||
        pergunta.tipoResposta === 'COMBOBOX' ||
        pergunta.tipoResposta === 'RADIOBUTTON'
      ) {
        const opcInvalida = pergunta.opcoes.findIndex(opcao => {
          return (opcao.opcao.trim() === '' || opcao.opcao === undefined) && !opcao.outros
        })

        return opcInvalida !== -1;
      } else {
        return false
      }

    });

    return perguntasInvalidas !== -1;
  }

  verificaPerguntaTipoInvalido() {
    if (!this.entity.pesquisaModelo.tipoPesquisa) {
      return true;
    }

    if (!Array.isArray(this.entity.pesquisaModelo.tipoPesquisa.tipoResposta)) {
      return true;
    }

    if (this.entity.pesquisaModelo.tipoPesquisa.tipoResposta.length > 0) {
      const perguntasInvalidas = this.entity.perguntaPesquisa.findIndex((pergunta, index) => {

        return this.entity.pesquisaModelo.tipoPesquisa.tipoResposta.findIndex(tipoResposta => {
          return tipoResposta === pergunta.tipoResposta
        }) !== -1
      });

      return perguntasInvalidas === -1;
    } else {
      return false;
    }
  }

  previewPesquisa() {
    if (this.verificaPerguntaTipoInvalido()) {
      Swal.fire({
        icon: 'error',
        title: 'Existem perguntas com o tipo de resposta inválida.'
      })
      return;
    }

    if (this.verificaPerguntaPreenchimentoInvalido()) {
      Swal.fire({
        icon: 'error',
        title: 'Existem perguntas ou opções de resposta em branco.',
        confirmButtonColor: '#2c516e'
      })
      return;
    }
    const valurFormConcluir = this.formConcluir.value;
    this.entity = {
      ...this.entity,
      ...valurFormConcluir
    }

    window.open(`pesquisa/preview`)?.sessionStorage.setItem('entity', JSON.stringify(this.entity))
  }

  verificaPerguntaOpcaoRepetida() {
    let result = false;

    this.entity.perguntaPesquisa.map((pergunta, index) => {
      pergunta.opcoes.map(opcao => {
        const buscaOpc = this.entity.perguntaPesquisa[index].opcoes.filter(opc => opc.opcao === opcao.opcao)

        if (buscaOpc.length > 1) {
          result = true;
        }
      })
    })

    return result;
  }

  openModalSettings(index: number) {
    this.modalSettings.index = index;

    this.modalSettings.openModal({
      geraFiltro: !!this.entity.perguntaPesquisa[index].geraFiltro,
      nomeFiltro: String(this.entity.perguntaPesquisa[index].nomeFiltro),
      grupo: String(this.entity.perguntaPesquisa[index].grupo)
    });
  }

  salvarPerguntaBanco(index: number) {
    this.help.salvarPerguntaIndividual(this.entity.perguntaPesquisa[index]).subscribe(
      resp => {
        console.log(resp);
        // @ts-ignore
        this.entity.perguntaPesquisa[index] = this.help.pergunta_to_perguntaPesquisa(resp.data)[0];
      }
    )
  }

  saveConfigFiltros(ev: any) {
    console.log(ev.data);
    this.entity.perguntaPesquisa[ev.index].geraFiltro = !!ev.data.geraFiltro;
    this.entity.perguntaPesquisa[ev.index].nomeFiltro = ev.data.nomeFiltro;
    this.entity.perguntaPesquisa[ev.index].grupo = ev.data.grupo;

    this.modalSettings.close();
  }

  copyPergunta = (index: number) => copyPerguntaPesquisa(index, this.entity.perguntaPesquisa)

  exibirBtnSalvar(index: number) {

    if (this.entity.perguntaPesquisa[index].status.findIndex(stts => stts === 'ALTERADA') != -1) {
      return false;
    }

    return !!this.entity.perguntaPesquisa[index].perguntaOriginalId;
  }

  addPerguntaPredecessora(index: number) {
    this.modalPerguntaPredecessora.open(this.entity?.perguntaPesquisa[index], index)
  }

  callbackPerguntaPredecessora(ev: retornoperguntaPesquisaPredecessora) {
    this.entity.perguntaPesquisa[ev.index]['opcaoPerguntaPesquisaPredecessora'] = ev.opcaoRespostaPredecessora;
    this.entity.perguntaPesquisa[ev.index]['perguntaPesquisaPredecessora'] = ev.perguntaPredecessora;
  }

  verifyExibRelacionarPredecessor(pergunta: PerguntaPesquisaEntity): boolean {
    return pergunta.podeEditar || pergunta?.status.findIndex(e => e == 'NOVA') >= 0 || this.entity?.tipoPesquisa?.codigo == '1'
  }
}
