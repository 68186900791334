import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {PerguntaScoreService} from "../services/pergunta-score.service";
import {PerguntaScorePesquisaEntity} from "../entity/pergunta-score-pesquisa-entity";
import {
  ModalBuscaPerguntasPesquisaScoreComponent
} from "../../../../../components/ui/modals/modal-busca-perguntas-pesquisa-score/modal-busca-perguntas-pesquisa-score.component";
import {PerguntaPesquisaEntity} from "../../../../_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import Swal from "sweetalert2";

@Component({
  selector: 'app-pergunta-score-insert-edit',
  templateUrl: './pergunta-score-insert-edit.component.html',
  styleUrls: ['./pergunta-score-insert-edit.component.scss']
})
export class PerguntaScoreInsertEditComponent implements OnInit {

  @ViewChild('mudalBuscaPerguntas') modalBuscaPergunta!: ModalBuscaPerguntasPesquisaScoreComponent;

  public entityId: number = 0;
  public entity: PerguntaScorePesquisaEntity[] = [];
  public listTamplateScore: PerguntaScorePesquisaEntity[] = [];

  constructor(
    public service: PerguntaScoreService,
    public activatedRoute: ActivatedRoute,
    public location: Location
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.entityId = params.id;

        service.findOne(params.id).subscribe(
          resp => {
            //@ts-ignore
            this.entity = [...resp.data]

            //@ts-ignore
            this.listTamplateScore = resp.data;

            this.listTamplateScore.map((perguntaScore) => {
              //@ts-ignore
              perguntaScore.pesquisa = { id: this.entityId }
            });

          }
        );
      }
    })
  }

  ngOnInit(): void {
  }

  salvarScore(index: number) {
    this.service.save(this.listTamplateScore[index]).subscribe(
      resp => {

        if (!this.listTamplateScore[index].id) {
          //@ts-ignore
          this.listTamplateScore[index].id = resp.data.id;
        }

        //@ts-ignore
        if (!!resp.data.restoPontuacao) {
          this.listTamplateScore.map((score, index) => {
            if (score.listPerguntaPesquisa.length == 0) {
              //@ts-ignore
              this.listTamplateScore[index].pontuacaoMax = resp.data.restoPontuacao;
            }
          })
        }
      }
    )
  }

  addPerguntaScore() {
    this.listTamplateScore.push(new PerguntaScorePesquisaEntity(
      undefined,
      '',
      '',
      undefined,
      [],
      0,
      undefined,
      undefined,
      undefined,
      //@ts-ignore
      {id: this.entityId},
      true
    ))
  }

  removerPrtguntaScore(id: number | string | undefined, index: number){
    Swal.fire({
      icon: "warning",
      title: "Remover pergunta?",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: "Remover",
      confirmButtonColor: '#EF426F',
      cancelButtonColor: '#7A6FBE'
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        if (!id) {
          this.listTamplateScore.splice(index, 1)
          return;
        }

        this.service.delete(id).subscribe(
          resp => {
            this.listTamplateScore.splice(index, 1)
          }
        )
      }
    })
  }

  desvincularPergunta(index: number){
    this.listTamplateScore[index].listPerguntaPesquisa = [];
    this.salvarScore(index)
  }

  alteraPontos(d: string, index: number){
    if (d != '') {
      this.service.save(this.listTamplateScore[index]).subscribe(
        resp => {
          //@ts-ignore
          if (!!resp.data.restoPontuacao) {
            this.listTamplateScore.map((score, index) => {
              if (score.listPerguntaPesquisa.length == 0) {
                //@ts-ignore
                this.listTamplateScore[index].pontuacaoMax = resp.data.restoPontuacao;
              }
            })
          }
        }
      )
    }
  }

  naoSeAplica(index: number) {
    if (this.listTamplateScore[index].listPerguntaPesquisa.length > 0) {
      Swal.fire({
        icon: "warning",
        // title: "Remover pergunta?",
        text: 'A pergunta asociada será removida para que a marcação de "não se aplica" seja aplicada',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: "Remover",
        confirmButtonColor: '#EF426F',
        cancelButtonColor: '#7A6FBE'
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          if (this.listTamplateScore[index].resposta === 'NAO_APLICA') {
            this.listTamplateScore[index].resposta = 'INDEFINIDO'
          } else {
            this.listTamplateScore[index].resposta = "NAO_APLICA"
            this.listTamplateScore[index].pontuacaoMax = 0;
            this.listTamplateScore[index].listPerguntaPesquisa = [];
          }

          this.service.save(this.listTamplateScore[index]).subscribe(
            resp => {
              this.listTamplateScore[index].pontuacaoMax = 0;
              //@ts-ignore
              if (!!resp.data.restoPontuacao) {
                this.listTamplateScore.map((score, index) => {
                  if (score.listPerguntaPesquisa.length == 0 && score.resposta !== 'NAO_APLICA') {
                    //@ts-ignore
                    this.listTamplateScore[index].pontuacaoMax = resp.data.restoPontuacao;
                  }
                })
              }
            }
          )
        }
      })
    } else {

      if (this.listTamplateScore[index].resposta === 'NAO_APLICA') {
        this.listTamplateScore[index].resposta = 'INDEFINIDO'
      } else {
        this.listTamplateScore[index].resposta = "NAO_APLICA"
        this.listTamplateScore[index].pontuacaoMax = 0;
      }

      this.service.save(this.listTamplateScore[index]).subscribe(
        resp => {
          this.listTamplateScore[index].pontuacaoMax = 0;
          //@ts-ignore
          if (!!resp.data.restoPontuacao) {
            this.listTamplateScore.map((score, index) => {
              if (score.listPerguntaPesquisa.length == 0 && score.resposta !== 'NAO_APLICA') {
                //@ts-ignore
                this.listTamplateScore[index].pontuacaoMax = resp.data.restoPontuacao;
              }
            })
          }
        }
      )
    }
  }

  // modals
  openBuscaPerguntas(index: number) {
    this.modalBuscaPergunta.idPesquisa = this.entityId;
    this.modalBuscaPergunta.index = index;
    if (this.entity[index].listPerguntaPesquisa) {
      // this.modalBuscaPergunta.perguntaSelecionada = this.entity[index].perguntaModelo?.id;
      this.modalBuscaPergunta.selectList = this.entity[index].listPerguntaPesquisa;
    } else {
      this.modalBuscaPergunta.selectList = [];
    }
    this.modalBuscaPergunta.open();
  }

  calbackBuscaPergunta(ev: any) {
    const data = <PerguntaPesquisaEntity[]>ev.data;
    const index = ev.index;

    this.listTamplateScore[index].listPerguntaPesquisa = data;
    this.modalBuscaPergunta.close();

    this.salvarScore(index);
  }

  // help
  ajustaDecimalPontos(d: number | string | undefined){
    const numero = Number(d);

    return (Math.round(numero * 100) / 100).toFixed(2)
  }
}
