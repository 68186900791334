import { Injectable } from '@angular/core';
import {PerguntasService} from "../../pages/_cadastros/pages/perguntas/services/perguntas.service";
import {PerguntasEntity} from "../../pages/_cadastros/pages/perguntas/entity/perguntas-entity";
import {
  OpcaoRespostaPerguntaEntity
} from "../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {PerguntaPesquisaEntity} from "../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {
  OpcaoPerguntaPesquisaEntity
} from "../../pages/_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import {
  PerguntaPesquisaModeloEntity
} from "../../pages/_cadastros/pages/pesquisa-modelo/entity/pergunta-pesquisa-modelo-entity";
import {PesquisaModeloEntity} from "../../pages/_cadastros/pages/pesquisa-modelo/entity/pesquisa-modelo-entity";

@Injectable({
  providedIn: 'root'
})
export class HelpPerguntasService {

  constructor(
    public servicePerg: PerguntasService
  ) { }

  /**
   * salva uma pergunta criada pelo usuário como uma nova pergunta no banco de dados
   * @param pergunta
   */
  public salvarPerguntaIndividual(pergunta: PerguntaPesquisaEntity) {

    const novaPergunta = new PerguntasEntity(
      '',
      pergunta.pergunta,
      pergunta.tipoResposta,
      [],
      undefined,
      undefined,
      pergunta.categoria,
      0,
      true,
      true,
      true,
      pergunta.nomeFiltro ? pergunta.nomeFiltro : pergunta.pergunta,
      pergunta.grupo,
    )

    let replaceOpcao: OpcaoRespostaPerguntaEntity[] = [];

    if (
      pergunta.tipoResposta === 'CHECKBOX' ||
      pergunta.tipoResposta === 'RADIOBUTTON' ||
      pergunta.tipoResposta === 'COMBOBOX'
    ) {
      pergunta.opcoes.map((opcao) => {
        replaceOpcao.push(new OpcaoRespostaPerguntaEntity(
          undefined,
          opcao.opcao,
          opcao.outros
        ));
      })

      if (replaceOpcao.length > 0) {
        novaPergunta.opcoes = replaceOpcao;
      }
    }

    return this.servicePerg.save(novaPergunta, 'salvar');
  }

  //******************************************************//
  //  FUNÇÃO DE REPLACE DE PERGUNTAS
  //******************************************************//

  /**
   * transforma uma pergunta em perguntaPesquisa
   * @param perguntas
   */
  pergunta_to_perguntaPesquisa(perguntas: PerguntasEntity | PerguntasEntity[]): PerguntaPesquisaEntity[] {

    const perguntasAdd: PerguntaPesquisaEntity[] = []

    if (Array.isArray(perguntas)) {
      perguntas.map((pergunta, index) => {

        const opcoes = <OpcaoPerguntaPesquisaEntity[]> pergunta.opcoes.map((opcao, indexOpcPergunta) => {

          return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
            undefined,
            opcao.opcao,
            opcao.outros,
            Number(opcao.id),
          );
        });

        const addPergunta =  new PerguntaPesquisaEntity(
          undefined,
          pergunta.id,
          undefined,
          pergunta.pergunta,
          pergunta.tipoResposta,
          opcoes,
          undefined,
          undefined,
          pergunta.categoria,
          ['ADICIONADA', 'BANCO DE DADOS'],
          pergunta.podeEditar,
          pergunta.podeRemover,
          false,
          !!pergunta.geraFiltro,
          !!pergunta.nomeFiltro ? pergunta.nomeFiltro : pergunta.pergunta,
          !!pergunta.grupo ? pergunta.grupo : ''
        );

        delete addPergunta.configEntityTable;

        perguntasAdd.push(addPergunta);
      });
    } else {

      const opcoes = <OpcaoPerguntaPesquisaEntity[]> perguntas.opcoes.map((opcao, indexOpcPergunta) => {

        return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
          undefined,
          opcao.opcao,
          opcao.outros,
          Number(opcao.id),
        );
      });

      const addPergunta = new PerguntaPesquisaEntity(
        undefined,
        perguntas.id,
        undefined,
        perguntas.pergunta,
        perguntas.tipoResposta,
        opcoes,
        undefined,
        undefined,
        perguntas.categoria,
        ['ADICIONADA', 'BANCO DE DADOS'],
        perguntas.podeEditar,
        perguntas.podeRemover,
        false,
        !!perguntas.geraFiltro,
        !!perguntas.nomeFiltro ? perguntas.nomeFiltro : perguntas.pergunta,
        !!perguntas.grupo ? perguntas.grupo : ''
      );

      delete addPergunta.configEntityTable;

      perguntasAdd.push(addPergunta);
    }

    return perguntasAdd;

    // listAddPerguntaBanco.map((perguntaPesquisa, indexInsert) => {
    //   this.entity.perguntaPesquisa.splice((data.index + indexInsert + 1), 0, perguntaPesquisa);
    // });
  }

  perguntaModelo_to_perguntaPesquisa(indexModelo: number, perguntaPesquisa: PerguntaPesquisaModeloEntity): PerguntaPesquisaEntity{

    const opcoes = <OpcaoPerguntaPesquisaEntity[]> perguntaPesquisa.pergunta.opcoes.map((opcao, index) => {

      return <OpcaoPerguntaPesquisaEntity> new OpcaoPerguntaPesquisaEntity(
        undefined,
        opcao.opcao,
        opcao.outros,
        !!opcao.id ? opcao.id : undefined
      );
    });

    return <PerguntaPesquisaEntity> new PerguntaPesquisaEntity(
      undefined,
      perguntaPesquisa.pergunta.id,
      perguntaPesquisa.id,
      perguntaPesquisa.pergunta.pergunta,
      perguntaPesquisa.pergunta.tipoResposta,
      !!opcoes ? opcoes : [],
      undefined,
      undefined,
      !!perguntaPesquisa.pergunta.categoria ? perguntaPesquisa.pergunta.categoria : '',
      ['ORIGINAL'],
      !!perguntaPesquisa.podeEditar,
      !!perguntaPesquisa.podeRemover,
      perguntaPesquisa.perguntaObrigatoria,
      perguntaPesquisa.pergunta.geraFiltro,
      !!perguntaPesquisa.pergunta.nomeFiltro ? perguntaPesquisa.pergunta.nomeFiltro : perguntaPesquisa.pergunta.pergunta,
      !!perguntaPesquisa.pergunta.grupo ? perguntaPesquisa.pergunta.grupo : ''
    );
  }

}
