import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UiModule } from 'src/app/components/ui/ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfiguracoesComponent } from './configuracoes-insert/configuracoes.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [ConfiguracoesComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        UiModule,
        MatSlideToggleModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
    ]
})
export class ConfiguracoesModule { }
