import { Injectable } from '@angular/core';
import {HttpService} from "../../../../services/http/http.service";
import {EmpresasService} from "../../../../pages/_cadastros/pages/empresas/services/empresas.service";
import {RestReturn} from "../../../../_core/config/rest-return";
import {
  HistoricoPesquisaEntity
} from "../../../../pages/_pesquisas/pages/historico-pesquisa/entity/historico-pesquisa-entity";
import {Observable} from "rxjs";
import {PerguntaPesquisaEntity} from "../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";

@Injectable({
  providedIn: 'root'
})
export class FiltroService {

  constructor(
    private http: HttpService,
    private serviceEmpresa: EmpresasService
  ) { }


  getEmpresas(){
    return this.serviceEmpresa.findAll();
  }

  filtropesquisa(empresa: string | number, tipoPesquisa: string | number): Observable<RestReturn<HistoricoPesquisaEntity[]>>{
    return this.http.get<RestReturn<HistoricoPesquisaEntity[]>>(
      `api/dashboard/filtropesquisa?empresaId=${empresa}&tipoPesquisaId=${tipoPesquisa}`
    );
  }

  filtropergunta(pesquisa: number | string, comparacao?: number | string): Observable<RestReturn<PerguntaPesquisaEntity[]>>{

    if (!!comparacao) {
      return this.http.get(
        `api/dashboard/filtropergunta?pesquisaAId=${pesquisa}&pesquisaBId=${comparacao}`
      )
    }

    return this.http.get(
      `api/dashboard/filtropergunta?pesquisaAId=${pesquisa}`
    )
  }

  filtroPerguntaComparacao(pesquisa: number | string, pesquisaComparacao: number | string): Observable<RestReturn<PerguntaPesquisaEntity[]>>{
    return this.http.get(
      `api/dashboard/filtropergunta?pesquisaAId=${pesquisa}&pesquisaBId=${pesquisaComparacao}`
    )
  }


}
