import { Injectable } from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {HistoricoPesquisaEntity} from "../../entity/historico-pesquisa-entity";
import {SessionService} from "../../../../../../services/session/session.service";
import {Router} from "@angular/router";
import {PesquisaModeloEntity} from "../../../../../_cadastros/pages/pesquisa-modelo/entity/pesquisa-modelo-entity";

@Injectable({
  providedIn: 'root'
})
export class ActionsTableHistoricoPesquisaService extends AbstractActionsTableService {

  constructor(
    public session: SessionService,
    public router: Router
  ) {
    super();
    this.fixedColumnActions = false;
  }

  list(): InterfaceCustomActions[] {
    return  [
      { name: 'Exibir pendentes', action: (pesquisa: HistoricoPesquisaEntity) => {

          window.dispatchEvent(new CustomEvent('open-pendentes-historico-pesquisa', { detail: pesquisa}));

        }, permission: (pesquisa: HistoricoPesquisaEntity) => {
          return (pesquisa.status === 'EM_ANDAMENTO' && !pesquisa.pesquisaAnonima)
        }},
      { name: 'Analisar', action: (pesquisa: any) => {
          this.router.navigate([`/pesquisa/analisar-pesquisa/${pesquisa.id}`])
        }, permission: (pesquisa: HistoricoPesquisaEntity) => {
          if (!this.session.checkPerfil('GESTORTROCA')) {
            return false;
          }
          return pesquisa.status === 'EM_ANALISE'
        }},
      { name: 'Ver pesquisa', action: this.verPesquisa },
      { name: 'Editar pesquisa', action: (pesquisa: any) => {
          this.router.navigate([`/pesquisa/editar-pesquisa/${pesquisa.id}`])
        }, permission: (pesquisa: HistoricoPesquisaEntity) => {
          return (pesquisa.status !== 'EM_ANDAMENTO' && pesquisa.status !== 'CONCLUIDA')
        }},
      { name: 'Excluir pesquisa', action: (pesquisa: HistoricoPesquisaEntity) => {
        window.dispatchEvent(new CustomEvent('excluir-historico-pesquisa', { detail: pesquisa}));
      }},
      { name: 'Enviar pesquisa', action: this.enviarPesquisa, permission: this.permissionEnviarPesquisa},
      { name: 'Alterar prazo', action: this.estenderPesquisa, permission: (pesquisa: HistoricoPesquisaEntity) => {
        return (
          pesquisa.status === 'EM_ANDAMENTO' ||
          pesquisa.status === 'EM_CONSTRUCAO' ||
          pesquisa.status === 'EM_ANALISE' ||
          pesquisa.status === 'APROVADA' ||
          pesquisa.status === 'REPROVADA'
        )
      }},
      { name: 'Dashboard', action: (pesquisa: HistoricoPesquisaEntity) => this.dashboard(pesquisa) },
      { name: 'Configurar score', action: (pesquisa: HistoricoPesquisaEntity) => {
          this.router.navigate([`/pesquisa/configurar-perguntas-score/${pesquisa.id}`])
      }, permission: (pesquisa: HistoricoPesquisaEntity) => {
          return this.session.checkPerfil('GESTORTROCA') && pesquisa.codigoTipoPesquisa != 1
        }},
      {
        name: 'Ver score',
        action: (pesquisa: HistoricoPesquisaEntity) => this.dashboard(pesquisa, true),
        permission: (pesquisa: HistoricoPesquisaEntity) => {
          return pesquisa.codigoTipoPesquisa != 1
        }
      }
    ]
  }

  enviarPesquisa(pesquisa: HistoricoPesquisaEntity) {
    window.dispatchEvent(new CustomEvent('open-modal-enviar-pesquisa-historico-pesquisa', { detail: pesquisa}));
  }

  permissionEnviarPesquisa(pesquisa: HistoricoPesquisaEntity) {
    return pesquisa.status === 'EM_ANDAMENTO';
  }

  verPesquisa(pesquisa: HistoricoPesquisaEntity) {
    if (pesquisa.pesquisaAnonima) {
      window.open(`/realizar/pesquisa/anonima/${pesquisa.identificador}`)

    } else {
      if (pesquisa.codigoTipoPesquisa == 1) {
        window.open(`/pesquisa/realizar/possuo-dados/${pesquisa.identificador}`)
      } else {
        window.open(`/realizar/pesquisa/${pesquisa.identificador}`)
      }
    }
  }

  dashboard(pesquisa: HistoricoPesquisaEntity, score = false) {

    this.session.setDachboardPesquisa({
      empresa: pesquisa.empresaId,
      tipoPesquisa: pesquisa.codigoTipoPesquisa,
      pesquisa: pesquisa.id
    });

    if (score) {
      this.router.navigate(['score']);
      return;
    }

    this.router.navigate(['/'])
  }

  estenderPesquisa(pesquisa: HistoricoPesquisaEntity) {
    window.dispatchEvent(new CustomEvent('estender-historico-pesquisa', { detail: pesquisa}));
  }
}
