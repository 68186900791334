import {PerguntasEntity} from "../entity/perguntas-entity";
import {getTipoResposta} from "../../../enum/tipo-resposta";
import {OpcaoRespostaPerguntaEntity} from "../../../entitys/opcao-resposta/opcao-resposta-pergunta-entity";


export function categorias(categoria: string) {
  return !!categoria ? categoria : '--';
}


export const tipoResposta = (celula: string, entit: PerguntasEntity) => {
  if (!!entit.opcoes) {
    return `
          <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="${viewOpcoesRespostaTable(entit.opcoes)}">
            ${getTipoResposta(celula)}
          </span>
        `;
  } else {
    return `
          <span class="d-inline-block" tabindex="0" data-toggle="tooltip">
            ${getTipoResposta(celula)}
          </span>
        `;
  }
}


function viewOpcoesRespostaTable(opcoes?: OpcaoRespostaPerguntaEntity[]) {
  if (!!opcoes){

    const titleTooltip = opcoes.map(valor => valor.opcao);
    //titleTooltip = titleTooltip.join(', ');
    return titleTooltip.join('\n');
  }

  return '';
}
