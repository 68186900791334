<div
  class="card dg-card card-resposta w-100"
>

  <div
    *ngIf="pergunta.perguntaObrigatoria"
    [class.obrigatoria-pendente]="!resposta.respostaOk && touched"
    class="card-header d-inline-flex justify-content-center"
  >
    <span>Pergunta obrigatória</span>
  </div>

  <div class="card-body">

    <card-pergunta-label-acossiacao-pergunta-predecessora
      *ngIf="disableCard && pergunta?.perguntaPesquisaPredecessora?.pergunta && pergunta?.opcaoPerguntaPesquisaPredecessora?.opcao"
      [pergunta]="pergunta?.perguntaPesquisaPredecessora?.pergunta"
      [opcao]="pergunta?.opcaoPerguntaPesquisaPredecessora?.opcao"
    >
    </card-pergunta-label-acossiacao-pergunta-predecessora>

    <div class="row">
      <p>{{pergunta.pergunta}}</p>
    </div>
    <div class="row">
      <div *ngIf="pergunta.tipoResposta === 'TEXT'" class="col-12">
        <mat-form-field style="width: 100%" appearance="fill">
          <input (focusout)="inputFocusout(pergunta)" matInput (ngModelChange)="verificarInput()" type="text" [(ngModel)]="resposta.resposta">
        </mat-form-field>
      </div>
      <div *ngIf="pergunta.tipoResposta === 'BIG_TEXT'" class="col-12">
        <mat-form-field class="w-100 mt-5" appearance="fill">
          <textarea (focusout)="inputFocusout(pergunta)" (ngModelChange)="verificarInput()" [(ngModel)]="resposta.resposta" matInput cdkTextareaAutosize placeholder="Parágrafo"></textarea>
        </mat-form-field>
      </div>
      <div *ngIf="pergunta.tipoResposta === 'NUMBER'" class="col-6">
        <mat-form-field style="width: 100%" appearance="fill">
          <input (focusout)="inputFocusout(pergunta)" matInput (ngModelChange)="verificarInput()" type="number" placeholder="1 ou 1.5" [(ngModel)]="resposta.resposta">
        </mat-form-field>
      </div>
    </div>

    <!-- RADIO -->
    <div
      *ngIf="(pergunta.tipoResposta === 'RADIOBUTTON' && !!pergunta.opcoes)"
      class="col-12"
    >
      <div
        class="pt-2 form-check"
        *ngFor="let opcao of pergunta.opcoes; let indexOpcao = index"
        [class.d-inline-flex]="!!opcao?.outros"
        [class.align-items-center]="!!opcao?.outros"
        [class.w-100]="!!opcao?.outros"
      >
        <input
          class="form-check-input purple "
          [value]="opcao.fakeId"
          (change)="changeRadio(opcao.fakeId)"
          type="radio"
          id="opcao-resposta-radio-{{index}}-{{indexOpcao}}"
          name="opcao-resposta-radio-{{index}}"
          [disabled]="disableCard"
        >
        <label
          class="form-check-label d-inline-flex w-100 align-items-center"
          for="opcao-resposta-radio-{{index}}-{{indexOpcao}}"
          style="position: relative"
        >
          <span *ngIf="!!opcao.outros" style="width: 10% !important; padding-left: 1.5%">outros:</span>
          <span *ngIf="!opcao.outros" style="width: 100% !important; padding-left: 1%">{{ opcao.opcao }}</span>
          <div *ngIf="!!opcao.outros" style="width: 90%;">
            <mat-form-field class="w-100" appearance="fill">
              <input type="text" [(ngModel)]="valueOutros" (ngModelChange)="outrosRadio($event, opcao.fakeId)" [disabled]="disableCard" matInput placeholder="">
            </mat-form-field>
          </div>
        </label>
      </div>
    </div>
    <!-- CHECKBOX -->
    <div
      *ngIf="(pergunta.tipoResposta === 'CHECKBOX' && !!pergunta.opcoes)"
      class="col-12"
    >
      <div
        *ngFor="let opcao of pergunta.opcoes; let indexOpcao = index"
        class="form-check pt-2"
        [class.d-inline-flex]="!!opcao.outros"
        [class.align-items-center]="!!opcao.outros"
        [class.w-100]="!!opcao.outros"
      >
        <input [value]="opcao.fakeId" [disabled]="disableCard" (change)="changeCheckbox(opcao.fakeId)" class="form-check-input purple md" type="checkbox" name="opcao-resposta-checkbox-{{index}}" id="opcao-resposta-checkbox-{{index}}-{{indexOpcao}}">
        <label
          class="form-check-label d-inline-flex w-100 align-items-center"
          for="opcao-resposta-checkbox-{{index}}-{{indexOpcao}}"
          style="position: relative"
        >
          <span *ngIf="!!opcao.outros" style="width: 10% !important; padding-left: 1.5%">outros:</span>
          <span *ngIf="!opcao.outros" style="width: 100% !important; padding-left: 1%">{{ opcao.opcao }}</span>
          <div *ngIf="!!opcao.outros" style="width: 90%;">
            <mat-form-field class="w-100" appearance="fill">
              <input type="text" [disabled]="disableOutrosCheckbox(opcao.fakeId) || disableCard" [(ngModel)]="valueOutros" (ngModelChange)="outrosCheckBox($event, opcao.fakeId)" matInput placeholder="">
            </mat-form-field>
          </div>
        </label>
        <!--        </div>-->
      </div>
    </div>
    <!-- COMBOBOX -->
    <div
      *ngIf="(pergunta.tipoResposta === 'COMBOBOX' && !!pergunta.opcoes)"
      class="col-12"
    >
      <select
        [(ngModel)]="valueSelect"
        (change)="changeSelect()"
        class="form-select form-select-lg mb-3"
        aria-label=".form-select-lg example"
      >
        <option value="">Selecione uma opção</option>
        <option
          *ngFor="let opcao of pergunta.opcoes; let indexOpcao = index"
          [value]="opcao.fakeId"
          [disabled]="disableCard"
        >
          {{ (opcao?.opcao) }}
        </option>
      </select>
    </div>
  </div>
  <div class="card-footer">
    <p class="color-purple f-bold">
      {{ (!!pergunta.categoria && pergunta.categoria[0]) ? pergunta.categoria[0].toUpperCase() + pergunta.categoria.substr(1) : '' }}
    </p>
  </div>
</div>

