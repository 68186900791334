export class ConfiguracoesEntity {
    constructor(
        id?: number,
        modeloCensoPadrao?: { id: number },
        modeloCensoPossuoDadosPadrao?: { id: number },
        modeloDiagnosticoPadrao?: { id: number },
        host?: string,
        port?: string,
        username?: string,
        password?: string,
        emailName?: string,
        enviarEmails?: boolean,
        termoDeUsoNome?: string,
        politicaDePrivacidadeNome?: string
    ) {
        this.id = id;
        this.modeloCensoPadrao = modeloCensoPadrao;
        this.modeloCensoPossuoDadosPadrao = modeloCensoPossuoDadosPadrao;
        this.modeloDiagnosticoPadrao = modeloDiagnosticoPadrao;
        this.host = host;
        this.port = port;
        this.username = username;
        this.password = password;
        this.emailName = emailName;
        this.enviarEmails = !!enviarEmails;
        this.termoDeUsoNome = termoDeUsoNome;
        this.politicaDePrivacidadeNome = politicaDePrivacidadeNome;
    }
    public id: number | undefined;
    public modeloCensoPadrao: { id: number } | undefined;
    public modeloCensoPossuoDadosPadrao: { id: number } | undefined;
    public modeloDiagnosticoPadrao: { id: number } | undefined
    public host: string | undefined;
    public port: string | undefined;
    public username: string | undefined;
    public password: string | undefined;
    public emailName: string | undefined
    public enviarEmails: boolean | undefined
    public termoDeUsoNome: string | undefined
    public politicaDePrivacidadeNome: string | undefined

}
