import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {
  OpcaoRespostaPerguntaEntity
} from "../../../../pages/_cadastros/entitys/opcao-resposta/opcao-resposta-pergunta-entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PerguntaPesquisaEntity} from "../../../../pages/_cadastros/pages/pesquisa/entity/pergunta-pesquisa-entity";
import {
  OpcaoPerguntaPesquisaEntity
} from "../../../../pages/_cadastros/entitys/opcao-resposta/opcao-pergunta-pesquisa-entity";
import Swal from "sweetalert2";

export interface retornoperguntaPesquisaPredecessora {
  perguntaPredecessora: PerguntaPesquisaEntity | undefined,
  opcaoRespostaPredecessora: OpcaoPerguntaPesquisaEntity | undefined,
  index: number
}

@Component({
  selector: 'modal-relacao-pergunta-pesquisa-predecessora',
  templateUrl: './modal-relacao-pergunta-pesquisa-predecessora.component.html',
  styleUrls: ['./modal-relacao-pergunta-pesquisa-predecessora.component.scss']
})
export class ModalRelacaoPerguntaPesquisaPredecessoraComponent implements OnInit {

  @Input('listPerguntas') listPerguntas: PerguntaPesquisaEntity[] = [];
  @ViewChild('modal') modal!: ModalComponent;
  @Output('salvar') salvar = new EventEmitter<retornoperguntaPesquisaPredecessora>()

  public edited: boolean = false;
  public changed: boolean = false;

  private entity: PerguntaPesquisaEntity | undefined | null;
  private index: number | null = null;

  public perguntas: PerguntaPesquisaEntity[] = [];
  public opcoes: OpcaoPerguntaPesquisaEntity[] = [];

  public form = new FormGroup({
    pergunta: new FormControl('', [Validators.required]),
    opcao: new FormControl('', [Validators.required])
  })

  constructor() {}

  ngOnInit(): void {}

  isChanged = () => this.changed = true;

  open(perguntaFilha: PerguntaPesquisaEntity, index: number) {
    if (!perguntaFilha) return
    if (index == null) return

    this.entity = perguntaFilha;
    this.index = index;

    this.perguntas = this.listPerguntas.filter(perg => {
      return perg.tipoResposta == 'COMBOBOX' ||
        perg.tipoResposta == 'RADIOBUTTON' ||
        perg.tipoResposta =='CHECKBOX'
    })

    console.log(perguntaFilha)

    if (perguntaFilha.perguntaPesquisaPredecessora && perguntaFilha.opcaoPerguntaPesquisaPredecessora) {
      this.form.patchValue({
        //@ts-ignore
        pergunta: perguntaFilha.perguntaPesquisaPredecessora?.fakeId,
        // @ts-ignore
        opcao: perguntaFilha.opcaoPerguntaPesquisaPredecessora?.id || perguntaFilha.opcaoPerguntaPesquisaPredecessora?.opcaoOriginalId
      })

      this.changePergunta();
      this.edited = true;
    }

    this.modal.open()

    this.form.controls['pergunta'].valueChanges.subscribe(() =>{
      this.form.patchValue({ opcao: '' })
      this.isChanged;
    });
    this.form.controls['opcao'].valueChanges.subscribe(this.isChanged);
  }

  changePergunta() {
    const perguntaId = this.form.value.pergunta;

    const index = this.perguntas.findIndex(perg => {
      return (!!perg.fakeId ? perg.fakeId : perg.perguntaModeloId || perg.perguntaOriginalId) == perguntaId
    })

    if (index < 0) return

    this.opcoes = this.perguntas[index].opcoes;
  }

  verifySalvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    const form = this.form.value;

    const indexPergunta = this.perguntas.findIndex(perg => (perg.fakeId || perg.perguntaModeloId || perg.perguntaOriginalId) == form.pergunta)
    const indexOpcao = this.opcoes.findIndex(opcao => (!!opcao.fakeId ? opcao.fakeId : opcao.opcaoOriginalId) == form.opcao)

    if (indexPergunta >= 0 && indexOpcao >= 0) {
      this.salvar.emit({
        perguntaPredecessora: this.perguntas[indexPergunta],
        opcaoRespostaPredecessora: this.opcoes[indexOpcao],
        index: typeof this.index == 'number' ? this.index : -1
      })
      this.modal.close()
    }
  }

  desfazerRelacao() {
    Swal.fire({
      icon: 'warning',
      title: 'Desfazer relação com pergunta predecessora?',
      confirmButtonText: 'Desfazer relação',
      cancelButtonText: 'Sair',
      showCancelButton: true
    }).then(conf => {
      if (conf.isConfirmed) {
        this.salvar.emit({
          perguntaPredecessora: undefined,
          opcaoRespostaPredecessora: undefined,
          index: typeof this.index == 'number' ? this.index : -1
        })
        this.modal.close()
      }
    })
  }

  close() {
    this.modal.close();
  }

  _close = () => {
    this.form.reset({
      pergunta: '',
      opcao: ''
    })
    this.entity = null;
    this.changed = false;
    this.edited = false;
  }

  disablePergunta(pergunta: PerguntaPesquisaEntity): boolean {
    if (!this.entity) return true;

    if (pergunta.status.findIndex(status => status == 'REMOVIDA') != -1) return true;

    if (this.entity?.fakeId == pergunta.fakeId) return true

    return this.isPredecessor(pergunta);
  }

  isPredecessor(pergunta: PerguntaPesquisaEntity):boolean {
    const index = (typeof this.index == 'number' ? this.index : -1)
    if (index < 0) return false

    return pergunta.perguntaPesquisaPredecessora?.fakeId == this.listPerguntas[index]?.fakeId;
  }
}
