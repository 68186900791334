import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {HistoricoPesquisaService} from "../service/historico-pesquisa.service";
import {
  InterfaceColumns,
  InterfaceCustomActions
} from "../../../../../components/ui/dg-table/data-table/data-table.component";
import {EmpresasEntity} from "../../../../_cadastros/pages/empresas/entity/empresas-entity";
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {ModalComponent} from "../../../../../components/ui/modals/modal/modal.component";
import {HistoricoPesquisaEntity} from "../entity/historico-pesquisa-entity";
import {RestReturn} from "../../../../../_core/config/rest-return";
import {HttpService} from "../../../../../services/http/http.service";
import {SessionService} from "../../../../../services/session/session.service";
import {CrudDataTableComponent} from '@datagrupo/dg-crud';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";

@Component({
  selector: 'app-historico-pesquisa-main',
  templateUrl: './historico-pesquisa-main.component.html',
  styleUrls: ['./historico-pesquisa-main.component.scss']
})
export class HistoricoPesquisaMainComponent implements OnInit {
  public mapEntity: HistoricoPesquisaEntity = new HistoricoPesquisaEntity();

  public listaPendentes: string[] = [];
  public listaEmailEnviar: string[] = [];
  public formAddEmailEnviar = new FormGroup({
    email: new FormControl('', [Validators.required])
  });

  public formAlterarPrazo = new FormGroup({
    id: new FormControl('', [Validators.required]),
    dataFim: new FormControl('', [Validators.required])
  })

  // pesquisa que está sendo manusiada no momento
  public currentPesquisa: HistoricoPesquisaEntity | undefined;
  public linkPesquisa = '';

  public inputsAlteraPrazo = {
    id: '',
    dataFim: ''
  };

  @ViewChild('tableCrud') table!: CrudDataTableComponent;
  @ViewChild('modal') modalPendentes!: ModalComponent;
  @ViewChild('modalExcluir') modalExcluir!: ModalComponent;
  @ViewChild('modalEnviar') modalEnviar!: ModalComponent;
  @ViewChild('swallEnviaPesquisa') swall!: SwalComponent;
  @ViewChild('swallEstenderPesquisa') swallEstender!: SwalComponent;

  constructor(
    public service: HistoricoPesquisaService,
    private router: Router,
    private http: HttpService,
    public session: SessionService,
    public readonly swalTargets: SwalPortalTargets
  ) {
    if (this.mapEntity.setConfigTable) {
      this.mapEntity.setConfigTable({
        sort: 'dataCriacao,desc',
        sortField: 'dataCriacao',
        sortDirection: 'desc'
      }, undefined, session.checkPerfil('GESTORTROCA') ? undefined : ['pesquisaModeloNome']);
    }
    const empresa = <EmpresasEntity> new EmpresasEntity();
    empresa.nomeFantasia = "nome Empresa";
  }

  ngOnInit(): void {}

  msgSuccessEnvio(pesquisa?: HistoricoPesquisaEntity) {

    if (!!this.currentPesquisa) {
      this.linkPesquisa = window.location.origin + (
        this.currentPesquisa.pesquisaAnonima ? '/realizar/pesquisa/anonima/' : '/realizar/pesquisa/'
      ) + (!!this.currentPesquisa.identificador ? String(this.currentPesquisa.identificador) : String(this.currentPesquisa.id));
    }

    this.swall.fire();

    // Swal.fire({
    //   icon: 'success',
    //   title: 'A pesquisa foi enviada com sucesso.',
    //   confirmButtonText: 'Copiar link',
    //   cancelButtonText: 'ok',
    //   showCancelButton: true,
    //   confirmButtonColor: '#EC536C',
    //   cancelButtonColor: '#EC536C',
    //   text: window.location.origin + (
    //     pesquisa.pesquisaAnonima ? '/realizar/pesquisa/anonima/' : '/realizar/pesquisa/'
    //   ) + (!!pesquisa.identificador ? String(pesquisa.identificador) : String(pesquisa.id))
    // }).then(confirm => {
    //   if (confirm.isConfirmed) {
    //
    //     const url = window.location.origin + (
    //       pesquisa.pesquisaAnonima ? '/realizar/pesquisa/anonima/' : '/realizar/pesquisa/'
    //     ) + pesquisa.id;
    //
    //     navigator.clipboard.writeText(url);
    //   }
    // })
  }

  closeMsgSuccessEnvio(){
    this.currentPesquisa = undefined;
    this.linkPesquisa = '';
    this.swall.close();
  }

  copiarLink() {
    setTimeout(() => {
      const inputElement = document.getElementById("inputLink") as HTMLInputElement
      inputElement.select();

      document.execCommand("copy");
    }, 100)
  }

  // FUNÇÕES DE ENVIAR PESQUISA

  @HostListener('window:open-pendentes-historico-pesquisa', ['$event'])
  opemModal(pesquisaEvent: { detail: HistoricoPesquisaEntity }) {
    const pesquisa = pesquisaEvent.detail;
    this.modalPendentes.titleSpan = pesquisa.nomeFantasiaEmpresa;
    this.modalPendentes.data = pesquisa;

    this.http.getById<RestReturn<string[]>>('api/pesquisa/respondentespendentes', String(pesquisa.id)).subscribe(
      resp => {
        if (resp.data) {
          this.listaPendentes = <string[]> resp.data;
          this.modalPendentes.open();
        }
      }
    )
  }

  enviarLembretePendentes(data: HistoricoPesquisaEntity){
    Swal.fire({
      title:'Deseja enviar um e-mail para lembrar às pessoas que ainda não responderam?',
      cancelButtonText: 'Cancelar',
      confirmButtonText: "Sim, enviar",
      showCancelButton: true,
      confirmButtonColor: '#EC536C',
      cancelButtonColor: '#EC536C'
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.service.enviarPendentes(String(data.id)).subscribe(
          resp => {
            this.currentPesquisa = data;
            this.msgSuccessEnvio(data);
            this.modalPendentes.close();
          }
        )
      }
    })
  }


  // FUNÇÕES DE ENVIAR PESQUISA

  @HostListener('window:open-modal-enviar-pesquisa-historico-pesquisa', ['$event'])
  openModalEnviarPesquisaPesquisa(event: CustomEvent) {
    const pesquisa = event.detail;
    this.listaEmailEnviar = [];

    this.service.buscarEmailEnviarPesquisa(pesquisa.id).subscribe(
      resp => {
        this.modalEnviar.titleSpan = pesquisa.nomeFantasiaEmpresa;
        this.modalEnviar.data = pesquisa;
        this.formAddEmailEnviar.controls['email'].reset('');

        if (resp.data === '') {
          this.listaEmailEnviar = [];
        } else {
          const emails = resp.data.split(';');

          emails.map((email: string) => {
            if (email.trim() !== '') {
              this.listaEmailEnviar.push(email)
            }
          })

          // this.listaEmailEnviar = resp.data.split(';');
        }

        this.modalEnviar.open()
        console.log(resp);
      }
    )
  }

  keypressAddEmail(ev: KeyboardEvent){
    if (ev.key === 'Enter') {
      this.addEmail();
    }
  }

  addEmail(){
    if (!this.formAddEmailEnviar.valid) {
      this.formAddEmailEnviar.markAllAsTouched();
      return;
    }
    if (this.formAddEmailEnviar.value.email.trim() === '') {
      return;
    }
    let email = this.formAddEmailEnviar.value.email.trim();
    email = email.split(',').join(';');
    email = email.split(' ').join(';');

    email.split(';').map((singleEmail: string, index: number) => {
      this.listaEmailEnviar.push(singleEmail);
    })

    this.formAddEmailEnviar.controls['email'].reset('');
  }

  verificaDisableBtnEnviarPesquisa(): boolean {
    return this.formAddEmailEnviar.value.email?.trim() !== '';
  }

  removeEmailEnviar(email: string) {
    const index = this.listaEmailEnviar.findIndex(listEmail => listEmail === email)

    if (index !== -1) {
      this.listaEmailEnviar.splice(index, 1)
    }
  }

  enviarPesquisa(pesquisa: HistoricoPesquisaEntity, modal: ModalComponent) {

    if (!!pesquisa.id) {
      this.service.enviarEmailEnviarPesquisa({id: pesquisa.id, listaEmails: this.listaEmailEnviar.join(';')}).subscribe(
        resp => {
          this.currentPesquisa = pesquisa;
          this.msgSuccessEnvio(pesquisa);
          this.modalEnviar.close();
        }
      )
    }

    modal.close();
  }

  // FUNÇÕES DE EXCLUIR PESQUISA

  @HostListener('window:excluir-historico-pesquisa', ['$event'])
  openModalExcluirPesquisa(event: CustomEvent) {
    const pesquisa = event.detail;

    this.modalExcluir.titleSpan = pesquisa.nomeFantasiaEmpresa;
    this.modalExcluir.data = pesquisa;
    this.modalExcluir.open();
  }

  excluirPesquisa(data: HistoricoPesquisaEntity) {
    this.modalExcluir.close();
    this.service.delete(data.id)
      .subscribe(
        resp => {
          if (resp.status) {
            this.table.pesquisar();
            Swal.fire({
              icon: 'success',
              title: 'Excluído com sucesso!',
              text: 'Este registro foi deletado.',
              showConfirmButton: false,
              timer: 1500
            }).then()
          } else {
            Swal.fire(
              'Erro',
              'Não foi possível remover esse registro.',
              'error'
            )
          }
        },
        () => {
          Swal.fire(
            'Erro',
            'Não foi possível remover esse registro.',
            'error'
          )

        });
  }

  // FUNÇÕES DE ESTENDER PESQUISA

  @HostListener('window:estender-historico-pesquisa', ['$event'])
  estenderPesquisa(event: CustomEvent) {
    const pesquisa = event.detail;
    this.formAlterarPrazo.reset();

    const convertDate = (date: any) => {
      const d = date.split(' ')[0].split('/');

      return `${d[2]}-${d[1]}-${d[0]}T${date.split(' ')[1]}`;

      // return new Date(newDate);
    }

    this.formAlterarPrazo.patchValue({
      id: pesquisa.id,
      dataFim: convertDate(pesquisa.dataFim)
    });


    console.log(new Date('2/28/2022 09:17'));
    console.log(convertDate(pesquisa.dataFim));
    // return;

    this.swallEstender.fire();
  }

  enviarNovaDataPesquisa() {
    if (!this.formAlterarPrazo.valid) {
      this.formAlterarPrazo.markAllAsTouched();
      return;
    }

    this.service.alterarPrazo(this.formAlterarPrazo.value).subscribe(
      resp => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event'));
        Swal.fire({
          icon: "success",
          title: "Prazo alterado com sucesso!"
        });
      }
    )
  }
}
